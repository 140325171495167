// export const DateCell = (formatDate:)=> ({ cell }: any) => formatDate(cell.getValue(), format, i18n.language)
import FormattedDate from 'framework/components/FormattedDate'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
// eslint-disable-next-line camelcase
import { MRT_Header, MRT_TableInstance } from 'material-react-table/src'
import { styled } from '@mui/material'
import { resolvePathRecursive } from 'framework/lib/resolvePathRecursive'
import { DateTime } from 'luxon'
import { DatePicker } from '@codeflix/mui-managed-form'

export function DateTimeCell({ cell, ...rest }: any) {
  return (
    <FormattedDate
      noTypography
      date={cell.getValue()}
      format={cell.column.columnDef.datetimeFormat || 'DATETIME'}
    />
  )
}

interface IRangeProps<T extends Record<string, any>> {
  // eslint-disable-next-line camelcase
  header: MRT_Header<T>;
  rangeFilterIndex?: number;
  // eslint-disable-next-line camelcase
  table: MRT_TableInstance<T>;
}

const StyledDiv = styled('div')(({ theme }) => ({
  minWidth: '8rem',
}))
const mapUpdateValue = (rangeFilterIndex: number | undefined, value: any, newValue: any) => {
  if (typeof rangeFilterIndex === 'undefined') {
    return newValue
  }
  const newValueArr = value ? [...value] : []
  newValueArr[rangeFilterIndex] = newValue
  return newValueArr
}

export function DateTimeFilter<T extends Record<string, any>>({
                                                                header,
                                                                rangeFilterIndex,
                                                                table,
                                                                ...rest
                                                              }: IRangeProps<T>) {
  const { t } = useTranslation('table')
  // const index = header.column.getFilterIndex()
  const value = header.column.getFilterValue() as any
  // console.log('filter', 'val', header.column.id, value)
  const curValue = useMemo(() => {
    const displayValue: DateTime | Date | undefined | string = (typeof rangeFilterIndex !== 'undefined' ? value?.[rangeFilterIndex] : value)
    if (DateTime.isDateTime(displayValue)) return displayValue.toISO()
    if (displayValue instanceof Date) {
      // check valid
      return (displayValue as Date).toISOString()
    }
    return undefined
  }, [rangeFilterIndex, value])
  const handleChange = useCallback((newValue: string) => {
    // new Date(newValue)
    try {
      let mappedNewValue: Date|undefined = new Date(newValue)
      if (mappedNewValue.toString() === 'Invalid Date') {
          mappedNewValue = undefined
      }
      // const mappedNewValue = new Date(newValue)
      // console.log('filter', 'newvalue', newValue)
      header.column.setFilterValue(mapUpdateValue(rangeFilterIndex, value, mappedNewValue))
    } catch (e) {
      console.error(e)
    }
  }, [rangeFilterIndex, value, header.column])

  return (
    <StyledDiv>
      <DatePicker
        t={t}
        value={curValue}
        valid
        setValid={() => {}}
        onChange={handleChange}
        name=""
        label=""
        textFieldProps={{
          variant: 'standard',
        }}
      />
    </StyledDiv>
  )
}

export const DateAccessorFn = (prop: string | string[]) => (row: any) => {
  const resolved = resolvePathRecursive(row, prop)
  // console.log('resolved', prop, row?.id, resolved)
  return resolved ? new Date(resolved.split('T')[0]) : ''
}

export const DateCellProps = <T extends Record<string, any>>(prop: string | string[]) => ({
  Cell: DateTimeCell,
  muiTableHeadCellFilterTextFieldProps: {
    type: 'date',
  },
  datetimeFormat: 'DATE',
  sortingFn: 'datetime',
  // filterFn: 'greaterThanOrEqualTo',
  filterFn: 'betweenInclusive',
  // filterVariant: 'text',
  Filter: DateTimeFilter<T>,
  accessorFn: DateAccessorFn(prop),
})
