import { useTranslation } from 'react-i18next'
import { Typography, TypographyProps } from '@mui/material'
import { useMemo } from 'react'

export type DateFormat =
  | 'DATE'
  | 'DATETIME'
  | 'TIME'
  | 'SHORTDATE'
  | 'DATETIMEWITHDAY';

export interface IFormattedDateProps {
  date: string | Date
  format: DateFormat
  noTypography?: boolean
}
export function formatDate(
  date: string | Date | null,
  format: DateFormat,
  lng: string,
) {
  if (!date) {
    return ''
  }
  let dateStyle: 'short' | 'medium' | 'full' | 'long' | undefined
  let timeStyle: 'short' | 'medium' | 'full' | 'long' | undefined
  let weekday: 'short' | 'long' | undefined
  let hour: '2-digit' | undefined
  let minute: '2-digit' | undefined
  let day: '2-digit' | undefined
  let month: '2-digit' | undefined
  let year: 'numeric' | undefined
  const parsedDate = typeof date === 'string' ? new Date(date) : date
  switch (format) {
    case 'SHORTDATE':
      dateStyle = 'short'
      break
    case 'DATE':
      dateStyle = 'medium'
      break
    case 'DATETIME':
      dateStyle = 'medium'
      timeStyle = 'short'
      break
    case 'DATETIMEWITHDAY':
      weekday = 'short'
      day = '2-digit'
      month = '2-digit'
      year = 'numeric'
      hour = '2-digit'
      minute = '2-digit'
      break
    case 'TIME':
      timeStyle = 'short'
      break
    default:
      dateStyle = 'short'
      break
    // new Intl.DateTimeFormat('de',{dateStyle: "medium" , timeStyle: 'short'}).format(new Date("2021-05-01"))
  }
  if (dateStyle || timeStyle) {
    return new Intl.DateTimeFormat(lng, { dateStyle, timeStyle }).format(
      parsedDate,
    )
  }
  return new Intl.DateTimeFormat(lng, {
    day,
    month,
    year,
    hour,
    minute,
    weekday,
  }).format(parsedDate)
}
export function useFormatDate() {
  const { i18n } = useTranslation('datetime')
  return useMemo(() => (date: string | Date | null, format: DateFormat) => formatDate(date, format, i18n.language), [i18n.language])
}

export function useFormattedDate(
  date: string | Date | null,
  format: DateFormat,
) {
  const { i18n } = useTranslation('datetime')
  return useMemo(() => formatDate(date, format, i18n.language), [date, format, i18n.language])
}

export default function FormattedDate(
  props: IFormattedDateProps & TypographyProps,
) {
  const { date, format, noTypography, ...rest } = props
  // i18n.language
  const formattedDate = useFormattedDate(date, format)
  if (noTypography) {
    return <>{formattedDate}</>
  }
  return <Typography {...rest}>{formattedDate}</Typography>
}
