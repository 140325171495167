import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useLocation, useParams } from 'react-router'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'
import { ILinkStack } from 'framework/components/BreadCrumb/StyledBreadCrumps'
import { useManagedForm, ValidatedForm } from '@codeflix/mui-managed-form'

export function BreadCrumbYearSelector({ year }: { year: string }) {
  const navigate = useNavigate()
  const location = useLocation()
  // const path2 = useCurrentPath()
  const { t } = useTranslation('generic')
  const { values, formManagerProps } = useManagedForm({ year })
  useEffect(() => {
    const newPath = location.pathname.replace(year, values.year)
    if (values.year !== year) navigate(newPath)
  }, [values.year])
  return (
    <ValidatedForm
      {...formManagerProps}
      elements={[
        {
          type: 'year',
          variant: 'standard',
          name: 'year',
          textFieldProps: {
            variant: 'standard',
            size: 'small',
            label: undefined,
            // fullWidth: false,
          },
          // label: '',
          // label: ' ',
          minDate: DateTime.fromISO('2022-01-01'), // deploy date
          maxDate: DateTime.now(),
        },
      ]}
      t={t}
    />
  )
}

export const useArchiveLinkStack = (name: string|undefined, mode: RestViewMode = RestViewMode.Default, enableYearSelect:boolean = true, nested:number = 1) => {
  const { t } = useTranslation('application')
  const { year } = useParams() as { year: string }
  const nestedPath = useMemo(() => {
    let path = ''
    for (let i = 0; i < nested; i += 1) {
      if (i > 0) path += '/'
      path += '..'
    }
    return path
  }, [nested])
  return useMemo((): ILinkStack[] | undefined => {
    if (mode === RestViewMode.Default) return undefined
    return [
      { to: nestedPath, text: t('archiveView', { name }) },
      {
        text: year,
        ...(enableYearSelect ? ({
          component: (<BreadCrumbYearSelector year={year} />),
        }) : {
          to: nestedPath,
        }),
      },
    ]
  }, [t, mode, name, year, enableYearSelect])
}
export const useArchiveLinkStackTranslated = (mode: RestViewMode, name: string, enableYearSelect:boolean = true, nested:number = 1) => {
  const { t } = useTranslation('application')
  return useArchiveLinkStack(t(name).toString(), mode, enableYearSelect, nested)
}

export const useArchiveLinkStackOrProvided = (name: string|undefined, linkStack: ILinkStack[]|undefined, mode: RestViewMode = RestViewMode.Default) => {
  const archiveLinkStack = useArchiveLinkStack(name, mode)

  return useMemo(() => linkStack || archiveLinkStack, [linkStack, archiveLinkStack])
}
