import { useTranslation } from 'react-i18next'
import React, { MouseEvent, useCallback, useMemo } from 'react'
import { actions, hooks, useSiteMeasuringById } from 'features/siteMeasurings'
import { useParams } from 'react-router'
import EditView from 'framework/components/BaseRestViews/EditView'
import { IScaffoldInquiryModelAggregated, ScaffoldState } from 'api/models/scaffoldModel'
import { SiteMeasuringAgentPhase, SiteMeasuringModel, SiteMeasuringState } from 'api/models/siteMeasuringModel'
import { useScaffoldInquiryById } from 'features/scaffoldInquiries'
import { actions as siteMeasuringInspectionsActions } from 'features/siteMeasuringInspections'
import ScaffoldInquiryViewer from 'ui/views/SiteMeasurings/ScaffoldInquiryViewer'
import { Divider, Grid, Stack, useMediaQuery } from '@mui/material'
import BreadCrumb from 'framework/components/BreadCrumb'
import { Theme } from '@mui/system'
import { useSiteMeasuringSiteSaveViewOptions } from 'ui/views/SiteMeasurings/helper'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'app/hooks'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'
import { useArchiveLinkStackTranslated } from 'framework/components/BaseRestViews/BreadCrumbYearSelector'
import SiteMeasuringAccountedAction from 'ui/views/SiteMeasurings/EditActions/SiteMeasuringAccountedAction'
import SiteMeasuringPdfExport from 'ui/views/SiteMeasurings/EditActions/SiteMeasuringExport'
import AsyncActionButton from 'framework/components/AsyncActionButton'
import SiteMeasuringCancelAction from 'ui/views/SiteMeasurings/EditActions/SiteMeasuringCancelAction'
import SiteMeasuringGdkComment from 'ui/views/SiteMeasurings/components/SiteMeasuringGdkComment'

const grid = { xs: 12, sm: 6, md: 3 }
const itemGrid = { xs: 12, sm: 6, md: 3 }
const itemGridDouble = { xs: 12, sm: 12, md: 6 }
const smallGrid = { xs: 6, sm: 3, md: 2 }

const fullGrid = { xs: 12, md: 12 }
const ScaffoldStates = Object.keys(ScaffoldState)

const columnGrid = { direction: 'column', xs: 12, sm: 4 }

function Editor({ item, mode }: { item?: SiteMeasuringModel, mode: RestViewMode }) {
  const { t } = useTranslation('siteMeasuring')
  const inquiry = useScaffoldInquiryById(item?.scaffoldInquiryId) as IScaffoldInquiryModelAggregated

  const customer = inquiry?.customer
  const handleSubmit = useCallback((mode: 'submit' | 'draft') => (submitValues: any) => {
    const additionalPayload: any = {}
    switch (submitValues.agentPhase) {
      case SiteMeasuringAgentPhase.SCAFFOLDER:
        if (mode === 'submit') additionalPayload.state = SiteMeasuringState.SIGNED_BY_SCAFFOLDER
        break
      case SiteMeasuringAgentPhase.GDK:
        if (mode === 'submit') additionalPayload.state = SiteMeasuringState.FULLY_SIGNED
        break
      default:
        break
    }
    if (submitValues.agentPhase === SiteMeasuringAgentPhase.GDK) {
      return {
        agentPhase: SiteMeasuringAgentPhase.GDK,
        siteMeasuringId: submitValues.id,
        comment: submitValues.gdkComment,
        accepted: submitValues.acceptedByGdk,
      }
    }
    console.log('submit form', submitValues, additionalPayload)
    return {
      ...submitValues,
      ...additionalPayload,
    }
  }, [])
  const {
    saveViewOptions,
    isDisabled,
    isGDK,
    disableScaffolderInput,
    showGdkValues,
  } = useSiteMeasuringSiteSaveViewOptions(item)
  const navigate = useNavigate()
  const appDispatch = useAppDispatch()
  const handleCreateSiteMeasuringInspectation = useCallback(async (event: MouseEvent<HTMLButtonElement>) => {
    // todo api request
    event.preventDefault()
    event.stopPropagation()
    // await SiteMeasuringInspectionsRestModel.createItem(item!).then((result) => {
    const result = await appDispatch(siteMeasuringInspectionsActions.create(item))
    if (result) {
      const id = typeof result === 'string' ? result : result.id
      navigate(`/siteMeasuringInspection/${id}`)
    }
  }, [item, navigate, appDispatch])
  const elements = useMemo(() => ([
    {
      // disabled: item?.agentPhase !== ScaffoldAgentPhase.SCAFFOLDER,
      // type: (elementInScaffolderPhaseVisible(item, canSeeMoreThanClient) ? 'object' : 'hidden'),
      disableAll: disableScaffolderInput || isDisabled,
      type: 'object',
      name: 'scaffold',
      label: t('scaffoldDetails'),
      variant: 'borderbox',
      required: true,
      grid: { xs: 12 },
      elements: [
        {
          type: 'float',
          name: 'length',
          label: t('scaffoldLength'),
          required: true,
          grid: { xs: 12, md: 6, lg: 3 },
          unit: 'm',

        },
        {
          type: 'float',
          name: 'height',
          label: t('scaffoldHeight'),
          required: true,
          grid: { xs: 12, md: 6, lg: 3 },
          unit: 'm',

        },
        {
          type: 'float',
          name: 'depth',
          unit: 'm',

          label: t('scaffoldDepth'),
          required: true,
          grid: { xs: 12, md: 6, lg: 2 },
        },
        {
          type: 'number',
          name: 'stageCount',
          required: true,
          grid: { xs: 12, md: 6, lg: 2 },
        },
        {
          type: 'float',
          name: 'footPointHeight',
          allowNegative: true,
          unit: 'm',
          required: true,
          grid: { xs: 12, md: 6, lg: 2 },
        },
      ],
    },
    {
      type: 'object',
      variant: 'tab',
      name: 'siteMeasuring',
      required: true,
      disableAll: disableScaffolderInput || isDisabled,
      grid: { xs: 12, md: 12 },
      elements: item?.customerSiteMeasuringTabs?.map((tab) => (
        {
          type: 'object',
          name: tab.id,
          label: tab.name,
          variant: 'none',
          elements: tab.groups.map((group) => (
            {
              type: 'object',
              name: group.id,
              label: group.name || ' ',
              variant: 'borderbox',
              elements: group.fields.map((field) => ({
                type: field.type.toLowerCase(),
                name: field.id,
                tooltip: field.fullName,
                label: field.shortName,
                unit: field.unit,
                grid: field.type === 'MULTILINE' ? itemGridDouble : itemGrid,
              })),
            }
          )),
        }
      )),
    },

    {
      type: showGdkValues ? 'radio' : 'hidden',
      name: 'acceptedByGdk',
      disabled: !isGDK,
      required: item?.state === SiteMeasuringState.SIGNED_BY_SCAFFOLDER,
      grid: { xs: 12, sm: 6, md: 4 },
      options: [
        { value: true, label: t('accepted') },
        { value: false, label: <p dangerouslySetInnerHTML={{ __html: t('declined') }} /> },
      ],
    },
    {
      type: (element:any, value:any) => (showGdkValues && value.acceptedByGdk === false ? 'text' : 'hidden'),
      // type: 'text',
      name: 'gdkComment',
      disabled: !isGDK,
      // required: item?.state === SiteMeasuringState.SIGNED_BY_SCAFFOLDER,
      grid: { xs: 12, md: 6 },
      multiline: true,
      minRows: 4,
      conditionalProps: (element:any, value:any) => ({ required: value.acceptedByGdk === false }),
    },
    // {
    //   type: showGdkValues && isGDK ? 'button' : 'hidden',
    //   name: 'createSiteMeasuringInspectation',
    //   variant: 'contained',
    //   onClick: handleCreateSiteMeasuringInspectation,
    //   grid: { xs: 12, sm: 6, md: 4, xl: 2 },
    // },
  ]), [disableScaffolderInput, isDisabled, item?.customerSiteMeasuringTabs, item?.state, showGdkValues, isGDK, t, handleCreateSiteMeasuringInspectation])
  const baseLinkStack = useArchiveLinkStackTranslated(mode, 'constructions', false)
  const linkStack = useMemo(() => ([
    ...(baseLinkStack || [{ to: '..', text: t('application:constructions') }]), // { to: '..', text: t('application:constructions') },
    { text: item?.isNew ? t('application:new') : t('scaffoldInquiryNr', { serial: item?.scaffoldInquiry?.serialNumber?.fullSerial }) },
  ]), [t, item])
  const big = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <BreadCrumb
          color="black"
          linkStack={linkStack}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <Stack spacing={2}>
          {item?.scaffoldInquiry && <ScaffoldInquiryViewer item={item.scaffoldInquiry} />}
          {item && <SiteMeasuringGdkComment item={item} />}
          {showGdkValues && isGDK && <AsyncActionButton variant="contained" onClick={handleCreateSiteMeasuringInspectation}>{t('createSiteMeasuringInspectation')}</AsyncActionButton>}
          {item && item.state === SiteMeasuringState.FULLY_SIGNED
            && (
              <>
                <SiteMeasuringPdfExport item={item} />
                <SiteMeasuringAccountedAction item={item} />
              </>
            )}
          <SiteMeasuringCancelAction item={item} />
        </Stack>
      </Grid>
      <Grid item xs={1}><Divider orientation={big ? 'vertical' : 'horizontal'} /></Grid>
      <Grid item xs={12} lg={8}>
        <EditView
          loading={!item}
          // linkStack={linkStack}
          viewMode={mode}
          elements={elements}
          actions={actions}
          item={item}
          disableAll={isDisabled === true ? true : undefined}
          t={t}
          debug
          saveView={{
            ...saveViewOptions,
            // saveGrid: { xs: 12, sm: 6, md: 4 },
          }}
          middlewares={{
            onSubmit: handleSubmit('submit'),
            onDraft: handleSubmit('draft'),
          }}
        />
      </Grid>

    </Grid>
  )
}

export function Edit() {
  const { siteMeasuringId } = useParams() as { siteMeasuringId: string }
  const item = useSiteMeasuringById(siteMeasuringId)
  return (
    <Editor item={item} mode={RestViewMode.Default} />
  )
}

export function ViewArchive() {
  const { siteMeasuringId, year } = useParams() as { siteMeasuringId: string, year: string }
  const item = hooks.useArchiveDataById(year, siteMeasuringId)
  return (
    <Editor item={item} mode={RestViewMode.Archive} />
  )
}
