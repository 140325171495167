import { useTranslation } from 'react-i18next'
import { useCallback, useMemo, useRef } from 'react'
import { createScaffoldCertificate, hooks, useCurrentScaffolds } from 'features/scaffoldInquiries'
import OverviewView from 'framework/components/BaseRestViews/OverviewView'
import { Stack } from '@mui/material'
import { ScaffoldAgentPhase, ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { useOverviewData } from 'ui/views/ScaffoldInquiries/OverviewHelper'
// eslint-disable-next-line camelcase
import { MRT_TableInstance, useMaterialReactTable } from 'material-react-table'
import AsyncActionButton from 'framework/components/AsyncActionButton'
import CurrentActions from 'ui/views/ScaffoldInquiries/OverviewActions/CurrentActions'
import { useDefaultButtonEventHandlerDispatchParameterAction } from 'framework/lib/useDispatchAction'
import { useParams } from 'react-router'
import { InquiriesMode } from 'ui/views/ScaffoldInquiries/InquiriesMode'
import { useScaffoldMetaFromMode } from 'ui/views/ScaffoldInquiries/helper'
import LoadingCircle from 'framework/components/DataLoader/LoadingCircle'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'
import { useMyCompanyType } from 'features/companies'
import { CompanyType } from 'api/models/companyType'
import { SiteMeasuringState } from 'api/models/siteMeasuringModel'
import OptionalTooltip from 'framework/components/HelperComponents/OptionalTooltip'
import { currentScaffoldTableConfig } from 'ui/views/ScaffoldInquiries/Table/currentScaffoldTableConfig'
import { ScaffoldCertificateAction } from 'ui/views/ScaffoldInquiries/EditActions/ScaffoldCertificateAction'
import ButtonLink from '../../../framework/components/ButtonLink'

function ScaffoldInquiries({ data, mode }: { data: ScaffoldInquiryModel[], mode: InquiriesMode }) {
  const { t } = useTranslation('scaffolds')
  const { columns, scaffolderPermission, gdkPermission, downloadCertificatePermission } = useOverviewData(data)
  const myCompanyType = useMyCompanyType()

  // eslint-disable-next-line camelcase
  // const tableInstanceRef = useRef<MRT_TableInstance<ScaffoldInquiryModel> | null>(null)
  const createScaffoldCertificateHandler = useDefaultButtonEventHandlerDispatchParameterAction(createScaffoldCertificate)

  const renderActions = useCallback(({ table }:any) => (
    (table?.getIsSomeRowsSelected() || table?.getIsAllRowsSelected())
      ? (
        <CurrentActions t={t} table={table} />
      ) : <div />
  ), [t])

  const { name, linkStack } = useScaffoldMetaFromMode(mode, t)

  // @ts-ignore
  return (
    <OverviewView<ScaffoldInquiryModel>
      linkStack={linkStack}
      hideNew
      data={data || []}
      viewMode={mode === 'Archive' ? RestViewMode.Archive : RestViewMode.Default}
      name={t(`application:${name}`).toString()}
      columns={columns as any}
      enableRowActions
      enableRowSelection={(row) => (row.original as ScaffoldInquiryModel)?.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED}
      renderSelectionActions={renderActions}
      // muiTableBodyCellProps={({ row }) => ({
      //   sx: {
      //     // fontWeight: row.original.adHocOrder ? 500 : undefined,
      //   },
      // })}
      {...currentScaffoldTableConfig(myCompanyType)}
      renderRowActions={({ table, row }) => (
        <Stack direction="row" spacing={1}>

          {downloadCertificatePermission && (
            <ScaffoldCertificateAction
              item={row.original}
              disabled={(row.original as ScaffoldInquiryModel)?.agentPhase !== ScaffoldAgentPhase.FULLY_SIGNED}
              // onClick={createScaffoldCertificateHandler(row.original, false)}
              mode="button"
              t={t}
            />
          )}

          {scaffolderPermission && (
            <OptionalTooltip tooltip={(row.original as ScaffoldInquiryModel).constructionDate ? undefined : t('constructionDateRequiredForSiteMeasuring')}>
              <ButtonLink
                disabled={(row.original as ScaffoldInquiryModel)?.agentPhase !== ScaffoldAgentPhase.FULLY_SIGNED || !(row.original as ScaffoldInquiryModel).constructionDate}
                to={`/siteMeasuring/${(row.original as ScaffoldInquiryModel).siteMeasuringId || 'new'}?from=${(row.original as ScaffoldInquiryModel).id}`}
              >{t('createConstructionRequest')}
              </ButtonLink>
            </OptionalTooltip>

          )}
        </Stack>
      )}
    />
  )
}

export function ScaffoldInquiriesCurrent() {
  const data = useCurrentScaffolds()
  return <ScaffoldInquiries data={data} mode="Current" />
}

export function ScaffoldInquiriesArchive() {
  const { year } = useParams() as { year: string }
  const data = hooks.useArchiveData(year)
  const filtered = useMemo(() => data?.filter((x:ScaffoldInquiryModel) => x.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED) || [], [data])

  if (!data) return <LoadingCircle />
  return <ScaffoldInquiries data={filtered} mode="Archive" />
}
