import { ClickAwayListener, Hidden, Paper, Popover, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import styled2 from '@emotion/styled'
import Grid2 from '@mui/material/Unstable_Grid2'
import { useAnchorElementOpenState, useOpenState } from '@codeflix/mui-managed-form'
import { yncoris } from 'theme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DashboardTablePortal } from 'ui/views/Dashboard/components/DashboardTablePortal'
import { PropsWithChildren } from 'react'
import { ISingleOpenedState } from 'ui/views/Dashboard/lib/useSingleOpenState'

interface ICountMetricsProps extends ISingleOpenedState {
  value: number
  title: string,
  href?: string
}

const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 'normal',
}))
const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '3rem',
}))
const StyledChevronRight = styled(ExpandMoreIcon)<{ open: boolean }>(({
                                                                        theme,
                                                                        open,
                                                                      }) => ({
  height: 48,
  width: 48,
  transform: open ? 'rotate(90deg)' : 'rotate(-90deg)',
  [theme.breakpoints.down('xl')]: {
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  },
  transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

}))
const StyledPaper = styled(Paper)<{open: boolean}>(({ theme, open }) => ({
  padding: theme.spacing(2),
  cursor: 'pointer',
  width: '100%',
  // height: '100%',
  '&:hover': {
    backgroundColor: yncoris.lightgrey,
  },
  ...(open && {
    backgroundColor: yncoris.lightgrey,
  }),
}))
const RelativeDiv = styled2.div`
  position: relative;
`
const PulledRight = styled2.div`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%)
`

const shapeSize = {
  number: 10,
  unit: 'px',
}
const RightShape = styled2.div`
  position: absolute;
  right: -38px;
  top: 50%;
  width: 0;
  height: 0;
  color: red;
  
  transform: translateY(calc(-50% - ${shapeSize.number / 4}${shapeSize.unit}));
  border-top: ${shapeSize.number}${shapeSize.unit} solid transparent; /* skyblue */
  border-right: ${shapeSize.number}${shapeSize.unit} solid transparent; /* limegreen;  */
  border-bottom: ${shapeSize.number}${shapeSize.unit} solid transparent; /* orange; */
  border-left: ${shapeSize.number}${shapeSize.unit} solid ${yncoris.lightgrey}; 
`

export default function CountMetrics({ value, title, children, open, handleClose, toggle }: PropsWithChildren<ICountMetricsProps>) {
  // const {
  //   open,
  //   toggle,
  //   handleClose,
  // } = useOpenState(false)
  // const { open, handleClick,handleClose,anchorElement } =  useAnchorElementOpenState()
  return (
    <>
      <StyledPaper open={open} role="button" elevation={6} onClick={children ? toggle : undefined}>
        <RelativeDiv>
          <div>
            <StyledTitleTypography textAlign="center">{title}</StyledTitleTypography>
            <StyledTypography textAlign="center">{value}</StyledTypography>
          </div>
          {children && (
            <PulledRight>
              <StyledChevronRight open={open} />
            </PulledRight>
          )}
          {
            children && open && <Hidden xlDown><RightShape /></Hidden>
          }
        </RelativeDiv>

      </StyledPaper>
      {open && (
        <DashboardTablePortal >{children}</DashboardTablePortal>
      )}
    </>

    // </OptionalLink>
  )
}
