/* eslint-disable camelcase */

import {
  MaterialReactTableIntermediateProps,
} from 'framework/components/Table/types/materialReactTableIntermediateProps'
import {
  getAllLeafColumnDefs, getColumnId,
  MRT_ColumnDef,
  MRT_DisplayColumnIds,
  MRT_RowData,
  MRT_StatefulTableOptions,
  showRowExpandColumn,
} from 'material-react-table'
import { useMemo } from 'react'

export const showRowActionsColumn = <TData extends MRT_RowData>(
  tableOptions: MaterialReactTableIntermediateProps<TData>,
) => {
  const {
    createDisplayMode,
    editDisplayMode,
    enableEditing,
    enableRowActions,
    // state: { creatingRow },
  } = tableOptions
  return (
    enableRowActions
    || (enableEditing && ['modal', 'row'].includes(editDisplayMode ?? ''))
  )
}
const getPositionalRows = <TData extends MRT_RowData>(props:MaterialReactTableIntermediateProps<TData>, position:'first'|'last') => ([
  // (props.enableRowDragging || props.enableRowOrdering) && 'mrt-row-drag',
  (props.positionActionsColumn === position && showRowActionsColumn(props) && 'mrt-row-actions'),
  props.positionExpandColumn === position
  // @ts-ignore
  && showRowExpandColumn(props)
  && 'mrt-row-expand',
  props.enableRowSelection && position === 'first' && 'mrt-row-select',
  props.enableRowNumbers && position === 'first' && 'mrt-row-numbers',
].filter(Boolean) as MRT_DisplayColumnIds[]
)

const rows = {
  first: [
    'mrt-row-drag',
  ],
  last: [

  ],
}
export const useDefaultColumnOrder = <TData extends MRT_RowData>(props:MaterialReactTableIntermediateProps<TData>) => useMemo(() => {
    const leadingDisplayCols = getPositionalRows(props, 'first')
    const trailingDisplayCols = getPositionalRows(props, 'last')
    const allLeafColumns = getAllLeafColumnDefs(props.columns)
      .map((columnDef) => getColumnId(columnDef))
      .filter(
        (columnId) => !leadingDisplayCols.includes(columnId as any)
          && !trailingDisplayCols.includes(columnId as any),
      )
    return [...leadingDisplayCols, ...allLeafColumns, ...trailingDisplayCols]
  }, [props])
