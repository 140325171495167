import { BaseModel, NamedBaseModel } from 'api/baseModel'
import { useMemo } from 'react'
import { roundToNextPrecision, sortByProp } from 'lib/modelHelper'

export const groupData = <T = any>(data:T[], groupKey: keyof T) => {
  const dataReduced: {[key: string]: T[]} = data.reduce((acc:{[key: string]: T[]}, cur) => ({
    ...acc,
    [cur[groupKey] as string]: [...(acc[cur[groupKey] as string] || []), cur],
  }), {})
  return dataReduced
}

export const useGroupedDataForPlotly = <T extends BaseModel>(data:T[], lookup: NamedBaseModel[], groupKey: keyof T) => useMemo(() => {
  const dataReduced = groupData(data, groupKey)
  const labels: string[] = []
  const values: number[] = []
  const keys = Object.keys(dataReduced)
    .map((x) => ({ key: x, name: lookup.find((model) => model.id === x)?.name || ' ' }))
    .sort(sortByProp('name'))
  keys.forEach((key) => {
    labels.push(key.name)
    values.push(dataReduced[key.key].length)
  })
  console.log('labels', labels)
  return {
    labels, // Object.keys(dataReduced).map((x) => lookup.find((model) => model.id === x)?.name || ' '),
    values, // Object.values(dataReduced).map((x) => x.length),
    // type: 'pie',
  }
}, [data, lookup, groupKey])

export function round(value: number, decimals: number = 2) {
  const factor = 10 ** decimals
  const roundedValue = Math.round(value * factor) / factor
  return roundedValue
}
export const sumNumbers = (values: number[]) => values.reduce((acc, cur) => acc + cur, 0)
export const averageNumbers = (values: number[]) => round(sumNumbers(values) / values.length, 2)
