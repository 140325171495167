import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

const StyledPaper = styled(Paper)((
  {
    theme,
  },
) => ({
  padding: theme.spacing(3),
  paddingTop: 0,
}))

function Error() {
  const { t } = useTranslation('error')
  const goToStartPage = () => {
    window.location.reload()
  }
  return (
    <div>
      <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <Typography align="center" variant="h1">
            {t('title')}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <StyledPaper>
            <br />
            <Typography align="center">

              {t('description')}
            </Typography>
            <br />
            <Typography align="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={goToStartPage}
              >
                {t('reloadPage')}
              </Button>
            </Typography>
          </StyledPaper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Error
