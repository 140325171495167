import { RestModel } from 'app/RestModel'
import { api } from 'app/apiClient'
import { store } from 'app/store'
import { saveToken } from '../features/auth'
import { fetchData } from '../features/user'
import { UserModel } from './models/UserModel'

const login = (email: string, password: string) => api.post('login', { json: { email, password } })
const requestResetPassword = (email: string) => api.post('login/request-reset-password', { json: { email } })
const resetPassword = (email: string, password: string, token:string) => api.post('login/reset-password', { json: { email, password, token } })

class UserRestModel extends RestModel<UserModel> {
  path = 'login'

  login = login

  requestResetPassword = requestResetPassword

  resetPassword = resetPassword

  async activateAccount(email: string, password: string, token:string) {
    const result = api.post(`${this.path}/activate-account`, { json: { email, password, token } })
    return result
  }

  // eslint-disable-next-line class-methods-use-this
  async changePassword(password:string, newPassword:string) {
    const result = api.post('user/changepassword', { json: { password, newPassword } })
    return result
  }

  async requestAccount(values:{firstName: string, lastName: string, email: string, password: string, phoneNumber:string}) {
    const result = api.post(`${this.path}/request-account`, { json: values })
    return result
  }

  async confirmEmail(values:{email: string, token:string}) {
    const result = api.post(`${this.path}/confirm-email`, { json: values })
    return result
  }
}

export default new UserRestModel()
