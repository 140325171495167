import FullScreenDialog from 'framework/components/ManagedForm/Fields/FullscreenDialog'
import { useOpenState,
  OptionalBadge,
} from '@codeflix/mui-managed-form'
import { ICellAddress, IFormManagerProps } from '@codeflix/mui-spreadsheet'
import { Badge, BadgeProps, IconButton, ThemeProvider } from '@mui/material'
import { AttachFile } from '@mui/icons-material'
import { LazyAttachmentOverview } from 'ui/views/ScaffoldInquiries/Attachments/AttachmentOverview'
import { ScaffoldDraftModel } from 'api/models/scaffoldDraftModel'
import theme, { yncoris } from 'theme'
import { useScaffoldInquiryAttachments } from 'features/scaffoldInquiriesAttachments'
import { numberOrFallback } from 'lib/numberHelper'
import { styled } from '@mui/system'
import { useCallback, useMemo } from 'react'

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    // right: -3,
    top: 5,
    border: `2px solid ${yncoris.petrol}`,
    // padding: '0 4px',
  },
}))
export default function AttachmentPopup({ state, scaffoldInquiryId, value, cellAddress, disabled } : {state: IFormManagerProps<ScaffoldDraftModel>, scaffoldInquiryId: string, value: ScaffoldDraftModel, cellAddress: ICellAddress, disabled:boolean}) {
  const { open, handleClose, handleOpen } = useOpenState()
  // const data = useScaffoldInquiryAttachments(scaffoldInquiryId)
  const handleChange = useCallback((count: number) => () => {
    state.updatePath({
      path: [cellAddress.row, 'attachmentCount'],
      value: numberOrFallback(value.attachmentCount, 0) + count,
    }, false)
  }, [cellAddress.row, state, value.attachmentCount])
  const Dialog = useMemo(() => open && (
    <ThemeProvider theme={theme}>
      <FullScreenDialog open={open} title={`${cellAddress.row + 1} - ${value?.description}`} onClose={handleClose}>
        <LazyAttachmentOverview mode="massEditor" scaffoldInquiryId={scaffoldInquiryId} onAdd={handleChange(1)} onDelete={handleChange(-1)} />
      </FullScreenDialog>
    </ThemeProvider>
  ), [cellAddress.row, handleChange, handleClose, open, scaffoldInquiryId, value?.description])
  return (
    <>

      <IconButton disabled={disabled} onClick={handleOpen}><StyledBadge variant="dot" color="primary" badgeContent={numberOrFallback(value.attachmentCount, 0)}><AttachFile /></StyledBadge></IconButton>
      {Dialog}

    </>

  )
}
