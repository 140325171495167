import { AppThunk } from 'app/store'
import { useAppDispatch } from 'app/hooks'
import { useCallback } from 'react'
import { useDefaultButtonEventHandler, useDefaultMenuItemEventHandler } from 'framework/lib/useDefaultEventHandler'

export const useDispatchAction = (action: AppThunk) => {
  const appDispatch = useAppDispatch()
  return useCallback(() => appDispatch(action), [appDispatch, action])
}
export const useDispatchParameterAction = (action: (...randomParameters:any)=>AppThunk) => {
  const appDispatch = useAppDispatch()
  return useCallback((...params:any) => appDispatch(action(...params)), [appDispatch, action])
}
export const useDefaultButtonEventHandlerDispatchParameterAction = (action: (...randomParameters:any)=>AppThunk) => {
  const actionDispatch = useDispatchParameterAction(action)
  const handler = useDefaultButtonEventHandler(actionDispatch)
  return handler
}

export const useDefaultMenuItemEventHandlerDispatchParameterAction = (action: (...randomParameters:any)=>AppThunk) => {
  const actionDispatch = useDispatchParameterAction(action)
  const handler = useDefaultMenuItemEventHandler(actionDispatch)
  return handler
}
