/* eslint-disable camelcase  */
/* eslint-disable react/jsx-pascal-case */
import {
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_DensityState,
  MRT_PaginationState,
  MRT_RowData,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableOptions,
  MRT_VisibilityState,
} from 'material-react-table'
import {
  MaterialReactTableIntermediateProps,
} from 'framework/components/Table/types/materialReactTableIntermediateProps'
import { useEffect, useMemo, useState } from 'react'
import { useLocalStorage } from 'framework/lib/useBrowserStorage'
import { useDefaultColumnOrder } from 'framework/components/Table/helper/columnOrder'

import { defaultPage } from 'framework/components/Table/config'

export function useTableState<TData extends MRT_RowData>(props: MaterialReactTableIntermediateProps<TData>, pathname: string) {
  const {
    initialState,
    name,
    columns,
    state,
    data,
  } = props
  const key = useMemo(
    () => `${pathname}_${name}_${columns.map((x) => x.id || x.accessorKey as string)
      .join('|')}_`,
    [columns, name, pathname],
  )

  const [blockUpdates, setBlockedUpdate] = useState(true)
  useEffect(() => {
    // block updates for column sorting on global filter
    setBlockedUpdate(true)
    setTimeout(() => setBlockedUpdate(false), 100)
  }, [data])

  const [columnFilters, setColumnFilters] = useLocalStorage<MRT_ColumnFiltersState>(`columnFilters${key}`, initialState?.columnFilters || [], blockUpdates)
  const [columnVisibility, setColumnVisibility] = useLocalStorage<MRT_VisibilityState>(
    `columnVisibility${key}`,
    initialState?.columnVisibility || {},
    blockUpdates,
  )
  const [density, setDensity] = useLocalStorage<MRT_DensityState>(`Density${key}`, initialState?.density || 'comfortable', blockUpdates)
  const [globalFilter, setGlobalFilter] = useLocalStorage<string | undefined>(
    `globalFilter${key}`,
    initialState?.globalFilter || undefined,
    blockUpdates,
  )
  const [showGlobalFilter, setShowGlobalFilter] = useLocalStorage(`ShowGlobalFilter${key}`, initialState?.showGlobalFilter || false, blockUpdates)
  const [showColumnFilters, setShowColumnFilters] = useLocalStorage(`ShowColumnFilters${key}`, initialState?.showColumnFilters || false, blockUpdates)
  const defaultColumnOrder = useDefaultColumnOrder(props)

  const [columnOrder, setColumnOrder] = useLocalStorage<MRT_ColumnOrderState>(`columnOrder${key}`, initialState?.columnOrder || defaultColumnOrder, blockUpdates)
  const [rowSelection, setRowSelection] = useLocalStorage<MRT_RowSelectionState>(`RowSelection${key}`, initialState?.rowSelection || {}, blockUpdates)
  const [sorting, setSorting] = useLocalStorage<MRT_SortingState>(`sorting${key}`, initialState?.sorting || [], blockUpdates)
  const [pagination, setPagination] = useLocalStorage<MRT_PaginationState>(`pagination${key}`, defaultPage, blockUpdates)

  return useMemo(() => ({
    state: {
      ...state,
      columnOrder,
      rowSelection,
      sorting,
      pagination,
      columnFilters,
      columnVisibility,
      density,
      globalFilter,
      showColumnFilters,
      showGlobalFilter,
    },
    autoResetAll: false,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    onColumnOrderChange: setColumnOrder,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection, // connect internal row selection state to your own
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onDensityChange: setDensity,
    onGlobalFilterChange: setGlobalFilter,
    onShowColumnFiltersChange: setShowColumnFilters,
    onShowGlobalFilterChange: setShowGlobalFilter,
  } as Partial<MRT_TableOptions<TData>> & {
    data: TData[]
  }), [data, columnFilters, columnOrder, columnVisibility, density, globalFilter, pagination, rowSelection, setColumnFilters, setColumnOrder, setColumnVisibility, setDensity, setGlobalFilter, setPagination, setRowSelection, setShowColumnFilters, setShowGlobalFilter, setSorting, showColumnFilters, showGlobalFilter, sorting, state])
}
