import {
  MaterialReactTableIntermediateProps,
} from 'framework/components/Table/types/materialReactTableIntermediateProps'
import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { CompanyType } from 'api/models/companyType'

export const currentScaffoldTableConfig: (myCompanyType?: CompanyType)=> Partial<MaterialReactTableIntermediateProps<ScaffoldInquiryModel>> = (myCompanyType?: CompanyType) => ({
  // muiTableBodyCellProps: ({ row }) => ({
  //   sx: {
  //     fontWeight: row.original.adHocOrder ? 500 : undefined,
  //   },
  // }),
  initialState: {
    sorting: [
      {
        id: 'createdOn',
        desc: true,
      },
    ],
    columnVisibility: {
      id: false,
      constructionDate: true,
      deconstructionDate: true,
      preferredConstructionDate: false,
      preferredDeconstructionDate: myCompanyType !== CompanyType.SCAFFOLDER,
      agentPhase: false,
      canceledToDate: myCompanyType === CompanyType.SCAFFOLDER,
      rentFreeInDays: false,
      projectId: false,
      buildingId: false,
      orderedByUserId: false,
      area: false,
      orderNr: false,
      type: false,
      individualScaffoldOrderFields: false,
    },
  },
  positionActionsColumn: 'last',
})
