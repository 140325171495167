import { useCurrentUser } from 'features/user'
import { useMemo } from 'react'
import { UserModel } from 'api/models/UserModel'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import { IPermissionRequirement } from 'framework/auth/IPermissionRequirement'

const checkPermission = (userPermissions: string[], permission: string) => userPermissions.includes(permission)

const getPermissionRequirementFulfilled = (userPermissions: string[], requirement: IPermissionRequirement):boolean => {
  const granted = requirement.permissions?.map((permission) => {
    switch (typeof (permission)) {
      case 'string':
        return checkPermission(userPermissions, permission)
      case 'object':
        return getPermissionRequirementFulfilled(userPermissions, permission)
      default: return false // should not happen, should really not happen
    }
  })
  switch (requirement.operation) {
    case PermissionOperation.AND:
      return !granted.includes(false) // at least one not granted
    case PermissionOperation.OR:
      return granted.includes(true) // at least one granted
    case PermissionOperation.NOT:
      return !granted.includes(true)
    default:
      return false // should not happen!!!
  }
}

export const getPermissionRequirementFulfilledByUser = (user: UserModel|undefined, permissionRequirement: IPermissionRequirement|undefined) => {
  if (!user) return false
  if (!permissionRequirement) return true
  return getPermissionRequirementFulfilled(user.permissions, permissionRequirement)
}
export const userHasAllPermissions = (user: UserModel|undefined, permissions: string[]) => getPermissionRequirementFulfilledByUser(user, {
    operation: PermissionOperation.AND,
    permissions,
  })
export const useUserHasPermissions = (permissionRequirement?: IPermissionRequirement) => {
  const user = useCurrentUser()
  return useMemo(() => getPermissionRequirementFulfilledByUser(user, permissionRequirement), [permissionRequirement, user])
}
export const useUserHasAllPermissions = (permissions: string[]) => {
  const user = useCurrentUser()
  return useMemo(() => userHasAllPermissions(user, permissions), [permissions, user])
}

export type useUserPermissionCheckFunction = (permissionRequirement: IPermissionRequirement) => boolean
export const useUserPermissionCheck = ():useUserPermissionCheckFunction => {
  const user = useCurrentUser()
  return useMemo(() => (permissionRequirement: IPermissionRequirement) => getPermissionRequirementFulfilledByUser(user, permissionRequirement), [user])
}
