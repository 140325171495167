import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

export interface IOpenState{
  open: boolean,
  handleOpen: ()=>void,
  handleClose: ()=>void,
  toggle: ()=>void
}
export function useOpenState(initial: boolean = false, closeOnPathChange = false):IOpenState {
  const [open, setOpen] = useState(initial)
  const location = useLocation()
  const { pathname } = location

  const handleOpen = useCallback(() => setOpen(true), [setOpen])
  const handleClose = useCallback(() => setOpen(false), [setOpen])
  const toggle = useCallback(() => setOpen((state) => !state), [setOpen])
  useEffect(() => {
    if (closeOnPathChange) {
      handleClose()
    }
  }, [closeOnPathChange, handleClose, pathname])
  return useMemo(() => ({ open, handleOpen, handleClose, toggle }), [open, handleOpen, handleClose, toggle])
}

export function useAnchorElementOpenState(initial: HTMLElement|undefined = undefined, closeOnPathChange = false) {
  const [anchorElement, setAnchorElement] = useState<HTMLElement|undefined>(initial)
  const location = useLocation()
  const { pathname } = location
  const handleClick = useCallback((event:MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }, [setAnchorElement])
  // const handleOpen = useCallback((anchorElement:HTMLElement) => setAnchorElement(anchorElement), [setAnchorElement])
  const handleClose = useCallback(() => setAnchorElement(undefined), [setAnchorElement])
  useEffect(() => {
    if (closeOnPathChange) {
      handleClose()
    }
  }, [closeOnPathChange, handleClose, pathname])
  return useMemo(() => ({
    anchorElement,
    open: Boolean(anchorElement),
    handleClick,
    handleClose,
  }), [anchorElement, handleClick, handleClose])
}
