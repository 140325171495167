// eslint-disable camelcase
import { useTranslation } from 'react-i18next'
import { useCustomers } from 'features/customers'
import { useScaffolders } from 'features/scaffolders'
import { useOperationUnits } from 'features/operationUnits'
import { useBuildings } from 'features/buildings'
import { useMemo } from 'react'
import { LookupAccessorFn } from 'framework/components/Table/helper/lookupAccessorFn'
import { CustomerModel } from 'api/models/customerModel'
import { ScaffolderModel } from 'api/models/scaffolderModel'
import { BuildingModel } from 'api/models/buildingModel'
import { DateCellProps } from 'framework/components/Table/Cells/DateTimeCell'
import { IMappedData } from 'ui/views/SiteMeasurings/models/IMappedData'
import { CheckboxCellProps } from 'framework/components/Table/Cells/CheckboxCell'
import { enumToTableTranslatedValues } from 'lib/enumHelper'
import { SiteMeasuringAgentPhase, SiteMeasuringModel, SiteMeasuringState } from 'api/models/siteMeasuringModel'
// eslint-disable-next-line camelcase
import { MRT_ColumnDef } from 'material-react-table'

export function useSiteMeasuringColumns() {
  const { t } = useTranslation('siteMeasuring')
  const matchingCustomer = useCustomers()
  const matchingScaffolder = useScaffolders()
  const operationUnits = useOperationUnits()
  const buildings = useBuildings()
  const { t: siteMeasuringStateT } = useTranslation('siteMeasuringState')
  const { t: siteMeasuringAgentPhaseT } = useTranslation('siteMeasuringAgentPhase')
  const columns = useMemo(() => [
    {
      header: t('generic:id'),
      accessorKey: 'id',
      filterFn: 'includesString',
    },
    {
      header: t('scaffoldInquirySerial'),
      accessorKey: 'scaffoldInquiry.serialNumber.fullSerial',
      // accessorFn: (row) => row.scaffoldInquiry?.serialNumber?.fullSerial || '',
      id: 'serialNr',
      filterFn: 'includesString',
    },
    {
      header: t('application:customer'),
      accessorFn: LookupAccessorFn<CustomerModel>(matchingCustomer, ['scaffoldInquiry', 'customer'], 'customer', undefined, 'name'),
      filterFn: 'includesString',
    },
    {
      id: 'scaffolder',
      header: t('application:scaffolder'),
      accessorFn: LookupAccessorFn<ScaffolderModel>(matchingScaffolder, undefined, 'scaffolder', undefined, 'name'),
      filterFn: 'includesString',
    },
    {
      id: 'building',
      header: t('application:building'),
      accessorFn: LookupAccessorFn<BuildingModel>(buildings, ['scaffoldInquiry', 'building'], 'building', undefined, 'name'),
      filterFn: 'includesString',
    },
    {
      id: 'area',
      header: t('scaffolds:area'),
      accessorKey: 'scaffoldInquiry.area',
      filterFn: 'includesString',
    },
    {
      id: 'orderNr',
      header: t('scaffolds:orderNr'),
      accessorKey: 'scaffoldInquiry.orderNr',
      filterFn: 'includesString',
    },
    {
      header: t('scaffolds:constructionDate'),
      // id: 'constructionDate',
      ...DateCellProps<IMappedData>(['scaffoldInquiry', 'constructionDate']),
    },
    {
      header: t('scaffolds:deconstructionDate'),
      // id: 'deconstructionDate',
      ...DateCellProps<IMappedData>(['scaffoldInquiry', 'deconstructionDate']),
    },
    {
      header: t('ok'),
      // accessorKey: 'acceptedByGdk',
      accessorFn: (row: any) => row?.acceptedByGdk === true,
      ...CheckboxCellProps(),
    },
    {
      header: t('notOk'),
      id: 'acceptedByGdk2',
      accessorFn: (row: any) => row?.acceptedByGdk === false,
      ...CheckboxCellProps(),
    },
    {
      header: t('accounted'),
      accessorKey: 'isAccounted',
      ...CheckboxCellProps(),
    },
    {
      header: t('state'),
      accessorKey: 'state',
      // Cell: ({ cell }: any) => (t(`siteMeasuringState:${cell.getValue()}`)),
      accessorFn: (row: any) => ((row.isCancelled) ? t('siteMeasuringState:CANCELLED')
        .toString() : t(`siteMeasuringState:${row.state}`)
        .toString()),
      filterFn: 'arrIncludesSome',
      filterVariant: 'multi-select',
      filterSelectOptions: enumToTableTranslatedValues(siteMeasuringStateT, SiteMeasuringState),
    },
    {
      header: t('agentPhase'),
      accessorKey: 'agentPhase',
      accessorFn: (row: any) => t(`siteMeasuringAgentPhase:${row.agentPhase}`)
        .toString(),
      // Cell: TranslatedCell(t, 'siteMeasuringState'), // ({ cell }: any) => (t(`siteMeasuringState:${cell.getValue()}`)),
      filterFn: 'arrIncludesSome',
      filterVariant: 'multi-select',
      filterSelectOptions: enumToTableTranslatedValues(siteMeasuringAgentPhaseT, SiteMeasuringAgentPhase),
    },
    // eslint-disable-next-line camelcase
  ] as MRT_ColumnDef<SiteMeasuringModel>[], [buildings, matchingCustomer, matchingScaffolder, siteMeasuringAgentPhaseT, siteMeasuringStateT, t])
  return columns
}
