import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import OverviewView from 'framework/components/BaseRestViews/OverviewView'
import { useCustomerByPath } from 'features/customers'
import { useParams } from 'react-router'
import { useProjectByCustomerId } from 'features/projects'
import { CheckboxCellProps } from 'framework/components/Table/Cells/CheckboxCell'
// eslint-disable-next-line camelcase
import { MRT_ColumnDef } from 'material-react-table'
import { ProjectModel } from 'api/models/projectModel'

export default function Overview() {
  const { customerId } = useParams<{customerId: string}>()
  const customer = useCustomerByPath()
  const data = useProjectByCustomerId(customerId || '')
  const { t } = useTranslation('customers')
  // eslint-disable-next-line camelcase
  const columns:MRT_ColumnDef<ProjectModel>[] = useMemo(() => [
    {
      header: t('generic:id').toString(),
      accessorKey: 'id',
      enableHiding: true,
      filterFn: 'includesString',
    },
    {
      header: t('name').toString(),
      accessorKey: 'name',
      filterFn: 'includesString',
    },
    {
      header: t('generic:archived').toString(),
      accessorKey: 'archived',
      // accessorFn: (row:any) => row?.archived === true,
      ...CheckboxCellProps(),
    },
  ], [t])
  return (
    <OverviewView<ProjectModel>
      data={data || []}
      columns={columns}
      name={t('application:projects').toString()}
      linkStack={[
        { to: '/administration/customers', text: t('application:customers') },
        { to: `/administration/customers/${customer?.id}`, text: `${customer?.name}` },
        { to: `/administration/customers/${customer?.id}/projects`, text: t('application:projects') },
      ]}
    />
  )
}
