import { Divider, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import items from 'ui/navItems'
import { isAuthorized } from 'features/auth'
import { MouseEvent } from 'react'
import { PropsWithClassName } from 'framework/models/PropsWithClassName'
import { useUserPermissionCheck } from 'framework/auth/permissions'
import { NavItem } from './NavItem'
import { INavItemProps } from './NavItemStyleComponents'
import { useCurrentUser } from '../../../features/user'

interface INavigationProps {
  mobile?: boolean
  onClick: (evt: MouseEvent<HTMLButtonElement>) => void
}
function NavItemWithDivider({ mobile, index, isSignedIn, ...rest }:INavItemProps & {
  index:number,
}) {
  return (
    <>
      {mobile && index > 0 && <Divider />}
      <NavItem mobile={mobile} isSignedIn={isSignedIn} {...rest} />
    </>
  )
}
export default function Navigation({ mobile, className, onClick }: PropsWithClassName<INavigationProps>) {
  const { t } = useTranslation('nav')
  const isSignedIn = useSelector(isAuthorized)
  const permissions = useCurrentUser()?.permissions || []
  const permissionCheck = useUserPermissionCheck()
  const direction = mobile ? 'column' : 'row'
  return (
    <Stack spacing={0} className={className} direction={direction} alignContent="center" alignItems="centers" justifyContent="center" >
      {
        items.map((x, index) => (
          <NavItemWithDivider
            index={index}
            mobile={mobile}
            key={x.key}
            navItem={x}
            isSignedIn={isSignedIn}
            onClick={onClick}
            permissionCheck={permissionCheck}
            // permissions={permissions}
            t={t}
          />
        ))
      }
    </Stack>
  )
}
