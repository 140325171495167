import { useAppDispatch } from 'app/hooks'
import { useOpenState } from 'lib/stateHelper'
import { MouseEvent, useCallback, useState } from 'react'
import { cloneScaffolds, convertScaffolds } from 'features/scaffoldInquiries'
import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import Dialog2 from 'framework/components/Dialog2'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { ArrowRight } from 'mdi-material-ui'
import { AsyncActionMenuitem } from 'framework/components/AsyncActionButton'
import { styled } from '@mui/system'
import { IProps } from 'ui/views/ScaffoldInquiries/EditActions/IProps'

interface ICloneProps extends IProps {
  mode: 'convert' | 'clone'
}

const StyledStack = styled(Stack)(({ theme }) => ({
  minWidth: 400,
  padding: theme.spacing(2),
}))

export function CloneAction({ item, t, mode }: ICloneProps) {
  const appDispatch = useAppDispatch()
  const { open, handleOpen, handleClose } = useOpenState()
  // const { t } = useTranslation('cloneScaffold')
  const [mapped, setMapped] = useState<{ oldSerial: string | undefined, newSerial: string | undefined }[]>([])
  const handleClick = useCallback(async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const response = await appDispatch(
      mode === 'clone'
        ? cloneScaffolds([item])
        : convertScaffolds([item]),
    ) as ScaffoldInquiryModel[]
    const mappedResponseSerials = response.map((x) => ({
      oldSerial: item?.serialNumber?.fullSerial,
      newSerial: x.serialNumber?.fullSerial,
    }))
    setMapped(mappedResponseSerials)
    handleOpen()
  }, [appDispatch, item, handleOpen, setMapped])
  return (
    <>
      <Dialog2
        t={t}
        open={open}
        onClose={handleClose}
        title={t(mode === 'clone' ? 'cloneOverview' : 'convertOverview')}
      >
        <StyledStack>
          <Grid container spacing={1} direction="row" justifyContent="space-between">
            <Grid item xs={4}><Typography variant="subtitle1" align="left">{t('oldSerial')}</Typography></Grid>
            <Grid item xs={1}><ArrowRight /></Grid>
            <Grid item xs={4}><Typography variant="subtitle1" align="right">{t('newSerial')}</Typography></Grid>
          </Grid>
          {mapped.map((x) => (
            <Grid container spacing={1} direction="row" justifyContent="space-between">
              <Grid item xs={4}><Typography variant="body1" align="left">{x.oldSerial}</Typography></Grid>
              <Grid item xs={1}><ArrowRight /></Grid>
              <Grid item xs={4}><Typography variant="body1" align="right">{x.newSerial}</Typography></Grid>
            </Grid>
          ))}
          <Button variant="contained" fullWidth onClick={handleClose}>{t('generic:close')}</Button>
        </StyledStack>
      </Dialog2>
      <AsyncActionMenuitem
        onClick={handleClick}
        disabled={mode === 'convert' && typeof item.deconstructionDate !== 'undefined'}
      >
        {t(mode)}
      </AsyncActionMenuitem>
    </>

  )
}
