/* eslint-disable */
import {UserModel} from "api/models/UserModel";
import UsersRestModel from "api/users";
import LoadStateModel from "../framework/LoadStateModel";
import {IBaseRestState} from "features/base/BaseRestFeature";
import createBaseRestSlice from "features/base/creators/CreateBaseRestSlice";
import {useTranslation} from "react-i18next";

type DataType = UserModel
const key = 'users'

const initialState: IBaseRestState<DataType> = {
  data: [],
  loadState: LoadStateModel.INITIAL,
  archive: {}
}
export const {actions,index,hooks} = createBaseRestSlice(
  key,
  initialState,
  {},
  UsersRestModel
)
export const useUsers = hooks.useAll
export const useUser = hooks.useById

export const userIsInCustomerGroup = (customerId: string|undefined, x:UserModel|undefined,primaryOnly:boolean=false) =>
  Boolean(!customerId || (x?.companyId === customerId || (!primaryOnly && x?.customerIds.some(y=>y===customerId))))


export default index.reducer
