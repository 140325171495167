import { CssBaseline } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import CookieBanner from 'framework/views/CookieBanner'
import Notifier from 'framework/Notifier'
import Router from './Router'
import { useAppDispatch } from '../../app/hooks'
import { loadTokenOnPageLoad } from '../../features/auth'
import AppBar from './AppBar'
import Footer from './Footer'

const StyledRouteWrapper = styled('div')(({ theme }) => ({
  // marginTop: theme.spacing(6),
  // padding: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  minHeight: '85vh',
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(10),
  },
}))
export default function Page() {
  const appDispatch = useAppDispatch()
  useEffect(() => {
    appDispatch(loadTokenOnPageLoad())
  }, [])
  return (
    <>
      <CssBaseline />
      <Notifier />
      <CookieBanner />
      <BrowserRouter>
        <AppBar />
        <StyledRouteWrapper>
          <Router />
        </StyledRouteWrapper>
      </BrowserRouter>
      <Footer />
    </>

  )
}
