import { INamedCustomerBaseModel } from 'api/baseModel'
import { RestModel } from 'app/RestModel'
import { RootState } from 'app/store'
import { useSelector } from 'react-redux'
import useNamedOptions from 'lib/useNamedOptions'
import { useParams } from 'react-router'
import { IBaseRestState } from 'features/base/BaseRestFeature'
import createBaseRestSlice from 'features/base/creators/CreateBaseRestSlice'

export function createNamedCustomerBaseRestSlice<T extends INamedCustomerBaseModel>(name: string, initialState: IBaseRestState<T>, reducers: any, restModel: RestModel<T>) {
  const { selectors, hooks, ...rest } = createBaseRestSlice(name, initialState, reducers, restModel)
  const newSelectors = {
    ...selectors,
    byCustomerId: (id: string) => (state: RootState) => ((state as any)[name] as IBaseRestState<T>).data.filter((x) => x.customerId === id),
    byId: (id: string) => (state: RootState) => ((state as any)[name] as IBaseRestState<T>).data.find((x) => x.id === id),
  }
  const useByCustomerId = (id: string): T[] | undefined => useSelector(newSelectors.byCustomerId(id))
  const newHooks = {
    ...hooks,
    useByCustomerId,
    useNamedOptionsByCustomerId: (id: string, includeArchived: boolean = true) => {
      const data = useSelector(newSelectors.byCustomerId(id)) as T[]
      return useNamedOptions(data || [], includeArchived)
    },
    useByCustomerIdPathBased: () => {
      const { customerId } = useParams() as { customerId: string }
      return useByCustomerId(customerId)
    },
  }
  return {
    selectors: newSelectors,
    hooks: newHooks,
    ...rest,
  }
}
