import { Typography } from '@mui/material'
import { styled } from '@mui/system'

interface IProps {
  value: number,
  max: number,
  className?: string,
}
const BadgeDiv = styled(Typography)(({ theme }) => ({
  borderRadius: '50px',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.2),
  paddingBottom: theme.spacing(0.2),
  fontSize: 12,
  fontWeight: 500,
}))
function UnstyledCountBadge({ value, max, className }:IProps) {
  const display = value > max ? `${max}+` : value
  if (value > 0) return (<BadgeDiv className={className} >{display}</BadgeDiv>)
  return (null)
}
type StyledNavButtonProps = {
  value: number,
  max: number,
}
const StyledCountBadge = styled(UnstyledCountBadge, { shouldForwardProp: (prop) => true })

export default UnstyledCountBadge
