import { TextField } from '@codeflix/mui-managed-form'
import * as React from 'react'
import { DragEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function DraggableTextField() {
  const [value, setValue] = useState('')
  const [valid, setValid] = useState(false)
  const { t } = useTranslation('imageInput')
  const handleChange = useCallback((val:any) => {
    setValue(val)
  }, [setValue])
  const handleDragStart = useCallback((e:DragEvent<HTMLImageElement>) => {
    e.dataTransfer.setData('dragType', 'text')
    e.dataTransfer.setData('payload', value)
  }, [value])
  return (
    // <Tooltip arrow placement="bottom" title={t('textFieldTooltip')}>
    <TextField
      t={t}
      value={value}
      valid={valid}
      setValid={setValid}
      onChange={handleChange}
      multiline
      maxRows={10}
      name="Text"
      draggable={Boolean(value)}
      onDragStart={handleDragStart}
    />
    // </Tooltip>
  )
}
