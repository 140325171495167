import { ScaffoldAgentPhase, ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { InquiriesModeProps } from 'ui/views/ScaffoldInquiries/InquiriesMode'
import { TFunction } from 'i18next'
import { useCurrentUser } from 'features/user'
import { FullySignedEditActions } from 'ui/views/ScaffoldInquiries/EditActions/FullySignedEditActions'
import { OrderUserActions } from 'ui/views/ScaffoldInquiries/EditActions/OrderUserActions'
import { useUserHasPermissions } from 'framework/auth/permissions'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import PermissionModel from 'models/PermissionModel'

export default function EditActions({ item, mode, t }: { item: ScaffoldInquiryModel | undefined, t: TFunction } & InquiriesModeProps) {
  const user = useCurrentUser()
  const hasPermissionToRestartClientWorkflow = useUserHasPermissions({
    operation: PermissionOperation.AND,
    permissions: [PermissionModel.RESTART_SCAFFOLD_CLIENT_WORKFLOW],
  })
  const fullySigned = item?.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED
  if (fullySigned) return <FullySignedEditActions item={item} t={t} mode={mode} />

  if (item?.orderedByUserId === user?.id || hasPermissionToRestartClientWorkflow) return (<OrderUserActions item={item} t={t} mode={mode} />)// is ordered by user
}
