import { IScaffoldInquiryModelAggregated } from 'api/models/scaffoldModel'
import { Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormatDate } from 'framework/components/FormattedDate'
import { useCustomer } from 'features/customers'
import { useOperationUnitById } from 'features/operationUnits'
import { useBuildingsById } from 'features/buildings'
import { useScaffolder } from 'features/scaffolders'
import React from 'react'

function TwoStackedTypography({ value }:{value:Array<string|undefined>}) {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        <Typography>{value[0] || ''}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography style={{ wordBreak: 'break-word' }}>{value[1] || ''}</Typography>
      </Grid>
    </Grid>
  )
}

export default function ScaffoldInquiryViewer({ item }: {item:IScaffoldInquiryModelAggregated}) {
  const { t } = useTranslation('scaffolds')
  const { t: tScaffoldType } = useTranslation('scaffoldType')
  const formatDate = useFormatDate()
  const customer = useCustomer(item.customerId)
  const operationUnit = useOperationUnitById(item.operationUnitId)
  const building = useBuildingsById(item.buildingId)
  const scaffolder = useScaffolder(item?.scaffolderId)
  return (
    <Stack direction="column">
      {/* <Typography variant="h5">{t('construction', { item })}</Typography> */}
      <Typography variant="h6">{t('csOrder', { item })}</Typography>
      <TwoStackedTypography value={[t('application:customer'), (item.customer || customer)?.name]} />
      <TwoStackedTypography value={[t('application:operationUnit'), (item.operationUnit || operationUnit)?.name]} />
      <TwoStackedTypography value={[t('application:building'), (item.building || building)?.name]} />
      <TwoStackedTypography value={[t('area'), item?.area]} />
      <TwoStackedTypography value={[t('application:scaffolder'), (item?.scaffolder || scaffolder)?.name]} />
      <TwoStackedTypography value={[t('orderedByUserId'), item.clientApproval && `${item.clientApproval?.approvedBy || ''}, ${item.clientApproval?.approverCompany || ''}`]} />
      <TwoStackedTypography value={[t('email'), item.clientApproval?.approverEmail]} />
      <TwoStackedTypography value={[t('constructionDate'), formatDate(item.constructionDate!, 'SHORTDATE')]} />
      <TwoStackedTypography value={[t('deconstructionDate'), formatDate(item.deconstructionDate!, 'SHORTDATE')]} />
      <TwoStackedTypography value={[t('permitRequired'), t(`boolean:${item.permitRequired}`)]} />
      <TwoStackedTypography value={[t('type'), tScaffoldType(item.type)]} />
      <TwoStackedTypography value={[t('description'), item.description]} />
    </Stack>
  )
}
