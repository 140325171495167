import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import OverviewView from 'framework/components/BaseRestViews/OverviewView'
import { hooks } from 'features/siteMeasuringInspections'
import { useUserHasAllPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'
import { useCustomers } from 'features/customers'
import { useScaffolders } from 'features/scaffolders'
import { useBuildings } from 'features/buildings'
// eslint-disable-next-line camelcase
import { MRT_ColumnDef } from 'material-react-table'
import { CustomerModel } from 'api/models/customerModel'
import { ScaffolderModel } from 'api/models/scaffolderModel'
import { BuildingModel } from 'api/models/buildingModel'
import { DateCellProps } from 'framework/components/Table/Cells/DateTimeCell'
import { ISiteMeasuringInspection } from 'api/models/siteMeasuringInspections'
import { useParams } from 'react-router'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'
import { LookupAccessorFn } from 'framework/components/Table/helper/lookupAccessorFn'
import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'

function SiteMeasuringInspectionOverview({ data, mode }: {data: ISiteMeasuringInspection[], mode: RestViewMode}) {
  const matchingCustomer = useCustomers()
  const matchingScaffolder = useScaffolders()
  const buildings = useBuildings()

  const { t } = useTranslation('siteMeasuring')
  const isGDK = useUserHasAllPermissions([PermissionModel.GDK_REVIEW_SITE_MEASURING])
  const { t: scaffoldT } = useTranslation('scaffolds')

  // eslint-disable-next-line camelcase
  const columns = useMemo<MRT_ColumnDef<ISiteMeasuringInspection & {scaffoldInquiry: ScaffoldInquiryModel}>[]>(() => [
    {
      header: t('generic:id'),
      accessorKey: 'id',
      filterFn: 'includesString',
    },
    {
      header: t('scaffoldInquirySerial'),
      accessorKey: 'scaffoldInquiry.serialNumber.fullSerial',
      filterFn: 'includesString',
    },
    {
      header: t('application:customer'),
      accessorFn: LookupAccessorFn<CustomerModel>(matchingCustomer, ['scaffoldInquiry', 'customer'], 'customer', undefined, 'name'),
      filterFn: 'includesString',
    },
    {
      header: t('application:scaffolder'),
      accessorFn: LookupAccessorFn<ScaffolderModel>(matchingScaffolder, undefined, 'scaffolder', undefined, 'name'),
      filterFn: 'includesString',
    },
    {
      header: t('application:building'),
      accessorFn: LookupAccessorFn<BuildingModel>(buildings, ['scaffoldInquiry', 'building'], 'building', undefined, 'name'),
      filterFn: 'includesString',
    },
    {
      header: scaffoldT('area'),
      accessorKey: 'scaffoldInquiry.area',
      filterFn: 'includesString',
    },
    {
      header: scaffoldT('orderNr'),
      accessorKey: 'scaffoldInquiry.orderNr',
      filterFn: 'includesString',
    },
    {
      header: scaffoldT('constructionDate'),
      ...DateCellProps(['scaffoldInquiry', 'constructionDate']),
    },
    {
      header: scaffoldT('deconstructionDate'),
      ...DateCellProps(['scaffoldInquiry', 'deconstructionDate']),
    },
    {
      header: t('acceptedSiteMeasuringInspection'),
      accessorKey: 'overallAssessmentAccepted',
      Cell: ({ cell }:any) => (cell.getValue() ? t('generic:yes') : t('generic:no')),

    },
    // eslint-disable-next-line camelcase
  ] as MRT_ColumnDef<ISiteMeasuringInspection>[], [t, scaffoldT, matchingCustomer, matchingScaffolder, buildings])
  return (
    <OverviewView<ISiteMeasuringInspection>
      data={data || []}
      columns={columns}
      viewMode={mode}
      hideNew
      name={t('application:siteMeasuringInspection').toString()}
      initialState={{
        showColumnFilters: false,
        columnVisibility: {
          id: false,
          scaffolderId: isGDK,
        },
      }}
    />
  )
}

export function OverviewArchive() {
  const { year } = useParams() as {year: string}
  const data = hooks.useArchiveData(year)
  return (
    <SiteMeasuringInspectionOverview data={data} mode={RestViewMode.Archive} />
  )
}

export default function Overview() {
  const data = hooks.useAll()
  return (
    <SiteMeasuringInspectionOverview data={data} mode={RestViewMode.Default} />
  )
}
