import FullscreenDialog from 'framework/components/ManagedForm/Fields/FullscreenDialog'
import { useOpenState } from 'lib/stateHelper'
import { Button, IconButton } from '@mui/material'
import { MouseEvent, useCallback, useId, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IBaseElementProps, Dropzone } from '@codeflix/mui-managed-form'
import ImageWithLocationPointer from 'framework/components/ManagedForm/Fields/ImageEditor/ImageWithLocationPointer/index'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { BaseModel } from 'api/baseModel'
import { IImageInputValue } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IImageInputValue'
import { IPin } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IPin'
import { IText } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IText'

interface IProps extends IBaseElementProps {
  onDelete?: (event: MouseEvent<HTMLElement>) => void
}

export class Attachment extends BaseModel implements IImageInputValue {
  file?: File | Blob

  fileId?: string

  image?: File | Blob

  imageId?: string

  pin?: IPin

  text?: IText
}

export default function AttachmentPreview(props: IProps) {
  const { onDelete, value, onChange } = props
  const { open, handleOpen, handleClose, toggle } = useOpenState(false)
  const id = useId()
  const { t } = useTranslation('attachments')
  const showDeleteButton = useMemo(() => typeof onDelete === 'function', [onDelete])
  const handleDelete = useCallback((evt: MouseEvent<HTMLElement>) => {
    if (!showDeleteButton) return
    onDelete!(evt)
    handleClose()
  }, [onDelete, handleClose])
  // const handleDownload = useCallback((version: 'marked' | 'original') => (evt: MouseEvent<HTMLButtonElement>) => {
  //   let downloadFile = null
  //   switch (version) {
  //     case 'marked':
  //       downloadFile = value.imageExported || value.image
  //       break
  //     case 'original':
  //       downloadFile = value.file
  //       break
  //     default: break
  //   }
  //   console.log('download', version, downloadFile)
  //   if (downloadFile) FileSaver.saveAs(downloadFile)
  // }, [value])
  const { t: dropzoneT } = useTranslation('dropzone')
  const handleDrop = useCallback((innerval: any) => {
    // setImage(innerval)
    onChange({ path: ['file'], value: innerval })
  }, [onChange])
  return (
    <>

      {!value.file ? (
        <Dropzone
          t={dropzoneT}
          onChange={handleDrop}
          name="upload"
          id={id}
          accept={{
            'application/pdf': ['.pdf'],
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
          }}
          maxSize={10 * 1024 * 1024}
          setValid={props.setValid}
          valid={props.valid}
          debug={props.debug}
          highlightErrorsAlways={props.highlightErrorsAlways}
          value={null}
          loading={false}
        />
      ) : (
        <Button onClick={handleOpen}>
          {t('viewAndEdit')}
        </Button>

        // <IconButton onClick={handleOpen}>
        //   <AttachmentIcon value={props.value} />
        // </IconButton>
      )}
      <FullscreenDialog
        open={open}
        title={props.t(props.name)}
        onClose={handleClose}
        menu={(
          <>
            {/* {props.value?.file && ( */}
            {/*  <IconButton onClick={handleDownload('original')} size="large"> */}
            {/*    <Download color="secondary" /> */}
            {/*  </IconButton> */}
            {/* )} */}
            {/* {props.value?.image && ( */}
            {/*  <IconButton onClick={handleDownload('marked')} size="large"> */}
            {/*    <Download color="secondary" /> */}
            {/*  </IconButton> */}
            {/* )} */}
            {showDeleteButton && (
              <IconButton
                onClick={handleDelete}
                size="large"
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            )}
          </>
        )}
      >

        <ImageWithLocationPointer {...props} />
      </FullscreenDialog>
    </>

  )
}
