import { useTranslation } from 'react-i18next'
import { useCustomers } from 'features/customers'
import { useScaffoldInquiriesFiltered } from 'features/scaffoldInquiries'
import OptionalPermissionView from 'framework/auth/OptionalPermissionView'
import LocalizedPlot from 'framework/components/Charts/LocalizedPlot'
import { sumNumbers, useGroupedDataForPlotly } from 'framework/lib/useGroupedDataForPlotly'
import PermissionModel from 'models/PermissionModel'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import { Typography } from '@mui/material'
import DashboardPaper from 'ui/views/Dashboard/components/DashboardPaper'
import { ScaffoldAgentPhase, ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import * as Plotly from 'plotly.js'
import { useHeight } from 'ui/views/Dashboard/lib/useHeight'

function CurrentScaffoldsByCustomerInternal() {
  const { t } = useTranslation('dashboard')

  const customers = useCustomers()
  const scaffolds = useScaffoldInquiriesFiltered((x:ScaffoldInquiryModel) => ((x.deconstructionDate && DateTime.fromISO(x.deconstructionDate) > DateTime.now()) || !x.deconstructionDate)
    && x.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED)

  // const scaffolds = useCurrentScaffolds()
  const data = useGroupedDataForPlotly<ScaffoldInquiryModel>(scaffolds, customers, 'customerId')
  const data2 = useMemo<Plotly.Data[]>(() => ([{
    labels: data.labels, // [...data.labels, ...data.labels.map((x) => `${x}1`), ...data.labels.map((x) => `${x}2`), ...data.labels.map((x) => `${x}3`), ...data.labels.map((x) => `${x}4`)],
    values: data.values, // [...data.values, ...data.values, ...data.values, ...data.values, ...data.values],
    type: 'pie',
  }]), [data])
  const height = useHeight(1, 36)

  return (
    <DashboardPaper>
      <LocalizedPlot
        title={t('countOfScaffoldsByCustomer')}
        data={data2}
        layout={{
          height,
          legend: {
            // orientation: 'h',
            // valign: 'bottom',
            // yanchor: 'bottom',
            // y: 0,
            // x: 0,
          },
          // height: 900,
        }}
      />
      <Typography textAlign="center">{t('totalCountOfScaffolds')}: {sumNumbers(data.values)}</Typography>
    </DashboardPaper>
  )
}

export default function CurrentScaffoldsByCustomer() {
  return (
    <OptionalPermissionView permissions={{
      permissions: [PermissionModel.VIEW_ALL_CUSTOMERS, PermissionModel.CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST],
      operation: PermissionOperation.OR,
    }}
    >
      <CurrentScaffoldsByCustomerInternal />
    </OptionalPermissionView>
  )
}
