import { RestModel } from 'app/RestModel'
import { IScaffoldInquiryRequestAttachment } from 'api/models/scaffoldInquiryAttachment'
import { jsonToFormData } from 'api/helper/formData'
import { getQueryForArchive } from 'api/helper/archiveQuery'
import { IScaffoldScaffolderRequest, ScaffoldAgentPhase, ScaffoldInquiryModel } from './models/scaffoldModel'
import PermissionModel from '../models/PermissionModel'
import { api } from '../app/apiClient'

const clearPayload = (payload: ScaffoldInquiryModel) => ({
  ...payload,
  rentFreeInDays: payload.agentPhase === 'CLIENT' ? undefined : payload.rentFreeInDays,
  clientWorkflowSteps: undefined,
  orderedByUser: undefined,
  copiedFromScaffoldInquiryId: undefined,
  scaffoldReferenceSerialNumber: undefined,
  csOrderId: payload.agentPhase === ScaffoldAgentPhase.GDK || payload.agentPhase === ScaffoldAgentPhase.SCAFFOLDER ? payload.csOrderId : undefined,
})
class ScaffoldInquiryRestModel extends RestModel<ScaffoldInquiryModel> {
  path = 'scaffoldinquiries'

  getItems = async (parameters: any, permissions: string[]|undefined) => {
    try {
      const query = getQueryForArchive(parameters)
      const fetchAll = permissions?.includes('VIEW_ALL_CUSTOMERS')
      if (!fetchAll && permissions?.includes(PermissionModel.CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST)) {
        return await api.get(`${this.path}/MyInquiries${query}`).json() as ScaffoldInquiryModel[]
      }
      return super.getItems(parameters, permissions, fetchAll, query)
    } catch (e) {
        console.error(e)
        return []
    }
  }

  async updateItem(payload: ScaffoldInquiryModel): Promise<unknown> {
    return super.updateItem(clearPayload(payload))
  }

  async updateItemScaffolder(payload: IScaffoldScaffolderRequest): Promise<unknown> {
    return await api.put(`${this.path}/scaffolddetails`, { json: payload }).json()
  }

  async createItem(payload: ScaffoldInquiryModel): Promise<unknown> {
    return super.createItem(clearPayload(payload))
  }

  async cancelScaffolds(payload: ScaffoldInquiryModel[], date: Date):Promise<ScaffoldInquiryModel[]> {
    const ids = payload.map((x) => x.id)
    const payloadToSend = {
      ids,
      date,
    }
    return await api.post(`${this.path}/cancel`, { json: payloadToSend }).json()
  }

  async deconstructScaffolds(payload: ScaffoldInquiryModel[], date: Date):Promise<ScaffoldInquiryModel[]> {
    const ids = payload.map((x) => x.id)
    const payloadToSend = {
      ids,
      date,
    }
    return await api.post(`${this.path}/deconstruct`, { json: payloadToSend }).json()
  }

  async constructScaffolds(payload: ScaffoldInquiryModel[], date: Date):Promise<ScaffoldInquiryModel[]> {
    const ids = payload.map((x) => x.id)
    const payloadToSend = {
      ids,
      date,
    }
    return await api.post(`${this.path}/construct`, { json: payloadToSend }).json()
  }

  async createCertificate(payload: ScaffoldInquiryModel, withAttachments: boolean) {
    if (withAttachments) {
      return await api.post(`${this.path}/${payload.id}/certificateWithAttachments`).json()
    }
    return await api.post(`${this.path}/${payload.id}/certificate`).json()
  }

  async getCertificateDownloadToken(pdfJobId: string) {
    return await api.get(`${this.path}/${pdfJobId}/certificate/token`, { timeout: false }).json()
  }

 async createSiteMeasuringPdf(payload: ScaffoldInquiryModel) {
    return await api.post(`${this.path}/${payload.id}/siteMeasuringPdf`).json()
  }

  async getSiteMeasuringPdfoken(pdfJobId: string) {
    return await api.get(`${this.path}/${pdfJobId}/siteMeasuringPdf/token`, { timeout: false }).json()
  }

  async cloneScaffolds(payload: ScaffoldInquiryModel[]):Promise<ScaffoldInquiryModel[]> {
    const ids = payload.map((x) => x.id)
    return await api.post(`${this.path}/clone`, { json: ids }).json()
  }

  async convert(payload: ScaffoldInquiryModel[]):Promise<ScaffoldInquiryModel[]> {
    const ids = payload.map((x) => x.id)
    return await api.post(`${this.path}/convert`, { json: ids }).json()
  }

  async revertCanceledDate(payload: ScaffoldInquiryModel):Promise<ScaffoldInquiryModel> {
    return await api.post(`${this.path}/revertCanceledDate`, { json: payload.id }).json()
  }

  async restartClientWorkflow(payload: ScaffoldInquiryModel):Promise<ScaffoldInquiryModel> {
    return await api.post(`${this.path}/${payload.id}/restartClientWorkflow`).json()
  }

  async deleteForced(payload: ScaffoldInquiryModel) {
    return await api.delete(`${this.path}/${payload.id}/force`).json()
  }
}

export default new ScaffoldInquiryRestModel()
