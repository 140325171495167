import { NamedCustomerBaseModel } from '../baseModel'

export class OperationUnitModel extends NamedCustomerBaseModel {
  buildings: string[]

  constructor(customerId: string) {
    super(customerId)
    this.buildings = []
  }
}
