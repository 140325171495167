import { useParams } from 'react-router'
import { actions, useScaffoldInquiryAttachments } from 'features/scaffoldInquiriesAttachments'
import LoadingCircle from 'framework/components/DataLoader/LoadingCircle'
import { IScaffoldInquiryAttachment } from 'api/models/scaffoldInquiryAttachment'
import RestModel from 'api/scaffoldInquiriesAttachments'
import React, { useCallback, useMemo } from 'react'
import { ThumbnailList } from 'framework/components/ManagedForm/Fields/ImageEditor/ThumbailView'
import { IRemoteAttachment } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IRemoteAttachment'
import { useAppDispatch } from 'app/hooks'
import { hooks } from 'features/scaffoldInquiries'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'framework/components/BreadCrumb'
import { uploadBlobToThumbnail } from 'framework/components/ManagedForm/Fields/ImageEditor/libs/blobToPreview'
import { sortByDate } from 'lib/modelHelper'
import { InquiriesMode, InquiriesModeProps } from 'ui/views/ScaffoldInquiries/InquiriesMode'
import { useScaffoldMetaFromMode } from 'ui/views/ScaffoldInquiries/helper'
import { useArchiveLinkStackTranslated } from 'framework/components/BaseRestViews/BreadCrumbYearSelector'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'
import ButtonLink from 'framework/components/ButtonLink'
import useMultiDataLoaderWithFetchPolicy from 'framework/components/DataLoader/hooks/useMultiDataLoaderWithFetchPolicy'
import LoadStateModel from 'framework/LoadStateModel'

interface IAttachmentPreviewProps {
  scaffoldInquiryId: string
  data: IScaffoldInquiryAttachment[],
  onAdd?: ()=>void,
  onDelete?: ()=>void
}
const loadFileWithMode = (id: string, mode: 'file'|'image'|'thumbnail') => () => RestModel.getDownload(id, mode)
function AttachmentPreview({ data, scaffoldInquiryId, mode, onAdd, onDelete }: IAttachmentPreviewProps&InquiriesModeProps) {
  const dispatch = useAppDispatch()
  const mappedData = useMemo(() => [...data]?.sort(sortByDate('createdOn', 'asc')).map((x) => ({
    ...x,
    file: loadFileWithMode(x.id, 'file'),
    image: x.imageId ? loadFileWithMode(x.id, 'image') : undefined,
    thumbnail: x.thumbnailId ? loadFileWithMode(x.id, 'thumbnail') : undefined,
  })), [data]) as unknown as IRemoteAttachment[]
  const handleDelete = useCallback((value:any) => {
    dispatch(actions.deleteItem(value))
    if (typeof onDelete === 'function') onDelete()
  }, [dispatch, onDelete])
  const handleNew = useCallback(async (value:any) => {
    // todo generate thumbnail
    const thumbnail = await uploadBlobToThumbnail(value)
    const test = {
      isNew: true,
      file: value,
      thumbnail,
      scaffoldInquiryId,
     }as IScaffoldInquiryAttachment
    dispatch(actions.create(test))
    if (typeof onAdd === 'function') onAdd()
  }, [dispatch, onAdd, scaffoldInquiryId])
  const handleSave = useCallback((value:any) => {
    if (value.isNew) {
      dispatch(actions.create(value.image))
    } else {
      dispatch(actions.update(value.image))
    }
    // todo refetch thumbnail?
    // const result = RestModel.createOrUpdateItem(value.image)
  }, [dispatch])
  const scaffoldInquiry = hooks.useByIdAndMode(scaffoldInquiryId, mode === 'Archive' ? RestViewMode.Archive : RestViewMode.Default)
  const { t } = useTranslation('scaffolds')
  const { name } = useScaffoldMetaFromMode(mode, t)
  const baseLinkStack = useArchiveLinkStackTranslated(mode === 'Archive' ? RestViewMode.Archive : RestViewMode.Default, name, false, 2)

  const linkStack = useMemo(() => ([
    ...baseLinkStack || [
      {
        to: '../..',
        text: t(`application:${name}`),
      },
    ],
    {
      to: `../../${scaffoldInquiryId}`,
      text: scaffoldInquiry?.serialNumber?.fullSerial || '',
    },
    {
      text: t('attachments'),
    },
  ]), [t, scaffoldInquiry, baseLinkStack])
  return (
    <>
      {
        mode !== 'massEditor' && (
          <>
            <BreadCrumb
              color="black"
              linkStack={linkStack}
            />
            <ButtonLink to="..">{t('generic:back')}</ButtonLink>
          </>
        )
      }

      <ThumbnailList data={mappedData} onSave={handleSave} onDelete={handleDelete} onNew={handleNew} />

    </>
  )
}

type IProps = {
  mode: InquiriesMode,
  scaffoldInquiryId: string,
  onAdd?: ()=>void,
  onDelete?: ()=>void

}

function AttachmentPreviewLoading({
                                    mode,
                                    scaffoldInquiryId,
                                    ...rest
                                  }: IProps) {
  const data = useScaffoldInquiryAttachments(scaffoldInquiryId)
  if (!data) return <LoadingCircle />
  return <AttachmentPreview scaffoldInquiryId={scaffoldInquiryId} data={data} mode={mode} {...rest} />
}

export function LazyAttachmentOverview({
                                  mode,
                                  scaffoldInquiryId, ...rest
                                }: IProps) {
  const loadProp = useMemo(() => ({
    requiredProps: [{
      prop: 'scaffoldInquiriesAttachments',
      nested: [{
        prop: 'data',
        key: 'scaffoldInquiryId',
      }],
      fetch: 'always',
    }],
    parameters: { scaffoldInquiryId },
  }), [scaffoldInquiryId])
  const state = useMultiDataLoaderWithFetchPolicy(loadProp as any)
  // console.log('loadState', state, scaffoldInquiryId)
  // if (state !== LoadStateModel.LOADED) return <LoadingCircle />
  return <AttachmentPreviewLoading mode={mode} scaffoldInquiryId={scaffoldInquiryId} {...rest} />
}

export default function AttachmentOverview({ mode }: InquiriesModeProps) {
  const { scaffoldInquiryId } = useParams() as { scaffoldInquiryId: string }
  return <AttachmentPreviewLoading mode={mode} scaffoldInquiryId={scaffoldInquiryId} />
}
