import { IImageInputValue } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IImageInputValue'
import {
  ImageLoad,
  useImageSrcFromBlob,
} from 'framework/components/ManagedForm/Fields/ImageEditor/libs/UseImageSrcFromBlob'
import { Fab, styled, Tooltip } from '@mui/material'
import { useOpenState } from 'lib/stateHelper'
import React, { MouseEvent, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Draw } from 'mdi-material-ui'
import Center from 'framework/components/Center'
import { PreviewImageLoadAsync } from 'framework/components/ManagedForm/Fields/ImageEditor/PreviewImage'
import EditView from 'framework/components/ManagedForm/Fields/ImageEditor/EditView'

interface IProps {
  onChange: (value: any) => Promise<void> | void
  value: IImageInputValue,
  disabled?: boolean,
  file?: ImageLoad
  image?: ImageLoad
}

const PositionedEditButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}))
const PositionRelativeDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}))
export default function ImageViewer({ file, image, disabled, value, onChange, ...rest }: IProps) {
  const { open, toggle } = useOpenState()
  const editRef = useRef<{ exportImage:() => Promise<void> }>()
  const handleEditButtonClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onChange({ path: ['changed'], value: true })
    toggle()
  }, [toggle, onChange])
  const { imageSrcUrl, bitmap, originalType } = useImageSrcFromBlob(file)
  const { t } = useTranslation('attachments')

  return (
    <>
      {(!open && imageSrcUrl) && (
        <Tooltip arrow title={originalType && t(originalType).toString()}>
          <PositionedEditButton
            color="primary"
            aria-label={t('edit').toString()}
            size="large"
            onClick={handleEditButtonClick}
          >
            <Draw />
          </PositionedEditButton>
        </Tooltip>

      )}
      <PositionRelativeDiv>
        {open
          ? (
            <EditView
              ref={editRef}
              value={value}
              onChange={onChange}
              {...rest}
              imageSrcUrl={imageSrcUrl!}
              imageBitmap={bitmap!}
            />
          )

          : (
            <Center>
              <PreviewImageLoadAsync source={imageSrcUrl} image={image} />
            </Center>
          )}
      </PositionRelativeDiv>

    </>

  )
}
