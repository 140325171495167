/* eslint-disable camelcase */
import { MaterialReactTable, MRT_RowData } from 'material-react-table'
import ErrorBoundary from 'framework/components/ErrorBoundary'
import {
  MaterialReactTableIntermediateProps,
} from 'framework/components/Table/types/materialReactTableIntermediateProps'
import usePersistedMaterialReactTable from 'framework/components/Table/usePersistedMaterialReactTable'
import FilterAlert from 'framework/components/Table/FilterView/FilterAlert'
import { Paper } from '@mui/material'
import { IBaseModel } from 'api/baseModel'
import { useNavigate } from 'react-router-dom'

export default function Table<TData extends MRT_RowData>(props: MaterialReactTableIntermediateProps<TData>) {
  const table = usePersistedMaterialReactTable(props)
  const { pathname } = props
  console.log('pathname', pathname)
  const navigate = useNavigate()
  return (
    <ErrorBoundary>
      <Paper>
        <FilterAlert table={table} />
        <MaterialReactTable<TData>
          table={table}
        />
      </Paper>
    </ErrorBoundary>
  )
}
