import { ISelectionToolsButtonProps } from 'ui/views/ScaffoldInquiries/MassEditor/SelectionTools/ISelectionToolsProps'
import { useAppDispatch } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { UpdateType } from '@codeflix/mui-spreadsheet'
import { deleteItems } from 'features/scaffoldInquiriesDraft'
import { Button } from '@mui/material'

export function DeleteTool<T>({
                                state,
                                selected,
                              }: ISelectionToolsButtonProps<T>) {
  const appDispatch = useAppDispatch()
  const { t } = useTranslation('generic')
  const handleDelete = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    // todo delete via api and local
    const updates: UpdateType[] = []
    const items: any[] = []
    selected.forEach((val: any) => {
      updates.push(val.index)
      items.push(val.value)
    })
    state.deleteRows(updates)
    appDispatch(deleteItems(items))
  }, [appDispatch, selected, state])
  return <Button onClick={handleDelete}>{t('delete')}</Button>
}
