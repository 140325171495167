import { Skeleton, styled } from '@mui/material'
import React from 'react'
import {
  ImageLoad,
  useImageSrcFromBlob,
} from 'framework/components/ManagedForm/Fields/ImageEditor/libs/UseImageSrcFromBlob'
import LoadingCircle from 'framework/components/DataLoader/LoadingCircle'

const StyledImg = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: '90vh',
}))
const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '90vw',
  height: '90vh',
}))

interface IPreviewImage extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>{
  // getImageBlobAsync: () => Promise<Blob>
  imageSrcUrl: string | undefined
}
const FullWidthHeightDiv = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
}))
export default function PreviewImage({ imageSrcUrl, ...rest }: IPreviewImage) {
  return (
    imageSrcUrl
      ? (
        <StyledImg
          alt="preview"
          src={imageSrcUrl}
          {...rest}
        />
      )
      : (<FullWidthHeightDiv><LoadingCircle /></FullWidthHeightDiv>)
  )
}
interface IPreviewImageLoad extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>{
  // getImageBlobAsync: () => Promise<Blob>
  image?: ImageLoad|undefined
  source: string|undefined
}
export function PreviewImageLoadAsync({ source, image }:IPreviewImageLoad) {
  const { imageSrcUrl: previewImageSrcUrl } = useImageSrcFromBlob(image)

  return <PreviewImage imageSrcUrl={previewImageSrcUrl || source} />
}
