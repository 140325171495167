import { Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/system'
import LoginForm from './LoginForm'

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
}))

export default function LoginComponent() {
  const { t } = useTranslation('login')
  return (
    <StyledPaper elevation={24}>
      <LoginForm />
    </StyledPaper>
  )
}
