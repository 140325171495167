import { useAppDispatch } from 'app/hooks'
import { MouseEvent, useCallback, useState } from 'react'
import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { cloneScaffolds, convertScaffolds } from 'features/scaffoldInquiries'
import AsyncActionButton from 'framework/components/AsyncActionButton'
import { useOpenState } from 'lib/stateHelper'
import Dialog2 from 'framework/components/Dialog2'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { ArrowRight } from 'mdi-material-ui'
import { styled } from '@mui/system'
import { IProps } from './IProps'

interface ICloneProps extends IProps {
  mode: 'clone'|'convert'
}
const StyledStack = styled(Stack)(({ theme }) => ({
  minWidth: 400,
  padding: theme.spacing(2),
}))
export function CloneAction({ table, t, mode }: ICloneProps) {
  const appDispatch = useAppDispatch()
  const { open, handleOpen, handleClose } = useOpenState()
  // const { t } = useTranslation('cloneScaffold')
  const [mapped, setMapped] = useState<{oldSerial: string|undefined, newSerial: string|undefined}[]>([])
  const handleClick = useCallback(async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const rows = (table?.getSelectedRowModel()?.rows || [])
    const original = rows.map((x:any) => x.original)as ScaffoldInquiryModel[]
    const response = await appDispatch(mode === 'clone' ? cloneScaffolds(original) : convertScaffolds(original)) as ScaffoldInquiryModel[]
    const mappedResponseSerials = response.map((x) => ({
      oldSerial: original.find((y) => y.id === x.copiedFromScaffoldInquiryId)?.serialNumber?.fullSerial,
      newSerial: x.serialNumber?.fullSerial,
    }))
    setMapped(mappedResponseSerials)
    handleOpen()
  }, [appDispatch, table, handleOpen, setMapped, mode])
  const onClose = useCallback(() => {
    table?.resetRowSelection()
    handleClose()
  }, [table, handleClose])
  return (
    <>
      <Dialog2 t={t} open={open} onClose={onClose} title={t(mode === 'clone' ? 'cloneOverview' : 'convertOverview')}>
        <StyledStack>
          <Grid container spacing={1} direction="row" justifyContent="space-between">
            <Grid item xs={4}><Typography variant="subtitle1" align="left">{t('oldSerial')}</Typography></Grid>
            <Grid item xs={1}><ArrowRight /></Grid>
            <Grid item xs={4}><Typography variant="subtitle1" align="right">{t('newSerial')}</Typography></Grid>
          </Grid>
          {mapped.map((x) => (
            <Grid container spacing={1} direction="row" justifyContent="space-between">
              <Grid item xs={4}><Typography variant="body1" align="left">{x.oldSerial}</Typography></Grid>
              <Grid item xs={1}><ArrowRight /></Grid>
              <Grid item xs={4}><Typography variant="body1" align="right">{x.newSerial}</Typography></Grid>
            </Grid>
          ))}
          {mapped.length === 0 && mode === 'convert' && (
            <Typography textAlign="center" variant="body1">{t('noConvertSucceededHint')}</Typography>
          )}

          <Button variant="contained" fullWidth onClick={onClose}>{t('generic:close')}</Button>
          {mode === 'convert' && <Typography variant="body2">{t('convertOverviewHint')}</Typography>}

        </StyledStack>
      </Dialog2>
      <AsyncActionButton
        onClick={handleClick}
      >
        {t(mode)}
      </AsyncActionButton>
    </>

  )
}
