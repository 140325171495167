import { CustomerModel } from 'api/models/customerModel'
import { SiteMeasuringAccountingTabs } from 'api/models/siteMeasuringTemplateModel'
import { INamedBaseModel, NamedCustomerBaseModel } from '../baseModel'
import { ScaffoldInquiryModel } from './scaffoldModel'

export enum SiteMeasuringState {
  DRAFT='DRAFT',
 SIGNED_BY_SCAFFOLDER='SIGNED_BY_SCAFFOLDER',
  FULLY_SIGNED='FULLY_SIGNED',
  REJECTED_BY_GDK='REJECTED_BY_GDK',
  CANCELLED='CANCELLED',
}
export enum SiteMeasuringAgentPhase {
  GDK='GDK',
  SCAFFOLDER='SCAFFOLDER',
  FULLY_SIGNED='FULLY_SIGNED'
}
export class SiteMeasuringAdditionalProperties {
  gdkNote: string = ''
}

export class SiteMeasuringModel extends NamedCustomerBaseModel implements INamedBaseModel {
  scaffoldInquiryId: string

  scaffoldInquiry?: ScaffoldInquiryModel

  state: SiteMeasuringState = SiteMeasuringState.DRAFT

  scaffolderId: string

  agentPhase: SiteMeasuringAgentPhase = SiteMeasuringAgentPhase.SCAFFOLDER

  customer?: CustomerModel

  siteMeasuring: any = {}

  customerSiteMeasuringTabs?: SiteMeasuringAccountingTabs[]

  isAccounted?: boolean

  isCancelled?: boolean

  additionalProperties?: SiteMeasuringAdditionalProperties

  constructor(scaffoldInquiry: ScaffoldInquiryModel) {
    super(scaffoldInquiry.customerId)
    this.scaffolderId = scaffoldInquiry.scaffolderId!
    this.scaffoldInquiryId = scaffoldInquiry.id

    this.name = scaffoldInquiry.name
  }
}

export class SiteMeasuringAccountedRequestModel {
  ids: string[] = []

  isAccounted: boolean = false
}
export class SiteMeasuringCancelledRequestModel {
  ids: string[] = []

  isCancelled: boolean = false
}
