import { Divider } from '@mui/material'
import { NavItem } from './NavItem'
import { INavItemProps } from './NavItemStyleComponents'

type IProps = INavItemProps &{divider?: boolean}
function NavItemChildrenWrapper({ navItem, onClick, permissionCheck, t, isSignedIn, mobile, divider }: IProps) {
  const hasPermission = navItem.requirePermissions ? permissionCheck(navItem.requirePermissions) : true
  return (
    <>
      {hasPermission && (Boolean(divider)) && <Divider />}
      <NavItem
        onClick={onClick}
        navItem={navItem}
        nested
        permissionCheck={permissionCheck}
        t={t}
        isSignedIn={isSignedIn}
        mobile={mobile}
      />
    </>
  )
}
export function NavItemChildren({ navItem, divider, ...rest }: IProps) {
  return (
    <>
      {navItem.children?.map((it, index) => (
        <NavItemChildrenWrapper
          key={`navChildren${it.key}`}
          navItem={it}
          divider={divider && index > 0}
          {...rest}
        />
      ))}
    </>
  )
}
