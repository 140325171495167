import { useSelector } from 'react-redux'
import Login from 'ui/views/Login'
import { useParams } from 'react-router'
import useMultiDataLoaderWithFetchPolicy from 'framework/components/DataLoader/hooks/useMultiDataLoaderWithFetchPolicy'
import { useUserHasPermissions } from 'framework/auth/permissions'
import ErrorBoundary from 'framework/components/ErrorBoundary'
import { isAuthorized } from '../../features/auth'
import LoadStateModel from '../../framework/LoadStateModel'
import LoadingCircle from '../../framework/components/DataLoader/LoadingCircle'
import { userLoaded } from '../../features/user'
import { CustomRoute } from '../../models/IRoute'

function AuthorizedRouteDataLoader({ element, requirePermissions, fetch, ...rest }: CustomRoute) {
  // const authorized = useSelector(isAuthorized)
  // const isUserLoaded = useSelector(userLoaded)
  const params = useParams()
  const loadState = useMultiDataLoaderWithFetchPolicy({ requiredProps: fetch || [], parameters: params })
  // useMultiDataLoader(fetch,
  // )
  const permissionsGranted = useUserHasPermissions(requirePermissions)
  // if (isLoadingAuth) return <LoadingCircle />
  // if (!authorized) return (<Login />)
  // if (!isUserLoaded) return <LoadingCircle />
  if (requirePermissions && !permissionsGranted) {
    return (<div> Unauthorized</div>)
  }
  if (!element) return (null)
  if (loadState === LoadStateModel.LOADED) {
    return element
  }
  return (<LoadingCircle />)
}

export default function AuthorizedRoute(props: CustomRoute) {
  const authorized = useSelector(isAuthorized)
  const isUserLoaded = useSelector(userLoaded)
  if (!authorized) return (<Login />)
  if (!isUserLoaded) return <LoadingCircle />
  return <ErrorBoundary><AuthorizedRouteDataLoader {...props} /></ErrorBoundary>
}
