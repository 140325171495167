import { UserNameBaseModel } from '../baseModel'
import { RoleModel } from './RoleModel'

export interface IUserModel extends UserNameBaseModel {
  firstName: string,
  lastName: string,
  email: string,
  companyId: string,
  companyName: string,
  password?: string,
  lastLogin?: string,
  roles: Array<string>,
  permissions: Array<string>
  tags: Array<string>
}
export class UserModel extends UserNameBaseModel implements IUserModel {
  firstName: string

  lastName: string

  email: string

  companyId: string

  customerIds: string[]

  companyName: string

  password?: string

  lastLogin?: string

  roles: Array<RoleModel>

  permissions: Array<string>

  tags: Array<string> = []

  constructor() {
    super('', '', '')
    this.firstName = ''
    this.lastName = ''
    this.email = ''
    this.companyId = ''
    this.companyName = ''
    this.roles = []
    this.permissions = []
    this.customerIds = []
  }
}
