import { stringArrayToCSV } from 'lib/stringHelper'
import { IValidatedFormAutocompleteOptions } from '@codeflix/mui-managed-form'
import { NamedBaseModel, NamedDescriptionBaseModel, UserNameBaseModel } from '../baseModel'

export const modelToAutocompleteOption = (model: NamedBaseModel) => ({ value: model.id, label: model.name })
export const modelWithDescriptionToAutocompleteOption = (model: NamedDescriptionBaseModel) => ({ value: model.id, label: `${model.name} (${model.description})` })
export const userModelToAutocompleteOption = (model: UserNameBaseModel) => ({ value: model.id, label: `${model.firstName} ${model.lastName} (${model.email}) ${model.tags ? stringArrayToCSV(model.tags) : ''}` })

export const modelOrOptions = (model: NamedBaseModel|undefined, options: IValidatedFormAutocompleteOptions[]) => (model ? [modelToAutocompleteOption(model)] : options)
export const modelWithDescriptionOrOptions = (model: NamedDescriptionBaseModel|undefined, options: IValidatedFormAutocompleteOptions[]) => (model ? [modelWithDescriptionToAutocompleteOption(model)] : options)
export const userModelOrOptions = (model: UserNameBaseModel|undefined, options: IValidatedFormAutocompleteOptions[]) => (model ? [userModelToAutocompleteOption(model)] : options)
