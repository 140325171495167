/* eslint-disable */
import SiteMeasuringsRestModel from "api/siteMeasurings";
import LoadStateModel from "../framework/LoadStateModel";
import {IBaseRestState} from "features/base/BaseRestFeature";
import {SiteMeasuringModel} from "api/models/siteMeasuringModel";
import {AppThunk} from "app/store";

import {createNamedBaseRestSlice} from "features/base/creators/CreateNamedBaseRestSlice";
import i18n from "i18next";
import {displayNotification} from "features/notifications";
import {responseAction} from "features/lib/defaultApiActions";


type DataType = SiteMeasuringModel
const key = 'siteMeasurings'

const initialState: IBaseRestState<DataType> = {
  data: [],
  loadState: LoadStateModel.INITIAL,
  archive: {}
}
export const {actions,index,hooks} = createNamedBaseRestSlice(
  key,
  initialState,
  {},
  SiteMeasuringsRestModel
)
// const { get, update, deleteItem } = actions
const { create,update} = index.actions as any


export const setSiteMeasuringAsAccounted = (data: SiteMeasuringModel[],accounted: boolean) :AppThunk => async (dispatch, getState) => {
  try {
    const newPayload = await SiteMeasuringsRestModel.setAccounted({
      ids: data.map(x=>x.id),
      isAccounted: accounted,
    })
    newPayload.forEach(it=>dispatch(update(it)))
    const message = i18n.t(`api:saveSuccess`)
    const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
    dispatch(snackbar)
    return true;
  }
  catch(e){
    return false;
  }
}
export const setSiteMeasuringCancelled = (data: SiteMeasuringModel[], cancelled: boolean = true) :AppThunk => async (dispatch, getState) => {
  try {
    const newPayload = await SiteMeasuringsRestModel.setCancelled({
      ids: data.map(x=>x.id),
      isCancelled: cancelled,
    })
    newPayload.forEach(it=>dispatch(update(it)))
    const message = i18n.t(`api:saveSuccess`)
    const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
    dispatch(snackbar)
    return true;
  }
  catch(e){
    return false;
  }
}
export const setGdkNoteInAdditionalProperties = (values: {id: string, gdkNote: string}):AppThunk => async (dispatch) => {
  const result = await dispatch(responseAction<SiteMeasuringModel>(
    `api:saveSuccess`,
    SiteMeasuringsRestModel.setGdkNoteAdditionalProperties(values),
  ))
  dispatch(update(result.json))
}

export const useSiteMeasuringById = hooks.useById
export const useSiteMeasurings = hooks.useAll
export const useSiteMeasuringsFiltered = hooks.useFiltered

export default index.reducer
