import { Divider, Stack } from '@mui/material'
import * as React from 'react'
import { FontSizeTool } from 'framework/components/ManagedForm/Fields/ImageEditor/components/FontSizeTool'
import { ZoomSize } from 'framework/components/ManagedForm/Fields/ImageEditor/components/ZoomTool'
import { IToolsProps } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IToolsProps'
import { FullScreenDialogPortal } from 'framework/components/ManagedForm/Fields/FullscreenDialog'

export default function FullScreenToolsPortal(props: IToolsProps) {
  const { t, value, onDelete, onChange, exportImage } = props

  return (
    <FullScreenDialogPortal>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        divider={<Divider light variant="middle" flexItem orientation="vertical" />}
      >
        <ZoomSize {...props} />
        <FontSizeTool {...props} />
        {/* <DownloadItem {...props} /> */}
      </Stack>
    </FullScreenDialogPortal>
  )
}
