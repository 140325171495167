/* eslint-disable */
import RestModel from "api/scaffolders";
import LoadStateModel from "../framework/LoadStateModel";
import {IBaseRestState} from "features/base/BaseRestFeature";
import {ScaffolderModel} from "api/models/scaffolderModel";

import {createNamedBaseRestSlice} from "features/base/creators/CreateNamedBaseRestSlice";


type DataType = ScaffolderModel
const key = 'scaffolders'

const initialState: IBaseRestState<DataType> = {
  data: [],
  loadState: LoadStateModel.INITIAL,
  archive: {}
}
export const {actions,index,hooks} = createNamedBaseRestSlice(
  key,
  initialState,
  {},
  RestModel
)
const { get, update, deleteItem } = actions


export const useScaffolders = hooks.useAll
export const useScaffolder = hooks.useById
export const useScaffolderByPath = () => hooks.useByPathKey('scaffolderId')


export const useScaffolderOptions = hooks.useNamedOptions

export default index.reducer
