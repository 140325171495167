/* eslint-disable */
import CustomersRestModel from "api/customers";
import LoadStateModel from "../framework/LoadStateModel";
import {CustomerModel} from "../api/models/customerModel";
import {IBaseRestState} from "features/base/BaseRestFeature";

import {createNamedBaseRestSlice} from "features/base/creators/CreateNamedBaseRestSlice";


type DataType = CustomerModel
const key = 'customers'

const initialState: IBaseRestState<DataType> = {
  data: [],
  loadState: LoadStateModel.INITIAL,
  archive: {},
}
export const {actions,index,hooks} = createNamedBaseRestSlice(
  key,
  initialState,
  {},
  CustomersRestModel
)
const { get, update, deleteItem } = actions


export const updateCustomer = update
export const deleteCustomer = deleteItem
export const fetchData = get

export const useCustomers = hooks.useAll
export const useCustomer = hooks.useById
export const useCustomerByPath = () => hooks.useByPathKey('customerId')


export const useCustomerOptions = hooks.useNamedOptions

export default index.reducer
