import { useLocation } from 'react-router'
import { useState } from 'react'
import { ClickAwayListener, Collapse, MenuItem, Paper, Popper } from '@mui/material'
import { Stack, styled } from '@mui/system'
import { shouldDisplayChild } from 'models/INavItem'
import { NavItemChildren } from './NavItemChildren'
import {
  INavItemProps,
  NavIconSpan,
  StyledCountBadge,
  StyledExpandable,
  StyledNavButton, StyledNavButtonLink,
  StyledNavExpandableButton,
  StyledNavExpandableIcon,
  useNavItemCount,
} from './NavItemStyleComponents'
import { useOpenState } from '../../../lib/stateHelper'

const StyledPopOverInner = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: 200,
}))
const MuiPopperStyled = styled(Popper)(({ theme }) => ({
  zIndex: 1000,
}))
export function NavItem(props: INavItemProps) {
  const { navItem, isSignedIn, permissionCheck, t, mobile, onClick } = props
  const location = useLocation()
  const matchesRoute = typeof navItem.matchesRoute === 'function' ? navItem.matchesRoute(location.pathname) : false
  const count = useNavItemCount(navItem)
  const { open, toggle, handleClose } = useOpenState(Boolean(mobile && matchesRoute), true)
  // const [expanded, setExpanded] = useState(Boolean(mobile && matchesRoute))

  const [anchorEl, setAnchorEl] = useState<undefined| HTMLButtonElement>(undefined)
  if (navItem.requireLogin && !isSignedIn) return null
  if (navItem.requireLogout && isSignedIn) return null

  if (navItem?.requirePermissions) {
    const allowed = permissionCheck(navItem.requirePermissions)
    if (!allowed) return null
  }

  if (shouldDisplayChild(navItem, matchesRoute)) {
    // if item has children show
    // if should be shown if only route is active....
    return (
      <>
        <StyledNavExpandableButton
          key={navItem.key}
          onClick={(event) => {
            toggle()
            setAnchorEl(open ? undefined : event.currentTarget)
          }}
          size="medium"
          mobile={mobile}
          variant={mobile ? 'text' : 'outlined'}
          fullWidth={!navItem.small}
          expanded={matchesRoute}
          // active={false}
          active={matchesRoute}

        >
          <>
            <NavIconSpan>{navItem.icon}</NavIconSpan>
            {t(navItem.key)}
            <StyledCountBadge mobile={mobile} value={count} max={99} />
            <StyledNavExpandableIcon expanded={open} />
          </>
        </StyledNavExpandableButton>
        {mobile
          ? (
            <Collapse in={open} timeout="auto">
              <StyledExpandable>
                <NavItemChildren {...props} />

              </StyledExpandable>
            </Collapse>
          )
          : (
            <MuiPopperStyled
              open={open}
              // onClose={handleClose}
              anchorEl={anchorEl}
              placement="bottom"
            >
              <ClickAwayListener onClickAway={handleClose}>
                <StyledPopOverInner>
                  <Stack spacing={1}>
                    <NavItemChildren {...props} mobile divider />
                  </Stack>
                </StyledPopOverInner>
              </ClickAwayListener>
            </MuiPopperStyled>
)}

      </>
    )
  }
  return (
    <StyledNavButtonLink
      to={navItem.target}
      variant={mobile ? 'text' : 'outlined'}
      mobile={mobile}
      fullWidth={!navItem.small}
      active={matchesRoute}
    >
      <>
        <NavIconSpan>{navItem.icon}</NavIconSpan>
        {t(navItem.key)}
        <StyledCountBadge mobile={mobile} value={count} max={99} />
      </>
    </StyledNavButtonLink>

  )
}
