import { styled } from '@mui/material/styles'
import {
  AppBar as MaterialAppBar,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import AppLogo from './AppBarItems/AppLogo'
import LoginUser from './AppBarItems/LoginUser'
import Navigation from './AppBarItems/Navigation'
import { useOpenState } from '../../lib/stateHelper'
import { isAuthorized } from '../../features/auth'

const StyledMaterialAppBar = styled(MaterialAppBar)(({ theme }) => ({
  // backgroundColor: 'primary',
  [theme.breakpoints.up('lg')]: {
    zIndex: theme.zIndex.drawer + 1,
  },
  height: '50',
  width: '100%',
}))
const StyledGrid = styled(Grid)(({ theme }) => ({
  height: '100%',
  // paddingTop: theme.spacing(1),
  // paddingLeft: theme.spacing(2),
  // paddingRight: theme.spacing(2),
}))
const FullHeightDiv = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
}))
const AppTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  width: '100%',
  verticalAlign: 'middle',
  height: '100%',
  paddingTop: theme.spacing(1),
}))
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  padding: 0,
  paddingLeft: 0,
  paddingRight: 0,
  backgroundColor: theme.palette.primary.main,
  width: '100%',
}))
const WhiteStack = styled(Stack)(({ theme }) => ({
  backgroundColor: 'white',
}))
const FullWidthStack = styled(Stack)(({ theme }) => ({
  width: '100%',
}))
const StyledHeader = styled('header')(({ theme }) => ({
  display: 'fixed',
}))
const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: 60,
  borderRadius: 0,
}))
function AppBarDesktop() {
  const isSignedIn = useSelector(isAuthorized)

  return (
    <StyledHeader>
      <FullWidthStack >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={4} lg={2}><AppLogo /></Grid>
          <Grid item xs={12} sm={8} lg={10}>
            <StyledPaper>
              <AppTitle variant="h1" color="white" align="center" textAlign="center">Gerüstverwaltung</AppTitle>
            </StyledPaper>
          </Grid>
        </Grid>
        { isSignedIn && (
          <Grid container>
            <Grid item sm={4} lg={2}>
              <LoginUser />
            </Grid>
            <Grid item sm={8} lg={10}>
              <Navigation onClick={() => {}} />

            </Grid>
          </Grid>
        )}
      </FullWidthStack>

    </StyledHeader>
  )
}
const StyledNavigation = styled(Navigation)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}))
function AppBarMobil() {
  const { open, handleOpen, handleClose, toggle } = useOpenState(false)
  const isSignedIn = useSelector(isAuthorized)
  return (
    <>
      <StyledMaterialAppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <AppTitle variant="h1" color="white" align="center" textAlign="center" >Gerüstverwaltung</AppTitle>

        </Toolbar>
      </StyledMaterialAppBar>
      <SwipeableDrawer
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        anchor="left"
      >
        <AppLogo />
        <Divider />
        <LoginUser />
        <Divider />

        {isSignedIn && <StyledNavigation onClick={toggle} mobile />}
      </SwipeableDrawer>
    </>
  )
}

export default function AppBar() {
  return (
    <>
      <Hidden implementation="js" lgUp>
        <AppBarMobil />
      </Hidden>
      <Hidden implementation="css" lgDown>
        <AppBarDesktop />
      </Hidden>
    </>

  )
}
