import { useCallback, useMemo } from 'react'
// eslint-disable-next-line camelcase
import { Stack, Typography } from '@mui/material'
import PopupActionButton from 'framework/components/PopupActions/PopupActionButton'
import { useAppDispatch } from 'app/hooks'
import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { cancelScaffolds, constructScaffolds, deconstructScaffolds } from 'features/scaffoldInquiries'
import { useOpenState } from 'lib/stateHelper'
import { useTranslation } from 'react-i18next'
import { useUserHasAllPermissions, useUserHasPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'
import { CloneAction } from 'ui/views/ScaffoldInquiries/OverviewActions/CloneAction'
import { IProps } from 'ui/views/ScaffoldInquiries/OverviewActions/IProps'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import { ManagedForm } from '@codeflix/mui-managed-form'

interface ICancelActionProps extends IProps {
  mode: 'cancel' | 'deconstruct' | 'construct'
}

function CancelAction({ mode, t, table }: ICancelActionProps) {
  const appDispatch = useAppDispatch()

  const { t: genericT } = useTranslation('generic')
  const openState = useOpenState()
  const { open, handleOpen, toggle, handleClose } = openState
  const handleCancel = useCallback(async (values: any) => {
    const rows = table.getSelectedRowModel()?.rows || []
    const original = rows.map((x) => x.original) as ScaffoldInquiryModel[]
    switch (mode) {
      case 'cancel':
        await appDispatch(cancelScaffolds(original, values.date))
        break
      case 'construct':
        await appDispatch(constructScaffolds(original, values.date))
        break
      case 'deconstruct':
        await appDispatch(deconstructScaffolds(original, values.date))
        break
      default:
        break
    }
    handleClose()
    table.resetRowSelection()
  }, [appDispatch, handleClose, table])
  const dateString = useMemo(() => {
    switch (mode) {
      case 'cancel':
        return t('canceledToDate')
      case 'deconstruct':
        return t('deconstructionDate')
      case 'construct':
        return t('constructionDate')
      default:
        return ''
    }
  }, [mode, t])
  const initial = useMemo(() => ({ date: new Date().toISOString() }), [])
  return (
    <PopupActionButton
      t={t}
      label={t(mode)}
      openState={openState}
    >
      <Typography variant="body1">{t(`${mode}Hint`)}</Typography>
      <ManagedForm
        initial={initial}
        elements={[
          {
            type: 'calendar',
            name: 'date',
            label: dateString,
          },
          {
            type: 'button',
            onClick: handleClose,
            name: 'abort',
            label: genericT('abort'),
            grid: { xs: 12, sm: 6 },
          },
          {
            type: 'submit',
            name: 'save',
            label: genericT('save'),
            color: 'success',
            grid: { xs: 12, sm: 6 },

          },
        ]}
        onSubmit={handleCancel}
        t={t}
      />
    </PopupActionButton>
  )
}

export default function CurrentActions(props: IProps) {
  const isScaffolder = useUserHasAllPermissions([PermissionModel.CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST])
  // const isGdk = useUserHasAllPermissions([PermissionModel.EDIT_ALL_CUSTOMERS])
  const allowEdit = useUserHasPermissions({
    operation: PermissionOperation.OR,
    permissions: [PermissionModel.EDIT_ALL_CUSTOMERS, PermissionModel.EDIT_ALL_SCAFFOLD_REQUESTS_MY_CUSTOMER],
  })
  return (
    <Stack direction="row" spacing={1}>
      {isScaffolder
        && (
        <>
          <CancelAction mode="construct" {...props} />
          <CancelAction mode="deconstruct" {...props} />
        </>
)}
      {allowEdit
        && (
          <>
            <CancelAction mode="cancel" {...props} />
            <CloneAction mode="clone" {...props} />
            <CloneAction mode="convert" {...props} />
          </>
        )}

    </Stack>
  )
}
