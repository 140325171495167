import { NavigateFunction, useLocation } from 'react-router'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'app/hooks'
import SiteMeasuringsRestModel from 'api/siteMeasurings'
import { SiteMeasuringModel } from 'api/models/siteMeasuringModel'
import { actions } from 'features/siteMeasurings'
import LoadingCircle from 'framework/components/DataLoader/LoadingCircle'
import debounce from 'debounce'
import { AppDispatch } from 'app/store'

const createRequest = async (scaffoldInquiryId: string|undefined|null, appDispatch: AppDispatch, navigate: NavigateFunction) => {
  if (!scaffoldInquiryId) return
  const result = await SiteMeasuringsRestModel.createItemServer({
    scaffoldInquiryId,
  }) as SiteMeasuringModel
  if (result) {
    await appDispatch(actions.create(result))
    navigate(`../${result.id}`)
  }
}
const createRequestDebounced = debounce(createRequest)
export function New() {
  const { search } = useLocation()
  const scaffoldInquiryId = useMemo(() => new URLSearchParams(search).get('from'), [search])
  const navigate = useNavigate()
  const appDispatch = useAppDispatch()

  useEffect(() => {
    createRequestDebounced(scaffoldInquiryId, appDispatch, navigate)
  }, [])
  return (<LoadingCircle />)
}
