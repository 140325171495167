import { RestModel } from 'app/RestModel'
import { api } from 'app/apiClient'
import { IExcelExportModel } from 'api/models/excelExportModel'

class ExcelExportRestModel extends RestModel<any> {
  path = 'excelexport'

  getExport(request: IExcelExportModel) {
    return api.post(this.path, { json: request }).blob()
  }
}

export default new ExcelExportRestModel()
