import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useAppDispatch } from '../app/hooks'
import { notificationSelector, removeNotification } from '../features/notifications'

let displayed:any[] = []

export default function Notifier() {
  const dispatch = useAppDispatch()
  const notifications = useSelector(notificationSelector)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = (id:any) => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id:any) => {
    displayed = [...displayed.filter((key) => id !== key)]
  }
  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        // dismiss
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey)
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeNotification(myKey))
          removeDisplayed(myKey)
        },
      })
      if (options.autoClose) {
        setTimeout(() => {
          closeSnackbar(key)
        }, 5000)
      }

      // keep track of snackbars that we've displayed
      storeDisplayed(key)
    })
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}
