import { createSlice, Draft, PayloadAction, Slice } from '@reduxjs/toolkit'
import LoadStateModel from 'framework/LoadStateModel'
import {
  addToNestedState,
  editNestedStateById,
  removeFromNestedState,
  setDataToNestedKey,
} from 'framework/lib/reducerHelper'
import { IBaseModel, INamedBaseModel, INamedCustomerBaseModel, IUpdateResult } from 'api/baseModel'
import { RestModel } from 'app/RestModel'
import { capitalizeFirstLetter } from 'lib/capitalize'
import { AppThunk, RootState, store } from 'app/store'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import i18n from 'i18next'
import { BaseRestReducerActionsType } from 'features/base/BaseRestFeature'
import useNamedOptions from 'lib/useNamedOptions'
import { displayNotification } from 'features/notifications'

export function BaseKeyedModelReducer<State, T extends IBaseModel>(initialState: State, key: keyof T) {
  const payload: any = {}
  return {
    setData: (state: Draft<State>, action: PayloadAction<{ data: T[], parentId: string }>) => {
      if (action) {
        return {
          ...setDataToNestedKey(state, 'data', action.payload.parentId, action.payload.data),
          loadState: LoadStateModel.LOADED,
        }
      }
      return { ...initialState, loadState: LoadStateModel.LOADED }
    },
    create: (state: Draft<State>, action: PayloadAction<T>) => addToNestedState(state, 'data', action.payload[key] as string, action.payload),
    update: (state: Draft<State>, action: PayloadAction<T>) => editNestedStateById(state, 'data', action.payload[key] as string, action.payload),
    deleteItem: (state: Draft<State>, action: PayloadAction<T>) => removeFromNestedState(state, 'data', action.payload[key] as string, action.payload),
    reset: (state: Draft<State>, action: PayloadAction<unknown>) => ({ ...initialState }),
  }
}

export interface IBaseNestedRestState<T extends IBaseModel> {
  data: { [id: string]: T[] },
  loadState: LoadStateModel,
}

export function BaseNestedRestReducerActions<T extends IBaseModel>(name: string, parentId: keyof T, restModel: RestModel<T>, { actions }: Slice<any, any, string>): BaseRestReducerActionsType {
  const capitalized = capitalizeFirstLetter(name)
  const { setData, update, create, deleteItem, reset } = actions as any
  return {
    get: (parameters: any): AppThunk => async (dispatch, getState) => {
      try {
        const userPermissions = getState().user.user?.permissions
        const data = await restModel.getItems(parameters, userPermissions) as T[]
        if (data) {
          dispatch(setData({ data, parentId: parameters[parentId] }))
        }
      } catch (e) {
        // do nothing
      }
    },
    create: (data: T): AppThunk => async (dispatch, getState) => {
      try {
        const response = await restModel.createItem(data) as IBaseModel | string
        if (response) {
          if (typeof response === 'string') {
            dispatch(create({ ...data, response, isNew: false }))
          } else {
            dispatch(create({ ...response }))
          }
          // const id = typeof response === 'string' ? response : response?.id
          // dispatch(create({ ...data, id, isNew: false }))
          const message = `${i18n.t('api:saveSuccess')}`
          const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
          store.dispatch(snackbar)
          return response
        }
      } catch (e) {
        //
      }
      return undefined
    },
    update: (data: T): AppThunk => async (dispatch, getState) => {
      try {
        const response = await restModel.updateItem(data) as IBaseModel | IUpdateResult
        if (response) {
          dispatch(update((response as IUpdateResult)?.isAcknowledged ? data : response as IBaseModel))
          const message = `${i18n.t('api:saveSuccess')}`
          const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
          store.dispatch(snackbar)
          return true
        }
        return false
      } catch (e) {
        //
        return false
      }
    },
    archive: (data: T): AppThunk => async (dispatch, getState) => {
      try {
        const response = await restModel.archiveItem(data)
        if (response) {
          dispatch(update(data))
          return true
        }
        return false
      } catch (e) {
        //
        return false
      }
    },
    deleteItem: (data: T): AppThunk => async (dispatch, getState) => {
      try {
        const response = await restModel.deleteItem(data.id)
        if (response) {
          dispatch(deleteItem(data))
          return true
        }
        return false
      } catch (e) {
        //
        return false
      }
    },
    reset: (): AppThunk => (dispatch) => {
      dispatch(reset())
    },
  }
}

export default function createBaseKeyedRestSlice<T extends IBaseModel>(name: string, parentId: keyof T, initialState: IBaseNestedRestState<T>, reducers: any, restModel: RestModel<T>) {
  const baseReducerActions = BaseKeyedModelReducer(initialState, parentId)
  const index = createSlice({
    name,
    initialState,
    reducers: {
      ...baseReducerActions,
      ...reducers,
    },
  })
  const actions = BaseNestedRestReducerActions(name, parentId, restModel, index)
  const selectors = {
    all: (keyId: string) => (state: RootState) => ((state as any)[name] as IBaseNestedRestState<T>)?.data?.[keyId],
    byId: (keyId: string, id?: string) => (state: RootState) => ((state as any)[name] as IBaseNestedRestState<T>)?.data?.[keyId]?.find((x) => x.id === id),
  }
  const useById = (keyId: string, id?: string): T | undefined => useSelector(selectors.byId(keyId, id))

  const hooks = {
    useById,
    useAll: (keyId: string) => useSelector(selectors.all(keyId)) as T[],
    useByPathKey: (parentKey: string, key: string) => {
      const params = useParams() as any
      const id = params[key]
      const pId = params[parentKey]
      return useById(pId, id)
    },
  }
  return {
    index,
    actions,
    selectors,
    hooks,
  }
}

export function createNamedBaseKeyedRestSlice<T extends INamedBaseModel>(name: string, parentId: keyof T, initialState: IBaseNestedRestState<T>, reducers: any, restModel: RestModel<T>) {
  const { selectors, hooks, ...rest } = createBaseKeyedRestSlice(name, parentId, initialState, reducers, restModel)
  const newSelectors = {
    ...selectors,
  }
  const newHooks = {
    ...hooks,
    useNamedOptions: (keyId: string) => {
      const data = useSelector(newSelectors.all(keyId))
      return useNamedOptions(data || [])
    },
  }
  return {
    selectors: newSelectors,
    hooks: newHooks,
    ...rest,
  }
}

export function createNamedCustomerBaseKeyedRestSlice<T extends INamedCustomerBaseModel>(name: string, parentId: keyof T, initialState: IBaseNestedRestState<T>, reducers: any, restModel: RestModel<T>) {
  const { selectors, hooks, ...rest } = createBaseKeyedRestSlice(name, parentId, initialState, reducers, restModel)
  const newSelectors = {
    ...selectors,
    byCustomerId: (keyId: string, id: string) => (state: RootState) => ((state as any)[name] as IBaseNestedRestState<T>).data[keyId]?.filter((x) => x.customerId === id),
    byId: (keyId: string, id: string) => (state: RootState) => ((state as any)[name] as IBaseNestedRestState<T>).data[keyId]?.find((x) => x.id === id),
  }
  const useByCustomerId = (keyId: string, id: string): T[] | undefined => useSelector(newSelectors.byCustomerId(keyId, id))
  const newHooks = {
    ...hooks,
    useByCustomerId,
    useNamedOptionsByCustomerId: (keyId: string, id: string) => {
      const data = useSelector(newSelectors.byCustomerId(keyId, id))
      return useNamedOptions(data || [])
    },
    useByCustomerIdPathBased: () => {
      const params = useParams() as any
      const { customerId } = params
      const pId = params[parentId]
      return useByCustomerId(pId, customerId)
    },
  }
  return {
    selectors: newSelectors,
    hooks: newHooks,
    ...rest,
  }
}

// export const byCustomerId = (key: string) => (id: string) => (state: RootState) => (state[key] as any).data.filter((x) => x.customerId === id)
