import { IRequiredProp } from 'framework/components/DataLoader/types/IRequiredProp'
import { lazySiteMeasuring, lazySiteMeasuringInspection } from 'ui/views/lazyFetchAllViews'

export const getRequiredPropsToFetch:IRequiredProp[] = [
    { prop: 'customers', fetch: 'always', fetchPolicy: 'lazy' },
    { prop: 'projects', fetch: 'always', fetchPolicy: 'lazy' },
    { prop: 'buildings', fetch: 'always', fetchPolicy: 'lazy' },
    { prop: 'operationUnits', fetch: 'always', fetchPolicy: 'lazy' },
    { prop: 'users', fetch: 'always', fetchPolicy: 'lazy' },
    { prop: 'scaffolders', fetch: 'always', fetchPolicy: 'lazy' },
    { prop: 'scaffoldInquiries', fetch: 'always', fetchPolicy: 'lazy' },
    { prop: 'siteMeasurings', fetch: 'always', fetchPolicy: 'lazy' },
    // lazin
    lazySiteMeasuring,
    lazySiteMeasuringInspection,

  ]
