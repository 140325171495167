import { CustomRoute } from 'models/IRoute'
import BaseRestRoutes from 'framework/components/BaseRestViews/BaseRestRoutes'
import loadLazyAlways from 'ui/views/lazyFetchAllViews'
import PermissionModel from 'models/PermissionModel'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import Overview from './Overview'
import { Edit, New } from './Edit'

const routes: CustomRoute[] = BaseRestRoutes(
  'projects',
  'projectId',
  (<Overview />),
  (<Edit />),
  (<New />),
  {
    operation: PermissionOperation.OR,
    permissions: [PermissionModel.VIEW_ALL_CUSTOMERS, PermissionModel.EDIT_PROJECTS_MY_CUSTOMER],
  },
  undefined,
  [
    { prop: 'customers' },
    { prop: 'projects' },
    ...loadLazyAlways,
  ],
)
export default routes
