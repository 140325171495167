import PermissionModel from 'models/PermissionModel'
import { CustomRoute } from 'models/IRoute'
import BaseRestRoutes from 'framework/components/BaseRestViews/BaseRestRoutes'
import loadLazyAlways from 'ui/views/lazyFetchAllViews'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import Overview from './Overview'
import { Edit, New } from './Edit'

const routes: CustomRoute[] = BaseRestRoutes(
  'buildings',
  'buildingId',
  (<Overview />),
  (<Edit />),
  (<New />),
  { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_ALL_CUSTOMERS] },
  undefined,
  [
    { prop: 'customers' },
    { prop: 'buildings' },
    ...loadLazyAlways,
  ],
)
export default routes
