import { useTheme } from '@mui/material'
import useResizeObserver from '@react-hook/resize-observer'
import { useSize } from 'lib/resizeObserver'
import { Ref } from 'react'

export const useHeight = (divisor: number = 2, reduceBy: number = 0) => {
  const minHeight = 200
  const theme = useTheme()
  const reduceHeightBy = 360 + reduceBy
  // eslint-disable-next-line no-restricted-globals
  const height = Math.floor((screen.availHeight - reduceHeightBy) / divisor)
  // eslint-disable-next-line no-restricted-globals
  // const size = useSize(ref)
  // eslint-disable-next-line no-restricted-globals
  console.log('size', screenY)

  const resultHeight = height > minHeight ? height : minHeight
  // eslint-disable-next-line no-restricted-globals
  console.log('target height', height, minHeight, screen.availHeight)
  return resultHeight
}
