import { useMemo } from 'react'
import { InquiriesMode } from 'ui/views/ScaffoldInquiries/InquiriesMode'
import { TFunction } from 'i18next'
import { useParams } from 'react-router'
import { ILinkStack } from 'framework/components/BreadCrumb/StyledBreadCrumps'
import {
  BreadCrumbYearSelector,
  useArchiveLinkStackTranslated,
} from 'framework/components/BaseRestViews/BreadCrumbYearSelector'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'

export const useScaffoldMetaFromMode = (mode: InquiriesMode, t: TFunction) => {
  const { year } = useParams() as { year: string | undefined }
  const name = useMemo(() => {
    switch (mode) {
      case 'Current':
        return 'scaffoldInquiriesCurrent'
      case 'Archive':
        return 'scaffoldInquiriesArchive'
      case 'New':
      default:
        return 'scaffoldInquiries'
    }
  }, [year, mode, t])
  const baseLinkStack = useArchiveLinkStackTranslated(mode === 'Archive' ? RestViewMode.Archive : RestViewMode.Default, name, mode === 'Archive', 0)

  const linkStack = useMemo(() => {
    if (mode !== 'Archive') return undefined
    const result: ILinkStack[] = [
      { to: '..', text: t(`application:${name}`) },
    ]
    if (mode === 'Archive' && year) {
      return baseLinkStack || []
    }
    return result
  }, [t, name, mode, year, baseLinkStack])
  return { name, linkStack }
}
