import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useMediaQuery, useTheme } from '@mui/material'
import Collapsed from './Collapsed'
import StyledBreadcrumbs, { IProps, StyledBreadCrumbsButton } from './StyledBreadCrumps'

const themeBreakpoint = (linkStackLength: number) => {
  if (linkStackLength <= 2) return 'xs'
  if (linkStackLength <= 3) return 'md'
  if (linkStackLength <= 4) return 'lg'
  if (linkStackLength <= 5) return 'xl'
  return 2500
}

function BreadCrumb({ color, linkStack, ...rest }: IProps) {
  const theme = useTheme()
  const bigScreen = useMediaQuery(theme.breakpoints.up(themeBreakpoint(linkStack.length)))
  const [expanded, setIsExpanded] = useState(bigScreen)
  useEffect(() => {
    setIsExpanded(bigScreen)
  }, [bigScreen])
  if (!expanded) {
    return (<Collapsed expanded={expanded} setExpanded={setIsExpanded} color={color} linkStack={linkStack} {...rest} />)
  }
  return (
    (
      <StyledBreadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        color={color}
      >
        {
          linkStack.map((link: any) => {
            const attributes: any = {}
            if (link.component) {
              return (<div key={`breadcrump-${link.text}`}>{link.component}</div>)
            }
            if (link.to) {
              attributes.component = Link
              attributes.to = link.to
            }
            return (
              <StyledBreadCrumbsButton
                color="inherit"
                key={`breadcrump-${link.text}`}
                variant="text"
                size="large"
                {...attributes}
              >
                {link.text}
              </StyledBreadCrumbsButton>
            )
          })
        }
      </StyledBreadcrumbs>
    )
  )
}

BreadCrumb.defaultProps = {
  color: 'white',
}
export default BreadCrumb
