import * as React from 'react'
import { useCallback } from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import { IToolsProps } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IToolsProps'

export function FontSizeTool(props: IToolsProps) {
  const { onChange, value, t } = props
  const increaseFont = useCallback((mode: 'inc' | 'dec') => () => {
    onChange({
      path: ['text', 'fontSize'],
      value: (value?.text?.fontSize || 18) + (mode === 'inc' ? 2 : -2),
    })
  }, [value?.text?.fontSize, onChange])
  if (!value?.text) return null
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography>
        {t('fontSize')}
      </Typography>
      <IconButton size="small" color="secondary" onClick={increaseFont('dec')}>-</IconButton>

      <Typography>
        {value?.text?.fontSize}
      </Typography>
      <IconButton size="small" color="secondary" onClick={increaseFont('inc')}>+</IconButton>

    </Stack>

  )
}
