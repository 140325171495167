import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo } from 'react'
import { useCustomer } from 'features/customers'
import { actions, deleteScaffoldForced, hooks, updateItemScaffolder } from 'features/scaffoldInquiries'
import { useParams } from 'react-router'
import EditView from 'framework/components/BaseRestViews/EditView'
import { useProjectByCustomerId } from 'features/projects'
import { ScaffoldAgentPhase, ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { useCurrentUser } from 'features/user'
import { useScaffoldInquirySubmit } from 'ui/views/ScaffoldInquiries/useScaffoldInquirySubmit'
import { DateTime } from 'luxon'
import { InquiriesMode } from 'ui/views/ScaffoldInquiries/InquiriesMode'
import { useScaffoldMetaFromMode } from 'ui/views/ScaffoldInquiries/helper'
import { useArchiveLinkStackTranslated } from 'framework/components/BaseRestViews/BreadCrumbYearSelector'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'
import { ManagedPayload, useDataEffect, useManagedForm } from '@codeflix/mui-managed-form'
import { useUserHasAllPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'
import { DeleteEnabled } from 'ui/views/SaveView/DeleteEnabled'
import { useEditorElements, useEvaluatedPhase } from './EditorHelper'

const useScaffoldDeleteAction = (item? : ScaffoldInquiryModel) => {
  const allowAdminDelete = useUserHasAllPermissions([PermissionModel.DELETE_ALL_SCAFFOLDS]) && item?.agentPhase !== ScaffoldAgentPhase.FULLY_SIGNED
  const user = useCurrentUser()
  const allowClientDelete = useUserHasAllPermissions([PermissionModel.DELETE_ALL_CLIENT_PHASE_SCAFFOLDS]) && item?.orderedByUserId === user?.id

  return {
    enabled: !item?.isNew && (allowClientDelete || allowAdminDelete),
    deleteAction: allowAdminDelete ? deleteScaffoldForced : actions.delete,
  }
}
function Editor({ item, mode }: { item?: ScaffoldInquiryModel, mode: InquiriesMode }) {
  const { t } = useTranslation('scaffolds')
  const formManager = useManagedForm(item, true)
  const { values, handleChange } = formManager
  const customer = useCustomer(values.customerId)
  const projects = useProjectByCustomerId(values.customerId)
  const { saveText, disableNextStep, phase } = useEvaluatedPhase(item, customer, values)
  const elements = useEditorElements(item, values, mode)
   useEffect(() => {
    if (item?.isNew) {
      const fields = customer?.individualScaffoldOrderFields.reduce((acc, cur) => ({ ...acc, [cur.id]: '' }), {})
      const workflowFields = customer?.scaffoldWorkflow.reduce((acc, cur) => ({
        ...acc,
        [cur.id]: { workflowStepId: cur.id, userId: undefined, approved: false, approvedOn: undefined },
      }), {})
      handleChange('individualFields')(fields)
      handleChange('clientWorkflowStatus')(workflowFields)
    }
  }, [item, customer, handleChange])
  const projectSelected = useMemo(() => projects?.find((x) => values?.projectId === x.id), [projects, values?.projectId])

  useDataEffect(() => {
    if (projectSelected && projectSelected.defaultOrderNr) handleChange('orderNr')(projectSelected.defaultOrderNr)
  }, [projectSelected, handleChange])
  useDataEffect(() => {
    // todo this should not triggered if disabled
    if (values.preferredConstructionDate && !values.preferredDeconstructionDate) {
      const construction = DateTime.fromISO(values?.preferredConstructionDate)
      const deconstruction = construction.plus({ days: 28 }).toISODate()
      handleChange('preferredDeconstructionDate')(deconstruction)
    }
  }, [handleChange, values?.preferredConstructionDate])
  useEffect(() => {
    if (values?.type === 'New') {
      // remove referenceId
      handleChange('scaffoldReferenceId')(undefined)
    }
  }, [values?.type, handleChange])

  const handleSubmit = useScaffoldInquirySubmit(item)
  const { name, linkStack: linkStackBasis } = useScaffoldMetaFromMode(mode, t)
  const baseLinkStack = useArchiveLinkStackTranslated(mode === 'Archive' ? RestViewMode.Archive : RestViewMode.Default, name, false)
  const linkStack = useMemo(() => ([
    ...(baseLinkStack || [{ to: '..', text: t(`application:${name}`) }]), // { to: '..', text: t('application:constructions') },
    { text: !(item?.isNew) ? item!.serialNumber?.fullSerial || '' : t('new') },
  ]), [baseLinkStack, t, name, item])
  const { enabled, deleteAction } = useScaffoldDeleteAction(item)
  const actions2 = useMemo(() => ({
    ...actions,
    update: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER ? updateItemScaffolder : actions.update,
    delete: deleteAction,
  }), [item, deleteAction])
  // console.log('actions', actions2.update === updateItemScaffolder, updateItemScaffolder.toString())
  return (
    <EditView
      loading={!item}
      linkStack={linkStack}
      elements={elements}
      formManager={formManager}
      actions={actions2}
      item={item}
      disableAll={item?.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED || disableNextStep}
      debug
      saveView={{
              disableDraft: !values?.customerId || disableNextStep,
              // onDraft: handleDraft,
              disabled: disableNextStep,
              saveText,
              // saveGrid: { xs: 12, sm: 6, md: 4 },
              enableDelete: (enabled) ? DeleteEnabled.Always : DeleteEnabled.Never,
            }}
      middlewares={{
              onSubmit: handleSubmit('submit'),
              onDraft: handleSubmit('draft'),
            }}
      t={t}
    />
  )
}

export function Edit({ mode }: {mode: InquiriesMode}) {
  const { scaffoldInquiryId } = useParams() as { scaffoldInquiryId: string }
  const item = hooks.useById(scaffoldInquiryId)
  return (
    <Editor item={item} mode={mode} />
  )
}

export function New() {
  const user = useCurrentUser()
  const item = new ScaffoldInquiryModel(user!.id, user?.companyId)
  return (
    <Editor item={item} mode="New" />
  )
}
export function ViewArchive() {
  const { year, scaffoldInquiryId } = useParams() as { year: string, scaffoldInquiryId: string }
  const item = hooks.useArchiveDataById(year, scaffoldInquiryId)
  return (
    <Editor item={item} mode="Archive" />
  )
}
