import OperationUnitsRestModel from 'api/operationUnits'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { IBaseRestState } from 'features/base/BaseRestFeature'
import { createNamedCustomerBaseRestSlice } from 'features/base/creators/CreateNamedCustomerBaseRestSlice'
import LoadStateModel from '../framework/LoadStateModel'
import { OperationUnitModel } from '../api/models/operationUnitModel'

type DataType = OperationUnitModel
const key = 'operationUnits'

const initialState: IBaseRestState<DataType> = {
  data: [],
  loadState: LoadStateModel.INITIAL,
  archive: {},
}
export const { actions, index, hooks, selectors } = createNamedCustomerBaseRestSlice(
  key,
  initialState,
  {},
  OperationUnitsRestModel,
)
const { get, update, deleteItem } = actions
export const updateOperationUnit = update
export const deleteOperationUnit = deleteItem
export const fetchData = get

export const useOperationUnitById = hooks.useById
export const useOperationUnits = hooks.useAll
export const useOperationUnitsByCustomerId = hooks.useByCustomerId

export const useOperationUnitsByPath = hooks.useByCustomerIdPathBased

export const useOperationUnitByKey = (key: string) => {
  const params = useParams() as any
  const data = useSelector(selectors.all())
  const id = params[key]
  return () => {
    data.find((x) => x.id === id)
  }
}
export const useOperationUnitByPath = () => hooks.useByPathKey('operationUnitId')

export const useOperationUnitOptions = hooks.useNamedOptionsByCustomerId

export default index.reducer
