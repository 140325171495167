import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { hooks, useSiteMeasurings } from 'features/siteMeasurings'
import { Stack } from '@mui/material'
import OverviewView from 'framework/components/BaseRestViews/OverviewView'
import { SiteMeasuringModel, SiteMeasuringState } from 'api/models/siteMeasuringModel'
import { useUserHasAllPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'
import { useScaffolders } from 'features/scaffolders'
import { useOperationUnits } from 'features/operationUnits'
import { useBuildings } from 'features/buildings'
// eslint-disable-next-line camelcase
import { useParams } from 'react-router'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'
import DownloadSiteMeasuringPdf from 'ui/views/SiteMeasurings/Actions/DownloadSiteMeasuringPdf'
import { IMappedData } from 'ui/views/SiteMeasurings/models/IMappedData'
import { useSiteMeasuringColumns } from 'ui/views/SiteMeasurings/UseSiteMeasuringColumns'
import CurrentActions from './OverviewActions/CurrentActions'

const useScaffoldInquiryMetadata = (item: SiteMeasuringModel) => {
  const matchingScaffolder = useScaffolders()
  const operationUnits = useOperationUnits()
  const buildings = useBuildings()
}

export function SiteMeasuringData({ mappedData, mode }:{mappedData: IMappedData[], mode: RestViewMode}) {
  const { t } = useTranslation('siteMeasuring')
  const isGDK = useUserHasAllPermissions([PermissionModel.GDK_REVIEW_SITE_MEASURING])
  // eslint-disable-next-line camelcase
  const columns = useSiteMeasuringColumns()

  const renderActions = useCallback(({ table }:any) => (
    (table?.getIsSomeRowsSelected() || table?.getIsAllRowsSelected())
      ? (
        <CurrentActions t={t} table={table} />
      ) : <div >abc</div>
  ), [t])
  return (

    <OverviewView<IMappedData>
      columns={columns as any}
      renderSelectionActions={renderActions}
      data={mappedData || [] as IMappedData[]}
      viewMode={mode}
      name={t('application:siteMeasuring').toString()}
      initialState={{
        showColumnFilters: false,
        columnVisibility: {
          id: false,
          scaffolderId: isGDK,
          scaffolder: false,
          building: false,
          area: false,
          orderNr: false,
        },
      }}
      // muiTableBodyCellProps={({ row }) => ({
      //   sx: {
      //     textDecoration: row.original.state === SiteMeasuringState.CANCELLED ? 'line-through' : undefined,
      //   },
      // })}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '', // change header text
        },
      }}
      enableRowActions
      enableRowSelection={(row) => (row.original as SiteMeasuringModel)?.state === SiteMeasuringState.FULLY_SIGNED && !(row.original as SiteMeasuringModel).isAccounted}
      positionActionsColumn="last"
      renderRowActions={({ table, row }) => (
        <Stack direction="row" spacing={1}>
          <DownloadSiteMeasuringPdf disabled={(row.original as IMappedData).state !== SiteMeasuringState.FULLY_SIGNED} scaffoldInquiry={(row.original as IMappedData).scaffoldInquiry} />
        </Stack>
      )}
    />
  )
}

export default function Overview() {
  const data = useSiteMeasurings()
  return (
    <SiteMeasuringData mappedData={data as IMappedData[]} mode={RestViewMode.Default} />
  )
}

export function OverviewArchive() {
  const { year } = useParams() as { year: string }
  const data = hooks.useArchiveData(year)
  return (
    <SiteMeasuringData mappedData={data as IMappedData[]} mode={RestViewMode.Archive} />

  )
}
