import { IScaffoldInquiryModelAggregated } from 'api/models/scaffoldModel'
import { IValidatedFormAutocompleteOptions } from '@codeflix/mui-managed-form'
import { useMemo } from 'react'

export function useReferenceScaffoldAutocompleteOptions(item: IScaffoldInquiryModelAggregated | undefined, scaffoldOptionsActive: IValidatedFormAutocompleteOptions[]) {
  const scaffoldOptions = useMemo(() => {
    if (item?.scaffoldReferenceSerialNumber) {
      if (scaffoldOptionsActive.some((x) => x.value === item.scaffoldReferenceId)) return scaffoldOptionsActive // do not concat if in array
      return [
        {
          label: item.scaffoldReferenceSerialNumber.fullSerial,
          value: item.scaffoldReferenceId,
        },
      ].concat(scaffoldOptionsActive)
    }
    return scaffoldOptionsActive
  }, [scaffoldOptionsActive, item])
  return scaffoldOptions
}
