import { RestModel } from 'app/RestModel'
import PermissionModel from 'models/PermissionModel'
import { SiteMeasuringTemplateModel } from 'api/models/siteMeasuringTemplateModel'

class SiteMeasuringTemplatesRestModel extends RestModel<SiteMeasuringTemplateModel> {
  path = 'siteMeasuringTemplate'

  getItems = async (parameters: any, permissions: string[]|undefined) => {
    const fetchAll = permissions?.includes(PermissionModel.EDIT_SITE_MEASURING_TEMPLATES)

    return super.getItems(parameters, permissions, fetchAll)
  }
}

export default new SiteMeasuringTemplatesRestModel()
