import { CustomRoute } from 'models/IRoute'
import React from 'react'
import BaseRestRoutes from 'framework/components/BaseRestViews/BaseRestRoutes'
import PermissionModel from 'models/PermissionModel'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import Overview, { OverviewArchive } from './Overview'
import { Edit, New, ViewArchive } from './Edit'

const routes: CustomRoute[] = BaseRestRoutes(
  'siteMeasuringInspection',
  'siteMeasuringInspectionId',
  (<Overview />),
  (<Edit />),
  (<New />),

  { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_ALL_SITE_MEASURING] },
  undefined,
  [
    { prop: 'customers' },
    { prop: 'projects' },
    { prop: 'buildings' },
    { prop: 'operationUnits' },
    { prop: 'users' },
    { prop: 'scaffolders' },
    { prop: 'scaffoldInquiries', fetch: 'always' },
    { prop: 'siteMeasurings', fetch: 'always' },
    { prop: 'siteMeasuringInspections', fetch: 'always' },
  ],
)
const routesArchive: CustomRoute[] = BaseRestRoutes(
  'siteMeasuringInspection/archive/:year',
  'siteMeasuringInspectionId',
  (<OverviewArchive />),
  (<ViewArchive />),
  (<></>),

  { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_ALL_SITE_MEASURING] },
  undefined,
  [
    { prop: 'customers' },
    { prop: 'projects' },
    { prop: 'buildings' },
    { prop: 'operationUnits' },
    { prop: 'users' },
    { prop: 'scaffolders' },
    { prop: 'scaffoldInquiries', fetch: 'always' },
    { prop: 'siteMeasurings', fetch: 'always' },
    { prop: 'siteMeasuringInspections', fetch: 'always' },
    {
      prop: 'siteMeasuringInspections',
      fetch: 'always',
      mode: 'archive',
      nested: [{ prop: 'archive', key: 'year' }],

    },
  ],
)
export default routes.concat(routesArchive)
