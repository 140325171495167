import { PropsWithChildren } from 'react'
import { styled } from '@mui/material/styles'
import { Paper } from '@mui/material'

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
}))
export default function DashboardPaper({ children }: PropsWithChildren) {
  return (
    <StyledPaper elevation={6}>

      {children}
    </StyledPaper>
  )
}
