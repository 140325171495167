/* eslint-disable */
import RestModel from "api/scaffoldInquiriesAttachments";
import LoadStateModel from "../framework/LoadStateModel";
import {IScaffoldInquiryAttachment} from "api/models/scaffoldInquiryAttachment";
import {createNamedBaseKeyedRestSlice, IBaseNestedRestState} from "features/base/BaseNestedRestFeature";


type DataType = IScaffoldInquiryAttachment
const key = 'scaffoldInquiriesAttachments'

const initialState: IBaseNestedRestState<DataType> = {
  data: {},
  loadState: LoadStateModel.INITIAL,
}
export const {actions,index,hooks} = createNamedBaseKeyedRestSlice<IScaffoldInquiryAttachment>(
  key,
  'scaffoldInquiryId',
  initialState,
  {},
  RestModel
)


const { get, update, deleteItem } = actions

export const useScaffoldInquiryAttachments = (scaffoldInquiryId?: string) => hooks.useAll(scaffoldInquiryId||'')


// const fetch = (pa)
export default index.reducer
