import { Checkbox } from '@mui/material'
// eslint-disable-next-line camelcase
import { MRT_ColumnDef, MRT_RowData } from 'material-react-table'

export function CheckboxCell({ cell }:any) {
  return (
    <Checkbox checked={cell.getValue()} />
  )
}
// export const CheckboxCellProps:Partial<MRT_ColumnDef<any>> = {
//   Cell: CheckboxCell,
//   filterFn: 'equals',
//   filterVariant: 'checkbox',
// }
// eslint-disable-next-line camelcase
export function CheckboxCellProps<T extends MRT_RowData>():Partial<MRT_ColumnDef<T>> {
  return {
    Cell: CheckboxCell,
    filterFn: 'equals',
    filterVariant: 'checkbox',
  }
}
