import * as React from 'react'
import { DragEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  aspectRatioScaling,
  useSizeScaleImage,
} from 'framework/components/ManagedForm/Fields/ImageEditor/libs/UseSizeScaleImage'
import { useRefreshingState } from 'framework/lib/useRefreshingState'
import FullScreenToolsPortal
  from 'framework/components/ManagedForm/Fields/ImageEditor/components/FullScreenToolsPortal'
import { Divider, Grid, Stack, Typography } from '@mui/material'
import { FullWidthDiv } from 'lib/resizeObserver'
import Center from 'framework/components/Center'
import DraggablePin from 'framework/components/ManagedForm/Fields/ImageEditor/components/DraggablePin'
import DraggableTextField from 'framework/components/ManagedForm/Fields/ImageEditor/components/DraggableTextField'
import Editor from 'framework/components/ManagedForm/Fields/ImageEditor/components/Editor'
import { IEditProps } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IEditProps'
import { useDataEffect } from '@codeflix/mui-managed-form'

const EditView = forwardRef((props: IEditProps, ref) => {
  const target = useRef<any>(null)
  const stage = useRef<any>(null)
  const { t } = useTranslation('imageInput')
  // const { scaleX, scaleY, size } = useSizeScale(target, 1280)
  const { onChange, value } = props
  const { scaleRatio, size } = useSizeScaleImage(target, props.imageBitmap)
  const [zoom, setZoom] = useRefreshingState(scaleRatio)
  // console.log('image scale to fit', scaleToFit)
  const handleDrop = useCallback((e: DragEvent<HTMLDivElement>) => {
    const dragType = e.dataTransfer.getData('dragType')
    const payload = e.dataTransfer.getData('payload')
    e.preventDefault()
    stage.current.setPointersPositions(e)
    // get pin
    const position = stage.current.getRelativePointerPosition()
    switch (dragType) {
      case 'location':
        props.onChange({
          path: ['pin'],
          value: position,
        })
        break
      case 'text':
        props.onChange({
          path: ['text'],
          value: {
            ...position,
            fontSize: 18,
            value: payload,
          },
        })
        break
      default:
        break
    }
  }, [props.onChange, props.value])
  const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }, [])
  const exportImage = useCallback(async () => {
    if (stage.current) {
      // generate exported image
      try {
        const image = stage.current.toDataURL({
          pixelRatio: 1 / zoom,
        })

        const imageBlob = await (await fetch(image)).blob()
        // generate thumbnail image
        const { scaleRatio } = aspectRatioScaling(size, { width: 400, height: 400 })
        const thumbnail = stage.current?.toDataURL({
          quality: 0.7,
          pixelRatio: scaleRatio,

        })
        const thumbnailBlob = await (await fetch(thumbnail)).blob()
        // const thumbnailBlob = await (await (fetch(thumbnail))).blob()
        // const thumbnailBlob = await resizeImage(imageBlob, size, { width: 720, height: 720 })
        // return imageBlob
        // const image
        onChange({
          path: ['image'],
          value: imageBlob,
        })
        onChange({
          path: ['thumbnail'],
          value: thumbnailBlob,
        })
      } catch (e) {
        console.log('konva exporterror', e)
      }
    }
    // return undefined
  }, [onChange, zoom])
  useImperativeHandle(ref, () => ({ exportImage }), [exportImage])
  const { pin, text } = value
  useDataEffect(() => {
    // console.log('pin changed', pin, text)
    // todo redo
    exportImage()
  }, [pin, text])
  return (
    <>
      <FullScreenToolsPortal
        t={t}
        {...props}
        size={size}
        zoom={zoom}
        setZoom={setZoom}
        maxZoom={scaleRatio}
        scaleX={scaleRatio}
        scaleY={scaleRatio}
        exportImage={exportImage}
      />
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={10}>
          <FullWidthDiv ref={target}>
            <Center>
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <Editor
                  ref={stage}
                  size={size}
                  scaleX={zoom}
                  scaleY={zoom}
                  {...props}
                />
              </div>
            </Center>
          </FullWidthDiv>
        </Grid>
        <Grid item xs={2}>
          <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="center">
            <Grid item><Typography variant="body2">{t('tooltipDraggable')}</Typography></Grid>
            <Grid item>
              <DraggablePin zoom={zoom} />
            </Grid>
            <Grid item>
              <DraggableTextField />
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>

  )
})
export default EditView
