import { CustomRoute } from 'models/IRoute'
import Scaffold from 'ui/views/Redirect/Scaffold'

const routes: CustomRoute[] = [

  {
    path: 'redirect/scaffold',
    authorized: true,
    element: <Scaffold />,
    fetch: [

      {
        prop: 'scaffoldInquiries',
        fetch: 'always',
      },
    ],
  },
]
  //
  // [
  //   { prop: 'customers' },
  //   { prop: 'projects' },
  //   { prop: 'buildings' },
  //   { prop: 'operationUnits' },
  //   { prop: 'users' },
  //   { prop: 'scaffolders' },
  //   { prop: 'scaffoldInquiries',
  //     fetch: 'always',
  //     fetchPolicy: 'lazy',
  //   },
  //   { prop: 'scaffoldInquiries',
  //     mode: 'archive',
  //     fetch: 'always',
  //     nested: [{ prop: 'archive', key: 'year' }],
  //   },
  // ],
  // [
  //   {
  //     prop: 'scaffoldInquiriesAttachments',
  //     nested: [{ prop: 'data', key: 'scaffoldInquiryId' }],
  //     fetch: 'always' },
  // ],

export default routes
