import { MouseEvent, useCallback } from 'react'

export const useDefaultButtonEventHandler = (action: (...anyParams:any)=>void|Promise<void>) => useCallback((...params: any) => (event:MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    return action(...params)
  }, [action])
export const useDefaultMenuItemEventHandler = (action: (...anyParams:any)=>void|Promise<void>) => useCallback((...params: any) => (event:MouseEvent<HTMLLIElement>) => {
    event.preventDefault()
    event.stopPropagation()
    return action(...params)
  }, [action])
