import { useMemo } from 'react'
import { IScaffoldInquiryAttachment } from 'api/models/scaffoldInquiryAttachment'
import { ScaffolderModel } from 'api/models/scaffolderModel'
import { NamedCustomerBaseModel } from '../baseModel'
import { ApproverStep, CustomerModel, CustomerSpecificField } from './customerModel'
import { numberOrFallback } from '../../lib/numberHelper'
import { BuildingModel } from './buildingModel'
import { ProjectModel } from './projectModel'
import { OperationUnitModel } from './operationUnitModel'
import { UserModel } from './UserModel'

export enum ScaffoldAgentPhase
{
  CLIENT='CLIENT', GDK='GDK', SCAFFOLDER='SCAFFOLDER', FULLY_SIGNED='FULLY_SIGNED',
}
export enum ScaffoldState {
  'DRAFT'='DRAFT',
  'DRAFT'='DRAFT',
  'CLIENT_REVIEW'='CLIENT_REVIEW',
  'CLIENT_TRANSMITTED'='CLIENT_TRANSMITTED',
  GDK_DRAFT= 'GDK_DRAFT',
  GDK_TRANSMITTED='GDK_TRANSMITTED',
  SCAFFOLDER_DRAFT='SCAFFOLDER_DRAFT',
  SCAFFOLDER_CONSTRUCTION_CREATED='SCAFFOLDER_CONSTRUCTION_CREATED'
}
export interface IScaffoldWorkflow {
  workflowStepId: string, userId: string|undefined, approved?: boolean, approvedOn?: string
}
export interface ICounterResult {
  serialNumber: number,
  prefix?: string,
  fullSerial: string,
}
export interface IScaffoldDetails {
  length?: number
  height?: number
  depth?: number
  footPointHeight?: number
  stageCount?: number
}
export interface IApproval {
  approvedBy: string
  approverEmail: string,
  approverCompany: string,
}
export class ScaffoldInquiryModel extends NamedCustomerBaseModel {
  serialNumber: undefined|ICounterResult

  copiedFromScaffoldInquiryId?: string

  siteMeasuringId?: string // id for site measuring request

  operationUnitId: string|undefined

  buildingId: string|undefined

  area: string

  projectId: string|undefined

  clientApproval?: IApproval

  csOrderId? : string

  orderNr: string

  preferredConstructionDate?: string

  preferredDeconstructionDate?: string

  adHocOrder: boolean

  permitRequired?: boolean

  type: 'New'|'Change'

  scaffoldReferenceId?: string

  scaffoldReferenceSerialNumber?: ICounterResult

  rentFreeInDays?: number

  orderedByUserId: string

  description: string

  state: ScaffoldState

  customerIndividualFields?: CustomerSpecificField[]|undefined

  clientWorkflowPhase: number = -1

  clientWorkflowSteps?: ApproverStep[]|undefined

  individualFields: { [key: string]: any }

  clientWorkflowStatus: { [key: string]: IScaffoldWorkflow}

  agentPhase: ScaffoldAgentPhase

  scaffolderId?: string

  constructionDate?: string

  deconstructionDate?: string

  canceledToDate?: string

  singleLoadTonnes?: number

  loadClass?: number

  scaffold?: IScaffoldDetails

  attachments: IScaffoldInquiryAttachment[] = []

  constructor(currentUserId: string, customerId?: string) {
    super(customerId)
    this.name = ''
    this.type = 'New'
    this.adHocOrder = false
    this.orderNr = ''
    this.area = ''
    this.buildingId = undefined
    this.orderedByUserId = currentUserId
    this.permitRequired = undefined
    this.description = ''
    this.state = ScaffoldState.DRAFT
    this.clientWorkflowPhase = -1
    this.individualFields = {}
    this.clientWorkflowStatus = {}
    this.isNew = true
    this.agentPhase = ScaffoldAgentPhase.CLIENT
  }
}
export type EvaluatedPhase = {
 step: ApproverStep|undefined,
 nextStep: ApproverStep|undefined,
 currentPhase: IScaffoldWorkflow|undefined,
 nextPhase: IScaffoldWorkflow|undefined,
}
export const getScaffoldPhase = (
  model: ScaffoldInquiryModel|undefined,
  customerModel: CustomerModel|undefined,
) => {
  if (model?.isNew && customerModel && model.clientWorkflowStatus) {
    const phase = numberOrFallback(model.clientWorkflowPhase, -1)
    const step = customerModel?.scaffoldWorkflow[phase]
    const nextStep = customerModel?.scaffoldWorkflow[phase + 1] // might be out of index

    const currentPhase = step && model?.clientWorkflowStatus[step?.id]
    const nextPhase = nextStep && model?.clientWorkflowStatus[nextStep?.id]

    return {
      step,
      nextStep,
      currentPhase,
      nextPhase,
    }
  }
  if (!model || typeof model.clientWorkflowSteps === 'undefined' || typeof model.clientWorkflowStatus === 'undefined') {
    return undefined
  }

  const phase = numberOrFallback(model.clientWorkflowPhase, -1)
  const step = model.clientWorkflowSteps[phase]
  const nextStep = model.clientWorkflowSteps[phase + 1] // might be out of index
  return {
    step,
    nextStep,
    currentPhase: step && model.clientWorkflowStatus[step.id],
    nextPhase: nextStep && model.clientWorkflowStatus[nextStep.id],
  }
}
export const useEvaluatedScaffoldPhase = (model: ScaffoldInquiryModel|undefined, customerModel: CustomerModel|undefined):EvaluatedPhase|undefined => useMemo(() => getScaffoldPhase(model, customerModel), [model, customerModel])

export interface IScaffoldInquiryModelAggregated extends ScaffoldInquiryModel {
  customer?: CustomerModel
  building?: BuildingModel

  scaffolder?: ScaffolderModel
  project?: ProjectModel|undefined

  operationUnit?: OperationUnitModel
  orderedByUser?: UserModel

}
export interface IScaffoldScaffolderRequest {

  scaffoldInquiryId: string
  constructionDate?: string

  deconstructionDate?: string

  singleLoadTonnes?: number

  loadClass?: number

  scaffold?: IScaffoldDetails

  sign:boolean
}

export class ScaffoldInquiryServerModel extends ScaffoldInquiryModel {
  currentWorkflowStep: ApproverStep|undefined

  nextWorkflowStep: ApproverStep|undefined

  nextWorkflowPhase : IScaffoldWorkflow|undefined

  currentWorkflowPhase : IScaffoldWorkflow|undefined
}
