import { useSelector } from 'react-redux'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getUser, useCurrentUser } from 'features/user'
import Center from 'framework/components/Center'
import FormattedDate from 'framework/components/FormattedDate'
import ChangePassword from 'ui/views/Account/ChangePassword'
import ButtonLink from 'framework/components/ButtonLink'
import { useMyCompany } from 'features/companies'

export default function Index() {
  const { t } = useTranslation('user')
  const user = useCurrentUser()
  const company = useMyCompany()
  if (!user) return (null)
  return (
    <Center>
      <Stack spacing={2}>
        <Typography variant="h2">{t('greeting', { ...user })}</Typography>
        {
          user?.lastLogin && <Typography>{t('lastLogin')} <FormattedDate noTypography date={user?.lastLogin} format="DATETIME" /></Typography>
        }
        <ButtonLink to="./change-password" variant="contained">{t('changePassword')}</ButtonLink>
      </Stack>
    </Center>
  )
}
