import { RestModel } from 'app/RestModel'
import PermissionModel from 'models/PermissionModel'
import { api } from 'app/apiClient'
import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { CustomerModel } from './models/customerModel'

class CustomersRestModel extends RestModel<CustomerModel> {
  path = 'customer'

  getItems = async (parameters: any, permissions: string[]|undefined) => {
    const fetchAllUsers = permissions?.includes('VIEW_ALL_CUSTOMERS')
    if (fetchAllUsers) return super.getItems(parameters, permissions, fetchAllUsers)

    if (permissions?.includes(PermissionModel.VIEW_CUSTOMERS_WITH_SCAFFOLD_FOR_MY_COMPANY)) {
      // scaffolder
      return await api.get(`${this.path}/MyScaffoldCustomers`).json() as CustomerModel[]
    }

    return super.getItems(parameters, permissions, fetchAllUsers)
  }
}

export default new CustomersRestModel()
