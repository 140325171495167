import FullScreenDialog from 'framework/components/ManagedForm/Fields/FullscreenDialog'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, useMediaQuery } from '@mui/material'
import ScaffoldInquiryViewer from 'ui/views/SiteMeasurings/ScaffoldInquiryViewer'
import EditView from 'framework/components/BaseRestViews/EditView'
import React, { useMemo } from 'react'
import { ScaffoldAgentPhase, ScaffoldInquiryModel, ScaffoldState } from 'api/models/scaffoldModel'
import { Theme } from '@mui/system'
import { SiteMeasuringAgentPhase, SiteMeasuringModel, SiteMeasuringState } from 'api/models/siteMeasuringModel'
import { SiteMeasuringAccountingTabs } from 'api/models/siteMeasuringTemplateModel'

interface IProps {
  open: boolean
  handleClose: () => void
  values: { name: string, tabs: SiteMeasuringAccountingTabs[] }
}

const exampleInquiry: ScaffoldInquiryModel = {
  adHocOrder: false,
  agentPhase: ScaffoldAgentPhase.FULLY_SIGNED,
  area: '',
  attachments: [],
  buildingId: undefined,
  clientWorkflowPhase: 0,
  clientWorkflowStatus: {},
  createdOn: '',
  description: '',
  id: '',
  individualFields: {},
  csOrderId: '00001',
  permitRequired: true,
  isNew: false,
  modifiedOn: '',
  name: '',
  operationUnitId: undefined,
  orderNr: '',
  orderedByUserId: '',
  projectId: undefined,
  serialNumber: { serialNumber: 0, fullSerial: '000000' },
  state: ScaffoldState.SCAFFOLDER_CONSTRUCTION_CREATED,
  type: 'New',

}
const actions = {
  create: () => () => {
  },
  delete: () => () => {
  },
  update: () => () => {
  },
}
const itemGrid = { xs: 12, sm: 6, md: 3 }
const itemGridDouble = { xs: 12, sm: 12, md: 6 }

const item: SiteMeasuringModel = {
  agentPhase: SiteMeasuringAgentPhase.FULLY_SIGNED,
  createdOn: '',
  id: '',
  isNew: false,
  modifiedOn: '',
  name: '',
  scaffoldInquiryId: '',
  scaffolderId: '',
  state: SiteMeasuringState.FULLY_SIGNED,
  siteMeasuring: {},
}
export default function SiteMeasuringPreview({ open, handleClose, values }: IProps) {
  const { t } = useTranslation('siteMeasuring')
  const big = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const elements = useMemo(() => ([
    {
      type: 'object',
      variant: 'tab',
      name: 'siteMeasuring',
      required: true,
      grid: { xs: 12, md: 12 },
      elements: values?.tabs?.map((tab) => (
        {
          type: 'object',
          name: tab.id,
          label: tab.name,
          variant: 'none',
          elements: tab.groups.map((group) => (
            {
              type: 'object',
              name: group.id,
              label: group.name || ' ',
              variant: 'borderbox',
              elements: group.fields.map((field) => ({
                type: field.type.toLowerCase(),
                name: field.id,
                tooltip: field.fullName,
                label: field.shortName,
                unit: field.unit,
                grid: field.type === 'MULTILINE' ? itemGridDouble : itemGrid,
              })),
            }
          )),
        }
      )),
    },

  ]), [values])
  const mappedItem = useMemo(
    () => ({
      siteMeasuring: values.tabs.reduce((acc, tab) => ({
        ...acc,
        [tab.id]: tab.groups.reduce((groupAcc, group) => ({
          ...groupAcc,
          [group.id]: {},
        }), {}),
      }), {}),
    }),
    [values],
  ) as SiteMeasuringModel
  console.log('values', values)
  return (
    <FullScreenDialog open={open} title={t('generic:preview')} onClose={handleClose}>
      <Grid container spacing={0}>

        <Grid item xs={12} lg={3}>
          <ScaffoldInquiryViewer item={exampleInquiry} />
        </Grid>
        <Grid item xs={1}><Divider orientation={big ? 'vertical' : 'horizontal'} /></Grid>
        <Grid item xs={12} lg={8}>
          <EditView
            elements={elements}
            actions={actions}
            item={mappedItem}
            t={t}
            debug
            saveView={{
              // saveGrid: { xs: 12, sm: 6, md: 4 },
              disabled: true,
              disableAbort: true,
              disableDraft: true,
            }}
          />
        </Grid>
      </Grid>
    </FullScreenDialog>

  )
}
