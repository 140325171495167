export const numberOrFallback = (value: number|string|undefined, fallback: number = 0) => {
  let mappedValue = value
  if (typeof mappedValue === 'string') mappedValue = parseInt(value as string, 10)

  if (typeof mappedValue === 'undefined') return fallback
  if (Number.isNaN(mappedValue)) return fallback
  if (!mappedValue && mappedValue !== 0) return fallback
  return mappedValue
}
export const floatOrFallback = (value: number|string|undefined, fallback: number = 0) => {
  let mappedValue = value
  if (typeof mappedValue === 'string') mappedValue = parseFloat(value as string)

  if (typeof mappedValue === 'undefined') return fallback
  if (Number.isNaN(mappedValue)) return fallback
  if (!mappedValue && mappedValue !== 0) return fallback
  return mappedValue
}
