import { PropsWithChildren } from 'react'
import { Backdrop, CircularProgress, Grid } from '@mui/material'
import { DefaultPaper } from 'framework/components/DefaultPaper'
import { styled } from '@mui/system'

interface LoginFormsWrapperProps {
  backdropOpen?: boolean
}
const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}))
export default function LoginFormsWrapper({ children, backdropOpen } : PropsWithChildren<LoginFormsWrapperProps>) {
  return (
    <>
      <StyledBackdrop open={Boolean(backdropOpen)}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <DefaultPaper elevation={24}>
            {children}
          </DefaultPaper>
        </Grid>
      </Grid>
    </>

  )
}
