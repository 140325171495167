import { v4 as uuidv4 } from 'uuid'
import { NamedBaseModel } from 'api/baseModel'

export class SiteMeasuringAccountingGroupField {
  fullName: string = ''

  id: string = uuidv4()

  shortName: string = ''

  type: 'TEXT' | 'NUMBER' | 'FLOAT' |'MULTILINE' = 'TEXT'

  unit: string = ''
}

export class SiteMeasuringAccountingFieldGroups {
  name: string = ''

  id: string = uuidv4()

  fields: SiteMeasuringAccountingGroupField[] = []
}

export class SiteMeasuringAccountingTabs {
  name: string = ''

  id: string = uuidv4()

  groups: SiteMeasuringAccountingFieldGroups[] = []
}

export class SiteMeasuringTemplateModel extends NamedBaseModel {
  tabs: SiteMeasuringAccountingTabs[] = []
}
