import { v4 as uuidv4 } from 'uuid'
import { SiteMeasuringAccountingFieldGroups } from 'api/models/siteMeasuringTemplateModel'
import { CompanyModel, ICompanyModel } from 'api/models/companyModel'
import { CompanyType } from 'api/models/companyType'

export enum FormFieldRequiredOptions {
  ALWAYS='ALWAYS',
  NEVER='NEVER',
  DRAFT='DRAFT',
  CUSTOMER_REVIEW='CUSTOMER_REVIEW',
  TRANSMITTED_TO_GDK='TRANSMITTED_TO_GDK',
  GDK_REVIEWED='TRANSMITTED_TO_GDK',
  SCAFFOLDER_REVIEWED='TRANSMITTED_TO_GDK'
}

export class ApproverStep {
  id: string

  sortPosition : number

  name: string

  action: 'notify' | 'approve'

  statusOnCompletion?: string

  constructor() {
    this.id = uuidv4()
    this.sortPosition = 0
    this.name = ''
    this.action = 'approve'
    this.statusOnCompletion = undefined
  }
}
export type CustomerSpecificFieldType = 'text' | 'int'
export class CustomerSpecificField {
  id: string

  name: string

  type: CustomerSpecificFieldType

  required: 'NEVER'| 'ALWAYS'| 'CUSTOMER_REVIEW'| 'TRANSMITTED_TO_GDK'| 'GDK_REVIEWED'| 'SCAFFOLDER_REVIEWED'

  constructor() {
    this.id = uuidv4()
    this.name = ''
    this.type = 'text'
    this.required = 'NEVER'
  }
}

export interface ICustomerModel extends ICompanyModel {
  type: CompanyType.CUSTOMER
  individualScaffoldOrderFields: CustomerSpecificField[],
  scaffoldWorkflow: ApproverStep[]

  siteMeasuringAccountingFieldGroups: SiteMeasuringAccountingFieldGroups[]
}
export class CustomerModel extends CompanyModel implements ICustomerModel {
  type: CompanyType.CUSTOMER = CompanyType.CUSTOMER

  individualScaffoldOrderFields: CustomerSpecificField[]

  scaffoldWorkflow: ApproverStep[]

  siteMeasuringAccountingFieldGroups: SiteMeasuringAccountingFieldGroups[] = []

  constructor() {
    super()
    this.individualScaffoldOrderFields = []
    this.scaffoldWorkflow = []
  }

  archived?: boolean | undefined
}
