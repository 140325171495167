import { styled } from '@mui/system'
import { Button, Grid } from '@mui/material'
import { MouseEvent, PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Archive, Delete } from '@mui/icons-material'
import AsyncActionButton from 'framework/components/AsyncActionButton'
import { DeleteEnabled } from 'ui/views/SaveView/DeleteEnabled'
import PopupActionButton, { ManagedPopupActionButton } from 'framework/components/PopupActions/PopupActionButton'
import { AsyncStoreActionButton } from 'framework/components/BaseRestViews/AsyncStoreActionButton'
import { restartClientWorkflow } from 'features/scaffoldInquiries'
import AsyncConfirmation from 'framework/components/BaseRestViews/AsyncConfirmation'

export const MarginTop2Grid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))
interface IActionProp {
  handler: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>,
  enabled: boolean
}
export interface ISaveViewProps {
  disabled?:boolean,
  isValid: boolean,
  onAbort?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>,
  onSubmit: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>,
  onDelete: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>,
  onArchive: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>,
  disableDraft?: boolean,
  onDraft?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>,
  onCheckInput?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>,
  saveText?: string,
  saveGrid?: {xs?: number, sm?: number, md?: number, lg?: number, xl?:number}
  hideDraft?:boolean
  archive?: IActionProp
  enableArchive?: boolean,
  enableDelete?: DeleteEnabled,
  disableAbort?: boolean
  customActions?: ReactNode,
}

export default function SaveView({ disabled, customActions, onSubmit, enableArchive, onArchive, enableDelete, onDelete, disableAbort, saveGrid, hideDraft, onDraft, onAbort, isValid, onCheckInput, disableDraft, saveText }:PropsWithChildren<ISaveViewProps>) {
  const { t } = useTranslation('generic')
  return (
    <MarginTop2Grid spacing={2} container justifyContent="flex-end" alignItems="center">
      {customActions}
      {enableArchive && typeof onArchive === 'function' && (
        <Grid item xs={12} sm={2}>
          <AsyncActionButton disabled={disabled} variant="contained" startIcon={<Archive />} color="secondary" fullWidth onClick={onArchive}><Archive />{t('archive')}</AsyncActionButton>
        </Grid>
      )}
      {(typeof enableDelete !== 'undefined' && [DeleteEnabled.Always, DeleteEnabled.IfEnabled].includes(enableDelete!) && typeof onDelete === 'function') && (
        <Grid item xs={12} sm={2}>
          <AsyncConfirmation
            onConfirm={onDelete}
            buttonProps={{
              variant: 'contained',
              startIcon: <Delete />,
              color: 'error',
              fullWidth: true,
            }}
            label={t('delete')}
            confirmLabel={(<><Delete />{t('delete')}</>)}
            description={t('confirmDelete')}
          />

          {/* <AsyncActionButton onClick={onDelete}><Delete />{t('delete')}</AsyncActionButton> */}
        </Grid>
      )}
      {!hideDraft && typeof onDraft === 'function' && (
        <Grid item xs={12} md={4}>
          <AsyncActionButton variant="contained" disabled={disableDraft} fullWidth onClick={onDraft}>{t('draft')}</AsyncActionButton>
        </Grid>
      )}
      <Grid item xs={12} md={2}>
        <Button variant="contained" disabled={disableAbort} color="inherit" fullWidth onClick={onAbort}>{t('abort')}</Button>
      </Grid>
      <Grid item xs={12} md={4} {...saveGrid}>
        {
          isValid
          && (
          <AsyncActionButton
            onClick={onSubmit}
            variant="contained"
            color="primary"
            fullWidth
            disabled={disabled}
          >{saveText || t('save')}
          </AsyncActionButton>
)
        }{
          !isValid && typeof onCheckInput !== 'undefined' && <AsyncActionButton disabled={disabled} onClick={onCheckInput} variant="contained" color="primary" fullWidth>{ t('check')}</AsyncActionButton>

        }
      </Grid>
    </MarginTop2Grid>
  )
}
