import { PropsWithChildren } from 'react'
import { MuiManagedFormProvider } from '@codeflix/mui-managed-form'
import { fieldResolver } from './FieldResolver'

export default function ManagedFormProvider({
                                                    children,
                                                  }: PropsWithChildren<any>) {
  return (
    <MuiManagedFormProvider fieldResolver={fieldResolver}>
      {children}
    </MuiManagedFormProvider>
  )
}
