import { useTranslation } from 'react-i18next'
import { useCurrentScaffolds } from 'features/scaffoldInquiries'
import LocalizedPlot from 'framework/components/Charts/LocalizedPlot'
import { sumNumbers, useGroupedDataForPlotly } from 'framework/lib/useGroupedDataForPlotly'
import { useOperationUnits } from 'features/operationUnits'
import { CustomerOnlyView } from 'ui/userRoles/CustomerOnlyView'
import { Paper, Typography } from '@mui/material'
import { useHeight } from 'ui/views/Dashboard/lib/useHeight'

function InternalPlot() {
  const { t } = useTranslation('dashboard')

  const lookup = useOperationUnits()
  const scaffolds = useCurrentScaffolds()
  const data = useGroupedDataForPlotly(scaffolds, lookup, 'operationUnitId')
  const height = useHeight(1)

  return (
    <Paper elevation={6}>
      <LocalizedPlot
        title={t('countOfScaffoldsByOperationUnit')}
        data={[{
          labels: data.labels,
          values: data.values,
          type: 'pie',
        }]}
        layout={{
          height,
          legend: {
            orientation: 'h',
          },
        }}
      />
      <Typography textAlign="center">{t('totalCountOfScaffolds')}: {sumNumbers(data.values)}</Typography>

    </Paper>
  )
}
export default function CurrentScaffoldsByOperationUnit() {
  return (
    <CustomerOnlyView>
      <InternalPlot />
    </CustomerOnlyView>
  )
}
