import { CustomRoute } from 'models/IRoute'
import React from 'react'
import BaseRestRoutes from 'framework/components/BaseRestViews/BaseRestRoutes'
import { lazySiteMeasuringInspection } from 'ui/views/lazyFetchAllViews'
import PermissionModel from 'models/PermissionModel'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import { New } from 'ui/views/SiteMeasurings/New'
import Overview, { OverviewArchive } from './Overview'
import { Edit, ViewArchive } from './Edit'

const routes: CustomRoute[] = BaseRestRoutes(
  'siteMeasuring',
  'siteMeasuringId',
  (<Overview />),
  (<Edit />),
  (<New />),

  { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_MY_CUSTOMER] },
  undefined,
  [
    { prop: 'customers' },
    { prop: 'projects' },
    { prop: 'buildings' },
    { prop: 'operationUnits' },
    { prop: 'users' },
    { prop: 'scaffolders' },
    { prop: 'scaffoldInquiries', fetch: 'always' },
    { prop: 'siteMeasurings', fetch: 'always' },
    lazySiteMeasuringInspection,
  ],
)
const routesArchive: CustomRoute[] = BaseRestRoutes(
  'siteMeasuring/archive/:year',
  'siteMeasuringId',
  (<OverviewArchive />),
  (<ViewArchive />),
  (<New />),

  { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_MY_CUSTOMER] },
  undefined,
  [
    { prop: 'customers' },
    { prop: 'projects' },
    { prop: 'buildings' },
    { prop: 'operationUnits' },
    { prop: 'users' },
    { prop: 'scaffolders' },
    { prop: 'scaffoldInquiries', fetch: 'always' },
    { prop: 'siteMeasurings', fetch: 'always' },
    {
      prop: 'siteMeasurings',
      mode: 'archive',
      fetch: 'always',
      nested: [{ prop: 'archive', key: 'year' }],
    },
    lazySiteMeasuringInspection,
  ],
)
export default routes.concat(routesArchive)
