import { Component, ErrorInfo, PropsWithChildren } from 'react'

import ErrorView from 'framework/views/Error'

interface IProps {
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends Component<PropsWithChildren<IProps>, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    console.error(error)
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error('rendererror', error, errorInfo)
    // this.setState({ hasError: true })
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { hasError } = this.state
    if (hasError) {
      // You can render any custom fallback UI
      return <ErrorView />
    }

    return this.props.children
  }
}

export default ErrorBoundary
