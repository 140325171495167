import { ReactNode, MouseEvent, useCallback, useMemo, useState } from 'react'
import { TFunction } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { BaseRestReducerActionsType } from 'features/base/BaseRestFeature'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'
import { useDefaultButtonEventHandlerDispatchParameterAction } from 'framework/lib/useDispatchAction'
import { ValidatedForm, useManagedForm, ManagedFormType } from '@codeflix/mui-managed-form'
import SaveView from 'ui/views/SaveView/SaveView'
import { DeleteEnabled } from 'ui/views/SaveView/DeleteEnabled'
import LoadingCircle from '../DataLoader/LoadingCircle'
import { ILinkStack } from '../BreadCrumb/StyledBreadCrumps'
import BreadCrumb from '../BreadCrumb'
import { IBaseModel, OptionalId } from '../../../api/baseModel'
import { useAppDispatch } from '../../../app/hooks'

interface IEditProps<T extends OptionalId<IBaseModel>> {
  item?: T,
  debug?: boolean
  viewMode?: RestViewMode
  disableAll?: boolean
  saveView?: {
    disabled?: boolean,
    disableDraft?: boolean,
    disableAbort?: boolean,
    // onDraft?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>,
    saveText?: string | undefined,
    saveGrid?: { xs?: number, sm?: number, md?: number, lg?: number, xl?: number },
    hideDraft?: boolean,
    customActions?: ReactNode,
    enableDelete?: DeleteEnabled,
    enableArchive?: boolean,
  },
  elements: Array<any>,
  actions: BaseRestReducerActionsType,
  t: TFunction,
  middlewares?: {
    onSubmit: (values: any) => any | Promise<any>,
    onDraft: (values: any) => any | Promise<any>,
  }
}

interface IProps<T extends OptionalId<IBaseModel>> extends IEditProps<T> {
  loading?: boolean,
  linkStack?: ILinkStack[],
  formManager?: ManagedFormType,
  item?: T,
}

function Edit<T extends OptionalId<IBaseModel>>({
                                                  t,
                                                  disableAll,
                                                  debug,
                                                  item,
                                                  middlewares,
                                                  saveView,
                                                  elements,
                                                  actions,
                                                  formManagerProps,
                                                }: IEditProps<T> & ManagedFormType) {
  const navigate = useNavigate()
  const appDispatch = useAppDispatch()

  const handleDelete = useCallback(async () => {
    const success = await appDispatch(actions.delete(formManagerProps.value))
    navigate('..')
  }, [appDispatch, actions, formManagerProps.value])

  const archiveEvent = useDefaultButtonEventHandlerDispatchParameterAction(actions.archive)
  const handleArchive = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const result = archiveEvent(formManagerProps.value)(event)
    if (result) {
      navigate('..')
    }
  }, [formManagerProps, navigate, archiveEvent])
  // const handleArchive = useCallback(async () => await actions.archive(values), [appDispatch, actions, values])
  const handleSubmit = useCallback((mode: 'onSubmit' | 'onDraft') => async () => {
    let valuesToSend = formManagerProps.value
    if (middlewares && typeof middlewares[mode] === 'function') valuesToSend = await middlewares[mode](formManagerProps.value)
    if (valuesToSend.isNew) {
      const result = await appDispatch(actions.create(valuesToSend))

      if (result) {
        navigate('..')
      }
    } else {
      const success = await appDispatch(actions.update(valuesToSend))
      if (success) navigate('..')
    }
  }, [navigate, middlewares, actions, appDispatch, formManagerProps.value])
  const handleAbort = useCallback(() => {
    navigate('..')
  }, [navigate])
  const [highlightErrorsAlways, setHighlightErrorsAlways] = useState(false)
  const handleCheckInput = useCallback(() => {
    setHighlightErrorsAlways((prev) => !prev)
  }, [setHighlightErrorsAlways])
  return (
    <>
      <ValidatedForm
        t={t}
        {...formManagerProps}
        disableAll={Boolean(disableAll)}// item?.archived || Boolean(disableAll || formManagerProps.value?.archived)}
        highlightErrorsAlways={highlightErrorsAlways}
        elements={elements}
        debug={debug}
        onSubmit={handleSubmit('onSubmit')}
      />
      <SaveView
        onAbort={handleAbort}
        isValid={formManagerProps.valid}
        onCheckInput={handleCheckInput}
        onSubmit={handleSubmit('onSubmit')}
        onDraft={handleSubmit('onDraft')}
        onDelete={handleDelete}
        onArchive={handleArchive}
        {...saveView}
        disabled={saveView?.disabled} // || formManagerProps.value?.archived}
      />
    </>
  )
}

function ManagedEdit<T extends OptionalId<IBaseModel>>({ item, ...rest }: IEditProps<T>) {
  const formManagerProps = useManagedForm({
    ...item,
  })
  // useEffect(() => {
  //   managedForm.setAll(item)
  // }, [item])
  return <Edit {...formManagerProps} {...rest} />
}

export default function EditView<T extends OptionalId<IBaseModel>>({
                                                                     loading,
                                                                     linkStack,
                                                                     formManager,
                                                                     item,
                                                                     ...rest
                                                                   }: IProps<T>) {
  const Component: any = useMemo(() => (formManager ? Edit : ManagedEdit), [formManager])
  if (loading) return <LoadingCircle />

  return (
    <>
      {linkStack && (
        <BreadCrumb
          color="black"
          linkStack={linkStack}
        />
      )}
      <Component
        item={item}
        {...formManager}
        {...rest}
      />
    </>
  )
}
