import { Backdrop, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { styled } from '@mui/system'
import { useAppDispatch } from 'app/hooks'
import { changePassword } from 'features/user'
import Center from 'framework/components/Center'
import { ManagedForm } from '@codeflix/mui-managed-form'

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}))

export default function ChangePassword() {
  const { t } = useTranslation('user')
  const navigate = useNavigate()
  const appDispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const onSubmit = useCallback(async (values: any) => {
    const success = await appDispatch(changePassword(values.currentPassword, values.newPassword))
    if (success) navigate('..')
  }, [appDispatch, navigate])

  return (
    <Center>
      <Typography variant="h2">{t('changeMyPassword')}</Typography>
      <ManagedForm
        initial={{ password: '', comparePassword: '' }}
        elements={[
          {
            type: 'password',
            name: 'currentPassword',
            details: true,
            required: true,
          },
          {
            type: 'password',
            name: 'newPassword',
            details: true,
            required: true,
          },
          {
            type: 'password',
            name: 'comparePassword',
            required: true,
            details: true,
            conditionalProps: (element: any, value: any) => ({
              comparePassword: value.newPassword,
            }),
          },
          {
            type: 'submit',
            name: 'submitChangePassword',
            variant: 'contained',
          },
        ]}
        onSubmit={onSubmit}
        // onChange={handleChange}
        t={t}
      />
    </Center>
  )
}
