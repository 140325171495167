import { PropsWithChildren } from 'react'
import { IPermissionRequirement } from 'framework/auth/IPermissionRequirement'
import { useUserHasPermissions } from 'framework/auth/permissions'

interface IOptionalPermissionViewProps {
  permissions: IPermissionRequirement
}

export default function OptionalPermissionView({ children, permissions }: PropsWithChildren<IOptionalPermissionViewProps>) {
  const haspermission = useUserHasPermissions(permissions)
  if (!haspermission) return (null)
  return children
}
