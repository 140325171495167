import PopupActionButton, { ManagedPopupActionButton } from 'framework/components/PopupActions/PopupActionButton'
import { DeleteEnabled } from 'ui/views/SaveView/DeleteEnabled'
import { Delete } from '@mui/icons-material'
import { Button, ButtonProps } from '@mui/material'
import AsyncActionButton from 'framework/components/AsyncActionButton'
import { useTranslation } from 'react-i18next'
import { useOpenState } from '@codeflix/mui-managed-form'
import { MouseEvent, ReactNode, useCallback } from 'react'
import { useDefaultButtonEventHandler } from 'framework/lib/useDefaultEventHandler'

interface IProps {
  disabled?: boolean,
  buttonProps: ButtonProps,
  label: string|ReactNode,
  description : string | ReactNode,
  confirmLabel?: string|ReactNode
  onConfirm: (event: MouseEvent<HTMLButtonElement>)=>void | Promise<void>,
  onAbort?: (event: MouseEvent<HTMLButtonElement>)=>void | Promise<void>,
}
export default function AsyncConfirmation({ disabled, buttonProps, confirmLabel, label, description, onConfirm, onAbort }: IProps) {
  const { t } = useTranslation('generic')
  const openState = useOpenState()
  const { open, handleOpen, handleClose } = openState
  const handleAbort = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    handleClose()
    if (typeof onAbort === 'function') onAbort(event)
    else {
      event.preventDefault()
      event.stopPropagation()
    }
  }, [handleClose, onAbort])
  const handleConfirm = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    handleClose()
    onConfirm(event)
  }, [handleClose, onConfirm])
  return (
    <PopupActionButton
      disabled={disabled}
      openState={openState}
      buttonProps={buttonProps}
      t={t}
      actions={(
        <>
          <Button fullWidth variant="outlined" onClick={handleAbort}>{t('generic:abort')}</Button>
          <AsyncActionButton fullWidth variant="outlined" color="error" onClick={handleConfirm}>{confirmLabel || t('confirm')}</AsyncActionButton>
        </>
      )}
      label={label}
    >
      {description}
    </PopupActionButton>
  )
}
