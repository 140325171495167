import { hooks } from 'features/scaffoldInquiries'
import { ScaffoldAgentPhase } from 'api/models/scaffoldModel'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import useParsedSearchParams from 'lib/useParsedSearchParams'

export default function Scaffold() {
  const params = useParsedSearchParams()
  const id = params.get('id') || undefined
  const scaffold = hooks.useById(id)
  const navigate = useNavigate()
  useEffect(() => {
    if (scaffold) {
      if (scaffold.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED) {
        navigate(`/scaffolds/${scaffold.id}`)
      } else navigate(`/scaffoldInquiries/${scaffold.id}`)// redirect(`/scaffoldInquiries/${scaffold.id}`)
    }
  }, [scaffold, navigate])

  return (null)
}
