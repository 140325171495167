import ky, { HTTPError, Options, RetryOptions } from 'ky'
import { Input } from 'ky/distribution/types/options'
import i18n from 'i18next'

import { getToken, removeToken } from 'features/auth'
import { store } from './store'
import { displayNotification } from '../features/notifications'

interface BaseErrorModel {
  status: number,
  success: boolean,
  traceId: string,
  title: string,
}

interface ApiError {
  type: string,
  message: string,
  isTerminal: boolean,
}

interface BaseResponseModel extends BaseErrorModel {
  metadata: {
    statusCode: number,
    status: number,
    success: boolean,
    requestId: string,
    errors: Array<ApiError>,
    version: string,
    build: string
  },
  payload: any,
}

const evaluateErrors = (response: BaseResponseModel) => {
  let errorDisplayed = false
  response.metadata.errors.forEach((error) => {
    if (error.type === 'NOT_LOGGED_IN') {
      store.dispatch(removeToken())
      // store.dispatch(resetUser())
      // history
      // .store.dispatch(push('/sign-in'))
    }
    const message = `${i18n.t(`api:${error.type}`)}, ${i18n.t('api:requestId')}: ${response.metadata.requestId}`
    const snackbar = displayNotification(message, { variant: 'error', autoClose: true })
    store.dispatch(snackbar)
    errorDisplayed = true
  })
  if (!errorDisplayed) {
    const message = `${i18n.t('api:generic')}, ${i18n.t('api:requestId')}: ${response.metadata.requestId}`
    const snackbar = displayNotification(message, { variant: 'error', autoClose: true })
    store.dispatch(snackbar)
  }
}
export const { apiUrl } = window
export const timezoneOffset = new Date().getTimezoneOffset() * -1
const backendApi = ky.extend({
  prefixUrl: apiUrl,
  retry: { afterStatusCodes: [502, 504, 500] } as RetryOptions,
  throwHttpErrors: false,
  timeout: 30000, // 30 seconds timeout

  hooks: {
    beforeRequest: [
      (request, options) => {
        if (!request.headers.get('Authorization')) {
          request.headers.set('Authorization', `Bearer ${getToken()}`)
        }
        request.headers.set('X-DateTimeOffset', timezoneOffset.toString())
      },
    ],
    afterResponse: [
      async (_request, _options, response) => {
        // You could do something with the response, for example, logging.
        // debugger
        if (response == null) {
          // debugger
        }
        if (response.type === 'cors') {
            // return new Response(null, { status: 200 })
           // return response
          // return
        }
        const contentType = response.headers.get('content-type')

        if (contentType?.startsWith('application/problem+json') || response.status === 500) {
          const innerJson: any = await response.json()
          if (response?.status == 400 && !innerJson.metadata && innerJson.title === 'One or more validation errors occurred.') {
            let message = `${i18n.t('api:validationError')}, ${i18n.t('api:requestId')}: ${innerJson.traceId}`
            if (typeof innerJson.errors.File !== 'undefined') {
              const error = innerJson.errors.File[0]
              if (error?.startsWith('MimeTypeMismatch')) {
                message = `${i18n.t('api:fileMimeTypeMismatch')}, ${i18n.t('api:requestId')}: ${innerJson.traceId}`
              } else if (error === 'VirusCheckFailed') {
                message = `${i18n.t('api:virusCheckFailed')}, ${i18n.t('api:requestId')}: ${innerJson.traceId}`
              } else message = `${i18n.t('api:corruptFile')}, ${i18n.t('api:requestId')}: ${innerJson.traceId}`
            }
            const snackbar = displayNotification(message, { variant: 'error', autoClose: true })
            store.dispatch(snackbar)
          } else {
            evaluateErrors(innerJson)
          }
          return new Response(null, { status: response?.status })
        }

        if (!contentType?.startsWith('application/json')) {
          // console.log(_request, _options, response)
          return response
        }

        const json: BaseResponseModel = await response.json()
        // console.log(response.status, json.title)
        if (response.status !== 200) {
          if (response.status === 400 && !json.metadata && json.title === 'One or more validation errors occurred.') {
            const message = `${i18n.t('api:validationError')}, ${i18n.t('api:requestId')}: ${json.traceId}`
            const snackbar = displayNotification(message, { variant: 'error', autoClose: true })
            store.dispatch(snackbar)
          } else if (response.status === 400) {
            await evaluateErrors(json)
          } else if (
            response.status === 401
          ) {
            console.log('api', 'unauthorized')
          } else {
            // evaluateErrors(json)
          }
          // json.payload = undefined
          return new Response(JSON.stringify(json.payload), { status: response.status })

          // throw Error('Could not fetch data')
        }
        if (!json.metadata?.success) {
          await evaluateErrors(json)
          // console.error(json) // ToDo dispatch this stuff
          // throw Error('Could not fetch data')
          json.payload = undefined
          return new Response(JSON.stringify(json.payload), { status: response.status })
        }
        if (_request.method === 'DELETE') {
          const message = i18n.t('api:deleteSuccessful')
          const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
          store.dispatch(snackbar)
          // const message = i18n.t('generic:deleteSuccessful')
          // const snackbar = enqueueSnackbar({ message, options: { variant: 'success' }, autoClose: true })
          // store.dispatch(snackbar)
        }
        // Or return a `Response` instance to overwrite the response.
        const mapStatusCode = typeof json.metadata.statusCode === 'number' ? json.metadata.statusCode : 200
        return new Response(JSON.stringify(json.payload), { status: mapStatusCode, headers: response.headers })
      },
    ],
  },
})

const requestCatch = (e: HTTPError) => {
  if ([200, 204, 400, 500].includes(e?.response?.status)) return
  console.log('err', e)
  const message = i18n.t('api:networkError')
  const snackbar = displayNotification(message, { variant: 'error', autoClose: true })
  store.dispatch(snackbar)
}
const backendApiCatchError = {
  // ...backendApi,
  get: (url: Input, options?: Options) => {
    const promise = backendApi.get(url, options)
    promise.catch(requestCatch)
    return promise
  },
  post: (url: Input, options?: Options) => {
    const promise = backendApi.post(url, options)
    promise.catch(requestCatch)
    return promise
  },
  put: (url: Input, options?: Options) => {
    const promise = backendApi.put(url, options)
    promise.catch(requestCatch)
    return promise
  },
  delete: (url: Input, options?: Options) => {
    const promise = backendApi.delete(url, options)
    promise.catch(requestCatch)
    return promise
  },
  patch: (url: Input, options?: Options) => {
    const promise = backendApi.patch(url, options)
    promise.catch(requestCatch)
    return promise
  },

}
export const api = backendApiCatchError
