import { PropsWithChildren, useEffect } from 'react'
import { createPortal } from 'react-dom'

interface IProps {
  id: string
}
 function DivPortal({ id, children }:PropsWithChildren<IProps>) {
  const appBarPortal = document.getElementById(id)
  const element = document.createElement('div')

  useEffect(() => {
    if (appBarPortal == null) {
      return () => {
      }
    }
    appBarPortal.appendChild(element)
    return () => {
      // unmount
      appBarPortal.removeChild(element)
    }
  }, [appBarPortal, element])
  return createPortal(children, element, id)
}

export const PortalWithId = (id:string) => function Portal(props: PropsWithChildren<any>) {
  return <DivPortal id={id} {...props} />
}
