import { SiteMeasuringAgentPhase, SiteMeasuringModel, SiteMeasuringState } from 'api/models/siteMeasuringModel'
import { getUser, useCurrentUser } from 'features/user'
import { userHasAllPermissions, useUserHasAllPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'
import { useMemo } from 'react'
import { RootState } from 'app/store'
import { useTranslation } from 'react-i18next'

export const unreadCountSelectorSiteMeasuring = (state: RootState) => {
  const agentPhases: SiteMeasuringAgentPhase[] = []
  const currentUser = getUser(state)
  if (userHasAllPermissions(currentUser, [PermissionModel.GDK_REVIEW_SITE_MEASURING])) {
    // is gdk
    agentPhases.push(SiteMeasuringAgentPhase.GDK)
  }
  if (userHasAllPermissions(currentUser, [PermissionModel.CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST])) {
    // is scaffolder
    agentPhases.push(SiteMeasuringAgentPhase.SCAFFOLDER)
  }
  return state.siteMeasurings.data.filter((x) => x.state !== SiteMeasuringState.FULLY_SIGNED && agentPhases.includes(x.agentPhase)).length
}
export const useSiteMeasuringPermissions = (item?: SiteMeasuringModel) => {
  const currentUser = useCurrentUser()
  const isGDK = useUserHasAllPermissions([PermissionModel.GDK_REVIEW_SITE_MEASURING])
  return useMemo(() => {
    const isScaffolder = currentUser?.companyId === item?.scaffolderId
    return { isScaffolder, isGDK }
  }, [item, currentUser, isGDK])
}
export const useSiteMeasuringSiteSaveViewOptions = (item?: SiteMeasuringModel) => {
  const { isScaffolder, isGDK } = useSiteMeasuringPermissions(item)
  const { t } = useTranslation('siteMeasuring')
  let isDisabled = false
  let disableScaffolderInput = false
  let showGdkValues = false
  switch (item?.agentPhase) {
    case SiteMeasuringAgentPhase.SCAFFOLDER:
      isDisabled = !isScaffolder
      if (item.state === SiteMeasuringState.REJECTED_BY_GDK) showGdkValues = true
      break
    case SiteMeasuringAgentPhase.GDK:
      disableScaffolderInput = true
      isDisabled = !isGDK
      showGdkValues = isGDK
      break
    default:
      isDisabled = true
      break
  }
  if (item?.state === SiteMeasuringState.CANCELLED) isDisabled = true

  let saveText = t('signScaffolder')
  if (item?.agentPhase === 'GDK') {
    saveText = item?.state === SiteMeasuringState.FULLY_SIGNED ? t('signGdkAgain') : t('signGdk')
  }
  const saveViewOptions = {
    disableDraft: isDisabled || item?.agentPhase !== SiteMeasuringAgentPhase.SCAFFOLDER,
    disabled: isDisabled,
    saveText,
  }
  return {
    saveViewOptions,
    showGdkValues,
    disableScaffolderInput,
    isGDK,
    isScaffolder,
    isDisabled,
  }
}
