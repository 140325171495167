import i18n from 'i18next'
// todo if multi language will be added we need to handle decimal below
const pathToFormData = (path: any[]) => path.join('.')

const nestedJsonToFormData = (formData: FormData, path: any[], payload:{[key:string]:any}) => {
  Object.keys(payload).forEach((key) => {
    if (typeof payload[key] === 'undefined') return
    if (payload[key] instanceof Blob) formData.append(pathToFormData(path.concat([key])), payload[key])
    if (typeof payload[key] === 'object') nestedJsonToFormData(formData, path.concat([key]), payload[key])
    else if (Array.isArray(payload[key])) {
      (payload[key] as any[]).forEach((nKey, index) => {
        nestedJsonToFormData(formData, path.concat(key, [index]), payload[key][index])
        })
    } else if (typeof payload[key] === 'number' && payload[key] % 1 !== 0) {
      let numberValue = payload[key].toString()
        if (i18n.language === 'de') numberValue = numberValue.replace('.', ',')
        formData.append(pathToFormData(path.concat([key])), numberValue)
      } else formData.append(pathToFormData(path.concat([key])), payload[key])
  })
}

export const jsonToFormData = (payload:{[key:string]:any}) => {
  const formData = new FormData()
  nestedJsonToFormData(formData, [], payload)
  return formData
}
