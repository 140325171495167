import * as React from 'react'
import { useCallback } from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import { IToolsProps } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IToolsProps'

export function ZoomSize({ t, value, onChange, zoom, setZoom, maxZoom }: IToolsProps) {
  const increaseFont = useCallback((mode: 'inc' | 'dec') => () => {
    if (mode === 'inc' && zoom >= maxZoom) return
    const value = mode === 'inc' ? 0.02 : -0.02
    setZoom((prev) => prev + value)
  }, [setZoom, zoom, maxZoom])
  // if (!value?.text) return null
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography>
        {t('zoom')}
      </Typography>
      <IconButton size="small" color="secondary" onClick={increaseFont('dec')}>-</IconButton>

      <Typography>
        {Math.round(zoom * 100)} %
      </Typography>
      <IconButton disabled={zoom >= maxZoom} size="small" color="secondary" onClick={increaseFont('inc')}>+</IconButton>

    </Stack>

  )
}
