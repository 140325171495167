import { INamedBaseModel, NamedBaseModel } from 'api/baseModel'
import { CompanyType } from 'api/models/companyType'

export interface ICompanyModel extends INamedBaseModel {
  type: CompanyType
}

export class CompanyModel extends NamedBaseModel implements ICompanyModel {
  type: CompanyType = CompanyType.CUSTOMER
}
