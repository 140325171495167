/* eslint-disable */
import {RootState} from 'app/store'
import ProjectsRestModel from "api/projects";
import LoadStateModel from "../framework/LoadStateModel";
import useNamedOptions from "../lib/useNamedOptions";
import {ProjectModel} from "../api/models/projectModel";
import {IBaseRestState} from "features/base/BaseRestFeature";
import {createNamedCustomerBaseRestSlice} from "features/base/creators/CreateNamedCustomerBaseRestSlice";

type DataType = ProjectModel
const key:string = "projects"

const initialState: IBaseRestState<DataType> = {
  data: [],
  loadState: LoadStateModel.INITIAL,
  archive: {},
}
export const { actions, index, hooks } = createNamedCustomerBaseRestSlice(
  key,
  initialState,
  {},
  ProjectsRestModel,
)
const { get, update, deleteItem } = actions
export default index.reducer
export const updateProject = update
export const deleteProject = deleteItem
export const fetchData = get
export const getProjects = (state: RootState) => state.projects.data
export const projectsByCustomerId = (id: string) => (state: RootState) =>  state.projects.data.filter(x=>x.customerId===id)
export const useProjectByPath = hooks.useByPathKey
export const useProjectByCustomerId = hooks.useByCustomerId
export const useProjects = hooks.useAll

export const useProjectOptions = (customerId: string,includeArchived:boolean|undefined) => {
  const data = useProjectByCustomerId(customerId)
  return useNamedOptions<ProjectModel>(data||[],includeArchived,(x)=>({
    label: x.name,
    value: x.id,
    defaultOrderNr: x.defaultOrderNr
  }))
}
