// eslint-disable-next-line no-shadow
enum PermissionModel {
  VIEW_ENTRIES = 'VIEW_ENTRIES',
  EDIT_ENTRIES = 'EDIT_ENTRIES',
  EDIT_SETTINGS = 'EDIT_SETTINGS',
  VIEW_ALL_CUSTOMERS = 'VIEW_ALL_CUSTOMERS',

  VIEW_MY_CUSTOMER = 'VIEW_MY_CUSTOMER',
  VIEW_USERS = 'VIEW_USERS',
  EDIT_ALL_CUSTOMERS = 'EDIT_ALL_CUSTOMERS',
  PREPARE_ORDER_SCAFFOLD = 'PREPARE_ORDER_SCAFFOLD',
  PLACE_ORDER_SCAFFOLD = 'PLACE_ORDER_SCAFFOLD',
  VIEW_MY_SCAFFOLDERS = 'VIEW_MY_SCAFFOLDERS',
  VIEW_ALL_SCAFFOLDERS = 'VIEW_ALL_SCAFFOLDERS',
  EDIT_PROJECTS_MY_CUSTOMER = 'EDIT_PROJECTS_MY_CUSTOMER',

  EDIT_USERS = 'EDIT_USERS',
  SUPER_ADMIN = 'SUPER_ADMIN',
  TRANSMIT_SCAFFOLD_REQUEST_TO_SCAFFOLDER = 'TRANSMIT_SCAFFOLD_REQUEST_TO_SCAFFOLDER',
  CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST = 'CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST',

  VIEW_ALL_SITE_MEASURING = 'VIEW_ALL_SITE_MEASURING',
  VIEW_MY_SITE_MEASURING = 'VIEW_MY_SITE_MEASURING',
  VIEW_MY_SITE_MEASURING_FOR_INQUIRIES = 'VIEW_MY_SITE_MEASURING_FOR_INQUIRIES',
  GDK_REVIEW_SITE_MEASURING = 'GDK_REVIEW_SITE_MEASURING',
  EDIT_SITE_MEASURING_TEMPLATES = 'EDIT_SITE_MEASURING_TEMPLATES',
  MARK_SITE_MEASURING_ACCOUNTED = 'MARK_SITE_MEASURING_ACCOUNTED',

  EDIT_ALL_SCAFFOLD_REQUESTS_MY_CUSTOMER = 'EDIT_ALL_SCAFFOLD_REQUESTS_MY_CUSTOMER',
  GET_SCAFFOLD_CERTIFICATE = 'GET_SCAFFOLD_CERTIFICATE',
  REVERT_CANCELED_DATE='REVERT_CANCELED_DATE',
  DELETE_ALL_SCAFFOLDS='DELETE_ALL_SCAFFOLDS',
  DELETE_ALL_CLIENT_PHASE_SCAFFOLDS='DELETE_ALL_CLIENT_PHASE_SCAFFOLDS',
  RESTART_SCAFFOLD_CLIENT_WORKFLOW='RESTART_SCAFFOLD_CLIENT_WORKFLOW',
  CANCEL_SITE_MEASURING = 'CANCEL_SITE_MEASURING',
  SITE_MEASURING_ADDITIONAL_PROPERTIES_EDIT_GDK_NOTE = 'SITE_MEASURING_ADDITIONAL_PROPERTIES_EDIT_GDK_NOTE',

  VIEW_CUSTOMERS_WITH_SCAFFOLD_FOR_MY_COMPANY='VIEW_CUSTOMERS_WITH_SCAFFOLD_FOR_MY_COMPANY',
  EDIT_MY_SCAFFOLD_REQUESTS = 'EDIT_MY_SCAFFOLD_REQUESTS',

}

export default PermissionModel
