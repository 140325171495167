import PermissionModel from 'models/PermissionModel'
import { CustomRoute } from 'models/IRoute'
import BaseRestRoutes from 'framework/components/BaseRestViews/BaseRestRoutes'
import React from 'react'
import AttachmentOverview from 'ui/views/ScaffoldInquiries/Attachments/AttachmentOverview'
import { lazySiteMeasuring, lazySiteMeasuringInspection } from 'ui/views/lazyFetchAllViews'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import { InquiriesMode } from 'ui/views/ScaffoldInquiries/InquiriesMode'
import { IRequiredProp } from 'framework/components/DataLoader/types/IRequiredProp'
import { Edit, New } from './Edit'

const routes = (mode: InquiriesMode) => BaseRestRoutes(
  'attachments',
  'scaffoldInquiryAttachmentId',
  (<AttachmentOverview mode={mode} />),
  (<Edit />),
  (<New />),

  { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_MY_CUSTOMER] },
  undefined,
  [
    { prop: 'customers' },
    { prop: 'projects' },
    { prop: 'buildings' },
    { prop: 'operationUnits' },
    { prop: 'users' },
    { prop: 'scaffolders' },
    ...(mode === 'Archive' ? [
      {
        prop: 'scaffoldInquiries',
        fetch: 'always',
        fetchPolicy: 'lazy',
      },
      { prop: 'scaffoldInquiries', fetch: 'always', mode: 'archive', nested: [{ prop: 'archive', key: 'year' }] },
    ] : [
      {
        prop: 'scaffoldInquiries',
        fetch: 'always',
      },
    ]) as IRequiredProp[],
    {
      prop: 'scaffoldInquiries',
      fetch: 'always',
    },
    {
      prop: 'scaffoldInquiriesAttachments',
      nested: [{ prop: 'data', key: 'scaffoldInquiryId' }],
      fetch: 'always',
    },
    lazySiteMeasuring,
    lazySiteMeasuringInspection,
  ],
)
export default routes
