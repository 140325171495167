/* eslint-disable */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AppThunk, RootState, store} from 'app/store'
import LoadStateModel from "../framework/LoadStateModel";
import {fetchData as fetchUser} from "./user";

export interface AuthState {
  token: string|undefined,
  loadState: LoadStateModel,
}

const initialState: AuthState = {
  token: undefined,
  loadState: LoadStateModel.INITIAL
}

export const index = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setToken: (state, action: PayloadAction<string|undefined>) => {
      state.token = action.payload
      state.loadState = LoadStateModel.LOADED
    },
  },

})

const { setToken } = index.actions

export const removeToken = ():AppThunk => (dispatch,getState)=>{
  try{
    localStorage.removeItem('auth-token')
    localStorage.removeItem('auth-expires')
    // sessionStorage.clear()
    dispatch(setToken(undefined))
    window.location.reload() // force refresh is safest
    //window.location.href = '/'
  }
  catch(e){
    // do nothing
  }

}
export const saveToken = (token:string, expires: string):AppThunk => (dispatch,getState)=>{
  try{
    localStorage.setItem('auth-token',token)
    localStorage.setItem('auth-expires',expires)
    // sessionStorage.clear() // remove previous table data
    dispatch(setToken(token))
    store.dispatch(fetchUser())
  }
  catch(e){
    // do nothing
    console.error(e)
  }

}
export const isAuthorized = (state: RootState) => Boolean(state.auth.token)
export const loadingAuth = (state: RootState) => state.auth.loadState === LoadStateModel.INITIAL


export const getToken = () => {
  let authToken = null

  const authExpires = localStorage.getItem('auth-expires')
  // if (authExpires > +(new Date())) {
  if(authExpires && +(new Date(authExpires)) > +(new Date())) {
    authToken = localStorage.getItem('auth-token')
  }

  return authToken

}
export const loadTokenOnPageLoad = ():AppThunk => (dispatch,getState)=>{
  const token = getToken()
  if(token) {
    dispatch(setToken(token))
  }
}
export default index.reducer
