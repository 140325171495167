import { CustomRoute } from 'models/IRoute'
import CustomerRoutes from './Customers/routes'
import UserRoutes from './Users/routes'
import ScaffolderRoutes from './Scaffolders/routes'
import SiteMeasuringTemplatesRoutes from './SiteMeasuringTemplates/routes'
import MyProjectRoutes from './MyProjects/routes'

const routes: CustomRoute[] = [
  {
    path: 'administration',
    children: [
      ...CustomerRoutes,
      ...UserRoutes,
      ...ScaffolderRoutes,
      ...SiteMeasuringTemplatesRoutes,
      ...MyProjectRoutes,
      /* {
        path: 'users',
        authorized: true,
        requiredPermissions: [PermissionModel.EDIT_USERS],
        element: (<Users />),
      }, */

    ],
  },

]
export default routes
