import React from 'react'
import './App.css'
import { Provider } from 'react-redux'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { SnackbarProvider } from 'notistack'
import ManagedFormProvider from 'framework/components/ManagedForm/ManagedFormProvider'
import ErrorBoundary from 'framework/components/ErrorBoundary'
import i18n from './i18n'
import { store } from './app/store'
import theme from './theme'
import Page from './ui/layout/Page'

function LocalizedWrapper() {
  const { i18n: i18n2 } = useTranslation('generic')
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n2.language} >
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ManagedFormProvider>
          <Page />
        </ManagedFormProvider>
      </SnackbarProvider>
    </LocalizationProvider>
  )
}
function App() {
  return (

    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizedWrapper />
          </ThemeProvider>
        </StyledEngineProvider>
      </I18nextProvider>

    </Provider>

  )
}

export default App
