import { IBaseSortedModel } from 'api/baseModel'

function sortByPosition<T extends IBaseSortedModel>(a: T, b: T): number {
  return (a?.sortPosition || 0) - (b?.sortPosition || 0)
}

export const sortBySortPosition = sortByPosition

export const roundToNextPrecision = (i: number, precision: number): number => Math.round(i / precision) * precision

export function getNextSortPosition<T extends IBaseSortedModel>(items: T[]): number | undefined {
  if (!items || items.length === 0) return 10
  const sorted = items.sort(sortByPosition)
  const highestElement = sorted[sorted.length - 1]
  if (typeof highestElement?.sortPosition === 'undefined') return undefined
  return roundToNextPrecision((highestElement?.sortPosition || 0) + 10, 10)
}

export const sortByDate = (prop: string, sortOrder: 'asc' | 'desc' = 'asc') => (a: any, b: any) => {
    const first = (typeof a[prop] === 'string') ? new Date(a[prop]) : a[prop]
    const second = (typeof b[prop] === 'string') ? new Date(b[prop]) : b[prop]
    const dateOrder = first - second < 0
    if (sortOrder === 'asc') return dateOrder ? -1 : 1
    return dateOrder ? 1 : -1
  }
export const sortByProp = (prop: string, sortOrder: 'asc' | 'desc' = 'asc') => (a: any, b: any) => {
  // const first = (typeof a[prop] === 'string') ? new Date(a[prop]) : a[prop]
  // const second = (typeof b[prop] === 'string') ? new Date(b[prop]) : b[prop]
  const first = a[prop]
  const second = b[prop]
  const result = first.localeCompare(second)
  return sortOrder === 'asc' ? result : result * -1
}
