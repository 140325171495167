/* eslint-disable */
import RestModel from 'api/scaffoldInquiriesDraft'
import LoadStateModel from '../framework/LoadStateModel'
import { IBaseRestState } from 'features/base/BaseRestFeature'

import { createNamedBaseRestSlice } from 'features/base/creators/CreateNamedBaseRestSlice'
import { ScaffoldDraftModel } from 'api/models/scaffoldDraftModel'
import { useMemo } from 'react'
import { ScaffoldAgentPhase, ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { useSelector } from 'react-redux'
import { AppThunk, RootState } from 'app/store'

type DataType = ScaffoldDraftModel
const key = 'scaffoldInquiriesDraft'

const initialState: IBaseRestState<DataType> = {
  data: [],
  loadState: LoadStateModel.INITIAL,
  archive: {},
}
export const {actions,index,hooks,selectors} = createNamedBaseRestSlice<DataType>(
  key,
  initialState,
  {},
  RestModel
)
// const { get, update,create, deleteItem } = actions
const { create,update, deleteItem } = actions


export const useDraftsByCustomerId = (id: string)=> {
  const data = hooks.useAll() as ScaffoldDraftModel[]

  // return data
  return useMemo(()=>data?.filter((x: ScaffoldDraftModel)=>x.customerId === id),[data,id])
}
export const deleteItems = (drafts: ScaffoldDraftModel[]) :AppThunk=> async (dispatch) =>{
  const ids = drafts.filter(x=>!x.isNew).map(x=>x.id)
  const result = await RestModel.deleteItems(ids)
  if(result.status === 204) {
    drafts.forEach((it)=>{
      dispatch(deleteItem(it))
    })
    return true
  }
  return false
}
export const createRequestsFromDraft = (drafts: ScaffoldDraftModel[]) :AppThunk => async (dispatch): Promise<ScaffoldInquiryModel[]|undefined> =>{
  const ids = drafts.filter(x=>!x.isNew).map(x=>x.id)
  try
  {

    const result = await RestModel.requestsFromDrafts(ids)
    return result;
  }catch(e) {
    console.error(e)
    return undefined
  }
}

export default index.reducer
