import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { NamedBaseModel } from 'api/baseModel'
import { KeysOfType } from 'ui/views/Dashboard/lib/keysOfType'
import { useMemo } from 'react'
import * as Plotly from 'plotly.js'
import { averageNumbers, groupData } from 'framework/lib/useGroupedDataForPlotly'
import { sortByDate, sortByProp } from 'lib/modelHelper'
import { DateTime } from 'luxon'

export const useTimeDiffConstructionAveraged = (data: ScaffoldInquiryModel[], lookup: NamedBaseModel[], date1: KeysOfType<ScaffoldInquiryModel, string|undefined>, date2: KeysOfType<ScaffoldInquiryModel, string|undefined>) => useMemo<Plotly.Data[]>(() => {
  const groupedByScaffolder = groupData(data, 'scaffolderId')

  return Object.entries(groupedByScaffolder)
    .map(([key, scaffoldByScaffolder]) => {
      const sorted = scaffoldByScaffolder
        .filter((x) => x[date1] && x[date2])
        .sort(sortByDate(date1, 'asc'))
      const scaffoldData = sorted
        .map((x) => ({
          key: DateTime.fromISO(x[date1]!)
            .toISODate(),
          value: DateTime.fromISO(x[date1]!)
            .diff(DateTime.fromISO(x[date2]!), 'days').days,
        }))
      const grouped = groupData(scaffoldData, 'key')
      const scaffolder = lookup.find((scaffolder) => scaffolder.id === key)
      const xAxis = Object.keys(grouped)
        .sort()
      const yAxis = xAxis.map((key) => averageNumbers(grouped[key].map((x) => x.value)))

      return {
        type: 'scatter',
        mode: 'lines+markers',
        // connectgaps: true,
        // fill: 'toself',
        x: xAxis,
        y: yAxis,
        name: scaffolder?.name || '',
      }
    }).sort(sortByProp('name'))
}, [data, date1, lookup, date2])
