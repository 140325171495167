import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { actions, useProjectByCustomerId, useProjectByPath } from 'features/projects'
import EditView from 'framework/components/BaseRestViews/EditView'
import { ProjectModel } from 'api/models/projectModel'
import { useCurrentUser } from 'features/user'

const bigGrid = { xs: 12, sm: 6 }
const grid = { xs: 12, sm: 6, md: 3 }
const smallGrid = { xs: 6, sm: 3, md: 2 }

function Editor({ item }: {item?: ProjectModel}) {
  const user = useCurrentUser()
  const data = useProjectByCustomerId(user?.companyId || '')
  const { t } = useTranslation('projects')
  const { t: tGeneric } = useTranslation('generic')
  const elements = useMemo(() => ([
    {
      type: 'text',
      required: true,
      grid: bigGrid,
      name: 'name',
    },
    {
      type: 'text',
      grid: bigGrid,
      name: 'defaultOrderNr',
    },
    {
      type: 'checkbox',
      grid: smallGrid,
      name: 'archived',
      label: tGeneric('archived'),
      // disabled: true,
    },
  ]), [tGeneric])
  const linkStack = useMemo(() => ([
    { to: '/administration/myProjects', text: t('application:projects') },
    { text: item?.isNew ? t('application:new') : `${item?.name}` },
  ]), [t, item])
  return (
    <EditView
      loading={!item}
      linkStack={linkStack}
      item={item}
      elements={elements}
      actions={actions}
      saveView={{
        hideDraft: true,
      }}
      t={t}
    />
  )
}
export function Edit() {
  const item = useProjectByPath('projectId')
  return (
    <Editor item={item} />
  )
}
export function New() {
  const user = useCurrentUser()
  const item = new ProjectModel(user?.companyId)
  return (
    <Editor item={item} />
  )
}
