import { DateTime } from 'luxon'
import { ISerialized } from 'lib/serializer/ISerialized'

function deserializeDate(value: ISerialized) {
  switch (value.subType) {
    case 'luxon':
      return DateTime.fromISO(value.value)
    case 'jsDate':
      return new Date(value.value)
    default:
      return value.value
  }
}
function deserializeValue<T>(value: ISerialized):T|undefined|null {
  switch (value.type) {
    case 'boolean':
    case 'string':
    case 'number':
      return value.value
    case 'array':
      return value.value.map((x:any) => deserializeValue(x))
    case 'object':
      return value.value.reduce((acc:any, cur:ISerialized) => ({
        ...acc,
        [cur.key!]: deserializeValue(cur),
      }), {})
    case 'undefined':
      return undefined
    case 'null':
      return null
    case 'unknown':
      return value.value
    case 'date':
      return deserializeDate(value)
    default:
      return null
  }
}

export default function deserializeObject<T>(value:string) {
  const parsedItem = JSON.parse(value)
  return deserializeValue<T>(parsedItem) as T // cast this, as top level is not designed to be null or undefined
}
