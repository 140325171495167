/* eslint-disable */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AppThunk, RootState} from 'app/store'
import {OptionsObject, SnackbarMessage} from "notistack";
import {addToState, removeFromStateByProp} from "../framework/lib/reducerHelper";
import i18n from "i18next";


export interface NotificationState {
  notifications: SnackbarPayload[],
}

const initialState: NotificationState = {
  notifications: [],
}

interface SnackbarOptionsObject extends OptionsObject {
  autoClose?: boolean,
}
interface SnackbarPayload {
  key?: any,
  message: SnackbarMessage,
  options?: SnackbarOptionsObject,
  dismissed?: boolean
}

export const index = createSlice({
  name: 'notifications',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    displayNotification: (state, action: PayloadAction<SnackbarPayload>) => {
      const notification:SnackbarPayload = {
        message: action.payload.message,
        options: {...action.payload.options},
        key: action.payload.options?.key || new Date().getTime() + Math.random()
      }
      return addToState(state,'notifications',notification)
    },
    closeNotification: (state,action: PayloadAction<any>) => {
      const index = state.notifications.findIndex(x=>x.key===action.payload)
      state.notifications[index].dismissed = true
      return state
    },
    removeNotification: (state, action:PayloadAction<any>)  =>{
      return removeFromStateByProp(state,'notifications','key',{key: action.payload})
    }
  },

})
export const notificationSelector = (state:RootState) => state.notifications.notifications

export const displayNotification = (message: SnackbarMessage,options?:SnackbarOptionsObject):AppThunk => async (dispatch,getState)=> {

  dispatch(index.actions.displayNotification({
    message,
    options
  }))
}


export const displayNotificationTranslated = (i18nMsgKey: string,options: SnackbarOptionsObject={ variant: 'success', autoClose: true }):AppThunk => async (dispatch,getState) => {
  const message = `${i18n.t(i18nMsgKey)}`
  const snackbar = displayNotification(message, options)
  dispatch(snackbar)
}







export const removeNotification = (key: any):AppThunk => async (dispatch,getState)=> {
  dispatch(index.actions.removeNotification(key))
}
export const closeNotification = (key: any):AppThunk => async (dispatch,getState)=> {
  dispatch(index.actions.closeNotification(key))
}

export const createPersistentNotification = (message: SnackbarMessage, options?: SnackbarOptionsObject):AppThunk<string> => (dispatch,getState)=> {
  const key = `NOTIFICATION_${new Date().getTime().toString(10)}`
  dispatch(index.actions.displayNotification({
    message,
    options: {
      key,
      autoClose: false,
      persist: true,
      ...options
    }
  }))
  return key
}
export const createPersistentNotificationTranslated = (i18nMsgKey: string,i18nOptions?: any, options?: SnackbarOptionsObject):AppThunk<string> => (dispatch, getState) => {
  const key = `NOTIFICATION_${new Date().getTime().toString(10)}`
  dispatch(index.actions.displayNotification({
    message: i18n.t(i18nMsgKey,i18nOptions).toString(),
    options: {
      key,
      ...options,
      autoClose: false,
    }
  }))
  return key
}

export default index.reducer
