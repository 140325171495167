import { ReactNode } from 'react'
import PermissionModel from 'models/PermissionModel'
import { RootState } from 'app/store'

import { IPermissionRequirement } from 'framework/auth/IPermissionRequirement'

export interface INavItem {
  key: string,
  small?: boolean,
  target: string,
  requireLogin?: boolean,
  requireLogout?: boolean,
  matchesRoute?: (location: string) => boolean
  icon?: ReactNode,
  requiredPermissions?: PermissionModel[], // todo @deprecated
  requirePermissions?: IPermissionRequirement,
  unreadCountSelector: (state: RootState) => number
}

export interface INavItemWithChildren extends INavItem {
  children?: INavItem[]
  childrenOnlyWhenRouteMatches? : boolean
}

export const shouldDisplayChild = (navItem: INavItemWithChildren, matchesRoute: boolean) => {
  if (!navItem.children) return false
  if (!navItem.childrenOnlyWhenRouteMatches) return true
  return navItem.childrenOnlyWhenRouteMatches && matchesRoute
}
