import { PropsWithChildren } from 'react'
import { IPopupActionProps } from 'framework/components/PopupActions/IPopupActionProps'
import { MenuItem } from '@mui/material'
import Dialog2 from 'framework/components/Dialog2'
import { useOpenState } from '@codeflix/mui-managed-form'

export function PopupActionMenuItem({
                                      label, disabled,
                                      actions, children, t, openState,
                                      ...rest
                                    }: PropsWithChildren<IPopupActionProps>) {
  const { open, handleOpen, handleClose } = openState
  return (
    <>
      <MenuItem onClick={handleOpen} disabled={disabled}>
        {/* {loading && <Loading variant="indeterminate" color="primary" size={20} />} */}
        {/* {children} */}
        {label}
      </MenuItem>
      <Dialog2
        t={t}
        title={label}
        open={open}
        onClose={handleClose}
        actions={actions}
        // actions={}
      >{children}
      </Dialog2>
    </>

  )
}
export function ManagedPopupActionMenuItem(props: PropsWithChildren<Omit<IPopupActionProps, 'openState'>>) {
  const openState = useOpenState()
  return (
    <PopupActionMenuItem openState={openState} {...props} />
  )
}
