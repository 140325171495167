import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import OverviewView from 'framework/components/BaseRestViews/OverviewView'
import { useProjectByCustomerId } from 'features/projects'
import { useCurrentUser } from 'features/user'
import { CheckboxCellProps } from 'framework/components/Table/Cells/CheckboxCell'

export default function Overview() {
  const user = useCurrentUser()
  const data = useProjectByCustomerId(user?.companyId || '')
  const { t } = useTranslation('customers')
  const columns = useMemo(() => [
    {
      header: t('general:id'),
      accessorKey: 'id',
      enableHiding: true,
      filterFn: 'includesString',
    },
    {
      header: t('name'),
      accessorKey: 'name',
      filterFn: 'includesString',
    },
    {
      header: t('generic:archived'),
      accessorKey: 'archived',
      // accessorFn: (row:any) => row?.archived === true,
      ...CheckboxCellProps(),
    },
  ], [t])
  return (
    <OverviewView
      data={data || []}
      columns={columns}
      name={t('application:projects').toString()}
      linkStack={[
        { to: '/administration/myProjects', text: t('application:projects') },
      ]}
    />
  )
}
