import { useCallback, useEffect, useMemo, useState } from 'react'
// @ts-ignore
import * as pdfjsLib from 'pdfjs-dist/webpack'

export const blobToImageSrcUrl = (input: File | Blob) => (window.URL || window.webkitURL).createObjectURL(input)

const convertPdfToImage = async (file:File|Blob, page: number = 1) => {
  // use canvas and pdfs lib to convert pdf to img
  const binary = blobToImageSrcUrl(file)
  const pdfDocument = await pdfjsLib.getDocument(binary).promise
  const pdfPage = await pdfDocument.getPage(page)
  const viewport = pdfPage.getViewport({ scale: 1 })
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (context) {
    canvas.height = viewport.height
    canvas.width = viewport.width
    const renderContext = {
      canvasContext: context,
      viewport,
    }
    await pdfPage.render(renderContext).promise
  }
  return await new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob!)
    })
  }) as Blob
}
export const getOrConvertToImageBlob = async (file:File|Blob): Promise<Blob> => {
  switch (file?.type) {
    case 'application/pdf':
      // todo convert?
      return await convertPdfToImage(file)
    default:
      return file
  }
}
export const blobToImageBitmap = async (file:File|Blob):Promise<ImageBitmap|undefined> => {
  let intermediateBlob: File|Blob
  let result: ImageBitmap
  switch (file.type) {
    case 'application/pdf':
      intermediateBlob = await convertPdfToImage(file)
      result = await createImageBitmap(intermediateBlob)
      return result
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
      // image
      return await createImageBitmap(file)
    default:
      return undefined
  }
}

type AsyncImageLoad = ()=> Promise<Blob>|undefined
export type ImageLoad = Blob | AsyncImageLoad
export const useImageSrcFromBlob = (getImageBlobAsync: ImageLoad|undefined) => {
  const [image, setImage] = useState<File | Blob | undefined>()
  const [loading, setLoading] = useState(false)
  const [bitmap, setBitmap] = useState<ImageBitmap | undefined>()
  const [originalType, setOriginalType] = useState<string>()
  const fetchImage = useCallback(async () => {
    if (!getImageBlobAsync) return
    setLoading(true)
    const blob = typeof getImageBlobAsync === 'function' ? await (getImageBlobAsync as AsyncImageLoad)() : getImageBlobAsync
    if (blob) {
      setOriginalType(blob.type)
      const mappedBlob = await getOrConvertToImageBlob(blob)
      const bitmap = await blobToImageBitmap(mappedBlob)
      // const bitmap = await createImageBitmap(blob)
      setBitmap(bitmap)
      setImage(mappedBlob)
    }
    setLoading(false)
  }, [getImageBlobAsync])
  useEffect(() => {
    fetchImage()
  }, [fetchImage])
  const imageSrcUrl = useMemo(() => (image ? blobToImageSrcUrl(image) : undefined), [image])
  return { image, imageSrcUrl, bitmap, originalType, fetchImage }
}

export const useImageFromRemote = (getImageBlobAsync: AsyncImageLoad) => {
  const [image, setImage] = useState<File|Blob|undefined>()
  useEffect(() => {
    getImageBlobAsync()?.then((result) => setImage(result))
  }, [])
  return image
}
