import { IPermissionRequirement } from 'framework/auth/IPermissionRequirement'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import PermissionModel from 'models/PermissionModel'

export const isOnlyGDKPermissionRequirement: IPermissionRequirement = {
  operation: PermissionOperation.AND,
  permissions: [
    PermissionModel.TRANSMIT_SCAFFOLD_REQUEST_TO_SCAFFOLDER,
  ],
}
