export function nestedResolver(parameters: any, item: any, nested: any) {
  for (let z = 0; z < nested.length; z += 1) {
    const check = nested[z]
    const result = item[check.prop]
    const compVal = parameters[check.key] // get comparison value from url
    if (!result) return false
    if (!result[compVal]) {
      return false
    }
  }
  return true
}
