import React, { MouseEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Paperclip } from 'mdi-material-ui'
import { useScaffoldInquiryAttachments } from 'features/scaffoldInquiriesAttachments'
import { useNavigate } from 'react-router-dom'
import { actions, updateItemScaffolder, useScaffoldOptions } from 'features/scaffoldInquiries'
import { useUserHasAllPermissions, useUserHasPermissions } from 'framework/auth/permissions'
import EditActions from 'ui/views/ScaffoldInquiries/EditActions/EditActions'
import { InquiriesMode } from 'ui/views/ScaffoldInquiries/InquiriesMode'
import { useAppDispatch } from 'app/hooks'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import { useUserOptions } from 'ui/helper/useUserOptions'
import { Stack } from '@mui/material'
import { styled } from '@mui/system'
import { DateTime } from 'luxon'
import {
  EvaluatedPhase,
  IScaffoldInquiryModelAggregated,
  ScaffoldAgentPhase,
  ScaffoldInquiryModel,
  ScaffoldState,
  useEvaluatedScaffoldPhase,
} from 'api/models/scaffoldModel'
import {
  useReferenceScaffoldAutocompleteOptions,
} from 'ui/views/ScaffoldInquiries/UseReferenceScaffoldAutocompleteOptions'
import { useCustomer, useCustomerOptions } from '../../../features/customers'
import { userIsInCustomerGroup, useUsers } from '../../../features/users'
import { useProjectByCustomerId, useProjectOptions } from '../../../features/projects'
import { useOperationUnitOptions, useOperationUnitsByCustomerId } from '../../../features/operationUnits'
import { useBuildingOptions } from '../../../features/buildings'
import { useCurrentUser } from '../../../features/user'
import {
  ApproverStep,
  CustomerModel,
  CustomerSpecificField,
  CustomerSpecificFieldType,
} from '../../../api/models/customerModel'
import PermissionModel from '../../../models/PermissionModel'
import { useScaffolderOptions } from '../../../features/scaffolders'
import { modelOrOptions, modelWithDescriptionOrOptions, userModelOrOptions } from '../../../api/models/modelHelper'

const grid = { xs: 12, sm: 6, md: 4, lg: 3 }
const smallGrid = { xs: 6, sm: 6, md: 4, lg: 2 }
const userGrid = { xs: 6, sm: 6 }

const StyledStack = styled(Stack)(() => ({
  width: '100%',
}))

const userRenderOption = (props:any, option:any, state: any, ownerState:any) => (
  <li {...props}>
    <StyledStack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
      <span>{option.renderLabel}</span>
      <span style={{ fontStyle: 'italic', fontSize: '0.8em', textAlign: 'right' }}>{option.tags}</span>
    </StyledStack>

  </li>
)

const elementInScaffolderPhaseVisible = (item?: ScaffoldInquiryModel, canSee?: boolean) => {
  if (!item || !canSee) return false
  return (item.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED || item.agentPhase === ScaffoldAgentPhase.SCAFFOLDER)
}
export const resolveIndividualFieldType = (type: CustomerSpecificFieldType) => {
  const lowerCaseType = type.toLowerCase()
  switch (lowerCaseType) {
    case 'int':
      return 'number'
    default:
      return lowerCaseType
  }
}

export function useEditorData(customerId: string, operationUnitId: string, phase: ScaffoldAgentPhase|undefined) {
  const includeArchived = useMemo(() => phase === ScaffoldAgentPhase.FULLY_SIGNED, [phase])
  const customer = useCustomer(customerId)
  const users = useUsers()
  const userOptions = useUserOptions(customerId)
  const primaryUserOptions = useUserOptions(customerId, true)
  const projects = useProjectByCustomerId(customerId)
  const projectOptions = useProjectOptions(customerId, includeArchived)
  const operationUnitOptions = useOperationUnitOptions(customerId, includeArchived)
  const operationUnits = useOperationUnitsByCustomerId(customerId)
  const buildingOptions = useBuildingOptions(
    customerId,
    operationUnits?.find((x) => operationUnitId === x.id)?.buildings || [],
    includeArchived,
  )
  const currentUser = useCurrentUser()
  return useMemo(() => ({
    customer,
    projects,
    currentUser,
    users,
    primaryUserOptions,
    userOptions,
    projectOptions,
    operationUnitOptions,
    operationUnits,
    buildingOptions,
  }), [buildingOptions, currentUser, customer, operationUnitOptions, operationUnits, primaryUserOptions, projectOptions, projects, userOptions, users])
}

/* export function useEditMode(item:ScaffoldInquiryModel, values:ScaffoldInquiryModel) {
  if (item.isNew) {
    return 'CLIENT'
  }
  switch (item?.agentPhase) {
    case
    default:
      return 'CLIENT'
  }
  return undefined
} */
const useDisableNextStep = (item:ScaffoldInquiryModel|undefined, values: ScaffoldInquiryModel, phase:EvaluatedPhase|undefined) => {
  const canSubmitToScaffolder = useUserHasAllPermissions([PermissionModel.TRANSMIT_SCAFFOLD_REQUEST_TO_SCAFFOLDER])
  const isScaffolder = useUserHasAllPermissions([PermissionModel.CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST])
  const currentUser = useCurrentUser()
  return useMemo(() => {
    switch (item?.agentPhase) {
      case ScaffoldAgentPhase.FULLY_SIGNED:
        return true
      case 'GDK':
        return !canSubmitToScaffolder
      case 'SCAFFOLDER':
        return !isScaffolder || !userIsInCustomerGroup(item.scaffolderId, currentUser)
      case 'CLIENT':
      default:
        // check acl
        return values.clientWorkflowPhase !== -1 && (!phase?.currentPhase || phase?.currentPhase.userId !== currentUser?.id) // && !currentUser?.customerIds.includes(values.customerId!)
    }
  }, [item?.agentPhase, item?.scaffolderId, canSubmitToScaffolder, isScaffolder, currentUser, values.clientWorkflowPhase, phase])
}
export function useEvaluatedPhase(item: ScaffoldInquiryModel | undefined, customer: CustomerModel | undefined, values: ScaffoldInquiryModel) {
  const phase = useEvaluatedScaffoldPhase(values, customer)
  const { t } = useTranslation('scaffolds')
  const disableNextStep = useDisableNextStep(item, values, phase)
  const saveText = useMemo(() => {
    switch (item?.agentPhase) {
      case ScaffoldAgentPhase.FULLY_SIGNED:
        return t('signed')
      case 'GDK':
        return t('signAndSubmitTo', { name: t('scaffolder') })
      case 'SCAFFOLDER':
        return t('sign')
      case 'CLIENT':
      default:
        if (!phase?.currentPhase && !phase?.nextPhase) {
          // fixed gdk process
          // if(item?.state === ScaffoldState.)
          return t('gdk') // t('submitTo', { name: t('gdk') })
        }
        if (!phase?.nextPhase) return t('gdk')
        return t('submitTo', { name: phase.nextStep?.name })
    }

    /* const user = users.find((x) => x.id === phase.nextPhase.userId)
    return user ? `${user.firstName} ${user.lastName}` : '' */
  }, [item, phase, t])
  return { disableNextStep, saveText, phase }
}

export function useEditorElements(item: IScaffoldInquiryModelAggregated | undefined, values: any, mode: InquiriesMode) {
  const {
    currentUser,
    operationUnitOptions,
    users,
    buildingOptions,
    userOptions,
    primaryUserOptions,
    projectOptions,
    customer,
    projects,
  } = useEditorData(values?.customerId, values?.operationUnitId, item?.agentPhase)
  const phase = useEvaluatedScaffoldPhase(item, customer)
  const disable = useDisableNextStep(item, values, phase)
  const attachments = useScaffoldInquiryAttachments(item?.id)

  const scaffoldOptionsActive = useScaffoldOptions(values?.customerId)
  const scaffoldOptions = useReferenceScaffoldAutocompleteOptions(item, scaffoldOptionsActive)
  const scaffolderOptions = useScaffolderOptions()
  const { t, i18n } = useTranslation('scaffolds')
  const customerOptions = useCustomerOptions()
  const navigate = useNavigate()
  const canSeeMoreThanClient = useUserHasPermissions({
    operation: PermissionOperation.OR,
    permissions: [PermissionModel.TRANSMIT_SCAFFOLD_REQUEST_TO_SCAFFOLDER, PermissionModel.CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST],
  })

  const appDispatch = useAppDispatch()
  const handleGoToAttachments = useCallback((e: MouseEvent<HTMLElement>) => {
    // save item on server
    if (!disable) {
      // console.log('update', actions.update.toString())
      if (item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER) {
        appDispatch(updateItemScaffolder(values))
      } else {
        appDispatch(actions.update(values))
      }
    }
    // save item as draft an forward
    navigate('./attachments')
  }, [disable, navigate, appDispatch, values, item])
  const elements = useMemo(
() => ([
    {
      type: 'group',
      name: 'formular',
      container: { xs: 12, sm: 11 },
      elements: [
        {
          type: 'autocomplete',
          disabled: !item?.isNew,
          name: 'customerId',
          options: modelOrOptions(item?.customer, customerOptions),
          grid,
          required: true,
        },
        {
          type: 'autocomplete',
          name: 'operationUnitId',
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          options: modelOrOptions(item?.operationUnit, operationUnitOptions),
          grid,
          required: true,
        },
        {
          type: 'autocomplete',
          name: 'buildingId',
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          options: modelWithDescriptionOrOptions(item?.building, buildingOptions).sort((a, b) => -b.label.localeCompare(a.label)),
          required: true,

          grid,
        },
        {
          type: 'text',
          name: 'area',
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          required: true,
          grid,
        },
        {
          type: 'autocomplete',
          name: 'projectId',
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          options: modelOrOptions(item?.project, projectOptions),
          grid,
        },
        {
          type: 'text',
          grid,
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          name: 'orderNr',
          required: phase?.nextStep ? phase?.step?.statusOnCompletion === ScaffoldState.CLIENT_TRANSMITTED : true,
          // required: true,
        },
        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: 'date',
          grid: smallGrid,
          name: 'preferredConstructionDate',
          required: true,
          minDate: DateTime.fromISO('2000-01-01'),
        },
        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: 'date',
          grid: smallGrid,
          name: 'preferredDeconstructionDate',
          disablePast: item?.agentPhase === ScaffoldAgentPhase.CLIENT,
          minDate: DateTime.fromISO('2000-01-01'),
        },
        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: 'checkbox',
          name: 'adHocOrder',
          grid: smallGrid,
        },

        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: 'autocomplete',
          name: 'permitRequired',
          placeholder: t('permitRequiredPlaceholder'),
          // label: `${phase?.currentPhase} - ${phase?.nextPhase} - ${phase?.nextStep?.statusOnCompletion} ${values.state}`,
          required: phase?.nextStep ? phase?.step?.statusOnCompletion === ScaffoldState.CLIENT_REVIEW : true,
          // required: true,
          options: [{ value: true, label: t('necessary') }, { value: false, label: t('unnecessary') }],
          grid,
        },
        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: 'autocomplete',
          grid,
          name: 'type',
          options: [{ value: 'New', label: t('scaffoldType:New') }, { value: 'Conversion', label: t('scaffoldType:Change') }],
          required: true,
        },
        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: (element: any, value: any) => (value.type !== 'Conversion' ? 'hidden' : 'autocomplete'),
          name: 'scaffoldReferenceId',
          options: scaffoldOptions,
          grid,
          conditionalProps: (element:any, value:any) => ({ required: value.type === 'Conversion' }),
          // required: value.type === 'new',
        },
        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: 'autocomplete',
          name: 'orderedByUserId',
          required: true,
          grid: userGrid,
          options: userModelOrOptions(item?.orderedByUser, userOptions),
          renderOption: userRenderOption,
        },
        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: 'object',
          variant: 'line',
          name: 'individualFields',
          // container: grid,
          grid: {},
          elements: (typeof customer !== 'undefined' ? (values.customerIndividualFields || customer.individualScaffoldOrderFields)?.map((individualField: CustomerSpecificField) => ({
            type: resolveIndividualFieldType(individualField.type),
            grid,
            name: individualField.id,
            disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,

            label: individualField.name,
            required: phase?.step?.statusOnCompletion === individualField.required,
            // required: individualField.required,
          })) : []),
        },
        {
          disabled: item?.state !== ScaffoldState.DRAFT || item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: (element: any, value: any) => ((value.agentPhase === 'CLIENT') ? 'object' : 'hidden'),
          variant: 'line',
          name: 'clientWorkflowStatus',
          elements: ((values?.clientWorkflowSteps || customer?.scaffoldWorkflow)?.map((workflow: ApproverStep) => (
            {
              type: 'object',
              name: workflow.id,
              variant: 'none',
              grid: userGrid,
              disabled: item?.state !== ScaffoldState.DRAFT || item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
              elements: [
                {
                  type: 'autocomplete',
                  name: 'userId',
                  disabled: item?.state !== ScaffoldState.DRAFT || item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
                  label: workflow.name,
                  options: primaryUserOptions,
                  renderOption: userRenderOption,
                  required: true,
                },
              ],
            }
          )) || []),
        },
        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: 'text',
          // minRows: 2,
          // maxRows: 3,
          // multiline: true,
          name: 'description',
          maxLength: 100,
          grid: { xs: 12 },
        },
        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: (item?.agentPhase !== ScaffoldAgentPhase.CLIENT && canSeeMoreThanClient) ? 'text' : 'hidden',
          name: 'csOrderId',
          grid,
          required: true,
        },
        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: (item?.agentPhase !== ScaffoldAgentPhase.CLIENT && canSeeMoreThanClient) ? 'autocomplete' : 'hidden',
          name: 'scaffolderId',
          options: scaffolderOptions,
          required: true,
          grid,
        },
        {
          disabled: item?.agentPhase === ScaffoldAgentPhase.SCAFFOLDER,
          type: (item?.agentPhase !== ScaffoldAgentPhase.CLIENT && canSeeMoreThanClient) ? 'number' : 'hidden',
          name: 'rentFreeInDays',
          unit: t('rentFreeInDaysUnit'),
          placeholder: 28,
          min: 0,
          grid,
        },
        {
          type: elementInScaffolderPhaseVisible(item, canSeeMoreThanClient) ? 'divider' : 'hidden',
          name: 'divider1',
        },
        // {
        //   disabled: item?.agentPhase !== ScaffoldAgentPhase.SCAFFOLDER,
        //   type: (elementInScaffolderPhaseVisible(item, canSeeMoreThanClient) ? 'object' : 'hidden'),
        //   name: 'scaffold',
        //   variant: 'line',
        //   required: true,
        //   grid: { xs: 12, lg: 4 },
        //   elements: [
        //     {
        //       type: 'float',
        //       name: 'length',
        //       label: t('scaffoldLength'),
        //       required: true,
        //       grid: { xs: 12, md: 6 },
        //       unit: 'm',
        //
        //     },
        //     {
        //       type: 'float',
        //       name: 'height',
        //       label: t('scaffoldHeight'),
        //       required: true,
        //       grid: { xs: 12, md: 6 },
        //       unit: 'm',
        //
        //     },
        //     {
        //       type: 'number',
        //       name: 'stageCount',
        //       required: true,
        //       grid: { xs: 12, md: 6 },
        //     },
        //     {
        //       type: 'float',
        //       name: 'depth',
        //       unit: 'm',
        //
        //       label: t('scaffoldDepth'),
        //       required: true,
        //       grid: { xs: 12, md: 6 },
        //     },
        //     {
        //       type: 'float',
        //       name: 'footPointHeight',
        //       allowNegative: true,
        //       unit: 'm',
        //       required: true,
        //       grid: { xs: 12, md: 6 },
        //     },
        //   ],
        // },
        {
          disabled: item?.agentPhase !== ScaffoldAgentPhase.SCAFFOLDER,
          type: elementInScaffolderPhaseVisible(item, canSeeMoreThanClient) ? 'radio' : 'hidden',
          name: 'loadClass',
          options: [
            { value: 2, label: t('loadClass:2') },
            { value: 3, label: t('loadClass:3') },
            { value: 4, label: t('loadClass:4') },
            { value: 5, label: t('loadClass:5') },
            { value: 6, label: t('loadClass:6') },
          ],
          grid: { xs: 12, md: 6, lg: 4, xl: 3 },
          required: true,
        },
        {
          type: elementInScaffolderPhaseVisible(item, canSeeMoreThanClient) ? 'group' : 'hidden',
          name: 'scaffoldDetails',
          variant: 'line',
          // container: { xs: 12 },
          grid: { xs: 12, md: 6, lg: 4 },
          elements: [
            {
              disabled: item?.agentPhase !== ScaffoldAgentPhase.SCAFFOLDER,
              type: 'text',
              name: 'widthLoad',
              grid: { xs: 12 },
            },
            {
              disabled: item?.agentPhase !== ScaffoldAgentPhase.SCAFFOLDER,
              type: 'date',
              name: 'constructionDate',
              minDate: DateTime.fromISO('2000-01-01'),
              grid: { xs: 12 },
            },
          ],
        },
      ],
    },
  {
    type: 'group',
    name: 'additional',
    container: { xs: 12, sm: 1 },
    elements: [
      {
        type: 'icon',
        name: 'attachments',
        grid: { xs: 12 },
        variant: 'iconbutton',
        tooltip: item?.isNew && t('pleaseSaveItem'),
        badge: true,
        enableAlways: true,
        disabled: Boolean(item?.isNew),
        disableAll: false,
        // conditionalProps: () => ({ disabled: false }),
        icon: <Paperclip fontSize="large" />,
        badgeCount: attachments?.length,
        onClick: handleGoToAttachments,
      },
      {
        type: 'icon',
        grid: { xs: 12 },
        // variant: 'icon',
        name: 'actions',
        enableAlways: true,
        disabled: Boolean(item?.isNew),
        disableAll: false,
        // conditionalProps: () => ({ disabled: false }),
        icon: <EditActions item={item!} mode={mode} t={t} />,
      },
    ],
  },

]),
  [item, customerOptions, operationUnitOptions, buildingOptions, projectOptions, phase?.nextStep, phase?.step?.statusOnCompletion, t, scaffoldOptions, userOptions, customer, values.customerIndividualFields, values?.clientWorkflowSteps, canSeeMoreThanClient, scaffolderOptions, attachments?.length, handleGoToAttachments, mode, primaryUserOptions],
)
  return elements
}
