import { RestModel } from 'app/RestModel'
import { api } from 'app/apiClient'
import { CustomerModel } from 'api/models/customerModel'
import PermissionModel from '../models/PermissionModel'
import { ScaffolderModel } from './models/scaffolderModel'

class CustomRestModel extends RestModel<ScaffolderModel> {
  path = 'scaffolder'

  getItems = async (parameters: any, permissions: string[]|undefined) => {
    const fetchAllUsers = permissions?.includes(PermissionModel.VIEW_ALL_SCAFFOLDERS)
    if (fetchAllUsers) {
      return super.getItems(parameters, permissions, fetchAllUsers)
    }

    if (permissions?.includes(PermissionModel.VIEW_MY_SCAFFOLDERS)) {
      // scaffolder
      return await api.get(`${this.path}/MyScaffolders`).json() as ScaffolderModel[]
    }

    return super.getItems(parameters, permissions, fetchAllUsers)
  }
}

export default new CustomRestModel()
