import { RestModel } from 'app/RestModel'
import { IScaffoldInquiryRequestAttachment } from 'api/models/scaffoldInquiryAttachment'
import { jsonToFormData } from 'api/helper/formData'
import { getQueryForArchive } from 'api/helper/archiveQuery'
import { ScaffoldDraftModel } from 'api/models/scaffoldDraftModel'
import { KyResponse, Options } from 'ky'
import { IScaffoldScaffolderRequest, ScaffoldAgentPhase, ScaffoldInquiryModel } from './models/scaffoldModel'
import PermissionModel from '../models/PermissionModel'
import { api } from '../app/apiClient'

const clearPayload = (payload: ScaffoldDraftModel) => ({
  ...payload,
  rentFreeInDays: payload.agentPhase === 'CLIENT' ? undefined : payload.rentFreeInDays,
  clientWorkflowSteps: undefined,
  orderedByUser: undefined,
  copiedFromScaffoldInquiryId: undefined,
  scaffoldReferenceSerialNumber: undefined,
  csOrderId: payload.agentPhase === ScaffoldAgentPhase.GDK || payload.agentPhase === ScaffoldAgentPhase.SCAFFOLDER ? payload.csOrderId : undefined,
})
class ScaffoldInquiryRestModel extends RestModel<ScaffoldDraftModel> {
  path = 'scaffoldinquiriesdraft'

  getItems = async (parameters: any, permissions: string[]|undefined) => {
    try {
      return super.getItems(parameters, permissions, true)
    } catch (e) {
        console.error(e)
        return []
    }
  }

  async updateItem(payload: ScaffoldDraftModel, options?: Partial<Options>): Promise<unknown> {
    return super.updateItem(clearPayload(payload), options)
  }

  async createItem(payload:ScaffoldDraftModel, options?: Partial<Options>): Promise<unknown> {
    return super.createItem(clearPayload(payload), options)
  }

  async deleteItems(ids: string[]): Promise<KyResponse> {
    return api.delete(this.path, {
      json: ids,
    })
  }

  async requestsFromDrafts(ids: string[]): Promise<ScaffoldInquiryModel[]> {
    return await api.post(`${this.path}/RequestsFromDraft`, {
      json: ids,
    }).json()
  }
}

export default new ScaffoldInquiryRestModel()
