import { Alert, ThemeProvider } from '@mui/material'
import { styled } from '@mui/system'
import { DeleteTool } from 'ui/views/ScaffoldInquiries/MassEditor/SelectionTools/DeleteTool'
import { SubmitTool } from 'ui/views/ScaffoldInquiries/MassEditor/SelectionTools/SubmitTools'
import { ScaffoldDraftModel } from 'api/models/scaffoldDraftModel'
import theme from 'theme'
import { IFormManagerProps } from '@codeflix/mui-spreadsheet'

const StyledAlert = styled(Alert)(() => ({
  // width: '100%',
  borderRadius: 0,
  '& .MuiAlert-icon': {
    alignItems: 'center',
  },
  '& .MuiAlert-action': {
    alignItems: 'center',
  },

}))

export default function SelectionTools(state:IFormManagerProps<ScaffoldDraftModel>, isRowValid: (row:ScaffoldDraftModel)=>boolean) {
  const open = state.value.map((x:any, idx:number) => ({ index: idx, value: x })).filter((x: { value: { selected: any } }) => x.value.selected)

  return (
    <ThemeProvider theme={theme}>
      <DeleteTool<ScaffoldDraftModel> selected={open} state={state} isRowValid={isRowValid} />
      <SubmitTool selected={open} state={state} isRowValid={isRowValid} />
    </ThemeProvider>
  )
}
