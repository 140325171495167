import { AppThunk } from 'app/store'
import { KyResponse } from 'ky'
import i18n from 'i18next'
import { displayNotification } from 'features/notifications'

interface IResponse<T> {
  success: boolean,
  response?: KyResponse
  json?: T
}

/**
 *
 * @param successMsgKey - translationKey
 * @param apiEndpoint - should return kypromise with args
 */
export const responseAction = <T>(
  successMsgKey: string|undefined,
  apiEndpoint: Promise<KyResponse>,
  ) :AppThunk<Promise<IResponse<T>>> => async (dispatch, getState, extraArgument) => {
  try {
    const result = await apiEndpoint

    switch (result.status) {
      case 200:
        if (successMsgKey) dispatch(displayNotification(i18n.t(successMsgKey), { variant: 'success', autoClose: true }))
        if (result?.headers?.get('content-type')?.startsWith('application/json')) {
          return {
            success: true,
            response: result,
            json: await result.json() as T,
          }
        }
        return {
          success: true,
          response: result,
        }
      case 204:
        if (successMsgKey) dispatch(displayNotification(i18n.t(successMsgKey), { variant: 'success', autoClose: true }))
        return {
          success: true,
          response: result,
        }
      default:
        return {
          success: false,
          response: result,
        }
        // else dispatch(displayNotification(i18n.t('api:generic'), { variant: 'error', autoClose: true }))
    }
  } catch (e) {
    console.error('api', e)
    return {
      success: false,
    }
  }
}
