import { ReactElement } from 'react'
import { CustomRoute } from 'models/IRoute'

import { IRequiredProp } from 'framework/components/DataLoader/types/IRequiredProp'

import { IPermissionRequirement } from 'framework/auth/IPermissionRequirement'

function BaseRestRoutes(
  path: string,
  paramIdName: string,
  Overview: ReactElement,
  Edit: ReactElement,
  New: ReactElement,
  requirePermissions?: IPermissionRequirement,
  subRoutes?: CustomRoute[],
  fetch?: IRequiredProp[],
  fetchDetails?: IRequiredProp[],
):CustomRoute[] {
  const fetchAll = (fetch || []).concat(fetchDetails || [])
  const routes = [
    {
      path,
      children: [
        {
          index: true,
          authorized: true,
          requirePermissions,
          element: Overview,
          fetch,
        },
        {
          path: `:${paramIdName}`,

          ...(subRoutes ? ({
            children: [
              ...subRoutes,
              {
                index: true,
                authorized: true,
                requirePermissions,
                element: Edit,
                fetch: fetchAll,
              },
            ],
          }) : ({
            authorized: true,
            requirePermissions,
            element: Edit,
            fetch: fetchAll,
          })),
        },
        {
          path: 'new',
          authorized: true,
          requirePermissions,
          element: New,
          fetch,
        },
      ],
    },
  ]

  return routes
}
export default BaseRestRoutes
