import MaterialDialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { TFunction } from 'i18next'
import { PropsWithChildren, ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { DialogProps } from '@mui/material/Dialog/Dialog'

export interface IDialog2 extends Omit<DialogProps, 'title'> {
  t: TFunction<string, undefined>,
  title: string|ReactNode,
  open: boolean,
  actions?: ReactNode,
}

const StyledMaterialDialog = styled(MaterialDialog)({
  minWidth: 500,

})
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}))
const StyledDialogTitleTypography = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.87)',
})
const StyledDialogContent = styled(DialogContent)({
  color: 'rgba(0, 0, 0, 0.87)',
})

function Dialog2({
                      t,
                      children,
                      actions,
                      title,
                      open,
                      ...rest
                    }: PropsWithChildren<IDialog2>) {
  return (
    <StyledMaterialDialog
      open={open}
      disableScrollLock
      PaperProps={{
        style: {
          backgroundColor: '#fff',
        },
      }}
      {...rest}
    >
      <StyledDialogTitle>
        <StyledDialogTitleTypography align="center" variant="h6" gutterBottom>
          {title}
        </StyledDialogTitleTypography>
      </StyledDialogTitle>
      <StyledDialogContent>
        {children}
      </StyledDialogContent>
      {actions && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}
    </StyledMaterialDialog>
  )
}

export default Dialog2
