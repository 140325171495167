import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { actions, useUser } from 'features/users'
import { UserModel } from 'api/models/UserModel'
import { RoleModel } from 'api/models/RoleModel'
import { useParams } from 'react-router'
import EditView from 'framework/components/BaseRestViews/EditView'
import { useKeyToAutocompleteOptionsArray } from '@codeflix/mui-managed-form'
import { DeleteEnabled } from 'ui/views/SaveView/DeleteEnabled'
import { UserStatus } from 'api/models/userStatus'
import { useAllCompaniesOptions } from '../../../../features/companies'

const grid = { xs: 12, sm: 6, md: 3 }
const smallGrid = { xs: 6, sm: 3, md: 2 }

function Editor({ item }: { item?: UserModel }) {
  const { t } = useTranslation('users')
  const { t: tRoles } = useTranslation('roles')
  const { t: tUserStatus } = useTranslation('userStatus')
  const roleOptions = useKeyToAutocompleteOptionsArray(tRoles, Object.keys(RoleModel))
  const userStatusOptions = useKeyToAutocompleteOptionsArray(tUserStatus, Object.keys(UserStatus))
  const companyOptions = useAllCompaniesOptions()
  const elements = useMemo(() => ([
    {
      type: 'text',
      name: 'firstName',
      required: true,
    },
    {
      type: 'text',
      name: 'lastName',
      required: true,
    },
    {
      type: 'email',
      name: 'email',
      required: true,
    },
    {
      type: 'text',
      name: 'phoneNumber',
    },
    {
      type: 'password',
      name: 'password',
      details: true,
      required: false,
    },
    {
      type: 'autocomplete',
      name: 'roles',
      required: true,
      options: roleOptions,
      multi: true,
    },
    {
      type: 'autocomplete',
      name: 'companyId',
      required: true,
      options: companyOptions,
    },
    {
      // type: (element:any, values:any) => (values.roles.includes('GDK') || values.roles.includes('SYSADMIN') ? 'autocomplete' : 'hidden'),
      type: 'autocomplete',
      // tooltip: t('customerIdsTooltip'),
      name: 'customerIds',
      multi: true,
      options: companyOptions,
    },
    {
      type: 'tags',
      name: 'tags',
      options: [],
    },
    {
      type: 'autocomplete',
      name: 'status',
      required: true,
      getOptionDisabled: (option:any) => Boolean(option.disabled),
      options: [
        {
          label: tUserStatus(UserStatus.PendingEmailVerificationBeforeApproval),
          value: UserStatus.PendingEmailVerificationBeforeApproval,
          disabled: true,
        },
        {
          label: tUserStatus(UserStatus.PendingApproval),
          value: UserStatus.PendingApproval,
          disabled: true,
        },
        {
          label: tUserStatus(UserStatus.Active),
          value: UserStatus.Active,
        },
        {
          label: tUserStatus(UserStatus.Inactive),
          value: UserStatus.Inactive,
        },
      ],
    },
  ]), [roleOptions, companyOptions])
  const linkStack = useMemo(() => ([
    { to: '/administration/users', text: t('application:users') },
    { text: item?.isNew ? t('application:new') : `${item?.lastName} - ${item?.firstName}` },
  ]), [t, item])

  return (
    <EditView
      loading={!item}
      linkStack={linkStack}
      elements={elements}
      actions={actions}
      item={item}
      saveView={{
        hideDraft: true,
        enableDelete: !item?.isNew ? DeleteEnabled.IfEnabled : DeleteEnabled.Never,
      }}
      t={t}
    />
  )
}

export function Edit() {
  const { userId } = useParams() as { userId: string }
  const item = useUser(userId)
  return (
    <Editor item={item} />
  )
}

export function New() {
  const item = new UserModel()
  return (
    <Editor item={item} />
  )
}
