import {
  MaterialReactTableIntermediateProps,
} from 'framework/components/Table/types/materialReactTableIntermediateProps'
import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'

export const openScaffoldTableConfig: Partial<MaterialReactTableIntermediateProps<ScaffoldInquiryModel>> = {
  muiTableBodyCellProps: ({ row }) => ({
    sx: {
      fontWeight: row.original.adHocOrder ? 500 : undefined,
    },
  }),
  initialState: {
    sorting: [
      {
        id: 'createdOn',
        desc: true,
      },
    ],
    columnVisibility: {
      id: false,
      constructionDate: false,
      deconstructionDate: false,
      canceledToDate: false,
      rentFreeInDays: false,
      projectId: false,
      area: false,
      orderNr: false,
      adhoc: false,
      type: false,
      individualScaffoldOrderFields: false,
    },
  },
  positionActionsColumn: 'last',
}
