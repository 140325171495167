import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useSiteMeasuringTemplates } from 'features/siteMeasuringTemplates'
import OverviewView from '../../../../framework/components/BaseRestViews/OverviewView'

export default function Overview() {
  const data = useSiteMeasuringTemplates()
  const { t } = useTranslation('siteMeasuringTemplates')
  const columns = useMemo(() => [
    {
      header: t('generic:id'),
      accessorKey: 'id',
      filterFn: 'includesString',
    },
    {
      header: t('name'),
      accessorKey: 'name',
      filterFn: 'includesString',
    },
  ], [t])
  // const onRowClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
  //
  // },[navigate])
  return (
    <OverviewView
      linkStack={[
        { text: t('application:siteMeasuringTemplates') },
      ]}
      name={t('application:siteMeasuringTemplates').toString()}
      data={data || []}
      columns={columns}

    />
  )
}
