import { DateTime } from 'luxon'

export const getQueryForArchive = (parameters:any) => {
  const { year } = parameters as any
  let standardDateRangeFrom = DateTime.now().minus(window.appConfig?.standardDateRange || { year: 1 }).toFormat('yyyy-MM-dd')
  if (parameters.archive === true) {
    if (!/^([0-9]{4})$/.test(year)) {
      throw new Error('QueryWrong')
    }
    const yearInt = parseInt(year, 10)
    standardDateRangeFrom = `${yearInt}-01-01&daterange.to=${yearInt + 1}-01-01`
  }
  const query = `?daterange.from=${standardDateRangeFrom}`
  return query
}
