import {
  aspectRatioScaling,
  WidthAndHeight,
} from 'framework/components/ManagedForm/Fields/ImageEditor/libs/UseSizeScaleImage'
import { blobToImageBitmap } from 'framework/components/ManagedForm/Fields/ImageEditor/libs/UseImageSrcFromBlob'

export const resizeImage = async (image: Blob|File, target: WidthAndHeight) => {
  // const bmp = await createImageBitmap(image, {
  //   // resizeWidth: actualWidth,
  //   // resizeHeight: actualHeight,
  //   resizeQuality: 'medium',
  // })
  const bmp = (await blobToImageBitmap(image))!
  const { actualWidth, actualHeight } = aspectRatioScaling(bmp, target, true)
  // console.log('scaling from to', bmp.)
  const canvas = document.createElement('canvas')
  const canvasCtx = canvas.getContext('2d')
  // resize to target
  canvas.width = actualWidth
  canvas.height = actualHeight
  // draw image?
  canvasCtx?.drawImage(bmp, 0, 0, actualWidth, actualHeight)

    // .drawImage(bmp, 0, 0)
  return await new Promise((resolve) => {
    canvas?.toBlob((blob) => resolve(blob), image.type)
  })
 //  const img = new Image(actualWidth, actualHeight)
 //  const previewSrcUrl = await new Promise((resolve) => {
 //    canvas.toBlob((blob) => {
 //      img.src = URL.createObjectURL(blob!)
 //      resolve(img.decode())
 //    })
 //  })
 //  // console.log()
 //  // const previewSrcUrl = await new Promise((resolve) => {
 //  //   canvas.toBlob((blob) => {
 //  //     resolve(URL.createObjectURL(blob!))
 //  //   })
 //  // })
 // return img.src
}
