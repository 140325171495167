import Login from 'ui/views/Login'
import AuthorizedRoute from 'ui/layout/AuthorizedRoute'
import { matchRoutes, RouteObject, useLocation } from 'react-router'
import { CustomRoute } from 'models/IRoute'
import { getRequiredPropsToFetch } from 'ui/fetchHelper'
import ResetPasswordForm from 'ui/views/Login/ResetPasswordForm'
import Index from 'ui/views/Account'
import ChangePassword from 'ui/views/Account/ChangePassword'
import ActivateAccount from 'ui/views/Login/ActivateAccount'
import RequestAccount from 'ui/views/Account/RequestAccount'
import ConfirmEmail from 'ui/views/Account/ConfirmEmail'
import Dashboard from 'ui/views/Dashboard/Dashboard'
import lazyFetchAllViews, { lazySiteMeasuring, lazySiteMeasuringInspection } from 'ui/views/lazyFetchAllViews'
import ResetPasswordRequestForm from './views/Login/ResetPasswordRequestForm'
import AdministrationRoutes from './views/Management/routes'
import InquiryRoutes from './views/ScaffoldInquiries/routes'
import ConstructionRoutes from './views/SiteMeasurings/routes'
import SiteMeasuringInspections from './views/SiteMeasuringInspection/routes'
import RedirectRoutes from './views/Redirect/routes'

export const customRoutes: CustomRoute[] = [
  {
    path: '/',
    element: (<Login />),
  },
  {
    path: '/dashboard',
    element: (<Dashboard />),
    authorized: true,
    fetch: [
      { prop: 'customers' },
      { prop: 'projects' },
      { prop: 'buildings' },
      { prop: 'operationUnits' },
      { prop: 'users' },
      { prop: 'scaffolders' },
      { prop: 'scaffoldInquiries', fetch: 'always' },
      lazySiteMeasuring,
      lazySiteMeasuringInspection,
    ],

  },
  {
    path: '/request-account',
    element: (<RequestAccount />),
  },
  {
    path: '/reset-password',
    element: (<ResetPasswordRequestForm />),
  },
  {
    path: '/reset-password/:email',
    element: (<ResetPasswordForm />),
  },
  {
    path: '/enroll/:email',
    element: (<ActivateAccount />),
  },
  {
    path: '/confirm-email/:email',
    element: (<ConfirmEmail />),
  },
  {
    path: '/account',
    children: [
      {
        index: true,
        authorized: true,
        element: (<Index />),
        fetch: getRequiredPropsToFetch,
      },
      {
        path: 'change-password',
        authorized: true,
        element: (<ChangePassword />),
        fetch: getRequiredPropsToFetch,
      },
    ],
  },
  ...RedirectRoutes,
  ...AdministrationRoutes,
  ...InquiryRoutes,
  ...ConstructionRoutes,
  ...SiteMeasuringInspections,

]
export const mapRoutes = (list:CustomRoute[]|undefined):RouteObject[]|undefined => list?.map((x) => {
  const children = mapRoutes(x.children)
  if (x.authorized) {
    return {
      ...x,
      children,
      element: <AuthorizedRoute {...x} />,
    } as RouteObject
  }
  return {
    ...x,
    children,
  } as RouteObject
})
export const useCurrentPath = () => {
  const location = useLocation()
  const result = matchRoutes(customRoutes, location)
  if (!result) return undefined

  const [{ route }] = result
  return route
}

export default mapRoutes(customRoutes)!
