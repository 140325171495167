import { IPropsLoader } from 'framework/components/DataLoader/types/IPropsLoader'
import { useEffect, useMemo } from 'react'
import { IRequiredProp } from 'framework/components/DataLoader/types/IRequiredProp'
import { BaseRestSliceType } from 'features/base/BaseRestFeature'
import { AppThunk } from 'app/store'
import { useLocation } from 'react-router'
import { useAppDispatch } from 'app/hooks'
import { usePropIsReadyWithFetchPolicy } from 'framework/components/DataLoader/hooks/usePropIsReadyWithFetchPolicy'
import LoadStateModel from 'framework/LoadStateModel'
import { useUserPermissionCheck } from 'framework/auth/permissions'

const loadProp = (requiredProp: IRequiredProp, parameters:any):AppThunk => (dispatch) => {
  const thisParameters = requiredProp.mode === 'archive' ? { ...parameters, archive: true } : parameters

  import(`features/${requiredProp.prop}`).then((module:BaseRestSliceType<any>) => {
    // @ts-ignore
    dispatch(module.actions.get.apply(this, [thisParameters]))
  })
}

export default function useMultiDataLoaderWithFetchPolicy(props:IPropsLoader) {
  const { requiredProps, parameters } = props
  const loadState = usePropIsReadyWithFetchPolicy(props) // use only blocking for ui load block
  const appDispatch = useAppDispatch()
  const { pathname } = useLocation()
  const permissionCheck = useUserPermissionCheck()
  useEffect(() => {
    // check for refetch???
    requiredProps.forEach((requiredProp) => {
      if (requiredProp.requirePermissions && !permissionCheck(requiredProp.requirePermissions)) return
      console.log(requiredProp.prop, parameters, loadState.props[requiredProp.prop])
      if (requiredProp.fetch === 'always' || loadState.props[requiredProp.prop] !== LoadStateModel.LOADED) {
        appDispatch(loadProp(requiredProp, parameters))
      }
    })
  }, [requiredProps, pathname])
  return useMemo(() => loadState.overall, [loadState])
}
