import { Button, ButtonProps, CircularProgress, ListItemIcon, MenuItem, MenuItemProps, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MouseEvent, useCallback, useState } from 'react'

const Loading = styled(CircularProgress)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}))

export interface IAsyncActionButtonProps extends ButtonProps {
  // loading?: boolean;
  onClick: (event:MouseEvent<HTMLButtonElement>)=>Promise<void>|void
}

export default function AsyncActionButton({
  children,
  onClick,
  disabled,
  ...rest
}: IAsyncActionButtonProps) {
  const { t } = useTranslation('AsyncActionButton')
  const [loading, setLoading] = useState(false)
  const handleClick = useCallback(async (event:MouseEvent<HTMLButtonElement>) => {
    if (typeof onClick === 'function') {
      setLoading(true)
      const result = onClick(event)
      if (result instanceof Promise) {
        await result
      }
      setLoading(false)
    }
  }, [setLoading, onClick])
  return (
    <Button onClick={handleClick} disabled={loading || disabled} {...rest} startIcon={loading && <Loading variant="indeterminate" color="primary" size={20} />}>
      <div>{children}</div>
    </Button>
  )
}

interface IProps2 extends MenuItemProps {
  // loading?: boolean;
  onClick: (event:MouseEvent<HTMLLIElement>)=>Promise<void>|void
}
export function AsyncActionMenuitem({
                                    children,
                                    onClick,
                                    disabled,
                                    ...rest
                                  }: IProps2) {
  const { t } = useTranslation('AsyncActionButton')
  const [loading, setLoading] = useState(false)
  const handleClick = useCallback(async (event:MouseEvent<HTMLLIElement>) => {
    if (typeof onClick === 'function') {
      setLoading(true)
      const result = onClick(event)
      if (result instanceof Promise) {
        await result
      }
      setLoading(false)
    }
  }, [setLoading, onClick])
  return (
    <MenuItem onClick={handleClick} disabled={loading || disabled} {...rest}>
      {loading && (
        <ListItemIcon>
          <Loading variant="indeterminate" color="primary" size={20} />
        </ListItemIcon>
      ) }
      <div>
        {children}
      </div>
    </MenuItem>
    // <Button onClick={handleClick} disabled={loading || disabled} {...rest} startIcon={loading && <Loading variant="indeterminate" color="primary" size={20} />}>
    //   {children}
    // </Button>
  )
}
