import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo } from 'react'
import { actions, useSiteMeasuringInspectionById, hooks } from 'features/siteMeasuringInspections'
import { useParams } from 'react-router'
import EditView from 'framework/components/BaseRestViews/EditView'
import { ScaffoldState } from 'api/models/scaffoldModel'
import ScaffoldInquiryViewer from 'ui/views/SiteMeasurings/ScaffoldInquiryViewer'
import { Divider, Grid, useMediaQuery } from '@mui/material'
import BreadCrumb from 'framework/components/BreadCrumb'
import { Theme } from '@mui/system'
import { ISiteMeasuringInspection } from 'api/models/siteMeasuringInspections'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'
import { useArchiveLinkStackTranslated } from 'framework/components/BaseRestViews/BreadCrumbYearSelector'
import DownloadSiteMeasuringPdf from 'ui/views/SiteMeasurings/Actions/DownloadSiteMeasuringPdf'

const grid = { xs: 12, sm: 6, md: 3 }
const itemGrid = { xs: 12, sm: 6, md: 3 }
const itemGrid2 = { xs: 12, sm: 6, md: 4 }
const itemGridDouble = { xs: 12, sm: 12, md: 6 }
const smallGrid = { xs: 6, sm: 3, md: 2 }

const fullGrid = { xs: 12, md: 12 }
const ScaffoldStates = Object.keys(ScaffoldState)

const columnGrid = { direction: 'column', xs: 12, sm: 4 }

function Editor({ item, mode }: { item?: ISiteMeasuringInspection, mode: RestViewMode }) {
  const { t } = useTranslation('siteMeasuringInspection')
  // const inquiry = useScaffoldInquiryById(item?.scaffoldInquiryId) as IScaffoldInquiryModelAggregated
  // const siteMeasuring = useSiteMeasuringById(item?.siteMeasuringId)
  const inquiry = item?.scaffoldInquiry
  // const customer = inquiry?.customer
  const handleSubmit = useCallback((mode: 'submit' | 'draft') => (submitValues: any) => {
    const additionalPayload: any = {}

    return {
      ...submitValues,
      ...additionalPayload,
    }
  }, [])
  const elements = useMemo(() => ([
    {
      type: 'object',
      variant: 'tab',
      name: 'siteMeasuring',
      required: true,
      grid: { xs: 12, md: 12 },
      elements: item?.customerSiteMeasuringTabs?.map((tab) => (
        {
          type: 'object',
          name: tab.id,
          label: tab.name,
          variant: 'none',
          elements: tab.groups.map((group) => (
            {
              type: 'object',
              name: group.id,
              label: group.name,
              variant: 'borderbox',
              divider: true,

              elements: group.fields.map((field) => ({
                type: 'object',
                name: field.id,
                variant: 'line',
                headerVariant: 'subtitle1',
                tooltip: field.fullName,
                label: field.shortName,
                container: { alignItems: 'center' },
                elements: [
                  {
                    type: field.type.toLowerCase(),
                    name: 'original',
                    label: field.shortName,
                    disabled: true, // its defined server side
                    unit: field.unit,
                    grid: field.type === 'MULTILINE' ? itemGridDouble : itemGrid,
                  },
                  {
                    type: 'radio',
                    name: 'correct',
                    label: ' ',
                    grid: itemGrid2,
                    group: { row: true },
                    options: [
                      { value: true, label: t('correct') },
                      { value: false, label: t('notCorrect') },
                    ],
                  },
                  {
                    type: (element: any, value: any) => (value?.correct === false ? field.type.toLowerCase() : 'hidden'),
                    name: 'correction',
                    unit: field.unit,
                    grid: field.type === 'MULTILINE' ? itemGridDouble : itemGrid2,
                  },
                ],

              })),
            }
          )),
        }
      )),
    },
    {
      type: 'radio',
      name: 'overallAssessmentAccepted',
      grid: itemGrid2,
      group: { row: true },
      options: [
        { value: true, label: t('correct') },
        { value: false, label: t('notCorrect') },
      ],
    },
  ]), [item?.customerSiteMeasuringTabs, t])

  const baseLinkStack = useArchiveLinkStackTranslated(mode, 'siteMeasuringInspection', false)
  const linkStack = useMemo(() => ([
    ...(baseLinkStack || [{ to: '..', text: t('application:siteMeasuringInspection') }]),
    { text: item?.isNew ? t('application:new') : t('scaffoldInquiryNr', { serial: inquiry?.serialNumber?.fullSerial }) },
  ]), [t, item, inquiry])
  const big = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <BreadCrumb
          color="black"
          linkStack={linkStack}
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        {inquiry
          && (
            <>
              <ScaffoldInquiryViewer item={inquiry} />
              <DownloadSiteMeasuringPdf scaffoldInquiry={inquiry} />
            </>
          )}

      </Grid>
      <Grid item xs={1}><Divider orientation={big ? 'vertical' : 'horizontal'} /></Grid>
      <Grid item xs={12} lg={8}>
        <EditView
          loading={!item}
          elements={elements}
          actions={actions}
          item={item}
          t={t}
          debug
          saveView={{ hideDraft: true }}
          middlewares={{
            onSubmit: handleSubmit('submit'),
            onDraft: handleSubmit('draft'),
          }}
        />
      </Grid>
    </Grid>
  )
}

export function Edit() {
  const { siteMeasuringInspectionId } = useParams() as { siteMeasuringInspectionId: string }
  const item = useSiteMeasuringInspectionById(siteMeasuringInspectionId)
  return (
    <Editor item={item} mode={RestViewMode.Default} />
  )
}

export function New() {
  return (null)
}

export function ViewArchive() {
  const { siteMeasuringInspectionId, year } = useParams() as { siteMeasuringInspectionId: string, year: string }
  const item = hooks.useArchiveDataById(year, siteMeasuringInspectionId)
  return (
    <Editor item={item} mode={RestViewMode.Archive} />
  )
}
