import CountMetrics from 'ui/views/Dashboard/components/CountMetrics'
import { useTranslation } from 'react-i18next'
import { useCurrentScaffolds, useScaffoldInquiriesFiltered } from 'features/scaffoldInquiries'
import { useMemo } from 'react'
import { ScaffoldAgentPhase, ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { useSiteMeasurings, useSiteMeasuringsFiltered } from 'features/siteMeasurings'
import { SiteMeasuringAgentPhase, SiteMeasuringModel, SiteMeasuringState } from 'api/models/siteMeasuringModel'
import { addDays } from 'lib/addDays'
import DashboardPaper from 'ui/views/Dashboard/components/DashboardPaper'
import Table from 'framework/components/Table'
import { openScaffoldTableConfig } from 'ui/views/ScaffoldInquiries/Table/openScaffoldTableConfig'
import { useOverviewData } from 'ui/views/ScaffoldInquiries/OverviewHelper'
import { useMyCompanyType } from 'features/companies'
import { currentScaffoldTableConfig } from 'ui/views/ScaffoldInquiries/Table/currentScaffoldTableConfig'
import { ISingleOpenedState } from 'ui/views/Dashboard/lib/useSingleOpenState'
import { useSiteMeasuringColumns } from 'ui/views/SiteMeasurings/UseSiteMeasuringColumns'

export function CountOfOpenScaffolds(props: ISingleOpenedState) {
  const { t } = useTranslation('dashboard')

  const scaffolds = useScaffoldInquiriesFiltered((x:ScaffoldInquiryModel) => x.agentPhase !== ScaffoldAgentPhase.FULLY_SIGNED)
  return (
    <CountMetrics {...props} href="/scaffoldInquiries" value={scaffolds?.length || 0} title={t('countOfOpenScaffolds')} />
  )
}

/**
 * Abgemeldet aber noch nicht abgebaut
 * @constructor
 */
export function CountOfCancelledButNotDeconstructedScaffolds(props: ISingleOpenedState) {
  const { t } = useTranslation('dashboard')
  const scaffolds = useCurrentScaffolds()
  const data = useMemo(() => scaffolds?.filter((x) => x.canceledToDate && !x.deconstructionDate), [scaffolds])
  const { columns } = useOverviewData(data)
  const companyType = useMyCompanyType()
  return (
    <CountMetrics {...props} value={data?.length} title={t('countOfCancelledButNotDeconstructedScaffolds')} >
      <DashboardPaper>
        <Table<ScaffoldInquiryModel>
          name={t('countOfCancelledButNotDeconstructedScaffolds')}
          pathname="/scaffolds"
          data={data}
          columns={columns as any}
          {...currentScaffoldTableConfig(companyType!)}
        />
      </DashboardPaper>
    </CountMetrics>
  )
}

/**
 * Abgemeldet
 * @constructor
 */
export function CountOfCancelledScaffolds(props: ISingleOpenedState) {
  const { t } = useTranslation('dashboard')
  const scaffolds = useCurrentScaffolds()
  const data = useMemo(() => scaffolds?.filter((x) => x.canceledToDate), [scaffolds])
  const { columns } = useOverviewData(data)
  const companyType = useMyCompanyType()
  return (
    <CountMetrics {...props} value={data?.length} title={t('countOfCancelledScaffolds')}>
      <DashboardPaper>
        <Table<ScaffoldInquiryModel>
          name={t('countOfCancelledScaffolds')}
          pathname="/scaffolds"
          data={data}
          columns={columns as any}
          {...currentScaffoldTableConfig(companyType)}
        />
      </DashboardPaper>
    </CountMetrics>
  )
}
/**
 * Not constructed yet
 * @constructor
 */
export function CountOfNotConstructedScaffolds(props: ISingleOpenedState) {
  const { t } = useTranslation('dashboard')
  const scaffolds = useScaffoldInquiriesFiltered((x:ScaffoldInquiryModel) => !x.constructionDate && x.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED)
  const { columns } = useOverviewData(scaffolds)
  const companyType = useMyCompanyType()
  return (
    <CountMetrics {...props} value={scaffolds.length} title={t('countOfNotConstructedScaffolds')} >
      <DashboardPaper>
        <Table<ScaffoldInquiryModel>
          name={t('countOfNotConstructedScaffolds')}
          pathname="/scaffolds"
          data={scaffolds}
          columns={columns as any}
          {...currentScaffoldTableConfig(companyType)}
        />
      </DashboardPaper>
    </CountMetrics>
  )
}

/**
 * Not accounted scaffolds yet
 * @constructor
 */
export function CountOfNoSiteMeasuring(props: ISingleOpenedState) {
  const { t } = useTranslation('dashboard')

  const scaffolds = useScaffoldInquiriesFiltered((x:ScaffoldInquiryModel) => x.constructionDate && x.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED)
  const siteMeasurings = useSiteMeasurings()
  const all = useMemo(() => {
    const scaffoldsWithoutSiteMeasuring = scaffolds
      .filter(((x:ScaffoldInquiryModel) => !siteMeasurings.some((y:SiteMeasuringModel) => y.scaffoldInquiryId === x.id)))

    return scaffoldsWithoutSiteMeasuring
  }, [scaffolds, siteMeasurings])

  const { columns } = useOverviewData(scaffolds)
  const companyType = useMyCompanyType()
  return (
    <CountMetrics {...props} value={all.length} title={t('countOfNoSiteMeasurings')}>
      <DashboardPaper>
        <Table<ScaffoldInquiryModel>
          name={t('countOfNoSiteMeasurings')}
          pathname="/scaffolds"
          data={all}
          columns={columns as any}
          {...currentScaffoldTableConfig(companyType)}
        />
      </DashboardPaper>
    </CountMetrics>
  )
}
/**
 * accepted but not invoiced yet
 * @constructor
 */
export function CountOfNotAccounted(props: ISingleOpenedState) {
  const { t } = useTranslation('dashboard')

  const siteMeasurings = useSiteMeasuringsFiltered((x:SiteMeasuringModel) => !x.isAccounted)
  const all = useMemo(() => {
    const all = siteMeasurings.filter((y:SiteMeasuringModel) => !y.isAccounted && y.state === SiteMeasuringState.FULLY_SIGNED)
    return all
  }, [siteMeasurings])

  const columns = useSiteMeasuringColumns()
  const companyType = useMyCompanyType()
  return (
    <CountMetrics {...props} value={all.length} title={t('countOfNotAccounted')}>
      <DashboardPaper>
        <Table<ScaffoldInquiryModel>
          name={t('countOfNotAccounted')}
          pathname="/siteMeasuring"
          data={all}
          columns={columns as any}
          {...currentScaffoldTableConfig(companyType)}
        />
      </DashboardPaper>
    </CountMetrics>
  )
}/**
 * accepted but not checked yet
 * @constructor
 */
export function CountOfNotCheckedSiteMeasuring(props: ISingleOpenedState) {
  const { t } = useTranslation('dashboard')

  const siteMeasurings = useSiteMeasuringsFiltered((x:SiteMeasuringModel) => !x.isAccounted)
  const all = useMemo(() => {
    const all = siteMeasurings.filter((y:SiteMeasuringModel) => y.state !== SiteMeasuringState.FULLY_SIGNED && y.agentPhase === SiteMeasuringAgentPhase.GDK)
    return all
  }, [siteMeasurings])

  const columns = useSiteMeasuringColumns()
  const companyType = useMyCompanyType()
  return (
    <CountMetrics {...props} value={all.length} title={t('countOfNotCheckedSiteMeasuring')}>
      <DashboardPaper>
        <Table<SiteMeasuringModel>
          name={t('countOfNotCheckedSiteMeasuring')}
          pathname="/siteMeasuring"
          data={all}
          columns={columns as any}
          {...currentScaffoldTableConfig(companyType)}
        />
      </DashboardPaper>
    </CountMetrics>
  )
}

export function CountOfOpenSiteMeasuringsForGdk(props: ISingleOpenedState) {
  const { t } = useTranslation('dashboard')

  const siteMeasurings = useSiteMeasuringsFiltered((x:SiteMeasuringModel) => x.agentPhase === SiteMeasuringAgentPhase.GDK)
  return (
    <CountMetrics {...props} value={siteMeasurings.length} title={t('countOfNotAccounted')} />
  )
}

const isScaffoldInRent = (scaffold: ScaffoldInquiryModel) => addDays(scaffold.constructionDate!, (scaffold.canceledToDate || scaffold.deconstructionDate)!, scaffold.rentFreeInDays!).days <= 0
export function GetScaffoldsWithRent(props: ISingleOpenedState) {
  const { t } = useTranslation('dashboard')
  const scaffolds = useScaffoldInquiriesFiltered((x:ScaffoldInquiryModel) => x.constructionDate && isScaffoldInRent(x))
  const { columns } = useOverviewData(scaffolds)
  const companyType = useMyCompanyType()
  return (
    <CountMetrics {...props} value={scaffolds.length} title={t('scaffoldsInRent')}>
      <DashboardPaper>
        <Table<ScaffoldInquiryModel>
          name={t('scaffoldsInRent')}
          pathname="/scaffolds"
          data={scaffolds}
          columns={columns as any}
          {...currentScaffoldTableConfig(companyType)}
        />
      </DashboardPaper>
    </CountMetrics>

  )
}
export function ScaffoldsInPlanningCount(props: ISingleOpenedState) {
  const { t } = useTranslation('dashboard')
  const scaffolds = useScaffoldInquiriesFiltered((x:ScaffoldInquiryModel) => x.agentPhase === ScaffoldAgentPhase.CLIENT)
  const { columns } = useOverviewData(scaffolds)
  return (
    <CountMetrics {...props} value={scaffolds.length} title={t('countOfScaffoldsInPlanning')}>
      <DashboardPaper>
        <Table<ScaffoldInquiryModel>
          name={t('scaffoldsInRent')}
          pathname="/scaffolds"
          data={scaffolds}
          columns={columns as any}
          {...openScaffoldTableConfig}
        />
      </DashboardPaper>
    </CountMetrics>

  )
}
