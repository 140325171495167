import { styled } from '@mui/material/styles'
import { MouseEvent, ReactNode } from 'react'
import { Breadcrumbs, Button } from '@mui/material'

export interface IBreadcrumbsProps {
  color: 'black'|'white'
}
export interface ILinkStack {
  to?: string,
  text: string|undefined,
  component?: ReactNode
  onClick?: (evt: MouseEvent<HTMLButtonElement>)=>void
}
export interface IProps extends IBreadcrumbsProps {
  linkStack: ILinkStack[],
}
const StyledBreadCrumbs = styled(Breadcrumbs)<IBreadcrumbsProps>(({ theme, color }) => ({
  color,
}))
export default StyledBreadCrumbs

export const StyledBreadCrumbsButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  styleOverrides: {
    MuiButtonBase: {
      root: {
        fontSize: '1.2rem',
      },
    },
    root: {
      fontSize: '1rem',
    },
  },
}))
