import { RestModel } from 'app/RestModel'
import { ProjectModel } from './models/projectModel'

class ProjectsRestModel extends RestModel<ProjectModel> {
  path = 'project'

  getItems = async (parameters: any, permissions: string[]|undefined) => {
    const fetchAllUsers = permissions?.includes('VIEW_ALL_CUSTOMERS')

    return super.getItems(parameters, permissions, fetchAllUsers)
  }
}

export default new ProjectsRestModel()
