import { forwardRef, MouseEvent, PropsWithChildren, ReactElement, Ref, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { PortalWithId } from 'framework/components/Portal'
import { Check } from '@mui/icons-material'

const StyledDialog = styled(Dialog)((
  {
    theme,
  },
) => ({

}))
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  top: 0,
}))
const StyledTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flex: 1,
}))
const StyledContent = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '91vh',
  width: '100%',
  marginTop: theme.spacing(8),
  marginBottom: 0,
  overflowY: 'hidden',
  padding: theme.spacing(1),
  // overflowY: 'auto',
}))
const Transition = forwardRef((
  props: PropsWithChildren<TransitionProps> & { children: ReactElement },
  ref: Ref<any>,
) => <Slide direction="up" ref={ref} {...props} />)

interface ITexts {
  title: string,
  save: string,
}

interface IFullScreenDialogProps {
  open: boolean,
  menu?: ReactElement,
  title: string,
  onClose: () => void,
  onSave?: (event: MouseEvent<HTMLButtonElement>) => Promise<void>|void,
}

export const fullScreenDialogPortalId = 'fullScreenPortal1'
export const FullScreenDialogPortal = PortalWithId(fullScreenDialogPortalId)

function FullScreenDialog(props: PropsWithChildren<IFullScreenDialogProps>) {
  const { open } = props

  const handleClose = useCallback(() => {
    // document.body.removeAttribute('style')
    props.onClose()
  }, [props])

  // useEffect(()=>{
  //   return () => {
  //     props.setOpen(false)
  //   }
  // },[])

  return (
    <StyledDialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} keepMounted={false}>
      <StyledAppBar>
        <Toolbar color="primary">
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          {(typeof props.onSave === 'function' && open) && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onSave}
              aria-label="close"
              size="large"
            >
              <Check />
            </IconButton>
          )}
          <StyledTitle variant="h6">
            {props.title}
          </StyledTitle>
          {props.menu}
          <div id={fullScreenDialogPortalId} />
        </Toolbar>
      </StyledAppBar>
      <StyledContent>
        {props.children}
      </StyledContent>
    </StyledDialog>
  )
}

export default FullScreenDialog
