import { useImageSrcFromBlob } from 'framework/components/ManagedForm/Fields/ImageEditor/libs/UseImageSrcFromBlob'
import { IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import { Fullscreen } from '@mui/icons-material'
import { IRemoteAttachment } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IRemoteAttachment'
import { useOpenState } from 'lib/stateHelper'
import FullScreenDialog from 'framework/components/ManagedForm/Fields/FullscreenDialog'
import { useTranslation } from 'react-i18next'
import { useManagedForm, Dropzone } from '@codeflix/mui-managed-form'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import ImageViewer from 'framework/components/ManagedForm/Fields/ImageEditor/ImageViewer'
import { TrashCan } from 'mdi-material-ui'

type ThumbnailUrlCrafter = (id: string) => string

interface IProps {

  value: IRemoteAttachment,
  // thumbnail?: ThumbnailUrlCrafter
  onSave?: (values: any) => Promise<void> | void
  onDelete?: (values: any) => Promise<void> | void
}

export default function ThumbnailView(props: IProps) {
  const { value, onSave, onDelete } = props
  const { thumbnail } = value
  const { t } = useTranslation('imageEditor')
  // if we have thumbnail, catch that
  const { open, toggle, handleClose } = useOpenState()
  const cachedVal = useMemo(() => ({ image: value }), [value])
  // console.log('loading data', cachedVal,value)
  const { values, handleChange, reset, setAll } = useManagedForm(cachedVal)
  const { imageSrcUrl } = useImageSrcFromBlob(values.image.thumbnail || thumbnail)
  const handleClose2 = useCallback(() => {
    setAll({ image: value })
    handleClose()
  }, [setAll, value, handleClose])
  const handleSave = useCallback(async (event: MouseEvent<HTMLButtonElement>) => {
    // todo handle export, generate thumbnail...
    if (typeof onSave === 'function') await onSave(values)
    handleClose()
    // console.log('how to save')
  }, [handleClose, values, onSave])
  const handleChange2 = useCallback((name: any) => (action: any) => {
    // todo handle export, generate thumbnail...
    handleChange(name)(action)
  }, [handleChange])
  const handleDownload = useCallback(() => {
    // FileSaver.saveAs()
    // values.image
  }, [value])
  const enableDelete = typeof onDelete === 'function'
  const handleDelete = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
    onDelete!(value)
  }, [onDelete, value])
  return (
    <ImageListItem>
      <img alt="preview" src={imageSrcUrl} />
      <ImageListItemBar
        title={
          enableDelete && (
            <IconButton onClick={handleDelete}>
              <TrashCan color="secondary" />
            </IconButton>
          )
        }
        actionIcon={(
          <>
            {/* <IconButton onClick={handleDownload}> */}
            {/*  <Download color="secondary" /> */}
            {/* </IconButton> */}
            <IconButton onClick={toggle}>
              <Fullscreen color="secondary" />
            </IconButton>
          </>
        )}
      />
      <FullScreenDialog
        open={open}
        title={t('editor')}
        onClose={handleClose2}
        onSave={values.image.thumbnail instanceof Blob ? handleSave : undefined}
      >
        <ImageViewer
          onChange={handleChange2('image')}
          value={values.image}
          file={values.image?.file}
          image={values.image?.image}
        />
      </FullScreenDialog>
    </ImageListItem>
  )
}

function ImageUpload({ onNew }: { onNew: (file: File | Blob) => Promise<void> | void }) {
  const { t: dropzoneT } = useTranslation('dropzone')
  const [valid, setValid] = useState(false)
  const handleDrop = useCallback((innerval: any) => {
    // setImage(innerval)
    // onChange({ path: ['file'], value: innerval })
    onNew(innerval)
  }, [onNew])
  return (
    <Dropzone
      t={dropzoneT}
      onChange={handleDrop}
      name="upload"
      // id={id}
      accept={{
        'application/pdf': ['.pdf'],
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
      }}
      valid={valid}
      setValid={setValid}
      maxSize={10 * 1024 * 1024}
      value={null}
      loading={false}
    />
  )
}

interface IThumbnailListProps {
  data: IRemoteAttachment[]
  onSave?: (values: any) => Promise<void> | void
  onDelete?: (values: any) => Promise<void> | void
  onNew?: (file: File | Blob) => Promise<void> | void

}

export function ThumbnailList({ data, onNew, ...rest }: IThumbnailListProps) {
  return (
    <>
      <ImageList cols={4} rowHeight={200} variant="masonry">
        {
          data?.map((x) => (
            <ThumbnailView key={`thumbnail${x.id}`} value={x} {...rest} />
          ))
        }
      </ImageList>
      {onNew && <ImageUpload onNew={onNew} /> }
    </>
  )
}
