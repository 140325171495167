import { IRequiredProp } from 'framework/components/DataLoader/types/IRequiredProp'
import PermissionModel from 'models/PermissionModel'

import { PermissionOperation } from 'framework/auth/permissionOperation'

export const lazySiteMeasuring: IRequiredProp = {
  prop: 'siteMeasurings',
  fetch: 'always',
  fetchPolicy: 'lazy',
  requirePermissions: {
    operation: PermissionOperation.OR,
    permissions: [PermissionModel.VIEW_MY_SITE_MEASURING, PermissionModel.VIEW_ALL_SITE_MEASURING],
  },
}
export const lazySiteMeasuringInspection: IRequiredProp = {
  prop: 'siteMeasuringInspections',
  fetch: 'always',
  fetchPolicy: 'lazy',
  requirePermissions: {
    operation: PermissionOperation.AND,
    permissions: [PermissionModel.VIEW_ALL_SITE_MEASURING],
  },
}
const loadLazyAlways: IRequiredProp[] = [
  { prop: 'scaffoldInquiries', fetch: 'always', fetchPolicy: 'lazy' },
  lazySiteMeasuring,
  lazySiteMeasuringInspection,
]
export default loadLazyAlways
