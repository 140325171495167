/* eslint-disable */
import RestModel from "api/siteMeasuringsInspections";
import LoadStateModel from "../framework/LoadStateModel";
import {IBaseRestState} from "features/base/BaseRestFeature";
import {ISiteMeasuringInspection} from "api/models/siteMeasuringInspections";
import {RootState} from "app/store";
import {getUser} from "features/user";
import PermissionModel from "models/PermissionModel";
import {userHasAllPermissions} from "framework/auth/permissions";
import createBaseRestSlice from "features/base/creators/CreateBaseRestSlice";


type DataType = ISiteMeasuringInspection
const key = 'siteMeasuringInspections'

const initialState: IBaseRestState<DataType> = {
  data: [],
  loadState: LoadStateModel.INITIAL,
  archive: {}
}
export const {actions,index,hooks} = createBaseRestSlice(
  key,
  initialState,
  {},
  RestModel
)
const { get, update, deleteItem } = actions

export const unreadCountSelectorSiteMeasuringInspection = (state: RootState) => {
  const currentUser = getUser(state)
  if (userHasAllPermissions(currentUser, [PermissionModel.GDK_REVIEW_SITE_MEASURING])) {
    // is gdk
    // return (state.siteMeasuringInspections.data as ISiteMeasuringInspection[]).filter((x)=>x.)
    return state.siteMeasuringInspections.data.length
  }

  return  0//state.siteMeasurings.data.filter((x) => x.state !== SiteMeasuringState.FULLY_SIGNED && agentPhases.includes(x.agentPhase)).length
}
export const useSiteMeasuringInspectionById = hooks.useById
export const useSiteMeasuringInspections = hooks.useAll

export default index.reducer
