import {
  // eslint-disable-next-line camelcase
  MRT_ToggleFullScreenButton,
  // eslint-disable-next-line camelcase
  MRT_ShowHideColumnsButton,
  // eslint-disable-next-line camelcase
  MRT_TableInstance,
  // eslint-disable-next-line camelcase
  MRT_ToggleDensePaddingButton,
  // eslint-disable-next-line camelcase
  MRT_ToggleFiltersButton,
  // eslint-disable-next-line camelcase
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table'
import Box from '@mui/material/Box'
import React from 'react'
import ExcelExportAction from 'framework/components/Table/ExcelExportAction'
import ResetTableAction from 'framework/components/Table/ResetTableAction'

// eslint-disable-next-line camelcase
export default function InternalToolbar<TData extends Record<string, any> = {}>({ table, name }: {name?: string, table: MRT_TableInstance<TData> }) {
  const {
    options: {
      enableColumnFilters,
      enableColumnOrdering,
      enableDensityToggle,
      enableFilters,
      enableFullScreenToggle,
      enableGlobalFilter,
      enableHiding,
      enablePinning,
      initialState,
      // renderToolbarInternalActions,
    },
  } = table

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        zIndex: 3,
      }}
    >
      {/* <ResetTableAction table={table} /> */}
      <ExcelExportAction table={table} name={name} />
      {enableFilters
        && enableGlobalFilter
        && !initialState?.showGlobalFilter && (
          // eslint-disable-next-line
          <MRT_ToggleGlobalFilterButton table={table}/>
        )}
      {enableFilters && enableColumnFilters && (
        // eslint-disable-next-line
        <MRT_ToggleFiltersButton table={table}/>
      )}
      {(enableHiding || enableColumnOrdering || enablePinning) && (
        // eslint-disable-next-line
        <MRT_ShowHideColumnsButton table={table}/>
      )}
      {enableDensityToggle && (
        // eslint-disable-next-line
        <MRT_ToggleDensePaddingButton table={table}/>
      )}
      {enableFullScreenToggle && (
        // eslint-disable-next-line
        <MRT_ToggleFullScreenButton table={table}/>
      )}
    </Box>
  )
}
