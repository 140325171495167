import { Backdrop } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useCallback, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import { styled } from '@mui/system'
import { useAppDispatch } from 'app/hooks'
import { requestResetPassword, resetPassword } from 'features/user'
import { useLocation, useParams } from 'react-router'
import { useManagedForm, ValidatedForm } from '@codeflix/mui-managed-form'
import LoginFormsWrapper from './LoginFormsWrapper'

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}))

export default function ResetPasswordForm() {
  const { t } = useTranslation('login')
  const params = useParams()
  const { search } = useLocation()
  const token = useMemo(() => new URLSearchParams(search).get('token'), [search])
  const navigate = useNavigate()
  const appDispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const { values, formManagerProps } = useManagedForm({
    password: '', comparePassword: '',
  })
  const onSubmit = useCallback(async () => {
    if (!token) return
    setLoading(true)
    const success = appDispatch(resetPassword(params.email!, values.password, token))
    setLoading(false)
    if (success) {
      navigate('/')
    }
  }, [appDispatch, navigate, params, token, values])

  return (
    <LoginFormsWrapper backdropOpen={loading}>

      <ValidatedForm
        {...formManagerProps}
        // editMode
        // setValid={setValid}
        elements={[
              {
                type: 'password',
                name: 'password',
                details: true,
                required: true,
              },
              {
                type: 'password',
                name: 'comparePassword',
                required: true,
                details: true,
                conditionalProps: (element:any, value:any) => ({
                  comparePassword: value.password,
                }),
              },
              {
                type: 'submit',
                name: 'reset',
                variant: 'contained',
              },
            ]}
        onSubmit={onSubmit}
        // onChange={handleChange}
        t={t}
      />
    </LoginFormsWrapper>
  )
}
