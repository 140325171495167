import { useCallback, useState } from 'react'

export interface ISingleOpenedState {
  open: boolean,
  handleOpen: ()=>void,
  handleClose: ()=>void,
  toggle: ()=>void
}

export const useSingleOpenState = () => {
  const [open, setOpen] = useState<string|undefined>()

  const openByKey:(key:string)=>ISingleOpenedState = useCallback((key:string) => ({
    open: open === key,
    handleOpen: () => setOpen(key),
    handleClose: () => setOpen(undefined),
    toggle: () => setOpen(open === key ? undefined : key),
  }), [open, setOpen])
  return [openByKey, open, setOpen]
}
