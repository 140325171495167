import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { actions, useCustomer } from 'features/customers'
import { useSiteMeasuringTemplateOptions } from 'features/siteMeasuringTemplates'
import { ApproverStep, CustomerModel, CustomerSpecificField } from 'api/models/customerModel'
import { useParams } from 'react-router'
import EditView from 'framework/components/BaseRestViews/EditView'
import { useKeyToAutocompleteOptionsArray } from '@codeflix/mui-managed-form'
import { TFunction } from 'i18next'
import { Button, Stack, Typography } from '@mui/material'
import PopupActionButton from 'framework/components/PopupActions/PopupActionButton'
import { useOpenState } from 'lib/stateHelper'
import { ScaffoldState } from '../../../../api/models/scaffoldModel'

const grid = { xs: 12, sm: 6, md: 3 }
const smallGrid = { xs: 6, sm: 3, md: 2 }
const validFieldNames = (t: TFunction) => (value: any) => {
  const isvalid = /^([a-zA-Z0-9ÄäÖöÜü]+)$/igm.test(value)
  return isvalid ? undefined : 'invalidChar'
}

const ScaffoldStates = Object.keys(ScaffoldState)
function WorkflowHeader() {
  const openState = useOpenState(false)
  const { t } = useTranslation('scaffoldWorkflow')
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
      <Typography variant="body1" >
        {t('scaffoldWorkflow')}
      </Typography>
      <PopupActionButton t={t} label={t('info')} openState={openState} >
        <Stack spacing={2}>
          <Typography variant="subtitle1">{t('description')}</Typography>
          <div>
            <Typography textAlign="center" variant="body1">{t(ScaffoldState.DRAFT)}</Typography>
            <Typography variant="body2">{t('draftDescription')}</Typography>
          </div>
          <div>
            <Typography textAlign="center" variant="body1">{t(ScaffoldState.CLIENT_REVIEW)}</Typography>
            <Typography variant="body2">{t('clientReviewDescription')}</Typography>
          </div>
          <div>
            <Typography textAlign="center" variant="body1">{t(ScaffoldState.CLIENT_TRANSMITTED)}</Typography>
            <Typography variant="body2">{t('clientTransmitted')}</Typography>
          </div>
          <Button onClick={openState.handleClose} fullWidth variant="contained">{t('generic:close')}</Button>

        </Stack>
      </PopupActionButton>
    </Stack>
  )
}
function Editor({ item }: { item?: CustomerModel }) {
  const { t } = useTranslation('customers')
  const stateOptions = useKeyToAutocompleteOptionsArray(t, ['NEVER', 'ALWAYS', ScaffoldState.DRAFT, ScaffoldState.CLIENT_REVIEW, ScaffoldState.CLIENT_TRANSMITTED, ScaffoldState.GDK_DRAFT, ScaffoldState.GDK_TRANSMITTED])
  const workflowOptions = useKeyToAutocompleteOptionsArray(t, [ScaffoldState.DRAFT, ScaffoldState.CLIENT_REVIEW, ScaffoldState.CLIENT_TRANSMITTED], true)
  const isValidTranslated = useMemo(() => validFieldNames(t), [t])
  const siteMeasuringTemplateOptions = useSiteMeasuringTemplateOptions()
  const elements = useMemo(() => ([
    {
      type: 'text',
      name: 'name',
      required: true,
    },
    {
      type: 'arraylist',
      subType: 'domain',
      variant: 'borderbox',
      grid: { xs: 12 },
      name: 'emailDomains',
      BaseItem: String,
      options: [],
      required: true,
    },
    {
      type: 'list',
      variant: 'borderbox',
      name: 'individualScaffoldOrderFields',
      BaseItem: CustomerSpecificField,
      maxItems: 5,
      elements: [
        {
          type: 'text',
          name: 'name',
          required: true,
          grid: { xs: 12, sm: 5 },
        },
        {
          type: 'autocomplete',
          name: 'type',
          required: true,
          grid: { xs: 12, sm: 4 },
          options: [{ value: 'TEXT', label: t('text') }, { value: 'INT', label: t('number') }],
        },
        {
          grid: { xs: 12, sm: 3 },
          type: 'autocomplete',
          required: true,
          name: 'required',
          options: stateOptions,
        },
      ],
    },
    {
      type: 'list',
      variant: 'borderbox',
      name: 'scaffoldWorkflow',
      BaseItem: ApproverStep,
      variantProps: {
        header: (<WorkflowHeader />),
      },
      container: { spacing: 2 },
      sorted: 'sortPosition',
      elements: [
        {
          type: 'text',
          grid: { xs: 12, sm: 5, md: 5 },
          required: true,
          name: 'name',
        },
        {
          type: 'autocomplete',
          grid: { xs: 12, sm: 4, md: 5 },
          name: 'statusOnCompletion',
          conditionalProps: (element:any, value:any, isLast:boolean, index: number) => {
            const previous = index > 0 ? value[index - 1] : undefined
            if (!previous) return {}
            const previousOptionValue = workflowOptions.findIndex((x:any) => x.value === previous.statusOnCompletion)

            return {
              options: workflowOptions.slice(previousOptionValue),
            }
          },
          required: true,

          options: workflowOptions,
        },
      ],
    },
    {
      type: 'autocomplete',
      name: 'siteMeasuringTemplateId',
      required: true,
      options: siteMeasuringTemplateOptions,
    },
  ]), [t, stateOptions, workflowOptions, siteMeasuringTemplateOptions])
  const linkStack = useMemo(() => ([
    { to: '/administration/customers', text: t('application:customers') },
    { text: item?.isNew ? t('application:new') : `${item?.name}` },
  ]), [t, item])
  return (
    <EditView
      loading={!item}
      linkStack={linkStack}
      elements={elements}
      actions={actions}
      item={item}
      t={t}
      debug
      saveView={{
        hideDraft: true,
      }}
    />
  )
}

export function Edit() {
  const { customerId } = useParams() as { customerId: string }
  const item = useCustomer(customerId)
  return (
    <Editor item={item} />
  )
}

export function New() {
  const item = new CustomerModel()
  return (
    <Editor item={item} />
  )
}
