import { MicrosoftExcel } from 'mdi-material-ui'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import { MouseEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line camelcase
import { MRT_Row, MRT_TableInstance } from 'material-react-table'
import { Row } from '@tanstack/react-table'
import FileSaver from 'file-saver'
import { IExcelExportModel } from 'api/models/excelExportModel'
import ExcelExport from 'api/excelExport'
import { closeNotification, createPersistentNotificationTranslated } from 'features/notifications'
import { useAppDispatch } from 'app/hooks'

// eslint-disable-next-line camelcase

// eslint-disable-next-line camelcase
const getRowValue = <TData extends Record<string, any>>(row: Row<TData>, column: any) => {
  const value = row.getValue(column.id)
  if (typeof column.columnDef.exportAccessorFn === 'function') return column.columnDef.exportAccessorFn(value)
  return value
}
// eslint-disable-next-line camelcase
export default function ExcelExportAction<TData extends Record<string, any> = {}>({ table, name }: {name?: string, table: MRT_TableInstance<TData>}) {
  const { t } = useTranslation('table')
  const appDispatch = useAppDispatch()
  const handleClick = useCallback(async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const exportName = `${name || t('export')}.xlsx`
    const notificationKey = await appDispatch(createPersistentNotificationTranslated('generic:preparingDownload', { name: exportName }, {
      action: <CircularProgress />,
    }))

    try {
      const displayedColumns = table.getVisibleFlatColumns().filter((x) => !x.id.startsWith('mrt-'))
      const columns = displayedColumns.map((x) => x.columnDef.header) as string[]
      // map table rows with columns to export model
      const rowDataMapping = table.getFilteredRowModel().rows.map((row) => displayedColumns.map((column) => ({
        value: getRowValue(row, column),
      })))
      const request:IExcelExportModel = {
        headlines: columns,
        data: rowDataMapping,
        fileName: 'Export.xlsx',
      }
      const blob = await ExcelExport.getExport(request)
      FileSaver.saveAs(blob, exportName, { autoBom: true })
    } finally {
      appDispatch(closeNotification(notificationKey))
    }
  }, [appDispatch, name, t, table])

  return (
    <Tooltip title={t('excelExport')}>
      <IconButton onClick={handleClick}>
        <MicrosoftExcel />
      </IconButton>
    </Tooltip>
  )
}
