import PermissionModel from 'models/PermissionModel'
import { CustomRoute } from 'models/IRoute'
import BaseRestRoutes from 'framework/components/BaseRestViews/BaseRestRoutes'
import loadLazyAlways from 'ui/views/lazyFetchAllViews'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import Overview from './Overview'
import { Edit, New } from './Edit'
import OperationUnitRoutes from './OperationUnits/routes'
import BuildingRoutes from './Buildings/routes'
import ProjectRoutes from './Projects/routes'

const routes: CustomRoute[] = BaseRestRoutes(
  'customers',
  'customerId',
  (<Overview />),
  (<Edit />),
  (<New />),

  { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_ALL_CUSTOMERS] },
  [
    ...ProjectRoutes,
    ...OperationUnitRoutes,
    ...BuildingRoutes,
  ],
  [
    { prop: 'customers' },
    { prop: 'siteMeasuringTemplates' },
    ...loadLazyAlways,
  ],
)
export default routes
