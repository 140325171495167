// import createMuiTheme from '@mui/material/styles/createMuiTheme'
import { createTheme } from '@mui/material'
import { Theme } from '@mui/system'

export const navDrawer = '#486eab'
// export const primary = '#c5cdce'
export const primary = '#4a6b99'
// export const primary = '#41b3a3'
export const secondary = '#a40e4c'

export const green = '#00a912'
export const greenDarker = '#008512'
export const red = '#a01333ff'

export const yncoris = {
  petrol: '#006b8d',
  lightblue: '#aad3e9',
  red: '#b70e0c',
  darkred: '#710e12',
  black: '#000',
  lightWebBlack: '#1a171b',
  lightgrey: '#ddddd3',
  darkgrey: '#969696',
  white: '#ffffff',
  orange: '#ef7d00',
  green: '#99cc00',
}
const drawerWidth = 250
const theme = createTheme({
  palette: {
    primary: {
      main: yncoris.petrol,
      contrastText: '#ffffff',
    },

    success: {
      main: green,
      contrastText: '#000000',
    },
    secondary: {
      main: yncoris.lightblue,
    },
    error: {
      main: yncoris.red,
    },
  },
  typography: {
    h1: {
      fontSize: 40,
      fontWeight: 600,
      // color: 'white',
      // marginTop: '1rem',
      // marginBottom: '1rem',
    },
    h6: {
      // fontSize: 24,
      // fontWeight: 600,
      // color: 'white',
      // margin: '1rem 0 .5rem 0',
    },
    body1: {
      fontSize: 18,
      fontWeight: 100,
    },
    caption: {
      whiteSpace: 'nowrap',
    },
  },
})

const responsiveTheme: Theme = {
  ...theme,
  components: {
    MuiTextField: {
      defaultProps: {
        // variant: 'standard',
      },
      styleOverrides: {
        borderColor: 'black',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {

        notchedOutline: {
          borderColor: 'black',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
        size: 'large',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          // minHeight: 50,
          // color: '#000',
        },
        containedSizeLarge: {
          fontSize: 18,
        },
      },
    },
    MuiTypography: {
      h1: {
        styleOverrides: {
          [theme.breakpoints.down('md')]: {
            fontSize: 26,
            padding: `0 ${theme.spacing(2)}`,
          },
        },
      },
      h2: {
        styleOverrides: {
          [theme.breakpoints.down('md')]: {
            fontSize: 22,
            padding: `0 ${theme.spacing(2)}`,
          },
        },
      },
      h3: {
        styleOverrides: {
          [theme.breakpoints.down('md')]: {
            fontSize: 18,
            padding: `0 ${theme.spacing(2)}`,
          },
        },
      },
      h5: {
        styleOverrides: {
          [theme.breakpoints.down('lg')]: {
            fontSize: 18,
            margin: 0,
            padding: 0,
          },
        },
      },
      h6: {
        styleOverrides: {
          [theme.breakpoints.down('md')]: {
            fontSize: 16,
            margin: 0,
            padding: 0,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiInputLabel: {
      // styleOverrides: {
      shrink: {
        color: 'black',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '10pt',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'visible', // Needed for autocomplete dropdown, overflow is otherwise hidden in background and options are not visible
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.5),
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
        },
        content: {},
        action: {
          flex: '0 1 auto',
          alignSelf: 'flex-end',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          overflowY: 'visible',
        },
        paper: {
          overflowY: 'visible',
        },
        paperFullScreen: {
          overflowY: 'auto',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: 'visible',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          zIndex: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: '#e0e0e0',
          minWidth: 200,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: drawerWidth,
        },
        paper: {
          width: drawerWidth,
        },
      },
    },
  },
}

export default responsiveTheme
