import { INamedBaseModel, NamedCustomerBaseModel } from 'api/baseModel'
import { ImageLoad } from 'framework/components/ManagedForm/Fields/ImageEditor/libs/UseImageSrcFromBlob'
import { IPin } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IPin'
import { IText } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IText'

export interface IScaffoldInquiryAttachment extends INamedBaseModel {
  // id: string
  // customerId: string
  customerId?: string

  scaffoldInquiryId?: string

  fileId?: string
  imageId?: string
  thumbnailId?: string

  pin?: IPin
  text?: IText

  file?: ImageLoad
  image?: ImageLoad

  thumbnail?: ImageLoad
}
export interface IScaffoldInquiryRequestAttachment {
  id?:string
  isNew?: boolean
  scaffoldInquiryId?: string
  customerId?: string
  file?: ImageLoad
  fileId?: string
  image?:ImageLoad
  imageId?: string

  pin?: IPin
  text?: IText
  // isNew?: true
}

export class ScaffoldInquiryAttachment extends NamedCustomerBaseModel implements IScaffoldInquiryAttachment {
  file?: ImageLoad

  fileId?: string

  image?: ImageLoad

  imageId?: string

  pin?: IPin

  scaffoldInquiryId?: string

  text?: IText

  constructor(customerId?: string, scaffoldInquiryId?: string) {
    super(customerId)
    this.scaffoldInquiryId = scaffoldInquiryId
  }
}
