import { ScaffoldDraftModel } from 'api/models/scaffoldDraftModel'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { IFormManagerProps } from '@codeflix/mui-spreadsheet'
import { CustomerModel } from 'api/models/customerModel'
import RestModel from 'api/scaffoldInquiriesDraft'
import { ScaffoldAgentPhase, ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { useAsyncEffect } from 'lib/useAsyncEffect'
import debounce from 'debounce'

const clearPayload = (payload: ScaffoldDraftModel) => ({
  ...payload,
  rentFreeInDays: payload.agentPhase === 'CLIENT' ? undefined : payload.rentFreeInDays,
  clientWorkflowSteps: undefined,
  orderedByUser: undefined,
  copiedFromScaffoldInquiryId: undefined,
  scaffoldReferenceSerialNumber: undefined,
  csOrderId: payload.agentPhase === ScaffoldAgentPhase.GDK || payload.agentPhase === ScaffoldAgentPhase.SCAFFOLDER ? payload.csOrderId : undefined,
})
const update = async (state: IFormManagerProps<ScaffoldDraftModel>, customer: CustomerModel, synchronization:{[key:string]: string}, setSynchronization: Dispatch<SetStateAction<{[key:string]: string}>>) => {
  // console.log('synchronization', new Date().toISOString())

  for (let index = 0; index < state.value?.length; index += 1) {
    const value = state.value[index]
    if (value.lastModified !== synchronization[value?.id]) {
      if (value.isNew || !value.id) {
        // const fields = customer?.individualScaffoldOrderFields.reduce((acc, cur) => ({ ...acc, [cur.id]: '' }), {})
        const workflowFields = customer?.scaffoldWorkflow.reduce((acc: any, cur: any) => ({
          ...acc,
          [cur.id]: {
            workflowStepId: cur.id,
            userId: value.clientWorkflowStatus?.[cur?.id]?.userId,
            approved: false,
            approvedOn: undefined,
          },
        }), {})
        // eslint-disable-next-line no-await-in-loop
        const result = await RestModel.createItem({
          ...state.defaultRow(),
          ...clearPayload(value),
          // @ts-ignore
          id: undefined,
          customerId: customer.id,
          customerIndividualFields: customer?.individualScaffoldOrderFields,
          clientWorkflowStatus: workflowFields,
        }) as ScaffoldDraftModel

        state.updatePath([
          {
            path: [index, 'isNew'],
            value: false,
          },
          {
            path: [index, 'id'],
            value: result.id,
          },
        ])
        setSynchronization((prev) => ({
          ...prev,
          [result.id]: value.lastModified,
        }))
      } else {
        // eslint-disable-next-line no-await-in-loop
        const workflowFields = customer?.scaffoldWorkflow.reduce((acc: any, cur: any) => ({
          ...acc,
          [cur.id]: {
            workflowStepId: cur.id,
            userId: value.clientWorkflowStatus?.[cur?.id]?.userId,
            approved: false,
            approvedOn: undefined,
          },
        }), {})
        // eslint-disable-next-line no-await-in-loop
        const result = await RestModel.updateItem({
          ...state.defaultRow(),
          ...clearPayload(value),
          customerId: customer.id,
          customerIndividualFields: customer?.individualScaffoldOrderFields,
          clientWorkflowStatus: workflowFields,
        }) as ScaffoldDraftModel
        setSynchronization((prev) => ({
          ...prev,
          [value.id]: value.lastModified,
        }))
      }
    }
  }
}
const updateDebounced = debounce(update)
const useDebounceAsync = (callback: ()=>Promise<void>) => {
  const [timerId, setTimerId] = useState<undefined|number>(undefined)
  const [running, setRunning] = useState<boolean>(false)
  const fct = useCallback(() => {
    if (timerId) clearTimeout(timerId)
    setTimeout(async () => {
      if (running) return
      setRunning(true)
      await callback()
      setRunning(false)
    }, 200)
  }, [callback, running, timerId])
  return fct
}

export const useSynchronization = (state: IFormManagerProps<ScaffoldDraftModel>, customer:CustomerModel) => {
  const [synchronization, setSynchronization] = useState<{[key:string]: string}>(() => state.value?.reduce((acc, cur) => ({
    ...acc, [cur.id]: cur.lastModified,
  }), {}))

  const fct = useDebounceAsync(() => update(state, customer, synchronization, setSynchronization))
  useEffect(() => {
    fct()
    // updateDebounced(state, customer, synchronization, setSynchronization)
  }, [state.value])
  // useEffect(() => {
  //   const interval = setInterval(fct, 10000)
  //   return () => clearInterval(interval)
  // }, [])
  return synchronization
}
