import { IPermissionRequirement } from 'framework/auth/IPermissionRequirement'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import PermissionModel from 'models/PermissionModel'

export const isOnlyScaffolderPermissionRequirement: IPermissionRequirement = {
  operation: PermissionOperation.AND,
  permissions: [
    PermissionModel.CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST,
    {
      operation: PermissionOperation.NOT,
      permissions: [PermissionModel.VIEW_ALL_CUSTOMERS],
    },
  ],
}
