import { Group, Image, Label, Layer, Rect, Stage, Tag, Text } from 'react-konva'
import * as React from 'react'
import { Ref, useCallback, useState } from 'react'
import useImage from 'use-image'
import { styled } from '@mui/material'
import MapPin from 'framework/components/ManagedForm/Fields/ImageEditor/miscellaneous/MapPin.svg'
import { IImageInputValue } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IImageInputValue'

export interface IImageInputPropsBase {
  onChange: (value: any) => Promise<void> | void
  value: IImageInputValue
  size?: { width: number, height: number },
  disabled?: boolean,

  scaleX: number,
  scaleY: number,
  imageSrcUrl: string
  imageBitmap: ImageBitmap
}

const StyledStage = styled(Stage)(({ theme }) => ({
  // border: '1px solid black',
}))

 const KonvaPinEditor = React.forwardRef(({
                                              value,
                                              scaleX,
                                              scaleY,
                                              disabled,
                                              size,
                                              onChange,
                                              imageSrcUrl,
                                              imageBitmap,
                                            }: IImageInputPropsBase, ref: Ref<any>) => {
   const [dragging, setDragging] = useState<undefined|'pin'|'text'>()
  const handleDragStart = useCallback((dragType: 'location' | 'text') => (e: any) => {
    switch (dragType) {
      case 'location':
        setDragging('pin')
        /* onChange({
          path: ['pin'],
          value: {
            ...value.pin,
            isDragging: true,
          },
        }) */
        break
      case 'text':
        setDragging('text')
        /* onChange({
          path: ['text'],
          value: {
            ...value.text,
            isDragging: true,
          },
        }) */
        break
      default:
        break
    }
  }, [onChange, value])

  const handleDragEnd = useCallback((dragType: 'location' | 'text') => (e: any, ...params: any[]) => {
    const newX = e.target.x()
    const newY = e.target.y()
    setDragging(undefined)
    // const dragType = e.target.getData('dragType')
    if (dragType === 'location') {
      onChange({
        path: ['pin'],
        value: {
          x: newX,
          y: newY,
          // isDragging: false,
        },
      })
    } else {
      onChange({
        path: ['text'],
        value: {
          ...value.text,
          x: newX,
          y: newY,
          // isDragging: false,
        },
      })
    }
    // const stageRef = (ref as React.MutableRefObject<any>)
    // stageRef.current.setPointersPositions(e)
    // const position = stageRef.current.getRelativePointerPosition()
  }, [ref, onChange, value.text])
  // const handleClick = useCallback((e: any) => {
  //   const stage = e.target.getStage()
  //   if (typeof stage === 'undefined') {
  //     return
  //   }
  //   if (disabled) return
  //   const pos = stage!.getRelativePointerPosition()
  //   console.error('click', pos)
  //   onChange({
  //     path: ['pin'],
  //     value: {
  //       x: pos.x,
  //       y: pos.y,
  //       isDragging: false,
  //     },
  //   })
  // }, [disabled, onChange])
  const handleTextClick = useCallback((e: any) => {
    onChange({ path: ['text'], value: undefined })
  }, [onChange])
  const handlePinClick = useCallback(() => {
    onChange({ path: ['pin'], value: undefined })
  }, [onChange])
  const [imageSvg] = useImage(MapPin)

  return (
    // @ts-ignore
    <StyledStage ref={ref} width={size.width} height={size.height} scaleX={scaleX} scaleY={scaleY}>
      <Layer>
        <Rect fill="#ffffff" width={size!.width} height={size!.height} x={0} y={0} />
        <Image
          image={imageBitmap}
          // scaleX={scaleX}
          // scaleY={scaleY}
          // onClick={handleClick}
        />

        {value?.pin && (
          <Image
            image={imageSvg}
            x={value?.pin.x}
            y={value?.pin.y}
            offsetX={imageSvg ? (imageSvg.width / 2) : 0}
            offsetY={imageSvg ? (imageSvg.height / 2) : 0}
            shadowColor="black"
            shadowBlur={10}
            shadowOpacity={0.6}
            shadowOffsetX={dragging === 'pin' ? 10 : 5}
            shadowOffsetY={dragging === 'pin' ? 10 : 5}
            scaleX={(dragging === 'pin' ? 1.2 : 1)}
            scaleY={(dragging === 'pin' ? 1.2 : 1)}
            onDragStart={handleDragStart('location')}
            onDragEnd={handleDragEnd('location')}
            onClick={handlePinClick}
            draggable={!disabled}
          />
        )}
        {value?.text && (
          <Group>
            <Label
              x={value.text.x}
              y={value.text.y}
              onClick={handleTextClick}
              draggable={!disabled}
              onDragStart={handleDragStart('text')}
              onDragEnd={handleDragEnd('text')}
              // offsetX={imageSvg ? imageSvg.width / 2 : 0}
              // offsetY={imageSvg ? imageSvg.height / 2 : 0}
              color="#ffffff"
              scaleX={(dragging === 'text' ? 1.2 : 1)}
              scaleY={(dragging === 'text' ? 1.2 : 1)}
            >
              <Tag
                fill="#ffffff"
                cornerRadius={10}
                // stroke="#3c3c3c"
                shadowColor="black"
                shadowBlur={10}
                shadowOpacity={0.6}
                lineJoin="round"
              />
              <Text
                padding={10}
                text={value?.text.value}
                fontSize={value?.text.fontSize}
                // shadowColor="black"
                // shadowBlur={10}
                // shadowOpacity={0.6}
                // shadowOffsetX={value.text.isDragging ? 10 : 5}
                // shadowOffsetY={value.text.isDragging ? 10 : 5}

              />
            </Label>
          </Group>

        )}
      </Layer>
    </StyledStage>
  )
})
export default KonvaPinEditor
