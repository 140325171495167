import { DateTime } from 'luxon'
import { ISerialized } from 'lib/serializer/ISerialized'

function serializeValue(value: any):ISerialized {
  const valueType = typeof value
  switch (valueType) {
    case 'boolean':
    case 'string':
    case 'number':
      return {
        type: valueType,
        value,
      }
    case 'object':
      if (value === null) {
        return {
          type: 'null',
          value: null,
        }
      }
      if (value instanceof Date) {
        return {
          type: 'date',
          subType: 'jsDate',
          value: value.toISOString(),
        }
      }
      if (DateTime.isDateTime(value)) {
        return {
          type: 'date',
          subType: 'luxon',
          value: value.toISO({ includeOffset: true, extendedZone: true }),
        }
      }
      if (Array.isArray(value)) {
        return {
          type: 'array',
          value: value.map((x) => serializeValue(x)),
        }
      }
      return {
        type: 'object',
        value: Object.keys(value).map((x) => ({
          key: x,
          ...serializeValue(value[x]),
        })),
      }
    case 'undefined':
      return {
        type: 'undefined',
        value: undefined,
      }
    default:
      return {
      type: 'unknown',
      value,
    }
  }
}

export default function serializeObject(value:any) {
  const preSerialized = serializeValue(value)
  return JSON.stringify(preSerialized)
}
