/* eslint-disable */
import RestModel from "api/siteMeasuringTemplates";
import LoadStateModel from "../framework/LoadStateModel";
import {IBaseRestState} from "features/base/BaseRestFeature";
import {SiteMeasuringTemplateModel} from "api/models/siteMeasuringTemplateModel";

import {createNamedBaseRestSlice} from "features/base/creators/CreateNamedBaseRestSlice";


type DataType = SiteMeasuringTemplateModel
const key = 'siteMeasuringTemplates'

const initialState: IBaseRestState<DataType> = {
  data: [],
  loadState: LoadStateModel.INITIAL,
  archive:{}
}
export const {actions,index,hooks} = createNamedBaseRestSlice(
  key,
  initialState,
  {},
  RestModel
)
const { get, update, deleteItem } = actions


export const updateSiteMeasuringTemplate = update
export const deleteSiteMeasuringTemplate = deleteItem
export const fetchData = get

export const useSiteMeasuringTemplates = hooks.useAll
export const useSiteMeasuringTemplate = hooks.useById
export const useSiteMeasuringTemplateByPath = () => hooks.useByPathKey('siteMeasuringTemplateId')


export const useSiteMeasuringTemplateOptions = hooks.useNamedOptions

export default index.reducer
