import { SiteMeasuringModel, SiteMeasuringState } from 'api/models/siteMeasuringModel'
import { useDefaultButtonEventHandlerDispatchParameterAction } from 'framework/lib/useDispatchAction'
import { setSiteMeasuringCancelled } from 'features/siteMeasurings'
import { useTranslation } from 'react-i18next'
import { useUserHasAllPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'
import AsyncConfirmation from 'framework/components/BaseRestViews/AsyncConfirmation'
import { Delete } from '@mui/icons-material'
import AsyncActionButton from 'framework/components/AsyncActionButton'

export default function SiteMeasuringCancelAction({ item }: {item: SiteMeasuringModel|undefined}) {
  const handleAction = useDefaultButtonEventHandlerDispatchParameterAction(setSiteMeasuringCancelled)
  const { t } = useTranslation('siteMeasuring')
  const hasPermission = useUserHasAllPermissions([PermissionModel.CANCEL_SITE_MEASURING])
  /* const handleClick = useCallback(async (event:MouseEvent<HTMLButtonElement>) => {
    await (event)
  }, [item, handleCreateSiteMeasuringPdf]) */
  if (!item || !hasPermission || item?.state === SiteMeasuringState.CANCELLED) return (null)
  if (item.isCancelled) {
    return (
      <AsyncActionButton onClick={handleAction([item], !item.isCancelled)}>{t('undoCancel')}
      </AsyncActionButton>
    )
  }
  return (
    <AsyncConfirmation
      onConfirm={handleAction([item], !item.isCancelled)}
      buttonProps={{
        variant: 'contained',
        startIcon: <Delete />,
        color: 'error',
        fullWidth: true,
      }}
      label={!item.isCancelled ? t('cancel') : t('undoCancel')}
      confirmLabel={(<><Delete />{t('cancel')}</>)}
      description={t('confirmCancellation')}
    />
    // <AsyncConfirmation
    //   variant="contained"
    //   disabled={item.state === SiteMeasuringState.CANCELLED}
    //   onClick={handleAction([item])}
    // >
    //   {t('cancel')}
    // </AsyncConfirmation>
  )
}
