import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { useScaffoldInquiryById } from 'features/scaffoldInquiries'
import { actions, hooks } from 'features/scaffoldInquiriesAttachments'
import { useParams } from 'react-router'
import EditView from 'framework/components/BaseRestViews/EditView'
import { IScaffoldInquiryAttachment, ScaffoldInquiryAttachment } from 'api/models/scaffoldInquiryAttachment'
import { useCurrentUser } from 'features/user'
import { useManagedForm } from '@codeflix/mui-managed-form'
import { useImageFromRemote } from 'framework/components/ManagedForm/Fields/ImageEditor/libs/UseImageSrcFromBlob'
import RestModel from 'api/scaffoldInquiriesAttachments'

const emptyVoid = () => {}

const useFileForId = (id: string) => {
  const loadFile = useCallback(() => RestModel.getFileBlobById(id), [id])
  const file = useImageFromRemote(loadFile)
  return useMemo(() => file, [file])
}
const useImageForId = (id: string, imageId: string|undefined) => {
  const loadFile = useCallback(() => (imageId ? RestModel.getImageBlobById(id) : undefined), [id, imageId])
  const file = useImageFromRemote(loadFile)
  return useMemo(() => file, [file])
}
const useFileBlobs = (item: IScaffoldInquiryAttachment) => {
  const { id, fileId, imageId } = item

  const file = useFileForId(id)
  const image = useImageForId(id, imageId)
  return useMemo(() => ({ file, image }), [file, image])
}
const loadFile = (id: string) => () => RestModel.getFileBlobById(id)
const loadImage = (id: string, imageId: string) => (imageId ? RestModel.getImageBlobById(id) : undefined)
function Editor({ item }: {
  item?: IScaffoldInquiryAttachment,
  // file?: File|Blob
  // image?: File|Blob
}) {
  const { t } = useTranslation('scaffoldInquiryAttachments')
  const mappedItem = useMemo(() => ({
    // name: '',
      ...item,
      // name: '',
      isNew: Boolean(item?.isNew),
      image: {
        file: item?.fileId ? loadFile(item.id) : undefined,
        image: item?.imageId ? loadImage(item.id, item.imageId) : undefined,
        pin: item?.pin,
        text: item?.text,
    },
  }), [item])
  const formManager = useManagedForm(mappedItem, true)
  const handleSubmit = useCallback((values: any) => ({
      id: values.id,
    name: values.name,
      pin: values.image.pin,
      text: values.image.text,
      scaffoldInquiryId: values.scaffoldInquiryId,
      customerId: values.customerId,
      image: values.image.image,
      file: values.isNew && values.image.file,
      isNew: values.isNew,
    }as IScaffoldInquiryAttachment), [])
  const elements = useMemo(() => [
    {
      type: 'text',
      name: 'name',
    },
    {
      type: 'attachment',
      name: 'image',
    },
  ], [])
  return (
    <EditView
      loading={!item}
      elements={elements}
      actions={actions}
      formManager={formManager}
      item={mappedItem}
      debug
      t={t}
      saveView={{ hideDraft: true }}
      middlewares={{
        onDraft: emptyVoid,
        onSubmit: handleSubmit,
      }}
    />
  )
}

export function LoadedWrapper({ item }: { item: IScaffoldInquiryAttachment }) {
  const { file, image } = useFileBlobs(item)
  // if (!file) return (null)

  return (
    <Editor item={item} />
  )
}

export function Edit() {
  const {
    scaffoldInquiryAttachmentId,
    scaffoldInquiryId,
  } = useParams() as { scaffoldInquiryId: string, scaffoldInquiryAttachmentId: string }
  const item = hooks.useById(scaffoldInquiryId, scaffoldInquiryAttachmentId)
  // // fetch image and file
  // console.log('rendering edit', item)
  // if (!item || !item.id) {
  //   return <LoadingCircle />
  // }
  return (
    <Editor item={item} />
  )
}

export function New() {
  const user = useCurrentUser()
  const { scaffoldInquiryId } = useParams() as { scaffoldInquiryId: string }

  const scaffoldInquiry = useScaffoldInquiryById(scaffoldInquiryId)
  const item = new ScaffoldInquiryAttachment(scaffoldInquiry?.customerId, scaffoldInquiryId)
  return (
    <Editor item={item} />
  )
}
