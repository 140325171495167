import { SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDataEffect } from '@codeflix/mui-managed-form'
import serializeObject from 'lib/serializer/serializeObject'
import deserializeObject from 'lib/serializer/deserializeObject'

type StorageProvider = 'localStorage' | 'sessionStorage'

function useItem<T>(storageProvider: StorageProvider, key:string, initialValue:T) {
  return useCallback(() => {
    const storedItem = window[storageProvider].getItem(key)
    if (storedItem) {
      try {
        const parsedItem = deserializeObject<T>(storedItem)
        return parsedItem
      } catch (e) {
        console.error(storageProvider, 'deserializiation error', key, e)
      }
    }
    return initialValue
  }, [initialValue, key, storageProvider])
}

function useBrowserStorage<T>(
  key: string,
  initialValue: T,
  storageProvider: StorageProvider,
  blockUpdates: boolean = false,
) {
  const initial = useItem(storageProvider, key, initialValue)
  const state = useState<T>(initial)
  const [value, setValue] = state
  useDataEffect(() => {
    try {
      const parsedItem = serializeObject(value)
      window[storageProvider].setItem(key, parsedItem)
    } catch (e) {
      // cannot be parsed
      console.error(storageProvider, 'serializiation error', key, e)
    }
  }, [key, storageProvider, value])
  const handleUpdate = useCallback((action: SetStateAction<T>) => {
    if (blockUpdates) return
    setValue(action)
  }, [blockUpdates, setValue])
  return [value, handleUpdate]
}

function useDeferredBrowserStorage<T>(
  key: string,
  initialValue: T,
  storageProvider: StorageProvider,
  blockUpdates: boolean = false,
) {
  const state = useState<T>(initialValue)
  const [value, setValue] = state
  useEffect(() => {
    const storedItem = window[storageProvider].getItem(key)
    if (storedItem) {
      try {
        const parsedItem = deserializeObject<T>(storedItem)
        setValue(parsedItem)
      } catch (e) {
        console.error(storageProvider, 'deserializiation error', key, e)
      }
    }
  }, [])
  useDataEffect(() => {
    try {
      const parsedItem = serializeObject(value)
      // const parsedItem = JSON.stringify(preSerialized)
      // console.log('writing', key, parsedItem)
      window[storageProvider].setItem(key, parsedItem)
    } catch (e) {
      // cannot be parsed
      console.error(storageProvider, 'serializiation error', key, e)
    }
  }, [key, storageProvider, value])
  // console.log('returning value', value)
  return state
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  blockUpdates: boolean = false,
) {
  return useBrowserStorage<T>(key, initialValue, 'localStorage', blockUpdates)
}
export function useDeferredLocalStorage<T>(
  key: string,
  initialValue: T,
  blockUpdates: boolean = false,
) {
  return useDeferredBrowserStorage<T>(key, initialValue, 'localStorage', blockUpdates)
}
export function useSessionStorage<T>(
  key: string,
  initialValue: T,
) {
  return useBrowserStorage<T>(key, initialValue, 'sessionStorage')
}
