import { RestModel } from 'app/RestModel'
import { SiteMeasuringModel } from 'api/models/siteMeasuringModel'
import { api } from 'app/apiClient'
import { ISiteMeasuringInspection } from 'api/models/siteMeasuringInspections'
import { getQueryForArchive } from 'api/helper/archiveQuery'

const clearPayload = (payload: ISiteMeasuringInspection) => ({
  ...payload,
  customerSiteMeasuringTabs: undefined,
  scaffoldInquiry: undefined,
})
class ConstructionInquiryRestModel extends RestModel<any> {
  path = 'siteMeasuringInspection'

  getItems = async (parameters: any, permissions: string[]|undefined):Promise<ISiteMeasuringInspection[]> => {
    const query = getQueryForArchive(parameters)
    return super.getItems(parameters, permissions, true, query)
  }
    // return []

  async updateItem(payload: ISiteMeasuringInspection): Promise<unknown> {
    return super.updateItem(clearPayload(payload))
  }

  async createItem(payload: SiteMeasuringModel): Promise<unknown> {
    return api.post(`${this.path}/${payload.id}`).json()
  }
}

export default new ConstructionInquiryRestModel()
