import { RestModel } from 'app/RestModel'
import { OperationUnitModel } from './models/operationUnitModel'

class OperationUnitsRestModel extends RestModel<OperationUnitModel> {
  path = 'operationUnit'

  getItems = async (parameters: any, permissions: string[]|undefined) => {
    const fetchAllUsers = permissions?.includes('VIEW_ALL_CUSTOMERS')

    return super.getItems(parameters, permissions, fetchAllUsers)
  }
}

export default new OperationUnitsRestModel()
