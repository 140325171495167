import useResizeObserver from '@react-hook/resize-observer'
import { styled } from '@mui/system'
import { ReactElement, useLayoutEffect, useState } from 'react'

export const useSize = (target:any|undefined) => {
  const [size, setSize] = useState<DOMRectReadOnly|undefined>()

  useLayoutEffect(() => {
    if (target?.current) setSize(target.current.getBoundingClientRect())
  }, [target?.current])

  // Where the magic happens
  useResizeObserver(target, (entry:ResizeObserverEntry) => setSize(entry.contentRect))
  return size
}
export const FullWidthDiv = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  minHeight: '80vh',
  maxHeight: '90vh',
  maxWidth: '90vw',
}))

export interface PropWithOneChild<T> {
  children: ReactElement
}
// export function FullWidthResizeObserver({ children: Children, ...rest }:PropWithOneChild<any>) {
//   const target = useRef<any>(null)
//   const size = useSize(target)
//   // Object.assign(children.props, { size })
//   return (
//     <FullWidthDiv ref={target}>
//       <Children size={size} {...rest} />
//     </FullWidthDiv>
//   )
// }
