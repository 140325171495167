import { styled } from '@mui/system'

const StyledLogo = styled('img')(({ theme }) => ({
  // height: 53,
  maxHeight: 53,
  maxWidth: 196,
  backgroundColor: 'white',
  // width: 196,
  // [theme.breakpoints.up('md')]: {
  //   width: '100%',
  //   height: 'auto',
  // },
}))
const StyledLogoWrapper = styled('div')(({ theme }) => ({
  background: 'url(/logo.svg) no-repeat center center;',
  backgroundSize: '190px 45px',
  backgroundOrigin: 'content-box',
  height: 53,
  padding: theme.spacing(1),
  //   height: 'auto',
  // },
}))
export default function AppLogo() {
  return (
    <StyledLogoWrapper>
      {/* <StyledLogo alt="logo" src="/logo.svg" /> */}
    </StyledLogoWrapper>
  )
}
