import { MouseEvent, MutableRefObject, useCallback } from 'react'
// eslint-disable-next-line camelcase
import { MRT_TableInstance } from 'material-react-table'
import { Stack } from '@mui/material'
import AsyncActionButton from 'framework/components/AsyncActionButton'
import { TFunction } from 'i18next'
import { useAppDispatch } from 'app/hooks'
import { useUserHasAllPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'
import { SiteMeasuringModel } from 'api/models/siteMeasuringModel'
import { setSiteMeasuringAsAccounted } from 'features/siteMeasurings'
import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { IMappedData } from 'ui/views/SiteMeasurings/models/IMappedData'

interface IProps {
  t: TFunction
  // eslint-disable-next-line camelcase
  table: MRT_TableInstance<IMappedData>,
}

function AccountedAction({ table, t }: IProps) {
  const appDispatch = useAppDispatch()

  const handleClick = useCallback(async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const rows = table?.getSelectedRowModel()?.rows || []
    const original = rows.map((x) => x.original) as SiteMeasuringModel[]
    await appDispatch(setSiteMeasuringAsAccounted(original, true))
    table?.resetRowSelection()
  }, [appDispatch, table])
  return (
    <AsyncActionButton
      onClick={handleClick}
    >
      {t('setAccounted')}
    </AsyncActionButton>
  )
}
export default function CurrentActions(props:IProps) {
  const markAccounted = useUserHasAllPermissions([PermissionModel.MARK_SITE_MEASURING_ACCOUNTED])
  return (
    <Stack direction="row" spacing={1}>
      {markAccounted
      && <AccountedAction {...props} />}

    </Stack>
  )
}
