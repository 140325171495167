import { Grid } from '@mui/material'
import ButtonLink from 'framework/components/ButtonLink'
import { useTranslation } from 'react-i18next'
import LoginForm from 'ui/views/Login/LoginForm'
import LoginComponent from 'ui/views/Login/LoginComponent'
import { styled } from '@mui/system'

const StyledButtonLink = styled(ButtonLink)(({ theme }) => ({
  fontSize: '1.1rem',
}))

export default function Login() {
  const { t } = useTranslation('login')
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <LoginComponent />
      </Grid>
      <Grid item>
        <StyledButtonLink size="large" variant="text" to="/request-account">{t('registerRequest')}</StyledButtonLink>
      </Grid>
    </Grid>

  )
}
