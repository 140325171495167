import { useMemo } from 'react'
import { stringArrayToCSV } from 'lib/stringHelper'
import { hooks, userIsInCustomerGroup } from 'features/users'
import { useTranslation } from 'react-i18next'
import { UserModel } from 'api/models/UserModel'
import { Typography } from '@mui/material'

const userIsInCustomerGroupCallback = (customerId?: string, primaryOnly: boolean = false) => (x:UserModel) => userIsInCustomerGroup(customerId, x, primaryOnly)

const useTextSorter = () => {
  const { i18n } = useTranslation()
  return (a:string, b:string) => a.localeCompare(b, i18n.language, { numeric: true })
}
export const useUserOptions = (customerId?: string, primaryOnly: boolean = false) => {
  const data = hooks.useAll()
  const sorter = useTextSorter()
  return useMemo(
() => data?.filter(userIsInCustomerGroupCallback(customerId, primaryOnly))
      .map((x) => ({
        // label: <div><Typography>{x.firstName} {x.lastName} ({x.email})</Typography><Typography variant="subtitle2">{stringArrayToCSV(x.tags)}</Typography></div>,
        label: `${x.firstName} ${x.lastName} (${x.email}) - ${x.tags ? stringArrayToCSV(x.tags) : ''}`,
        renderLabel: `${x.firstName} ${x.lastName} (${x.email})`,
        tags: x.tags ? stringArrayToCSV(x.tags) : '',
        value: x.id,
      }))
      .sort((a, b) => sorter(a.label, b.label)),
     [data, customerId],
)
}

export const useUserTableOptions = (customerId?: string, primaryOnly: boolean = false) => {
  const data = hooks.useAll()
  const sorter = useTextSorter()
  return useMemo(
    () => data?.filter(userIsInCustomerGroupCallback(customerId, primaryOnly))
      .map((x) => ({
        // label: <div><Typography>{x.firstName} {x.lastName} ({x.email})</Typography><Typography variant="subtitle2">{stringArrayToCSV(x.tags)}</Typography></div>,
        label: x.email,
        value: x.id,
      }))
      .sort((a, b) => sorter(a.label, b.label)),
    [data, customerId],
  )
}
