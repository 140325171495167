import React, { MouseEvent, PropsWithChildren, useCallback } from 'react'
import { Fab, FabProps, Tooltip } from '@mui/material'
import { Link, LinkProps } from 'react-router-dom'
import { PropsWithClassName } from '../models/PropsWithClassName'

export default function FabButtonLink({ children, tooltip, ref, className, to, ...rest }: PropsWithChildren<PropsWithClassName<{tooltip?: string} &LinkProps & FabProps>>) {
  const handleClick = useCallback((event?:MouseEvent<HTMLAnchorElement>) => {
    // event?.preventDefault()
    event?.stopPropagation()
  }, [])
  if (tooltip) {
    return (
      <Tooltip title={tooltip} >
        <Fab className={className} onClick={handleClick} component={Link} to={to} {...rest}>
          {children}
        </Fab>
      </Tooltip>
    )
  }
  return (
    <Fab className={className} onClick={handleClick} component={Link} to={to} {...rest}>
      {children}
    </Fab>
  )
}
