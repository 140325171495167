import { IPropsLoader } from 'framework/components/DataLoader/types/IPropsLoader'
import LoadStateModel from 'framework/LoadStateModel'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { nestedResolver } from 'framework/components/DataLoader/lib/nestedResolver'

interface IPropReadyResult {
  props: {[key: string]:LoadStateModel},
  overall: LoadStateModel
}

/**
 * checks loadstate of required props respecting fetchpolicy
 * @param props
 */
export function usePropIsReadyWithFetchPolicy(props: IPropsLoader): IPropReadyResult {
  const { requiredProps, parameters } = props
  const state = useSelector((store) => store)
  // const { lazy, blocking } = useRequiredProps(requiredProps)
  return useMemo(() => {
    const result:IPropReadyResult = { props: {}, overall: LoadStateModel.LOADED }
    requiredProps?.forEach((requiredProp: any) => {
      // @ts-ignore
      const propState = state[requiredProp.prop]
      let ready: LoadStateModel = LoadStateModel.LOADED
      if (propState.loadState !== LoadStateModel.LOADED) ready = propState.loadState
      if (requiredProp.nested && !nestedResolver(parameters, propState, requiredProp.nested)) {
        ready = LoadStateModel.INITIAL
      }
      result.props[requiredProp.prop] = ready
      if (requiredProp.fetchPolicy !== 'lazy' && ready !== LoadStateModel.LOADED) result.overall = ready
      // todo remove this from production build
      // if (process.env.REACT_APP_DEBUG_DATALOADING)console.log('prop ready', requiredProp, propState.loadState, propState.loadState === LoadStateModel.LOADED)
    })
    return result
  }, [requiredProps, parameters, state])
}
