import { IconButton, Tooltip, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { getUser } from 'features/user'
import { Account, Logout } from 'mdi-material-ui'
import { useTranslation } from 'react-i18next'
import { MouseEvent, useCallback } from 'react'
import { useAppDispatch } from 'app/hooks'
import { Link, useNavigate } from 'react-router-dom'

import ButtonLink, { IconButtonLink } from 'framework/components/ButtonLink'
import { styled } from '@mui/system'
import { removeToken } from '../../../features/auth'

const StyledButtonLink = styled(ButtonLink)(({ theme }) => ({
    color: 'black',
}))
export default function LoginUser() {
  const user = useSelector(getUser)
  const { t } = useTranslation('user')
  const appDispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleLogout = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    navigate('/')
    appDispatch(removeToken())
    sessionStorage.clear()
    localStorage.clear()
  }, [navigate, appDispatch])
  if (!user) return null
  return (
    <Typography align="center">
      <IconButtonLink to="/account"><Account /></IconButtonLink>
      {user?.firstName}, {user?.lastName}, {user?.companyName}
      <Tooltip title={t('logout')}><IconButton onClick={handleLogout}><Logout /></IconButton></Tooltip>
    </Typography>
  )
}
