import React, { useEffect, useMemo } from 'react'
import { confirmEmail } from 'features/user'
import { useAppDispatch } from 'app/hooks'
import LoginFormsWrapper from 'ui/views/Login/LoginFormsWrapper'
import { useLocation, useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AsyncHandlerLoadingState, useAsyncHandler } from 'framework/lib/useAsyncHandler'
import useParsedSearchParams from 'lib/useParsedSearchParams'
import { useDataEffect } from '@codeflix/mui-managed-form'
import ButtonLink from 'framework/components/ButtonLink'
import { Stack, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'

export default function ConfirmEmail() {
  const appDispatch = useAppDispatch()
  const params = useParams()
  const searchParams = useParsedSearchParams()
  const token = useMemo(() => searchParams.get('token'), [searchParams])
  const mode = useMemo(() => searchParams.get('mode'), [searchParams]) as 'account-request'|'confirm'
  const { t } = useTranslation('confirmEmail')
  const [state, submitHandler] = useAsyncHandler(() => appDispatch(confirmEmail(params.email!, token!)) as Promise<boolean>)
  useEffect(() => {
    if (params.email && token) {
      submitHandler()
    }
  }, [params.email, token])
  return (
    <LoginFormsWrapper backdropOpen={state <= AsyncHandlerLoadingState.Loading}>
      <Grid2 container justifyContent="center" spacing={2}>
        <Grid2 xs={12}><Typography variant="h5" component="h2">{state === AsyncHandlerLoadingState.Success ? t(mode) : t(state.toString())}</Typography></Grid2>
        <Grid2 xs={12} md={6}><ButtonLink fullWidth to="/" variant="contained">{t('goToStartPage')}</ButtonLink></Grid2>
      </Grid2>

    </LoginFormsWrapper>

  )
}
