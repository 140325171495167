import { getOrConvertToImageBlob } from 'framework/components/ManagedForm/Fields/ImageEditor/libs/UseImageSrcFromBlob'
import { resizeImage } from 'framework/components/ManagedForm/Fields/ImageEditor/libs/imageResize'

export const uploadBlobToThumbnail = async (file: Blob|File) => {
  const imageBlob = await getOrConvertToImageBlob(file)
  // const bitmap = await blobToImageBitmap(imageBlob)
  const thumbnail = await resizeImage(imageBlob, { width: 400, height: 400 })
  // const { scaleRatio } = aspectRatioScaling(size, { width: 400, height: 400 })
  // const thumbnail = stage.current?.toDataURL({
  //   quality: 0.7,
  //   pixelRatio: scaleRatio,
  //
  // })
  // const thumbnailBlob = await (await fetch(thumbnail)).blob()
  return thumbnail
}
