/* eslint-disable camelcase  */
/* eslint-disable react/jsx-pascal-case */
import { MRT_RowData, MRT_TableInstance, MRT_TableOptions, useMaterialReactTable } from 'material-react-table'
import { useLocation } from 'react-router'
import { useCallback, useMemo } from 'react'
import {
  MaterialReactTableIntermediateProps,
} from 'framework/components/Table/types/materialReactTableIntermediateProps'
import InternalToolbar from 'framework/components/Table/Toolbar/Internal'
import { useLanguageToLocalization } from 'framework/components/Table/helper/useLanguageToLocalization'
import { getRowId } from 'framework/components/Table/helper/getRowId'
import { useTableState } from 'framework/components/Table/helper/useTableState'
import { IBaseModel } from 'api/baseModel'
import { useNavigate } from 'react-router-dom'

export default function usePersistedMaterialReactTable<TData extends MRT_RowData>(props: MaterialReactTableIntermediateProps<TData>) {
  const {
    initialState,
      name,
    pathname,
      renderSelectionActions,
      renderTopToolbarCustomActions,
      columns,
      state,
       ...rest
  } = props
  const locale = useLanguageToLocalization()
  const { pathname: pathname2 } = useLocation()
  const navigate = useNavigate()

  const {
    state: tableState,
    ...tableStateProps
  } = useTableState<TData>(props, pathname2)
  // @ts-ignore
  const { rowSelection } = tableState
  const renderTopToolbarCustomActions2 = useMemo(() => ((Object.values(rowSelection).includes(true) && typeof renderSelectionActions === 'function') ? (props:{
    table: MRT_TableInstance<TData>;
  }) => renderSelectionActions(props) : renderTopToolbarCustomActions), [rowSelection, renderSelectionActions, renderTopToolbarCustomActions])
  const toolbarInternalActions = useCallback(({ table }: any) => <InternalToolbar table={table} name={name} />, [name])
  const allProps: MRT_TableOptions<TData> = {
    initialState: {
      showColumnFilters: false,
      columnVisibility: {
        id: false,
      },
      ...initialState,
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', // change header text
      },
    },
    state: tableState,
    ...rest,
    ...tableStateProps,
    columns: columns || [],
    // data: data || [],
    localization: locale,
    enableToolbarInternalActions: true,
    enableRowSelection: props.enableRowSelection,
    enableColumnOrdering: true,
    getRowId,
    // enableColumnFilterModes: true,
    renderToolbarInternalActions: toolbarInternalActions,
    renderTopToolbarCustomActions: renderTopToolbarCustomActions2,
    positionToolbarAlertBanner: 'top',
    positionPagination: 'bottom',
    muiTablePaperProps: {
      elevation: 0, // change the mui box shadow
      // customize paper styles
      sx: {
        borderRadius: '0',
        // border: '1px dashed #e0e0e0',
      },
    },
    muiTableBodyRowProps: ({ row }: any) => ({
      onClick: (event: any) => {
        console.log('path', event, row)
        navigate(`${pathname}/${(row.original as IBaseModel).id}`)
      },
      onAuxClick: (event: any) => {
        if (event.button === 1) {
          window.open(`${pathname}/${(row.original as IBaseModel).id}`, '_blank', 'noopener')
        }
      },
    }),
    // muiToolbarAlertBannerProps:
    // renderToolbarAlertBannerContent: MRT_ToolbarAlertBanner,
    // renderToolbarAlertBannerContent,
  }
  // eslint-disable-next-line camelcase

  return useMaterialReactTable(allProps)
}
