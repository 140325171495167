import { MuiManagedComponentType, MuiManagedResolveInfo } from '@codeflix/mui-managed-form'
import Grid2 from '@mui/material/Unstable_Grid2'
import AttachmentPreview from './Fields/ImageEditor/ImageWithLocationPointer/Attachments'

export const fieldResolver = (
  resolveInfo: MuiManagedResolveInfo,
): MuiManagedComponentType | undefined => {
  switch (resolveInfo.type) {
    case 'attachment':
      return {
        props: {},
        Component: AttachmentPreview,
        WrapperComponent: Grid2,
      }
    default:
      return undefined
  }
}
