import { Palette, styled, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormatDate } from 'framework/components/FormattedDate'

type StyledRangeColor = {
  textColor: keyof Palette|undefined
}
const StyledColorTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'textColor',
})<StyledRangeColor>(({ theme, textColor }) => ({
  ...(textColor && ({
    // @ts-ignore
    color: theme.palette[textColor].main,
  })),
}))

export function RemainingDaysCell({ cell, ...rest }: any) {
  const { t } = useTranslation('units')
  const value = cell.getValue() as {days: number, date: string}|undefined
  const formatDate = useFormatDate()
  if (!value) return undefined
  const color = cell.column.columnDef.getColor(value.days) as keyof Palette|undefined
  return (
    <Tooltip arrow title={formatDate(value.date, 'DATE')} followCursor>
      <StyledColorTypography variant="inherit" textColor={color}>
        {value.days} {t('dayS')}
      </StyledColorTypography>
    </Tooltip>
  )
}
