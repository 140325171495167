import { useMemo } from 'react'
import { INamedBaseModel } from '../api/baseModel'

interface ICallback<T extends INamedBaseModel> {
  label: string,
  value: string,
}
export const defaultMapFctNamedOptions = (x:INamedBaseModel) => ({
  label: x.name,
  value: x.id,
})

export default function useNamedOptions<T extends INamedBaseModel>(
  data: T[],
  includeArchived: boolean = true,
  mapFct: (x:T)=>ICallback<T> = defaultMapFctNamedOptions,
) {
  return useMemo(() => {
    let dataToFilter = data
    if (!includeArchived) dataToFilter = dataToFilter.filter((x) => !x.archived)
    return dataToFilter.map(mapFct)
  }, [data])
}
