import React, { MouseEvent, PropsWithChildren, useCallback } from 'react'
import { Button, IconButton } from '@mui/material'
import { Link, LinkProps } from 'react-router-dom'
import { ButtonProps } from '@mui/material/Button/Button'
import { PropsWithClassName } from '../models/PropsWithClassName'

// const ButtonLink = React.forwardRef(({ className, ref, to, children, ...rest }:PropsWithChildren<PropsWithClassName<LinkProps & ButtonProps>>, ref) => (
//   <Button className={className} component={Link} to={to} {...rest}>
//     {children}
//   </Button>
// ))

export default function ButtonLink({ children, ref, className, to, ...rest }: PropsWithChildren<PropsWithClassName<LinkProps & ButtonProps>>) {
  const handleClick = useCallback((event?:MouseEvent<HTMLAnchorElement>) => {
    // event?.preventDefault()
    event?.stopPropagation()
  }, [])

  return (
    <Button className={className} onClick={handleClick} component={Link} to={to} {...rest}>
      {children}
    </Button>
  )
}
export function IconButtonLink({ children, ref, className, to, ...rest }: PropsWithChildren<PropsWithClassName<LinkProps & ButtonProps>>) {
  const handleClick = useCallback((event?:MouseEvent<HTMLAnchorElement>) => {
    // event?.preventDefault()
    event?.stopPropagation()
  }, [])

  return (
    <IconButton className={className} onClick={handleClick} component={Link} to={to} {...rest}>
      {children}
    </IconButton>
  )
}
