import { Group, Image, Label, Layer, Rect, Stage, Tag, Text } from 'react-konva'
import * as React from 'react'
import { DragEvent, forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { FullWidthDiv } from 'lib/resizeObserver'
import useImage from 'use-image'
import { Grid, styled } from '@mui/material'
import { useSizeScaleImage } from 'framework/components/ManagedForm/Fields/ImageEditor/libs/UseSizeScaleImage'
import { useTranslation } from 'react-i18next'
import DraggableTextField from 'framework/components/ManagedForm/Fields/ImageEditor/components/DraggableTextField'
import FullScreenToolsPortal
  from 'framework/components/ManagedForm/Fields/ImageEditor/components/FullScreenToolsPortal'
import MapPin from 'framework/components/ManagedForm/Fields/ImageEditor/miscellaneous/MapPin.svg'
import { useRefreshingState } from 'framework/lib/useRefreshingState'
import DraggablePin from 'framework/components/ManagedForm/Fields/ImageEditor/components/DraggablePin'
import Center from 'framework/components/Center'
import { IImageInputValue } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IImageInputValue'

export interface IImageInputPropsBase {
  onChange: (value: any) => Promise<void> | void
  value: IImageInputValue
  size?: { width: number, height: number },
  disabled?: boolean,

  scaleX: number,
  scaleY: number,
  imageSrcUrl: string
  imageBitmap: ImageBitmap
}

const StyledStage = styled(Stage)(({ theme }) => ({
  // border: '1px solid black',
}))

const ImageInputWithPin = React.forwardRef(({
                                              value,
                                              scaleX,
                                              scaleY,
                                              disabled,
                                              size,
                                              onChange,
                                              imageSrcUrl,
                                              imageBitmap,
                                            }: IImageInputPropsBase, ref: Ref<any>) => {
  const handleDragStart = useCallback((dragType: 'location' | 'text') => (e: any) => {
    switch (dragType) {
      case 'location':
        onChange({
          path: ['pin'],
          value: {
            ...value.pin,
            isDragging: true,
          },
        })
        break
      case 'text':
        onChange({
          path: ['text'],
          value: {
            ...value.text,
            isDragging: true,
          },
        })
        break
      default:
        break
    }
  }, [onChange, value])

  const handleDragEnd = useCallback((dragType: 'location' | 'text') => (e: any, ...params: any[]) => {
    const newX = e.target.x()
    const newY = e.target.y()

    // const dragType = e.target.getData('dragType')
    if (dragType === 'location') {
      onChange({
        path: ['pin'],
        value: {
          x: newX,
          y: newY,
          isDragging: false,
        },
      })
    } else {
      onChange({
        path: ['text'],
        value: {
          ...value.text,
          x: newX,
          y: newY,
          isDragging: false,
        },
      })
    }
    // const stageRef = (ref as React.MutableRefObject<any>)
    // stageRef.current.setPointersPositions(e)
    // const position = stageRef.current.getRelativePointerPosition()
  }, [ref, onChange, value.text])
  // const handleClick = useCallback((e: any) => {
  //   const stage = e.target.getStage()
  //   if (typeof stage === 'undefined') {
  //     return
  //   }
  //   if (disabled) return
  //   const pos = stage!.getRelativePointerPosition()
  //   console.error('click', pos)
  //   onChange({
  //     path: ['pin'],
  //     value: {
  //       x: pos.x,
  //       y: pos.y,
  //       isDragging: false,
  //     },
  //   })
  // }, [disabled, onChange])
  const handleTextClick = useCallback((e: any) => {
    onChange({ path: ['text'], value: undefined })
  }, [onChange])
  const handlePinClick = useCallback(() => {
    onChange({ path: ['pin'], value: undefined })
  }, [onChange])
  const [imageSvg] = useImage(MapPin)

  return (
    // @ts-ignore
    <StyledStage ref={ref} width={size.width} height={size.height} scaleX={scaleX} scaleY={scaleY}>
      <Layer>
        <Rect fill="#ffffff" width={size!.width} height={size!.height} x={0} y={0} />
        <Image
          image={imageBitmap}
          // scaleX={scaleX}
          // scaleY={scaleY}
          // onClick={handleClick}
        />

        {value?.pin && (
          <Image
            image={imageSvg}
            x={value?.pin.x}
            y={value?.pin.y}
            offsetX={imageSvg ? (imageSvg.width / 2) : 0}
            offsetY={imageSvg ? (imageSvg.height / 2) : 0}
            shadowColor="black"
            shadowBlur={10}
            shadowOpacity={0.6}
            shadowOffsetX={value?.pin.isDragging ? 10 : 5}
            shadowOffsetY={value?.pin.isDragging ? 10 : 5}
            scaleX={(value?.pin.isDragging ? 1.2 : 1)}
            scaleY={(value?.pin.isDragging ? 1.2 : 1)}
            onDragStart={handleDragStart('location')}
            onDragEnd={handleDragEnd('location')}
            onClick={handlePinClick}
            draggable={!disabled}
          />
        )}
        {value?.text && (
          <Group>
            <Label
              x={value.text.x}
              y={value.text.y}
              onClick={handleTextClick}
              draggable={!disabled}
              onDragStart={handleDragStart('text')}
              onDragEnd={handleDragEnd('text')}
              // offsetX={imageSvg ? imageSvg.width / 2 : 0}
              // offsetY={imageSvg ? imageSvg.height / 2 : 0}
              color="#ffffff"
              scaleX={(value.text.isDragging ? 1.2 : 1)}
              scaleY={(value.text.isDragging ? 1.2 : 1)}
            >
              <Tag
                fill="#ffffff"
                cornerRadius={10}
                // stroke="#3c3c3c"
                shadowColor="black"
                shadowBlur={10}
                shadowOpacity={0.6}
                lineJoin="round"
              />
              <Text
                padding={10}
                text={value?.text.value}
                fontSize={value?.text.fontSize}
                // shadowColor="black"
                // shadowBlur={10}
                // shadowOpacity={0.6}
                // shadowOffsetX={value.text.isDragging ? 10 : 5}
                // shadowOffsetY={value.text.isDragging ? 10 : 5}

              />
            </Label>
          </Group>

        )}
      </Layer>
    </StyledStage>
  )
})

export interface IPinProps {
  onChange: (value: any) => Promise<void> | void
  value: IImageInputValue
  disabled?: boolean,
  imageSrcUrl: string
  imageBitmap: ImageBitmap
}

const ImageInputWithPinSizeWrapper = forwardRef((props: IPinProps, ref) => {
  const target = useRef<any>(null)
  const stage = useRef<any>(null)
  const { t } = useTranslation('imageInput')
  // const { scaleX, scaleY, size } = useSizeScale(target, 1280)
  const { onChange, value } = props
  const { scaleRatio, reverseScaleRatio, size } = useSizeScaleImage(target, props.imageBitmap)
  const [zoom, setZoom] = useRefreshingState(scaleRatio)
  // console.log('image scale to fit', scaleToFit)
  const handleDrop = useCallback((e: DragEvent<HTMLDivElement>) => {
    const dragType = e.dataTransfer.getData('dragType')
    const payload = e.dataTransfer.getData('payload')
    e.preventDefault()
    stage.current.setPointersPositions(e)
    // get pin
    const position = stage.current.getRelativePointerPosition()
    switch (dragType) {
      case 'location':
        props.onChange({
          path: ['pin'],
          value: position,
        })
        break
      case 'text':
        props.onChange({
          path: ['text'],
          value: {
            ...position,
            fontSize: 18,
            value: payload,
          },
        })
        break
      default:
        break
    }
  }, [props.onChange, props.value])
  const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }, [])

  const exportImage = useCallback(async () => {
    if (stage.current) {
      try {
        const image = stage.current?.toDataURL({
          pixelRatio: 1 / zoom,
        })
        const imageBlob = await (await fetch(image)).blob()
        // return imageBlob
        onChange({
          path: ['image'],
          value: imageBlob,
        })
      } catch (e) {
        console.error('konva exportimage2', e)
      }
    }
    // return undefined
  }, [onChange, zoom])
  useImperativeHandle(ref, () => ({ exportImage }), [exportImage])
  const { pin, text } = value
  useEffect(() => {
    exportImage()
  }, [pin, text, exportImage])
  return (
    <>
      <FullScreenToolsPortal
        t={t}
        {...props}
        size={size}
        zoom={zoom}
        setZoom={setZoom}
        maxZoom={scaleRatio}
        scaleX={scaleRatio}
        scaleY={scaleRatio}
        exportImage={exportImage}
      />
      {/* <PositionedFab color="primary" aria-label={t('save').toString()} size="large" onClick={exportImage}> */}
      {/*  <Save /> */}
      {/* </PositionedFab> */}
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={10}>
          <FullWidthDiv ref={target} >
            <Center>
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <ImageInputWithPin
                  ref={stage}
                  size={size}
                  scaleX={zoom}
                  scaleY={zoom}
                  {...props}
                />
              </div>
            </Center>
          </FullWidthDiv>
        </Grid>
        <Grid item xs={2}>
          <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="center">

            <Grid item>
              <DraggablePin zoom={zoom} />
            </Grid>
            <Grid item>
              <DraggableTextField />
            </Grid>
            {/* <Grid item> */}
            {/*  <Typography>{t('hint')}</Typography> */}
            {/* </Grid> */}
          </Grid>

        </Grid>
      </Grid>
    </>

  )
})
export default ImageInputWithPinSizeWrapper
