import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'

const defaultOptions: any = {
  year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
}

export default function useDateFormat(options:any = defaultOptions) {
  const { i18n } = useTranslation('global')
  return useCallback(
 (date: string | Date) => new Intl.DateTimeFormat(i18n.language, options).format(new Date(date)),
  [i18n.language],
  )
}
