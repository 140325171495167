import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useUsers } from 'features/users'
import OverviewView from 'framework/components/BaseRestViews/OverviewView'
import { CustomerModel } from 'api/models/customerModel'
import { useCompanies } from 'features/companies'
import { CompanyModel } from 'api/models/companyModel'
import { UserModel } from 'api/models/UserModel'
import { LookupAccessorFn } from 'framework/components/Table/helper/lookupAccessorFn'
import { RoleModel } from 'api/models/RoleModel'

export default function Customers() {
  const users = useUsers()
  const { t } = useTranslation('users')
  const companies = useCompanies()
  const columns = useMemo(() => [
    {
      header: t('generic:id'),
      accessorKey: 'id',
      filterFn: 'includesString',
    },
    {
      header: t('lastName'),
      accessorKey: 'lastName',
      filterFn: 'includesString',
    },
    {
      header: t('firstName'),
      accessorKey: 'firstName',
      filterFn: 'includesString',
    },
    {
      header: t('companyId'),
      accessorFn: LookupAccessorFn<UserModel>(companies, undefined, 'company', undefined, 'name'),
      filterFn: 'includesString',
    },
    {
      header: t('email'),
      accessorKey: 'email',
      filterFn: 'includesString',
    },
    {
      header: t('phoneNumber'),
      accessorKey: 'phoneNumber',
      filterFn: 'includesString',
    },
    {
      header: t('status'),
      accessorKey: 'status',
      accessorFn: (row:any) => t(`userStatus:${row.status}`),

    },
    {
      header: t('roles'),
      accessorFn: (row: any) => row.roles.map((cur:RoleModel) => t(`roles:${cur}`)).join(', '),
      filterFn: 'includesString',
    },
  ], [companies, t])
  // const onRowClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
  //
  // },[navigate])
  return (
    <OverviewView
      name={t('application:users').toString()}
      data={users || []}
      columns={columns}
    />
  )
}
