import { Backdrop, Button, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MouseEvent, useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { useAppDispatch } from 'app/hooks'
import { useSelector } from 'react-redux'
import ButtonLink from 'framework/components/ButtonLink'
import { ManagedForm } from '@codeflix/mui-managed-form'
import LoginFormsWrapper from 'ui/views/Login/LoginFormsWrapper'
import { getUser, loginUser } from '../../../features/user'

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}))

export default function LoginForm() {
  const { t } = useTranslation('login')
  const navigate = useNavigate()
  const appDispatch = useAppDispatch()
  const user = useSelector(getUser)
  const [loading, setLoading] = useState(false)
  const onSubmit = useCallback(async (values:any) => {
    setLoading(true)
    await appDispatch(loginUser(values.email, values.password))
    setLoading(false)
  }, [appDispatch])
  useEffect(() => {
    if (user) {
      navigate('/account')
    }
  }, [user])
  return (
    <>
      <StyledBackdrop open={loading}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
      <ManagedForm
        initial={{ from: new Date().toISOString(), to: new Date().toISOString(), location: undefined, mode: 'login' }}
        elements={[
          {
            type: 'email',
            name: 'email',
            required: true,
          },
          {
            type: 'password',
            name: 'password',
            required: true,
            helperText: (<ButtonLink size="small" to="/reset-password" variant="text">{t('resetPassword')}</ButtonLink>),
          },
          {
            type: 'submit',
            name: 'login',
            variant: 'contained',
          },
        ]}
        onSubmit={onSubmit}
        t={t}
      />
    </>

  )
}
