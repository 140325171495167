import { useTranslation } from 'react-i18next'
import { useCurrentScaffolds } from 'features/scaffoldInquiries'
import LocalizedPlot from 'framework/components/Charts/LocalizedPlot'
import { Paper } from '@mui/material'
import { useScaffolders } from 'features/scaffolders'
import { useTimeDiffConstructionAveraged } from 'ui/views/Dashboard/lib/useTimeDiffConstructionAveraged'
import { useHeight } from 'ui/views/Dashboard/lib/useHeight'

function InternalPlot() {
  const { t } = useTranslation('dashboard')
  const lookup = useScaffolders()
  const scaffolds = useCurrentScaffolds()
  const data2 = useTimeDiffConstructionAveraged(scaffolds, lookup, 'constructionDate', 'preferredConstructionDate')

  const height = useHeight()
  return (
    <Paper elevation={6}>
      <LocalizedPlot
        title={t('deltaPreferredToConstructionDate')}
        data={data2}
        layout={{
          height,
          legend: {
            // orientation: 'h',
          },
          xaxis: {
            type: 'date',
          },
          yaxis: {
            showgrid: true,
            // zeroline: false,
            // ticks: 'outside',
            autorange: true,
            type: 'linear',
          },
        }}
      />
    </Paper>
  )
}
export default function ScaffoldTimeDiffConstruction() {
  return (
    <InternalPlot />
  )
}
