import { Button, ButtonProps, CircularProgress, styled } from '@mui/material'
import { MouseEvent, PropsWithChildren, useCallback } from 'react'
import Dialog2 from 'framework/components/Dialog2'
import { IPopupActionProps } from 'framework/components/PopupActions/IPopupActionProps'
import { useOpenState } from '@codeflix/mui-managed-form'

const Loading = styled(CircularProgress)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}))
interface IProps extends IPopupActionProps {
  buttonProps?: ButtonProps
}

export default function PopupActionButton({
                                            label, disabled,
                                            actions, children, t, openState, buttonProps,
                                            ...rest
                                          }: PropsWithChildren<IProps>) {
  const { open, handleOpen, handleClose } = openState

  const handleOpen2 = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation()
    event?.preventDefault()
    handleOpen()
  }, [handleOpen])
  return (
    <>
      <Button onClick={handleOpen2} disabled={disabled} {...buttonProps} >
        {/* {loading && <Loading variant="indeterminate" color="primary" size={20} />} */}
        {/* {children} */}
        {label}
      </Button>
      <Dialog2
        t={t}
        title={label}
        open={open}
        onClose={handleClose}
        actions={actions}
        // actions={}
      >{children}
      </Dialog2>
    </>

  )
}

export function ManagedPopupActionButton(props: PropsWithChildren<Omit<IProps, 'openState'>>) {
  const openState = useOpenState()
  return (
    <PopupActionButton openState={openState} {...props} />
  )
}
