import { Ref, useMemo } from 'react'
import { useSize } from 'lib/resizeObserver'

export type WidthAndHeight = {width: number, height: number}
export const aspectRatioScaling = (source: WidthAndHeight, target: WidthAndHeight, useLowerNumber:boolean = false) => {
  let xRatio = 1
  let yRatio = 1
  let scaleRatio = 1
  if (source.width > target.width || source.height > target.height) {
    // scale down
    xRatio = target.width / source.width
    yRatio = target.height / source.height
    scaleRatio = xRatio < yRatio ? xRatio : yRatio
  } else {
    // scale up
    xRatio = target.width / source.width
    yRatio = target.height / source.height
    scaleRatio = xRatio < yRatio ? xRatio : yRatio
  }

  let actualHeight = source.height * scaleRatio
  let actualWidth = source.width * scaleRatio
  if (useLowerNumber) {
    if (actualWidth > source.width || actualHeight > source.height) {
      actualHeight = source.height
      actualWidth = source.width
    }
  }
  return {
    actualWidth,
    actualHeight,
    xRatio,
    yRatio,
    scaleRatio,
  }
}

export const useSizeScaleImage = (ref: Ref<any>, image: ImageBitmap) => {
  const size = useSize(ref)
  // console.log('scaling', 'px available', size?.width, size?.height)

  return useMemo(() => {
    if (!size) {
      return {
        scaleRatio: 1,
        reverseScaleRatio: 1,
        size: { width: 0, height: 0 },
      }
    }
    // let xRatio = 1
    // let yRatio = 1
    // let scaleRatio = 1
    // if (image.width > size.width || image.height > size.height) {
    //   // scale down
    //   xRatio = size.width / image.width
    //   yRatio = size.height / image.height
    //   scaleRatio = xRatio < yRatio ? xRatio : yRatio
    // } else {
    //   // scale up
    //   xRatio = size.width / image.width
    //   yRatio = size.height / image.height
    //   scaleRatio = xRatio < yRatio ? xRatio : yRatio
    // }
    //
    // const actualHeight = image.height * scaleRatio
    // const actualWidth = image.width * scaleRatio
    const { scaleRatio, actualHeight, actualWidth } = aspectRatioScaling(image, size)

    return {
      scaleRatio,
      reverseScaleRatio: 1 / scaleRatio,
      size: { width: actualWidth, height: actualHeight },
    }
  }, [size, image])
}
