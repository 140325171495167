import { useMatch, useNavigate, useNavigation, useResolvedPath } from 'react-router-dom'
import Table from 'framework/components/Table'
import { useLocation, useParams } from 'react-router'
import { styled } from '@mui/system'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'
import { Stack } from '@mui/material'
import {
  useArchiveLinkStack,
  useArchiveLinkStackOrProvided,
} from 'framework/components/BaseRestViews/BreadCrumbYearSelector'
import {
  MaterialReactTableIntermediateProps,
} from 'framework/components/Table/types/materialReactTableIntermediateProps'
import LoadingCircle from '../DataLoader/LoadingCircle'
import BreadCrumb from '../BreadCrumb'
import { IBaseModel } from '../../../api/baseModel'
import { ILinkStack } from '../BreadCrumb/StyledBreadCrumps'
import FabButtonLink from '../FabButtonLink'

type IProps<T extends IBaseModel> = MaterialReactTableIntermediateProps<T> & {
  loading?: boolean,
  linkStack?: ILinkStack[],
  hideNew?: boolean,
  name?: string
  viewMode?: RestViewMode
}

const BottomFab = styled(FabButtonLink)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),

}))
export default function OverviewView<T extends IBaseModel>({ loading, initialState, name, viewMode, linkStack, hideNew, ...rest } :IProps<T>) {
  const navigate = useNavigate()
  const { pathname, ...restLocation } = useLocation()
  const { t } = useTranslation('application')
  const archiveLinkStack = useArchiveLinkStackOrProvided(name, linkStack, viewMode)
  if (loading || !rest.data) return <LoadingCircle />
  return (
    <>
      <Stack direction="row">
        {archiveLinkStack && (
        <BreadCrumb
          color="black"
          linkStack={archiveLinkStack}
        />
      )}
        {/* {viewMode === RestViewMode.Archive && ( */}
        {/*  <div> Archivjahr setzen {pathname} {JSON.stringify(restLocation)}</div> */}
        {/* )} */}
      </Stack>

      <Table<T>
        name={name}
        pathname={pathname}
        {...rest}
      />
      {!hideNew && <BottomFab tooltip={t('new').toString()} color="secondary" to={`${pathname}/new`}><Add /></BottomFab>}
    </>
  )
}
