import { IProps } from 'ui/views/ScaffoldInquiries/EditActions/IProps'
import AsyncStoreActionMenuItem from 'framework/components/BaseRestViews/AsyncStoreActionMenuItem'
import { revertCanceledDate } from 'features/scaffoldInquiries'

export function RevertCancellationDate({ item, t }: IProps) {
  return (
    <AsyncStoreActionMenuItem
      disabled={Boolean(item.deconstructionDate)}
      name={t('revertCanceledDate')}
      action={revertCanceledDate}
      item={item}
    />
  )
  // const createScaffoldCertificateHandler = useDefaultMenuItemEventHandlerDispatchParameterAction(revertCanceledDate)
  // return (
  //   <AsyncActionMenuitem disabled={Boolean(item.deconstructionDate)} onClick={createScaffoldCertificateHandler(item)}>
  //     {t('revertCanceledDate')}
  //   </AsyncActionMenuitem>
  // )
}
