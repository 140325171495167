/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { MRT_Localization_EN } from 'material-react-table/locales/en'
import { MRT_Localization_DE } from 'material-react-table/locales/de'

export const useLanguageToLocalization = () => {
  const { i18n } = useTranslation('global')

  return useMemo(() => {
    switch (i18n.language) {
      case 'en':
        // eslint-disable-next-line camelcase
        return MRT_Localization_EN
      case 'de':
        // eslint-disable-next-line camelcase
        return MRT_Localization_DE
      default:
        // eslint-disable-next-line camelcase
        return MRT_Localization_DE
    }
  }, [i18n.language])
}
