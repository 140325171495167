import { useDefaultMenuItemEventHandlerDispatchParameterAction } from 'framework/lib/useDispatchAction'
import { AsyncActionMenuitem } from 'framework/components/AsyncActionButton'
import { BaseModel } from 'api/baseModel'
import { AppThunk } from 'app/store'
import { MenuItemProps } from '@mui/material'

interface IProps<T extends BaseModel> extends MenuItemProps{
  item:T,
  name: string
  action: (...randomParameters:any)=>AppThunk
}
export default function AsyncStoreActionMenuItem<T extends BaseModel>({ item, name, action, ...rest }: IProps<T>) {
  const actionHandler = useDefaultMenuItemEventHandlerDispatchParameterAction(action)
  return (
    <AsyncActionMenuitem onClick={actionHandler(item)} {...rest}>
      {name}
    </AsyncActionMenuitem>
  )
}
