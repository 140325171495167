import { INamedBaseModel } from 'api/baseModel'
import { IBaseRestState } from 'features/base/BaseRestFeature'
import { RestModel } from 'app/RestModel'
import createBaseRestSlice from 'features/base/creators/CreateBaseRestSlice'
import { useSelector } from 'react-redux'
import useNamedOptions from 'lib/useNamedOptions'

export function createNamedBaseRestSlice<T extends INamedBaseModel>(name: string, initialState: IBaseRestState<T>, reducers: any, restModel: RestModel<T>) {
  const { selectors, hooks, ...rest } = createBaseRestSlice(name, initialState, reducers, restModel)
  const newSelectors = {
    ...selectors,
  }
  const newHooks = {
    ...hooks,
    useNamedOptions: () => {
      const data = useSelector(newSelectors.all()) as T[]
      return useNamedOptions(data || [])
    },
  }
  return {
    selectors: newSelectors,
    hooks: newHooks,
    ...rest,
  }
}
