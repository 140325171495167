import { styled } from '@mui/system'
import { Button } from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { MouseEvent, useMemo } from 'react'
import { TFunction } from 'i18next'
import { useSelector } from 'react-redux'
import { useUserPermissionCheckFunction } from 'framework/auth/permissions'
import CountBadge from '../../../framework/components/CountBadge'
import { INavItemWithChildren } from '../../../models/INavItem'
import ButtonLink from '../../../framework/components/ButtonLink'

export type StyledNavButtonProps = {
  active: boolean,
  mobile?: boolean
}
export const StyledNavButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'mobile',
})<StyledNavButtonProps>(({ theme, active, mobile }) => ({

  ...(!mobile && ({
    borderRadius: 0,
    borderColor: 'black',
  })),
  ...(mobile && ({
    justifyContent: 'flex-start',

  })),
  fontSize: '1.2rem',
  fontWeight: 200,
  color: 'black',
  // marginBottom: theme.spacing(2),
  ...(active && {
    backgroundColor: '#959595',
    color: 'white',
    '&:hover': {
      backgroundColor: '#838383',
    },
  }),
}))
export const StyledNavButtonLink = styled(ButtonLink, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'mobile',
})<StyledNavButtonProps>(({ theme, active, mobile }) => ({

  ...(!mobile && ({
    borderRadius: 0,
    borderColor: 'black',
  })),
  ...(mobile && ({
    justifyContent: 'flex-start',

  })),
  fontSize: '1.2rem',
  fontWeight: 200,
  color: 'black',
  // marginBottom: theme.spacing(2),
  ...(active && {
    backgroundColor: '#959595',
    color: 'white',
    '&:hover': {
      backgroundColor: '#838383',
    },
  }),
}))
export interface StyledNavExpandableButtonProps {
  expanded?: boolean;
  active?: boolean
}
export const StyledNavExpandableButton = styled(Button, {
  shouldForwardProp: (propName: string) => propName !== 'highlight' && propName !== 'expanded' && propName !== 'active',
})<StyledNavExpandableButtonProps & StyledNavButtonProps>(({ theme, mobile, expanded, active }) => ({
  ...(!mobile && ({
    borderRadius: 0,
    borderColor: 'black',
  })),
  ...(mobile && ({
    justifyContent: 'flex-start',

  })),
  fontSize: '1.2rem',
  fontWeight: 200,
  color: 'black',
  // marginBottom: theme.spacing(2),
  ...(active && {
    backgroundColor: '#959595',
    color: 'white',
    '&:hover': {
      backgroundColor: '#838383',
    },
  }),
}))
export const StyledNavExpandableIcon = styled(ExpandMoreIcon, {
  shouldForwardProp: (propName: string) => propName !== 'expanded',
})<{expanded: boolean}>(({ theme, expanded }) => ({
  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  // marginLeft: 'auto',
  transition: (theme.transitions as any).create('transform', {
    duration: (theme.transitions as any).duration.shortest,
  }),
}))
export const NavIconSpan = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(1),
}))

export interface INavItemProps {
  navItem: INavItemWithChildren,
  permissionCheck: useUserPermissionCheckFunction,
  onClick: (event: MouseEvent<HTMLButtonElement>) => void,
  nested?: boolean,
  isSignedIn: boolean,
  t: TFunction,
  mobile?: boolean,
}
type StyledCountBadgeProps = {
  mobile?: boolean
}
export const StyledCountBadge = styled(CountBadge, {
  shouldForwardProp: (prop) => prop !== 'mobile',
})<StyledCountBadgeProps>(({ theme, mobile }) => ({
  position: 'absolute',
  // color: 'black',
  right: 1,
  ...(!mobile && ({

    bottom: 1,
  })),

}))
export const StyledExpandable = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))
export function useNavItemCount(navItem: INavItemWithChildren) {
  // const selector : (state: RootState) => Selected = navItem.unreadCountSelector || (state:RootState) =>0
  const data = useSelector(navItem.unreadCountSelector)
  return useMemo(() => data, [data, navItem])
}
