import { ISelectionToolsButtonProps } from 'ui/views/ScaffoldInquiries/MassEditor/SelectionTools/ISelectionToolsProps'
import { useAppDispatch } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import { createRequestsFromDraft } from 'features/scaffoldInquiriesDraft'
import { Backdrop, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { ArrowRight } from 'mdi-material-ui'
import Dialog2 from 'framework/components/Dialog2'
import { useOpenState } from '@codeflix/mui-managed-form'
import { ScaffoldDraftModel } from 'api/models/scaffoldDraftModel'
import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { UpdateType } from '../../../codeflix/mui-managed-form/spreadsheet/src'

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}))
const StyledStack = styled(Stack)(({ theme }) => ({
  minWidth: 400,
  padding: theme.spacing(2),
}))
export function SubmitTool({
                                state,
                                selected,
                             isRowValid,
                              }: ISelectionToolsButtonProps<ScaffoldDraftModel>) {
  const appDispatch = useAppDispatch()
  const { open, handleOpen, handleClose } = useOpenState(false)
  const [result, setResult] = useState<{old: number, new: string}[]>([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation('scaffolds')
  const handleSubmit = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    // todo delete via api and local
    setLoading(true)
    try {
      // console.log('valid', state.valid)

      const items = selected
        .filter((x) => isRowValid(x?.value))
        .map((x) => x?.value)
      const updates: UpdateType[] = []
      const apiResult = await appDispatch(createRequestsFromDraft(items))
      const map = apiResult?.map((r: { id: string; serialNumber: { fullSerial: any } }) => ({
        old: (selected?.find((x) => x?.value?.id === r?.id)?.index || 0) + 1,
        new: r.serialNumber.fullSerial,
      }))
      selected.forEach((val: any) => {
        if (apiResult.some((y:ScaffoldInquiryModel) => y.id === val.value.id)) {
          updates.push({
            path: [val.index],
            value: undefined,
          })
        }
      })
      state.updatePath(updates, false)
      setResult(map)
      handleOpen()
    } catch (e) {
      console.error('cannotsubmit', e)
    }
    setLoading(false)
  }, [selected, appDispatch, state, handleOpen])
  return (
    <>
      <Dialog2 t={t} open={open} onClose={handleClose} title={t('draftToRequestOverview')}>
        <StyledStack>
          <Grid container spacing={1} direction="row" justifyContent="space-between">
            <Grid item xs={4}><Typography variant="subtitle1" align="left">{t('oldSerial')}</Typography></Grid>
            <Grid item xs={1}><ArrowRight /></Grid>
            <Grid item xs={4}><Typography variant="subtitle1" align="right">{t('newSerial')}</Typography></Grid>
          </Grid>
          {result.map((x) => (
            <Grid key={`${x.old}_${x.new}`} container spacing={1} direction="row" justifyContent="space-between">
              <Grid item xs={4}><Typography variant="body1" align="left">{x.old}</Typography></Grid>
              <Grid item xs={1}><ArrowRight /></Grid>
              <Grid item xs={4}><Typography variant="body1" align="right">{x.new}</Typography></Grid>
            </Grid>
          ))}
          {result.length === 0 && (
            <Typography textAlign="center" variant="body1">{t('noDraftPublished')}</Typography>
          )}

          <Button variant="contained" fullWidth onClick={handleClose}>{t('generic:close')}</Button>

        </StyledStack>
      </Dialog2>
      <StyledBackdrop open={Boolean(loading)}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
      <Button onClick={handleSubmit}>{t('createRequests')}</Button>

    </>
  )
}
