/* eslint-disable */
import {hooks as scaffolderHooks} from './scaffolders'
import {hooks as customerHooks} from './customers'
import {useMemo} from "react";
import {CompanyModel} from "api/models/companyModel";
import {useCurrentUser} from "features/user";
import {useUserHasPermissions} from "framework/auth/permissions";
import {PermissionOperation} from "framework/auth/permissionOperation";
import PermissionModel from "models/PermissionModel";
import {IPermissionRequirement} from "framework/auth/IPermissionRequirement";


export const useAllCompaniesOptions = () => {
  const customerOptions = customerHooks.useNamedOptions()
  const scaffolderOptions = scaffolderHooks.useNamedOptions()
  return useMemo(()=>customerOptions.concat(scaffolderOptions),[customerOptions,scaffolderOptions])
}
export const useCompanies = ():CompanyModel[] => {
  const customers: CompanyModel[] = customerHooks.useAll()
  const scaffolder: CompanyModel[] = scaffolderHooks.useAll()
  return useMemo(()=>customers.concat(scaffolder),[scaffolder,customers])
}

export const useMyCompany = ():CompanyModel|undefined => {
  const companies = useCompanies()
  const user = useCurrentUser()
  return useMemo(()=>companies?.find(x=>x.id === user?.companyId),[user,companies])
}
export const useMyCompanyType = () => {
  const company = useMyCompany()
  return company?.type
}

