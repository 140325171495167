import { BaseModel } from 'api/baseModel'
import { useDefaultButtonEventHandlerDispatchParameterAction } from 'framework/lib/useDispatchAction'
import AsyncActionButton, { IAsyncActionButtonProps } from 'framework/components/AsyncActionButton'
import { MenuItemProps } from '@mui/material'
import { AppThunk } from 'app/store'

interface IProps<T extends BaseModel> extends Omit<IAsyncActionButtonProps, 'onClick'>{
  item:T,
  name: string
  action: (...randomParameters:any)=>AppThunk
}

export function AsyncStoreActionButton<T extends BaseModel>({ item, name, action, ...rest }: IProps<T>) {
  const actionHandler = useDefaultButtonEventHandlerDispatchParameterAction(action)
  return (
    <AsyncActionButton onClick={actionHandler(item)} {...rest}>
      {name}
    </AsyncActionButton>
  )
}
