import { Dropzone, IBaseElementProps } from '@codeflix/mui-managed-form'
import { useTranslation } from 'react-i18next'
import { MouseEvent, useCallback, useId } from 'react'
import { Grid } from '@mui/material'
import PreviewAttachment from 'framework/components/ManagedForm/Fields/ImageEditor/ImageWithLocationPointer/PreviewAttachment'

interface IProps extends IBaseElementProps {
  onDelete?: (event: MouseEvent<HTMLElement>) => void
}

export default function LocationPointEditorWithUpload({ valid, setValid, name, highlightErrorsAlways, disabled, value, onChange, debug, disableAll, loading }: IProps) {
  const { t: dropzoneT } = useTranslation('dropzone')
  const id = useId()
  const handleChange = useCallback((val: any) => {
    onChange(val)
  }, [])
  const handleDrop = useCallback((innerval: any) => {
    // setImage(innerval)
    onChange({ path: ['file'], value: innerval })
  }, [onChange])
  return (
    <div>
      <Grid container direction="row" spacing={1}>
        {value?.file
          ? (
            <Grid item xs={12} >
              <PreviewAttachment
                image={value?.image}
                file={value?.file}
                onChange={handleChange}
                value={value}
                disabled={disableAll || disabled}
              />

            </Grid>
          )
          : (
            <Grid item xs={12}>
              <Dropzone
                t={dropzoneT}
                onChange={handleDrop}
                name="upload"
                id={id}
                accept={{
                  'application/pdf': ['.pdf'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpeg', '.jpg'],
                }}
                maxSize={10 * 1024 * 1024}
                setValid={setValid}
                valid={valid}
                debug={debug}
                highlightErrorsAlways={highlightErrorsAlways}
                value={null}
                loading={false}
              />
            </Grid>
)}
        {/* <Grid item xs={12}><Dropzone t={dropzoneT} onChange={setImage} name="upload" id={id} /></Grid> */}
      </Grid>
    </div>

  )
}
