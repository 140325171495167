import { useCallback } from 'react'
import { ScaffoldAgentPhase, ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { numberOrFallback } from 'lib/numberHelper'
import ScaffoldInquiryRestModel from 'api/scaffoldInquiriesAttachments'
import { IImageInputValue } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IImageInputValue'

interface IAttachment extends IImageInputValue {
  id?: string
  isNew?: boolean
  changed?: boolean
}
const uploadAttachments = async (item: ScaffoldInquiryModel, attachments: IAttachment[]) => {
  // const changed, unchanged, deleted, create } = arrayDiff(item.attachments, attachments)
  const deleted = item.attachments?.filter((x) => !attachments.some((y) => y.id === x.id))
  if (!attachments) return

  for (let i = 0; i < attachments.length; i += 1) {
    const attachment = attachments[i]
    if (attachment.isNew) {
      // eslint-disable-next-line no-await-in-loop
      const result = await ScaffoldInquiryRestModel.createItem({
        file: attachment.file,
        image: attachment.image,
        pin: attachment.pin,
        text: attachment.text,
        customerId: item.customerId!,
        scaffoldInquiryId: item?.id,
        // isNew: true,
        // name: attachment.file.name,
      })
    }
    // if()
    // await Thread.sleep(2)
  }
}
export const useScaffoldInquirySubmit = (item?: ScaffoldInquiryModel) => {
  const test1 = 'hallo'
  return useCallback((mode: 'submit' | 'draft') => async (submitValues: any) => {
    const additionalPayload: any = {

    }

    switch (submitValues.agentPhase) {
      case ScaffoldAgentPhase.SCAFFOLDER:
        if (mode === 'submit') additionalPayload.agentPhase = ScaffoldAgentPhase.FULLY_SIGNED
        break
      case ScaffoldAgentPhase.GDK:
        if (mode === 'submit') {
          additionalPayload.agentPhase = ScaffoldAgentPhase.SCAFFOLDER
          additionalPayload.rentFreeInDays = numberOrFallback(submitValues.rentFreeInDays, 28)
        }
        break

      case ScaffoldAgentPhase.CLIENT:
      default:
        // eslint-disable-next-line no-case-declarations
        if (mode === 'submit') {
          additionalPayload.clientWorkflowPhase = numberOrFallback(item!.clientWorkflowPhase, 0) + 1
        }
        break
    }
    // if (item) await uploadAttachments(submitValues, submitValues.attachments)

    return {
      ...submitValues,
      ...additionalPayload,
      // agentPhase: ScaffoldAgentPhase.SCAFFOLDER,
    }
  }, [item])
}
