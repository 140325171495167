import { IBaseModel } from 'api/baseModel'
import { RestModel } from 'app/RestModel'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { BaseModelReducer, BaseRestReducerActions, IBaseRestState } from 'features/base/BaseRestFeature'
import { RestViewMode } from 'framework/components/BaseRestViews/RestViewMode'

const createBaseRestSelectors = <T extends IBaseModel>(name: string, additionalSelectors:any) => ({
  all: () => (state: RootState) => ((state as any)[name] as IBaseRestState<T>).data,
  byId: (id?: string) => (state: RootState) => ((state as any)[name] as IBaseRestState<T>).data.find((x) => x.id === id),
  ...additionalSelectors,
})

const baseRestHooks = <T extends IBaseModel>(selectors: any, additionalHooks: any) => {
  const useById = <T2 extends IBaseModel>(id?: string): T2 | undefined => useSelector(selectors.byId(id)) as T2|undefined
  return {
    useById,
    useAll: () => useSelector(selectors.all()) as T[],
    useByPathKey: (key: string) => {
      const params = useParams() as any
      const id = params[key]
      return useById(id)
    },
    ...additionalHooks,
  }
}

export default function createBaseRestSlice<T extends IBaseModel>(name: string, initialState: IBaseRestState<T>, reducers: any, restModel: RestModel<T>) {
  const baseReducerActions = BaseModelReducer(initialState)
  const index = createSlice({
    name,
    initialState,
    reducers: {
      ...baseReducerActions,
      ...reducers,
    },
  })
  const actions = BaseRestReducerActions(name, restModel, index)
  const selectors = {
    all: () => (state: RootState) => ((state as any)[name] as IBaseRestState<T>).data,
    filtered: (predicate: (value: T, index: number, array: T[]) => value is T, thisArg?: any): (state: RootState) => T[] | undefined => (state: RootState) => ((state as any)[name] as IBaseRestState<T>).data?.filter<T>(predicate, thisArg),
    archive: (year: string) => (state:RootState) => ((state as any)[name] as IBaseRestState<T>).archive[year],
    archiveById: (year: string, id?: string) => (state:RootState) => ((state as any)[name] as IBaseRestState<T>).archive[year]?.find((x) => x.id === id),
    byId: (id?: string) => (state: RootState) => ((state as any)[name] as IBaseRestState<T>).data.find((x) => x.id === id),
    // ...additionalSelectors,
  }
  const useById = (id?: string): T | undefined => useSelector(selectors.byId(id)) as T|undefined
  const hooks = {
    useById,
    useAll: () => useSelector(selectors.all()) as T[],
    useFiltered: (predicate: (value: T, index: number, array: T[]) => value is T, thisArg?: any): (state: RootState) => T[] | undefined => useSelector(selectors.filtered(predicate, thisArg)),
    useByIdAndMode: (id:string|undefined, mode: RestViewMode) => {
      const { year } = useParams<{year:string}>()
      const curSelector = (mode === RestViewMode.Archive && year) ? selectors.archiveById(year, id) : selectors.byId(id)
      return useSelector(curSelector)
    },
    useArchiveData: (year: string) => useSelector(selectors.archive(year)) as T[],
    useArchiveDataById: (year: string, id: string) => useSelector(selectors.archiveById(year, id)),
    useByPathKey: (key: string) => {
      const params = useParams() as any
      const id = params[key]
      return useById(id)
    },
    // ...additionalHooks,
  }
  return {
    index,
    actions,
    selectors,
    hooks,
  }
}
