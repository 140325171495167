// export const LookupAccessorFn = <T extends BaseModel>(possibleValues: IBaseModel[], path?: string|string[], propName?: string, idPropName?: string) => (row:any) => {
//   const resolvedValue = resolvePathRecursive(row, path)
//   if (resolvedValue) return resolvedValue
//   const searchPropName = idPropName || `${propName}Id`
//   const tryfind = possibleValues.find((x) => x.id === row[searchPropName])
//   return tryfind as T
// }
import { BaseModel, IBaseModel } from 'api/baseModel'
import { resolvePathRecursive } from 'framework/lib/resolvePathRecursive'

export const LookupAccessorFn = <T extends BaseModel>(possibleValues: IBaseModel[], path?: string | string[], propName?: string, idPropName?: string, rowProp?: keyof T) => (row: any) => {
  const resolvedValue = resolvePathRecursive(row, path)
  if (resolvedValue) {
    if (rowProp) {
      return (resolvedValue as T)[rowProp]
    }
    return resolvedValue as T
  }
  const searchPropName = idPropName || `${propName}Id`
  const tryfind = possibleValues.find((x) => x.id === row[searchPropName])
  if (rowProp) {
    return (tryfind as T)?.[rowProp]
  }
  return tryfind as T
}
