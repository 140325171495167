import MapPin from 'framework/components/ManagedForm/Fields/ImageEditor/miscellaneous/MapPin.svg'
import { Tooltip } from '@mui/material'
import * as React from 'react'
import { createRef, DragEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useImage from 'use-image'
import { numberOrFallback } from 'lib/numberHelper'

interface IProps {
  zoom: number
}

const useImageBitmapResizedFromRef = (zoom: number = 1) => {
  const [resized, setResized] = useState<HTMLImageElement>()
  const [mapPin] = useImage(MapPin)
  // const { width } = ref
  // const { height } = ref
  useEffect(() => {
    (async () => {
      if (!mapPin) return
      const newWidth = 90.867798 * zoom
      const newHeight = 125.9584 * zoom

      const bmp = await createImageBitmap(mapPin, { resizeWidth: newWidth, resizeHeight: newHeight })
      const canvas = document.createElement('canvas')
      canvas.getContext('2d')?.drawImage(bmp, 0, 0)
      const img = new Image(newWidth, newHeight)
      await new Promise((resolve) => {
        canvas.toBlob((blob) => {
          img.src = URL.createObjectURL(blob!)
          resolve(img.decode())
        })
      })
      setResized(img)
    })()
  }, [setResized, zoom, mapPin])
  return resized
}
export default function DraggablePin({ zoom }: IProps) {
  const { t } = useTranslation('imageInput')
  const imgRef = createRef<HTMLImageElement>()
  const resizedImg = useImageBitmapResizedFromRef(zoom)

  const handleDragStart = useCallback((e:DragEvent<HTMLImageElement>) => {
    e.dataTransfer.setData('dragType', 'location')
    if (resizedImg) e.dataTransfer.setDragImage(resizedImg, (90.867798) / 2, (125.9584) / 2)
    // e.preventDefault()
  }, [resizedImg])
  return (
    // <Tooltip arrow placement="bottom" title={t('locationPinTooltip')}>
    <img
      ref={imgRef}
      alt="locationPin"
      height={125.9584}
      width={90.867798}
      draggable="true"
      onDragStart={handleDragStart}
      src={MapPin}
    />
    // </Tooltip>
  )
}
