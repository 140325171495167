import { RestModel } from 'app/RestModel'
import { api } from 'app/apiClient'
import { UserModel } from './models/UserModel'

const getUsersInMyCompany = async () => await api.get('users/mycompany').json()

class UsersRestModel extends RestModel<UserModel> {
  path = 'Users'

  getItems = async (parameters: any, permissions: string[]|undefined) => {
    const fetchAllUsers = permissions?.includes('VIEW_USERS')
    return super.getItems(parameters, permissions, fetchAllUsers)
  }

  getUsersInMyCompany = getUsersInMyCompany
}

export default new UsersRestModel()
