import { Fab, styled, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ImageInputWithPinSizeWrapper
  from 'framework/components/ManagedForm/Fields/ImageEditor/ImageWithLocationPointer/ImageInputWithPin'
import React, { MouseEvent, useCallback, useRef } from 'react'
import { useOpenState } from 'lib/stateHelper'
import {
  ImageLoad,
  useImageSrcFromBlob,
} from 'framework/components/ManagedForm/Fields/ImageEditor/libs/UseImageSrcFromBlob'
import Center from 'framework/components/Center'
import { Draw } from 'mdi-material-ui'
import PreviewImage from 'framework/components/ManagedForm/Fields/ImageEditor/PreviewImage'
import { IImageInputValue } from 'framework/components/ManagedForm/Fields/ImageEditor/types/IImageInputValue'

interface IProps {
  onChange: (value: any) => Promise<void> | void
  value: IImageInputValue,
  disabled?: boolean,
  file: ImageLoad
  image: ImageLoad
}

const PositionedEditButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}))
const PositionRelativeDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}))

export default function PreviewAttachment({ file, image, value, onChange, ...rest }: IProps) {
  const { open, handleOpen, toggle, handleClose } = useOpenState()

  // const { values, handleChange } = useManagedValue(value, true)
  const editRef = useRef<{ exportImage:() => Promise<void> }>()
  const handleEditButtonClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onChange({ path: ['changed'], value: true })
    toggle()
  }, [toggle, onChange])
  const { imageSrcUrl, bitmap, originalType } = useImageSrcFromBlob(file)
  const { imageSrcUrl: previewImageSrcUrl } = useImageSrcFromBlob(image)

  // const handleDownloadOriginal

  const { t } = useTranslation('attachments')

  const previewImgSrcUrl = previewImageSrcUrl || imageSrcUrl
  return (
    <>
      {(!open && imageSrcUrl) && (
        <Tooltip arrow title={originalType && t(originalType).toString()}>
          <PositionedEditButton
            color="primary"
            aria-label={t('edit').toString()}
            size="large"
            onClick={handleEditButtonClick}
          >
            <Draw />
          </PositionedEditButton>
        </Tooltip>

      )}
      {/* {open && ( */}
      {/*  <PositionedEditButton */}
      {/*    color="primary" */}
      {/*    aria-label={t('save').toString()} */}
      {/*    size="large" */}
      {/*    onClick={handleSave} */}
      {/*  > */}
      {/*    <Check /> */}
      {/*  </PositionedEditButton> */}
      {/* )} */}
      <PositionRelativeDiv>
        {open
          ? (
            <ImageInputWithPinSizeWrapper
              ref={editRef}
              value={value}
              onChange={onChange}
              {...rest}
              imageSrcUrl={imageSrcUrl!}
              imageBitmap={bitmap!}
            />
)

          : (
            <Center>
              <PreviewImage imageSrcUrl={previewImgSrcUrl} />
            </Center>
        )}
      </PositionRelativeDiv>

    </>

  )
}
