import { ReactElement } from 'react'
import { Tooltip } from '@mui/material'

interface IProps {
  tooltip?: string
  children: ReactElement
}
export default function OptionalTooltip(props : IProps) {
  const { tooltip, children } = props
  if (tooltip) {
    return (
      <Tooltip arrow title={tooltip}>
        <div>
          {children}
        </div>
      </Tooltip>
    )
  }
  return children
}
