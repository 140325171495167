/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-underscore-dangle */
import { MRT_RowData, MRT_TableInstance } from 'material-react-table'
import { MRT_Header } from 'material-react-table/src'
import { Chip } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'

interface IFilterLabelProps<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>,
  header: MRT_Header<TData>
  onFilteredChange : (isFiltered: boolean) => void
}

export default function FilterLabel<TData extends MRT_RowData>({ table, header, onFilteredChange }: IFilterLabelProps<TData>) {
  const { column } = header
  const { columnDef } = column

  const filterValue = column.getFilterValue()

  const isFilterActive = useMemo(() => (Array.isArray(filterValue) && filterValue.some(Boolean))
    || (!!filterValue && !Array.isArray(filterValue)), [filterValue])

  // const isRangeFilter = columnDef.filterVariant?.includes('range')
  //   || ['between', 'betweenInclusive', 'inNumberRange'].includes(
  //     columnDef._filterFn,
  //   )
  const handleResetFilter = useCallback(() => {
    column.setFilterValue(undefined)
  }, [column])
  useEffect(() => {
    onFilteredChange(isFilterActive)
    return () => onFilteredChange(false)
  }, [isFilterActive])
  if (!isFilterActive) return null
  return (
    <Chip label={columnDef.header} onDelete={handleResetFilter} />
  )
}
