import { RestModel } from 'app/RestModel'
import { IScaffoldInquiryAttachment, IScaffoldInquiryRequestAttachment } from 'api/models/scaffoldInquiryAttachment'
import { jsonToFormData } from 'api/helper/formData'
import { ScaffoldInquiryModel } from './models/scaffoldModel'
import { api } from '../app/apiClient'

const clearPayload = (payload: ScaffoldInquiryModel|IScaffoldInquiryAttachment|IScaffoldInquiryRequestAttachment) => ({
  ...payload,
  // clientWorkflowSteps: undefined,
  uploader: undefined,
})
class ScaffoldInquiriesAttachmentRestModel extends RestModel<any> {
  path = 'scaffoldinquiriesattachment'

  async getItems(parameters:any, permissions?: string[], fetchAll: boolean = false): Promise<IScaffoldInquiryAttachment[]> {
    console.log('api', 'paramaeters', parameters)
    if (!parameters.scaffoldInquiryId) return []
    return api.get(`${this.path}/${parameters.scaffoldInquiryId}`).json()
  }

  async getItemsById(scaffoldInquiryId: string) : Promise<IScaffoldInquiryAttachment[]> {
    return api.get(`${this.path}/${scaffoldInquiryId}`).json()
  }

  getDownloadUrl = (id: string, mode: 'file'|'image'|'thumbnail') => `${this.path}/${id}/${mode}`

  getDownload = async (id: string, mode: 'file'|'image'|'thumbnail') => api.get(this.getDownloadUrl(id, mode)).blob()

  getFileBlobById = async (id: string) => api.get(`${this.path}/${id}/file`).blob()

  getFileBlob = async (payload: IScaffoldInquiryAttachment) => this.getFileBlobById(payload.id)

  getImageBlobById = async (id: string) => api.get(`${this.path}/${id}/image`).blob()

  getImageBlob = async (payload: IScaffoldInquiryAttachment) => this.getImageBlobById(payload.id)

  async updateItem(payload: IScaffoldInquiryRequestAttachment): Promise<IScaffoldInquiryAttachment> {
    const body = jsonToFormData({
      ...clearPayload(payload),
      file: undefined,
    })
    return api.put(`${this.path}`, { body, timeout: false }).json()
  }

  async createItem(payload: IScaffoldInquiryRequestAttachment): Promise<unknown> {
    const body = jsonToFormData({
      ...clearPayload(payload),
      id: undefined,
    })

    return api.post(`${this.path}`, { body, timeout: false }).json()
  }

  async createOrUpdateItem(payload: IScaffoldInquiryRequestAttachment): Promise<unknown> {
    // const body = jsonToFormData({
    //   ...clearPayload((payload)),
    //   id: payload.isNew ? undefined : payload.id,
    //   file: payload.isNew ? payload.file : undefined,
    // })
    return (payload.isNew) ? this.createItem(payload) : this.updateItem(payload)
  }
}

export default new ScaffoldInquiriesAttachmentRestModel()
