import { Backdrop, Typography } from '@mui/material'
import { useManagedForm, ValidatedForm } from '@codeflix/mui-managed-form'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Center from 'framework/components/Center'
import { requestAccount } from 'features/user'
import { useAppDispatch } from 'app/hooks'
import { styled } from '@mui/system'
import LoginFormsWrapper from 'ui/views/Login/LoginFormsWrapper'
import ButtonLink from 'framework/components/ButtonLink'
import { ChevronLeft } from '@mui/icons-material'
import { AsyncHandlerLoadingState, useAsyncHandler } from 'framework/lib/useAsyncHandler'

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}))
export default function RequestAccount() {
  const appDispatch = useAppDispatch()
  const { values, formManagerProps } = useManagedForm({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
  })
  // const [requested, setLoading] = useState(false)
  const [state, submitHandler] = useAsyncHandler(() => appDispatch(requestAccount(values)) as Promise<boolean>)
  const { t } = useTranslation('requestAccount')
  return (
    <LoginFormsWrapper backdropOpen={state == AsyncHandlerLoadingState.Loading}>
      <ButtonLink variant="text" to="/"><ChevronLeft />{t('backToLogin')}</ButtonLink>

      {
        state === AsyncHandlerLoadingState.Success
          ? (
            <>
              <Center><Typography component="h2" variant="h3">{t('successTitle')}</Typography></Center>
              <Center><Typography>{t('success')}</Typography></Center>
            </>
)
          : (
            <>
              <Center><Typography component="h2" variant="h3">{t('title')}</Typography></Center>
              <ValidatedForm
                {...formManagerProps}
                onSubmit={submitHandler}
                elements={[
              {
                type: 'text',
                name: 'firstName',
                required: true,
                grid: { xs: 12, md: 6 },
              },
              {
                type: 'text',
                name: 'lastName',
                required: true,
                grid: { xs: 12, md: 6 },
              },
              {
                type: 'text',
                name: 'email',
                required: true,
              },
              {
                type: 'text',
                name: 'phoneNumber',
                required: true,
              },
              {
                type: 'password',
                name: 'password',
                details: true,
                required: true,
              },
              {
                type: 'password',
                name: 'comparePassword',
                required: true,
                details: true,
                conditionalProps: (element:any, value:any) => ({
                  comparePassword: value.password,
                }),
              },
              {
                type: 'submit',
                name: 'Antrag abschicken',
                variant: 'contained',
              },
            ]}
                t={t}
              />
            </>
)
      }

    </LoginFormsWrapper>
  )
}
