export declare type EnhancedOmit<TRecordOrUnion, KeyUnion> = string extends keyof TRecordOrUnion ? TRecordOrUnion : TRecordOrUnion extends any ? Pick<TRecordOrUnion, Exclude<keyof TRecordOrUnion, KeyUnion>> : never;

export declare type OptionalId<TSchema> = EnhancedOmit<TSchema, 'id'> & {
  _id?: string;
};

export interface IUpdateResult {
  isAcknowledged: boolean
  matchedCount: number
  modifiedCount: number,
  upsertedId: string,
}

export interface IBaseModel {
  id: string,
  isNew: boolean,
  createdOn?: string
  modifiedOn?: string
  archived?: boolean
}
export interface IBaseSortedModel extends IBaseModel {
  sortPosition: number
}
export class BaseModel {
  id: string

  isNew: boolean = true

  createdOn: string

  modifiedOn: string

  constructor() {
    this.id = new Date().getTime().toString()
    this.isNew = true
    this.createdOn = new Date().toISOString()
    this.modifiedOn = new Date().toISOString()
  }
}
export interface INamedBaseModel extends IBaseModel{
  name: string
}
export class UserNameBaseModel extends BaseModel {
  firstName: string

  lastName: string

  email: string

  tags: string[] = []

  get name(): string {
    return `${this.firstName} ${this.lastName} (${this.email})`
  }

  constructor(firstName: string, lastName: string, email:string) {
    super()
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
  }
}
export class NamedBaseModel extends BaseModel {
  name: string

  constructor() {
    super()
    this.name = ''
  }
}
export class NamedDescriptionBaseModel extends BaseModel {
  name: string = ''

  description: string = ''
}

export interface INamedCustomerBaseModel extends INamedBaseModel{
  customerId?: string
}
export class NamedCustomerBaseModel extends NamedBaseModel {
  customerId?: string

  constructor(customerId?: string) {
    super()
    this.customerId = customerId
  }
}
export type OptionalCustomerId<T extends NamedCustomerBaseModel> = EnhancedOmit<T, 'customerId'> & {
  customerId?: string;
}
export class NamedCustomerDescriptionBaseModel extends NamedCustomerBaseModel {
  name: string = ''

  description: string = ''
}
