import Grid2 from '@mui/material/Unstable_Grid2'
import CurrentScaffoldsByCustomer from 'ui/views/Dashboard/metrics/CurrentScaffoldsByCustomer'
import CurrentScaffoldsByOperationUnit from 'ui/views/Dashboard/metrics/CurrentScaffoldsByOperationUnit'
import { Stack } from '@mui/material'
import CountMetrics from 'ui/views/Dashboard/components/CountMetrics'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import ScaffoldTimeDiffConstruction from 'ui/views/Dashboard/metrics/ScaffoldTimeDiffConstruction'
import ScaffoldTimeDiffDeconstruction from 'ui/views/Dashboard/metrics/ScaffoldTimeDiffDeconstruction'
import {
  CountOfCancelledButNotDeconstructedScaffolds,
  CountOfCancelledScaffolds, CountOfNoSiteMeasuring,
  CountOfNotAccounted, CountOfNotCheckedSiteMeasuring,
  CountOfNotConstructedScaffolds,
  CountOfOpenScaffolds, CountOfOpenSiteMeasuringsForGdk, GetScaffoldsWithRent, ScaffoldsInPlanningCount,
} from 'ui/views/Dashboard/metrics/Counts'
import OptionalPermissionView from 'framework/auth/OptionalPermissionView'
import { isOnlyCustomerPermissionRequirement } from 'ui/userRoles/IsOnlyCustomerPermissionRequirement'
import { isOnlyGDKPermissionRequirement } from 'ui/userRoles/isOnlyGDKPermissionRequirement'
import { isOnlyScaffolderPermissionRequirement } from 'ui/userRoles/isOnlyScaffolderPermissionRequirement'
import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useScaffoldInquiries } from 'features/scaffoldInquiries'
import { DashboardTablePortal } from 'ui/views/Dashboard/components/DashboardTablePortal'
import { styled } from '@mui/system'
import { IOpenState } from '@codeflix/mui-managed-form'
import { useSingleOpenState } from 'ui/views/Dashboard/lib/useSingleOpenState'

function CountGrid({ children }: PropsWithChildren) {
  return <Grid2 xs={12} sm={6} md={4} lg={2} xl={12}>{children}</Grid2>
}
const EmptyHiddenGrid2 = styled(Grid2)(({ theme }) => ({
  '&:empty': {
    display: 'none',
  },
}))

export default function Dashboard() {
  const { t } = useTranslation('dashboard')
  const scaffolds = useScaffoldInquiries()
  const [openByKey, open] = useSingleOpenState()
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} xl={2}>
          <div>
            <Grid2 container spacing={2}>
              <OptionalPermissionView
                permissions={isOnlyCustomerPermissionRequirement}
              >
                <CountGrid>
                  <CountGrid><GetScaffoldsWithRent {...openByKey('inRent')} /></CountGrid>
                  <CountGrid><ScaffoldsInPlanningCount {...openByKey('inplanning')} /></CountGrid>
                </CountGrid>
              </OptionalPermissionView>
              <OptionalPermissionView
                permissions={isOnlyGDKPermissionRequirement}
              >
                <CountGrid><CountOfOpenScaffolds {...openByKey('open')} /></CountGrid>
                <CountGrid><CountOfNotConstructedScaffolds {...openByKey('notConstructed')} /></CountGrid>
                <CountGrid><CountOfCancelledButNotDeconstructedScaffolds {...openByKey('notDeconstructed')} /></CountGrid>
                {/* <CountGrid><CountOfOpenSiteMeasuringsForGdk {...openByKey('openSiteMeasurings')} /></CountGrid> */}
                <CountGrid><CountOfNotCheckedSiteMeasuring {...openByKey('countOfNotCheckedSiteMeasuring')} /></CountGrid>
                <CountGrid><CountOfNotAccounted {...openByKey('countOfNotAccounted')} /></CountGrid>

              </OptionalPermissionView>
              <OptionalPermissionView
                permissions={isOnlyScaffolderPermissionRequirement}
              >
                <CountGrid><CountOfOpenScaffolds {...openByKey('open')} /></CountGrid>
                <CountGrid><CountOfCancelledScaffolds {...openByKey('cancelledScaffolds')} /></CountGrid>
                <CountGrid><CountOfCancelledButNotDeconstructedScaffolds {...openByKey('countOfNotDeconstructed')} /></CountGrid>
                <CountGrid><CountOfNotConstructedScaffolds {...openByKey('countOfNotConstructed')} /></CountGrid>
                <CountGrid><CountOfNoSiteMeasuring {...openByKey('countOfNoSiteMeasurings')} /></CountGrid>
                <CountGrid><CountOfNotAccounted {...openByKey('countOfNotAccounted')} /></CountGrid>
              </OptionalPermissionView>

            </Grid2>
          </div>
        </Grid2>
        <EmptyHiddenGrid2 xs={12} xl={10} id="dashboardTable" />
        {!open && (
          <Grid2 xs={12} xl={10}>
            <div>
              <Grid2 container spacing={2} alignItems="flex-start">
                <Grid2 xs={12} xl={6}>
                  <Stack spacing={2}>
                    <CurrentScaffoldsByCustomer />
                    <CurrentScaffoldsByOperationUnit />
                  </Stack>
                </Grid2>
                <Grid2 xs={12} xl={6}>
                  <Stack spacing={2}>
                    <ScaffoldTimeDiffConstruction />
                    <ScaffoldTimeDiffDeconstruction />
                  </Stack>
                </Grid2>
              </Grid2>
            </div>
          </Grid2>
        )}

      </Grid2>
    </Box>
  )
}
