/* eslint-disable */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AppThunk, RootState, store} from 'app/store'
import {UserModel} from "api/models/UserModel";
import UserRestModel from "api/user";
import LoadStateModel from "../framework/LoadStateModel";
import {useSelector} from "react-redux";
import loginApi from "api/login";
import {saveToken} from "features/auth";
import i18n from "i18next";
import {displayNotification} from "features/notifications";
import {responseAction} from "features/lib/defaultApiActions";

interface IState {
  user: UserModel | undefined
  loadState: LoadStateModel
}

const initialState: IState = {
  user: undefined,
  loadState: LoadStateModel.INITIAL
}
export const index = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUser: (state, action: PayloadAction<UserModel | undefined>) => {
      if (action) {
        return {
          ...state,
          user: action.payload,
          loadState: LoadStateModel.LOADED
        }
      } else return {...initialState}
    },
    resetUser: (state, action: PayloadAction<undefined>) => {
      return {...initialState}
    }
  },

})

const {setUser, resetUser} = index.actions

export const fetchData = (): AppThunk => async (dispatch, getState) => {
  try {
    const user = await UserRestModel.getUser()
    if (user) {
      dispatch(setUser(user as UserModel))

    }
  } catch (e) {
    // do nothing
  }

}
export const removeUser = (): AppThunk => async (dispatch) => {
  dispatch(resetUser())
}
export const actions = {
  get: fetchData,
  reset: removeUser
}
export const getUser = (state: RootState) => state.user.user
export const userLoaded = (state: RootState) => state.user.loadState === LoadStateModel.LOADED

export const useCurrentUser = () => {
  return useSelector(getUser)
}


export const loginUser = (email: string, password: string): AppThunk => async (dispatch) => {
  // await loginApi.login(values.email, values.password)
  try {
    const result = await loginApi.login(email, password)
    if (result.status !== 200) return false
    const jsonRes = await result.json()
    const {token, expires} = jsonRes as { token: string, expires: string }
    dispatch(saveToken(token, expires))
    dispatch(fetchData)

    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
export const requestAccount = (values: {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  phoneNumber: string
}): AppThunk => async (dispatch) => {

  const result = await dispatch(responseAction<boolean>(
    `login:accountRequestSubmitted`,
    loginApi.requestAccount(values)
  ));
  return result.success && result.json
}
export const requestResetPassword = (email: string): AppThunk => async (dispatch) => {
  // await loginApi.login(values.email, values.password)
  try {
    const result = await loginApi.requestResetPassword(email)
    const success = result.status === 204
    if (success) {
      const message = i18n.t(`login:requestAccountSuccessful`)
      const snackbar = displayNotification(message, {variant: 'success', autoClose: true})
      dispatch(snackbar)
    }
    return success
  } catch (e) {
    console.error(e)
    return false
  }
}
export const resetPassword = (email: string, password: string, token: string): AppThunk => async (dispatch) => {
  // await loginApi.login(values.email, values.password)
  try {
    const result = await loginApi.resetPassword(email, password, token)
    const success = result.status === 204
    if (success) {
      const message = i18n.t(`login:resetPasswordSuccessful`)
      const snackbar = displayNotification(message, {variant: 'success', autoClose: true})
      dispatch(snackbar)
    }
    return success
  } catch (e) {
    console.error(e)
    return false
  }
}

export const activateAccount = (email: string, password: string, token: string): AppThunk =>
  async (dispatch) => {
    const result = await dispatch(responseAction<{ token: string, expires: string }>(
      `login:accountActivationSuccessful`,
      loginApi.activateAccount(email, password, token)
    ));
    if (result.success) {
      const {token, expires} = result.json!
      dispatch(saveToken(token, expires))
      dispatch(fetchData)
    }
    return result.success
  }


export const confirmEmail = (email: string, token: string): AppThunk => async (dispatch) => {
  const result = await dispatch(responseAction<boolean>(undefined, loginApi.confirmEmail({
    email,
    token
  })))
  if(result.json) {
    dispatch(displayNotification(i18n.t('login:emailConfirmed'), { variant: 'success', autoClose: true }))
  }
  return result.json;

}


export const changePassword = (password: string, newPassword: string): AppThunk => async (dispatch) => {
  // await loginApi.login(values.email, values.password)
  try {
    const result = await loginApi.changePassword(password, newPassword)
    const success = result.status === 204
    if (success) {
      const message = i18n.t(`login:passwordChangedSuccessful`)
      const snackbar = displayNotification(message, {variant: 'success', autoClose: true})
      dispatch(snackbar)
    }
    return success
  } catch (e) {
    console.error(e)
    return false
  }
}
export default index.reducer
