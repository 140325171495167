import { RestModel } from 'app/RestModel'
import { BuildingModel } from './models/buildingModel'

class BuildingsRestModel extends RestModel<BuildingModel> {
  path = 'building'

  getItems = async (parameters: any, permissions: string[]|undefined) => {
    const fetchAllUsers = permissions?.includes('VIEW_ALL_CUSTOMERS')

    return super.getItems(parameters, permissions, fetchAllUsers)
  }
}

export default new BuildingsRestModel()
