import { SiteMeasuringModel } from 'api/models/siteMeasuringModel'
import { useUserHasAllPermissions, useUserHasPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'
import { ManagedForm, useManagedForm, ValidatedForm } from '@codeflix/mui-managed-form'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useDispatchAction, useDispatchParameterAction } from 'framework/lib/useDispatchAction'
import { setGdkNoteInAdditionalProperties } from 'features/siteMeasurings'

interface ISiteMeasuringGdkCommentProps {
  item?: SiteMeasuringModel
}

export default function SiteMeasuringGdkComment({ item }: ISiteMeasuringGdkCommentProps) {
  const { t } = useTranslation('siteMeasuringComment')
  const edit = useUserHasAllPermissions([PermissionModel.SITE_MEASURING_ADDITIONAL_PROPERTIES_EDIT_GDK_NOTE])
  const { formManagerProps, values } = useManagedForm(item?.additionalProperties, true)
  const actionHandler = useDispatchParameterAction(setGdkNoteInAdditionalProperties)
  const handleSubmit = useCallback(
    () => actionHandler({ id: item?.id, gdkNote: values.gdkNote }),
    [actionHandler, values],
  )
  return (
    <ValidatedForm
      {...formManagerProps}
      disableAll={!edit}
      grid={{ spacing: 0, rowSpacing: 1 }}
      elements={[

        { grid: { xs: 12 }, type: 'multiline', name: 'gdkNote' },
        {
          grid: { xs: 12 },
          type: edit ? 'submit' : 'hidden',
          variant: 'contained',
          name: 'save',
        },

      ]}
      onSubmit={handleSubmit}
      t={t}
    />

  )
}
