import { RestModel } from 'app/RestModel'
import { api } from 'app/apiClient'
import { UserModel } from './models/UserModel'

const login = async (payload: any) => api.post('Login', { json: payload }).json()

const getUser = async () => api.get('User').json()

const changePassword = async (password: string, newPassword: string) => api.post('User/ChangePassword', {
  json: {
    password,
    newPassword,
  },
})

class UserRestModel extends RestModel<UserModel> {
  path = 'User'

  login = login

  getUser = getUser

  changePassword = changePassword
}

export default new UserRestModel()
