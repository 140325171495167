import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { actions, useSiteMeasuringTemplate } from 'features/siteMeasuringTemplates'
import { useParams } from 'react-router'
import EditView from 'framework/components/BaseRestViews/EditView'
import {
  SiteMeasuringAccountingFieldGroups,
  SiteMeasuringAccountingGroupField,
  SiteMeasuringAccountingTabs,
  SiteMeasuringTemplateModel,
} from 'api/models/siteMeasuringTemplateModel'
import { Button, Grid } from '@mui/material'
import { useOpenState } from 'lib/stateHelper'
import SiteMeasuringPreview from 'ui/views/Management/SiteMeasuringTemplates/SiteMeasuringPreview'
import { useManagedForm } from '@codeflix/mui-managed-form'

const grid = { xs: 12, sm: 6, md: 3 }
const smallGrid = { xs: 6, sm: 3, md: 2 }

function Editor({ item }: {item?: SiteMeasuringTemplateModel }) {
  const { t } = useTranslation('siteMeasuringTemplates')
  const elements = useMemo(() => ([
    {
      type: 'text',
      name: 'name',
      required: true,
    },
    {
      type: 'list',
      name: 'tabs',
      BaseItem: SiteMeasuringAccountingTabs,
      variant: 'borderbox',
      elements: [
        {
          type: 'text',
          name: 'name',
          required: true,
        },
        {
          type: 'list',
          name: 'groups',
          BaseItem: SiteMeasuringAccountingFieldGroups,
          // variant: 'borderbox',
          elements: [
            {
              type: 'text',
              name: 'name',
              // grid: { xs: 12, sm: 6, md: 2 },
              // label: t('groupName'),
            },
            {
              type: 'list',
              name: 'fields',
              // grid: { xs: 12, md: 10 },
              BaseItem: SiteMeasuringAccountingGroupField,
              elements: [
                {
                  type: 'text',
                  name: 'shortName',
                  // checkValue: isValidTranslated,
                  grid: { xs: 12, xl: 4 },
                  required: true,
                },
                {
                  type: 'text',
                  name: 'fullName',
                  tooltip: t('fullNameTooltip'),
                  grid: { xs: 12, xl: 8 },
                  // required: true,
                },

                {
                  type: 'autocomplete',
                  name: 'type',
                  grid: { xs: 12, xl: 6 },
                  options: [{ value: 'TEXT', label: t('text') }, { value: 'NUMBER', label: t('number') }, {
                    value: 'FLOAT',
                    label: t('float'),
                  }, { value: 'MULTILINE', label: t('multiline') }],
                  required: true,
                },
                {
                  type: 'text',
                  name: 'unit',
                  tooltip: t('unitTooltip'),
                  grid: { xs: 12, sm: 6, lg: 4, xl: 3 },
                  options: [{ value: undefined, label: t('units:none') }, {
                    value: 'metres',
                    label: t('units:metres'),
                  }, { value: 'pieces', label: t('units:pieces') }],
                },
              ],
            },
          ],
        },
      ],
    },
  ]), [t])
  const linkStack = useMemo(() => ([
    { to: '/administration/siteMeasuringTemplates', text: t('application:siteMeasuringTemplates') },
    { text: item?.isNew ? t('application:new') : `${item?.name}` },
  ]), [t, item])
  const formManager = useManagedForm(item)
  const { values } = formManager
  const { open, handleOpen, handleClose } = useOpenState()
  return (
    <>
      <SiteMeasuringPreview open={open} handleClose={handleClose} values={values} />
      <EditView
        {...formManager}
        loading={!item}
        linkStack={linkStack}
        elements={elements}
        actions={actions}
        item={item}
        saveView={{
        hideDraft: true,
        customActions: (
          <Grid item xs={12} sm={2}>
            <Button variant="contained" color="info" fullWidth onClick={handleOpen}>{t('generic:preview')}</Button>

          </Grid>
        ),
      }}
        t={t}
      />
    </>
  )
}
export function Edit() {
  const { siteMeasuringTemplateId } = useParams() as { siteMeasuringTemplateId: string }
  const item = useSiteMeasuringTemplate(siteMeasuringTemplateId)
  return (
    <Editor item={item} />
  )
}
export function New() {
  const item = new SiteMeasuringTemplateModel()
  return (
    <Editor item={item} />
  )
}
