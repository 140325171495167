import { useTranslation } from 'react-i18next'
import { useOpenScaffoldInquiries } from 'features/scaffoldInquiries'
import OverviewView from 'framework/components/BaseRestViews/OverviewView'
import { Stack } from '@mui/material'
import { ScaffoldAgentPhase, ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { useOverviewData } from 'ui/views/ScaffoldInquiries/OverviewHelper'
import { useScaffoldMetaFromMode } from 'ui/views/ScaffoldInquiries/helper'
import { useUserHasAllPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'
import { openScaffoldTableConfig } from 'ui/views/ScaffoldInquiries/Table/openScaffoldTableConfig'
import ButtonLink from '../../../framework/components/ButtonLink'

function ScaffoldInquiries({ data }: {data:ScaffoldInquiryModel[]}) {
  const { t } = useTranslation('scaffolds')
  const { columns, scaffolderPermission, gdkPermission } = useOverviewData(data)
  const { name, linkStack } = useScaffoldMetaFromMode('New', t)
  const canCreateNewScaffoldInquiry = useUserHasAllPermissions([PermissionModel.PLACE_ORDER_SCAFFOLD])
  return (
    <OverviewView<ScaffoldInquiryModel>
      data={data || []}
      columns={columns as any}
      hideNew={!canCreateNewScaffoldInquiry}
      linkStack={linkStack}
      name={t(`application:${name}`).toString()}
      {...openScaffoldTableConfig}
    />
  )
}
export default function ScaffoldInquiriesOpen() {
  const data = useOpenScaffoldInquiries()
  return <ScaffoldInquiries data={data} />
}
