import { useDispatchParameterAction } from 'framework/lib/useDispatchAction'
import { createScaffoldSiteMeasuringPdf } from 'features/scaffoldInquiries'
import { useDefaultButtonEventHandler } from 'framework/lib/useDefaultEventHandler'
import AsyncActionButton from 'framework/components/AsyncActionButton'
import { SiteMeasuringState } from 'api/models/siteMeasuringModel'
import { IMappedData } from 'ui/views/SiteMeasurings/models/IMappedData'
import { useTranslation } from 'react-i18next'
import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'

interface IProps {
  disabled?: boolean
  scaffoldInquiry: ScaffoldInquiryModel
}
export default function DownloadSiteMeasuringPdf({ disabled, scaffoldInquiry }:IProps) {
  const siteMeasuringPdfDispatchAction = useDispatchParameterAction(createScaffoldSiteMeasuringPdf)
  const handleCreateSiteMeasuringPdf = useDefaultButtonEventHandler(siteMeasuringPdfDispatchAction)
  const { t } = useTranslation('siteMeasuring')
  return (
    <AsyncActionButton disabled={disabled} onClick={handleCreateSiteMeasuringPdf(scaffoldInquiry)}>
      {t('createSiteMeasuringPdf')}
    </AsyncActionButton>
  )
}
