'use client'

import Center from 'framework/components/Center'
import { useDataEffect, useManagedForm, ValidatedForm } from '@codeflix/mui-managed-form'
import { useTranslation } from 'react-i18next'
import { useCustomerOptions } from 'features/customers'
import { useNavigate } from 'react-router-dom'
import Grid2 from '@mui/material/Unstable_Grid2'
import { useEffect } from 'react'

export default function CustomerSelect() {
  const { values, formManagerProps } = useManagedForm({ customerId: undefined })
  const { t } = useTranslation('scaffolds')
  const customers = useCustomerOptions()
  const navigate = useNavigate()
  useEffect(() => {
    if (customers.length === 1) navigate(`./${customers[0].value}`)
  }, [customers])
  useDataEffect(() => {
    if (values.customerId) {
      navigate(`./${values.customerId}`)
    }
  }, [values.customerId])
  return (
    <Grid2 container justifyContent="center">
      <Grid2 xs={12} md={6}>
        <ValidatedForm
          {...formManagerProps}
          elements={[
            {
              type: 'autocomplete',
              name: 'customerId',
              options: customers,
              grid: {
                xs: 12,
              },
            },
          ]}
          t={t}
        />
      </Grid2>

    </Grid2>
  )
}
