/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */

import {
  MRT_RowData,
  MRT_TableInstance,
} from 'material-react-table'
import { Collapse, Alert, Button, Stack, Typography } from '@mui/material'
import { useOpenState } from '@codeflix/mui-managed-form'
import { useTranslation } from 'react-i18next'
import { Children, useCallback, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import FilterLabel from './FilterLabel'

interface IFilterViewProps<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>
}

const StyledAlert = styled(Alert)(() => ({
  // width: '100%',
  borderRadius: 0,
  '& .MuiAlert-icon': {
    alignItems: 'center',
  },
  '& .MuiAlert-action': {
    alignItems: 'center',
  },

}))

export default function FilterAlert<TData extends MRT_RowData>({
                                                                 table,
                                                               }: IFilterViewProps<TData>) {
  // const { open, handleClose } = useOpenState(true)
  const { t } = useTranslation('table')

  const [filtered, setFiltered] = useState<{[key:string]:boolean}>({})
  const handleSetFiltered = useCallback((key:string) => (isFiltered: boolean) => {
    setFiltered((prev) => ({ ...prev, [key]: isFiltered }))
  }, [setFiltered])
  const open = useMemo(() => Object.values(filtered).includes(true), [filtered])
  return (
    <Collapse orientation="vertical" in={open} collapsedSize={0}>
      <StyledAlert severity="info" onClose={() => { table.resetColumnFilters(true) }} >
        <Stack spacing={1} direction="row" alignItems="center" useFlexGap flexWrap="wrap">
          <Typography variant="inherit">{ t('filterText')}</Typography>
          {
            table.getLeafHeaders().map((header) => (
              <FilterLabel<TData> key={header.id} table={table} header={header} onFilteredChange={handleSetFiltered(header.id)} />
            ))
          }
        </Stack>

      </StyledAlert>

    </Collapse>
  )
}
