import PermissionModel from 'models/PermissionModel'
import { CustomRoute } from 'models/IRoute'
import BaseRestRoutes from 'framework/components/BaseRestViews/BaseRestRoutes'
import React from 'react'
import { lazySiteMeasuring, lazySiteMeasuringInspection } from 'ui/views/lazyFetchAllViews'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import { Edit, New, ViewArchive } from './Edit'
import Overview from './Overview'
import { ScaffoldInquiriesArchive, ScaffoldInquiriesCurrent } from './CurrentOverview'
import AttachmentRoutes from './Attachments/routes'
import massEditorRoutes from './MassEditor/routes'

const routes: CustomRoute[] = BaseRestRoutes(
  'scaffoldInquiries',
  'scaffoldInquiryId',
  (<Overview />),
  (<Edit mode="New" />),
  (<New />),

  { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_MY_CUSTOMER] },
  [
    ...AttachmentRoutes('New'),

  ],
  [
    { prop: 'customers' },
    { prop: 'projects' },
    { prop: 'buildings' },
    { prop: 'operationUnits' },
    { prop: 'users' },
    { prop: 'scaffolders' },
    { prop: 'scaffoldInquiries', fetch: 'always' },
    lazySiteMeasuring,
    lazySiteMeasuringInspection,
  ],
  [
  {
    prop: 'scaffoldInquiriesAttachments',
    nested: [{ prop: 'data', key: 'scaffoldInquiryId' }],
    fetch: 'always' },
  ],
)

const routes2:CustomRoute[] = BaseRestRoutes(
  'scaffolds',
  'scaffoldInquiryId',
  (<ScaffoldInquiriesCurrent />),
  (<Edit mode="Current" />),
  (<New />),
  { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_MY_CUSTOMER] },
  [
    ...AttachmentRoutes('Current'),
  ],
  [
      { prop: 'customers' },
      { prop: 'projects' },
      { prop: 'buildings' },
      { prop: 'operationUnits' },
      { prop: 'users' },
      { prop: 'scaffolders' },
      { prop: 'scaffoldInquiries', fetch: 'always' },
    lazySiteMeasuring,
    lazySiteMeasuringInspection,
  ],
  [
    {
      prop: 'scaffoldInquiriesAttachments',
      nested: [{ prop: 'data', key: 'scaffoldInquiryId' }],
      fetch: 'always' },
  ],
)

const archiveRoutes = BaseRestRoutes(
  'scaffolds/archive/:year',
  'scaffoldInquiryId',
  (<ScaffoldInquiriesArchive />),
  (<ViewArchive />),
  (<></>),
  { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_MY_CUSTOMER] },
  [
    ...AttachmentRoutes('Archive'),
  ],
  [
    { prop: 'customers' },
    { prop: 'projects' },
    { prop: 'buildings' },
    { prop: 'operationUnits' },
    { prop: 'users' },
    { prop: 'scaffolders' },
    { prop: 'scaffoldInquiries',
      fetch: 'always',
      fetchPolicy: 'lazy',
    },
    { prop: 'scaffoldInquiries',
      mode: 'archive',
      fetch: 'always',
      nested: [{ prop: 'archive', key: 'year' }],
    },
    lazySiteMeasuring,
    lazySiteMeasuringInspection,
  ],
  [
    {
      prop: 'scaffoldInquiriesAttachments',
      nested: [{ prop: 'data', key: 'scaffoldInquiryId' }],
      fetch: 'always' },
  ],
)
export default routes.concat(routes2).concat(archiveRoutes).concat(massEditorRoutes)
