import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useCustomer } from 'features/customers'
import { useParams } from 'react-router'
import { useBuildingsByCustomerIdOptions } from 'features/buildings'
import { actions, useOperationUnitByPath } from 'features/operationUnits'
import EditView from '../../../../../framework/components/BaseRestViews/EditView'
import { OperationUnitModel } from '../../../../../api/models/operationUnitModel'

const grid = { xs: 12, sm: 6, md: 3 }
const smallGrid = { xs: 6, sm: 3, md: 2 }

export default function Editor({ item }: {item?:OperationUnitModel}) {
  const { customerId, operationUnitId } = useParams() as { customerId: string, operationUnitId: string }
  const customer = useCustomer(customerId)
  const buildingOptions = useBuildingsByCustomerIdOptions(customerId)
  const { t } = useTranslation('customers')
  const elements = useMemo(() => ([
    {
      type: 'text',
      name: 'name',
    },
    {
      type: 'autocomplete',
      name: 'buildings',
      label: t('application:buildings'),
      multi: true,
      options: buildingOptions,
    },
  ]), [t, buildingOptions])
  const linkStack = useMemo(() => ([
    { to: '/administration/customers', text: t('application:customers') },
    { to: `/administration/customers/${customer?.id}`, text: `${customer?.name}` },
    { to: `/administration/customers/${customer?.id}/operationUnits`, text: t('application:operationUnits') },
    { text: `${item?.name}` },
  ]), [t, customer, item])
  return (
    <EditView
      loading={!customer || !item}
      linkStack={linkStack}
      item={item}
      elements={elements}
      actions={actions}
      t={t}
      saveView={{
        hideDraft: true,
      }}
    />
  )
}

export function Edit() {
  const item = useOperationUnitByPath()

  return (
    <Editor item={item} />
  )
}
export function New() {
  const { customerId } = useParams() as { customerId: string }
  const item = new OperationUnitModel(customerId)
  return (
    <Editor item={item} />
  )
}
