import { PermissionOperation } from 'framework/auth/permissionOperation'
import PermissionModel from 'models/PermissionModel'
import ScaffoldInquirySpreadsheetEditor from 'ui/views/ScaffoldInquiries/MassEditor/ScaffoldInquirySpreadsheetEditor'
import { lazySiteMeasuring, lazySiteMeasuringInspection } from 'ui/views/lazyFetchAllViews'
import React from 'react'
import { CustomRoute } from 'models/IRoute'
import CustomerSelect from 'ui/views/ScaffoldInquiries/MassEditor/index'

export default [{
  path: 'multiScaffoldInquiriesEditor',

  children: [
    {
      index: true,
      authorized: true,
      requirePermissions: { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_MY_CUSTOMER, PermissionModel.EDIT_MY_SCAFFOLD_REQUESTS] },
      element: <CustomerSelect />,
      fetch: [
        { prop: 'customers' },
        { prop: 'projects' },
        { prop: 'buildings' },
        { prop: 'operationUnits' },
        { prop: 'users' },
        { prop: 'scaffolders' },
        { prop: 'scaffoldInquiries', fetch: 'always' },
        { prop: 'scaffoldInquiriesDraft', fetch: 'always', fetchPolicy: 'blocking' },
        lazySiteMeasuring,
        lazySiteMeasuringInspection,
      ],
    },
    {
      path: ':customerId',
      authorized: true,
      requirePermissions: { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_MY_CUSTOMER] },
      element: <ScaffoldInquirySpreadsheetEditor />,
      fetch: [
        { prop: 'customers' },
        { prop: 'projects' },
        { prop: 'buildings' },
        { prop: 'operationUnits' },
        { prop: 'users' },
        { prop: 'scaffolders' },
        { prop: 'scaffoldInquiries', fetch: 'always' },
        { prop: 'scaffoldInquiriesDraft', fetch: 'always', fetchPolicy: 'blocking' },
        lazySiteMeasuring,
        lazySiteMeasuringInspection,
      ],
    },
  ],
}] as CustomRoute[]
