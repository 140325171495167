import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import authReducer, { getToken } from 'features/auth'
import dataProtection from 'features/dataProtection'
import notifications from 'features/notifications'
import user, { fetchData as fetchUser } from 'features/user'
import users from 'features/users'
import customers from 'features/customers'
import projects from 'features/projects'
import buildings from 'features/buildings'
import operationUnits from 'features/operationUnits'
import scaffoldInquiries from 'features/scaffoldInquiries'
import scaffoldInquiriesDraft from 'features/scaffoldInquiriesDraft'
import scaffolders from 'features/scaffolders'
import siteMeasurings from 'features/siteMeasurings'
import siteMeasuringTemplates from 'features/siteMeasuringTemplates'
import siteMeasuringInspections from 'features/siteMeasuringInspections'
import scaffoldInquiriesAttachments from 'features/scaffoldInquiriesAttachments'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dataProtection,
    notifications,
    user,
    users,
    customers,
    projects,
    buildings,
    operationUnits,
    scaffoldInquiries,
    scaffoldInquiriesAttachments,
    scaffolders,
    siteMeasurings,
    siteMeasuringTemplates,
    siteMeasuringInspections,
    scaffoldInquiriesDraft,
  },
})
const token = getToken()
if (token) {
  store.dispatch(fetchUser())
}
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = Promise<any>|void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;
