import { useCallback, useState } from 'react'

export enum AsyncHandlerLoadingState {
  Initial,
  Loading,
  Failed,
  Success,
}
export const useAsyncHandler = (effect?: () => Promise<boolean>):[AsyncHandlerLoadingState, ()=>void] => {
  const [loadingState, setLoadingState] = useState<AsyncHandlerLoadingState>(AsyncHandlerLoadingState.Initial)
  const handler = useCallback(() => {
    if (typeof effect === 'function') {
      setLoadingState(AsyncHandlerLoadingState.Loading)
      effect().then((result: boolean) => {
        setLoadingState(result ? AsyncHandlerLoadingState.Success : AsyncHandlerLoadingState.Failed)
      })
    }
  }, [effect, setLoadingState])
  return [
    loadingState,
    handler,
  ]
}
