import { Backdrop } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { useSnackbar } from 'notistack'
import { styled } from '@mui/system'
import { useAppDispatch } from 'app/hooks'
import { requestResetPassword } from 'features/user'
import ButtonLink from 'framework/components/ButtonLink'
import { ManagedForm } from '@codeflix/mui-managed-form'
import LoginFormsWrapper from './LoginFormsWrapper'

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}))

export default function ResetPasswordRequestForm() {
  const { t } = useTranslation('login')
  const navigate = useNavigate()
  const appDispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (values: any) => {
    setLoading(true)
    const success = appDispatch(requestResetPassword(values.email))
    setLoading(false)
    if (success) {
      navigate('/')
    }
  }, [appDispatch, navigate])

  return (
    <LoginFormsWrapper backdropOpen={loading}>

      <ManagedForm
        initial={{ email: '' }}
            // value={values}
            // editMode
        elements={[
              {
                type: 'email',
                name: 'email',
                required: true,
                helperText: (<ButtonLink size="small" to="/" variant="text">{t('backToLogin')}</ButtonLink>),
              },
              {
                type: 'submit',
                name: 'requestReset',
                variant: 'contained',
              },
            ]}
        onSubmit={onSubmit}
        t={t}
      />
    </LoginFormsWrapper>
  )
}
