import { ScaffoldAgentPhase, ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { TFunction } from 'i18next'
import { InquiriesModeProps } from 'ui/views/ScaffoldInquiries/InquiriesMode'
import { useUserHasAllPermissions, useUserHasPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import { useAnchorElementOpenState } from 'lib/stateHelper'
import { IconButton, Menu } from '@mui/material'
import { DotsVerticalCircleOutline as MenuIcon } from 'mdi-material-ui'
import { ScaffoldCertificateAction } from 'ui/views/ScaffoldInquiries/EditActions/ScaffoldCertificateAction'
import { CancelAction } from 'ui/views/ScaffoldInquiries/EditActions/CancelAction'
import { CloneAction } from 'ui/views/ScaffoldInquiries/EditActions/CloneAction'
import { RevertCancellationDate } from 'ui/views/ScaffoldInquiries/EditActions/RevertCancellationDate'

export function FullySignedEditActions({ item, mode, t }: {
  item: ScaffoldInquiryModel | undefined,
  t: TFunction
} & InquiriesModeProps) {
  // const { t } = useTranslation('scaffolds')
  const isScaffolder = useUserHasAllPermissions([PermissionModel.CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST])
  // const isGdk = useUserHasAllPermissions([PermissionModel.EDIT_ALL_CUSTOMERS])
  const canRevertCancellation = useUserHasAllPermissions([PermissionModel.REVERT_CANCELED_DATE])
  const allowEdit = useUserHasPermissions({
    operation: PermissionOperation.OR,
    permissions: [PermissionModel.EDIT_ALL_CUSTOMERS, PermissionModel.EDIT_ALL_SCAFFOLD_REQUESTS_MY_CUSTOMER],
  })
  // const allowAdminDelete = useUserHasAllPermissions([PermissionModel.DELETE_ALL_SCAFFOLDS])
  const downloadCertificatePermission = useUserHasAllPermissions([PermissionModel.GET_SCAFFOLD_CERTIFICATE])
  const { open, anchorElement, handleClose, handleClick } = useAnchorElementOpenState()
  if (!item || (!allowEdit && !downloadCertificatePermission && !isScaffolder)) return (null)
  if (item.agentPhase !== ScaffoldAgentPhase.FULLY_SIGNED) return (null)
  return (
    <>
      <IconButton onClick={handleClick}><MenuIcon fontSize="large" /></IconButton>
      <Menu anchorEl={anchorElement} open={open} onClose={handleClose}>
        {downloadCertificatePermission && <ScaffoldCertificateAction item={item} t={t} mode="menuItem" />}
        {allowEdit && <CancelAction disabled={Boolean(item.canceledToDate)} mode="cancel" item={item} t={t} />}
        {allowEdit && <CloneAction item={item} t={t} mode="clone" />}
        {allowEdit && <CloneAction item={item} t={t} mode="convert" />}
        {isScaffolder && <CancelAction disabled={Boolean(item.deconstructionDate)} mode="deconstruct" item={item} t={t} />}
        {isScaffolder && <CancelAction disabled={Boolean(item.constructionDate)} mode="construct" item={item} t={t} />}
        {canRevertCancellation && <RevertCancellationDate item={item} t={t} />}
        {canRevertCancellation && <RevertCancellationDate item={item} t={t} />}
      </Menu>
    </>
  )
}
