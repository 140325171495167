import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useCustomerByPath } from 'features/customers'
import { useParams } from 'react-router'
import { actions, useProjectByPath } from 'features/projects'
import EditView from 'framework/components/BaseRestViews/EditView'
import { ProjectModel } from '../../../../../api/models/projectModel'

const bigGrid = { xs: 12, sm: 6 }
const grid = { xs: 12, sm: 6, md: 3 }
const smallGrid = { xs: 6, sm: 3, md: 2 }

function Editor({ item }: {item?: ProjectModel}) {
  const customer = useCustomerByPath()
  const { t } = useTranslation('projects')
  const { t: tGeneric } = useTranslation('generic')

  const elements = useMemo(() => ([
    {
      type: 'text',
      required: true,
      grid: bigGrid,
      name: 'name',
    },
    {
      type: 'text',
      grid: bigGrid,
      name: 'defaultOrderNr',
    },
    {
      type: 'checkbox',
      grid: smallGrid,
      name: 'archived',
      label: tGeneric('archived'),
      // disabled: true,
    },
  ]), [])
  const linkStack = useMemo(() => ([
    { to: '/administration/customers', text: t('application:customers') },
    { to: `/administration/customers/${customer?.id}`, text: `${customer?.name}` },
    { to: `/administration/customers/${customer?.id}/projects`, text: t('application:projects') },
    { text: item?.isNew ? t('application:new') : `${item?.name}` },
  ]), [t, customer, item])
  return (
    <EditView
      loading={!customer || !item}
      linkStack={linkStack}
      item={item}
      elements={elements}
      actions={actions}
      saveView={{
        hideDraft: true,
        // enableArchive: !item?.isNew,
      }}
      t={t}
    />
  )
}
export function Edit() {
  const item = useProjectByPath('projectId')
  return (
    <Editor item={item} />
  )
}
export function New() {
  const { customerId } = useParams() as { customerId: string }
  const item = new ProjectModel(customerId)
  return (
    <Editor item={item} />
  )
}
