import { IProps } from 'ui/views/ScaffoldInquiries/EditActions/IProps'
import { useDefaultMenuItemEventHandlerDispatchParameterAction } from 'framework/lib/useDispatchAction'
import { createScaffoldCertificate } from 'features/scaffoldInquiries'
import { useCallback, useMemo, useState } from 'react'
import { useOpenState } from '@codeflix/mui-managed-form'
import { Button, CircularProgress, ListItemIcon, Stack, styled, Typography } from '@mui/material'
import { PopupActionMenuItem } from 'framework/components/PopupActions/PopupActionMenuItem'
import { useAppDispatch } from 'app/hooks'
import PopupActionButton from 'framework/components/PopupActions/PopupActionButton'

const Loading = styled(CircularProgress)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}))
export function ScaffoldCertificateAction({ item, t, mode, disabled }: IProps & {disabled?: boolean, mode: 'menuItem'|'button'}) {
  const appDispatch = useAppDispatch()
  // const createScaffoldCertificateHandler = useDefaultMenuItemEventHandlerDispatchParameterAction(createScaffoldCertificate)
  const [loading, setLoading] = useState<boolean>(false)
  const openState = useOpenState(false)
  const handleClick = useCallback((attachments: boolean) => async (event:React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    openState.handleClose()
    setLoading(true)
    try { await appDispatch(createScaffoldCertificate(item, attachments)) } catch (e) { console.error(e) }
    setLoading(false)
  }, [appDispatch, item, openState])
  const Component = useMemo(() => (mode === 'button' ? PopupActionButton : PopupActionMenuItem), [mode])
  return (
    <Component
      t={t}
      label={(
        <>
          {loading && (
            <ListItemIcon>
              <Loading variant="indeterminate" color="primary" size={20} />
            </ListItemIcon>
          ) }
          <div>
            {t('createCertificate')}
          </div>
        </>
)}
      disabled={loading || disabled}
      openState={openState}
    >
      <Stack spacing={2}>
        <Button fullWidth variant="contained" onClick={handleClick(false)}>
          {t('withoutAttachments')}
        </Button>
        <Button fullWidth variant="contained" onClick={handleClick(true)}>
          {t('withAttachments')}
        </Button>
      </Stack>

    </Component>
  )
}
