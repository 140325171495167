import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { actions, useScaffolder } from 'features/scaffolders'
import { useParams } from 'react-router'
import EditView from 'framework/components/BaseRestViews/EditView'
import { ScaffoldState } from '../../../../api/models/scaffoldModel'
import { ScaffolderModel } from '../../../../api/models/scaffolderModel'

const grid = { xs: 12, sm: 6, md: 3 }
const smallGrid = { xs: 6, sm: 3, md: 2 }

const ScaffoldStates = Object.keys(ScaffoldState)
function Editor({ item }: {item?: ScaffolderModel}) {
  const { t } = useTranslation('scaffolders')
  const elements = useMemo(() => ([
    {
      type: 'text',
      name: 'name',
    },
  ]), [])
  const linkStack = useMemo(() => ([
    { to: '/administration/scaffolders', text: t('application:scaffolders') },
    { text: item?.isNew ? t('application:new') : `${item?.name}` },
  ]), [t, item])
  return (
    <EditView
      loading={!item}
      linkStack={linkStack}
      elements={elements}
      actions={actions}
      item={item}
      t={t}
    />
  )
}
export function Edit() {
  const { scaffolderId } = useParams() as { scaffolderId: string }
  const item = useScaffolder(scaffolderId)
  return (
    <Editor item={item} />
  )
}
export function New() {
  const item = new ScaffolderModel()
  return (
    <Editor item={item} />
  )
}
