import { IPermissionRequirement } from 'framework/auth/IPermissionRequirement'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import PermissionModel from 'models/PermissionModel'

export const isOnlyCustomerPermissionRequirement: IPermissionRequirement = {
  operation: PermissionOperation.AND,
  permissions: [
    PermissionModel.EDIT_PROJECTS_MY_CUSTOMER,
    {
      operation: PermissionOperation.NOT,
      permissions: [PermissionModel.VIEW_ALL_CUSTOMERS],
    },
  ],
}
