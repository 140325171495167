import { ScaffoldAgentPhase, ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { TFunction } from 'i18next'
import { InquiriesModeProps } from 'ui/views/ScaffoldInquiries/InquiriesMode'
import { useAnchorElementOpenState } from 'lib/stateHelper'
import { Button, IconButton, Menu, Typography } from '@mui/material'
import { DotsVerticalCircleOutline as MenuIcon } from 'mdi-material-ui'
import { useOpenState } from '@codeflix/mui-managed-form'
import { AsyncStoreActionButton } from 'framework/components/BaseRestViews/AsyncStoreActionButton'
import { restartClientWorkflow } from 'features/scaffoldInquiries'
import { useTranslation } from 'react-i18next'
import { PopupActionMenuItem } from 'framework/components/PopupActions/PopupActionMenuItem'

function RevertProcessToOrderUser({ item, mode, t }: {
  item: ScaffoldInquiryModel | undefined,
  t: TFunction
} & InquiriesModeProps) {
  const openState = useOpenState()
  const { t: tGeneric } = useTranslation('generic')
  return (
    <PopupActionMenuItem
      t={t}
      label={t('restartWorkflow')}
      // disabled={disabled}
      openState={openState}
      actions={(
        <>
          <Button fullWidth variant="outlined" onClick={openState.handleClose}>{tGeneric('generic:abort')}</Button>
          <AsyncStoreActionButton fullWidth variant="outlined" color="success" action={restartClientWorkflow} item={item!} name={tGeneric('generic:confirm')} />
        </>
      )}
    >
      <Typography variant="subtitle1">{t('restartWorkflowDescription')}</Typography>
    </PopupActionMenuItem>
  )
}
export function OrderUserActions({ item, mode, t }: {
  item: ScaffoldInquiryModel | undefined,
  t: TFunction
} & InquiriesModeProps) {
  const { open, anchorElement, handleClose, handleClick } = useAnchorElementOpenState()
  console.log('item', item)
  if (item?.clientWorkflowPhase == -1 || item?.agentPhase !== ScaffoldAgentPhase.CLIENT) return (null)
  return (
    <>
      <IconButton onClick={handleClick}><MenuIcon fontSize="large" /></IconButton>
      <Menu anchorEl={anchorElement} open={open} onClose={handleClose} >
        <RevertProcessToOrderUser t={t} item={item} mode={mode} />
      </Menu>
    </>
  )
}
