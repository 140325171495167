import { DateTime } from 'luxon'

export const addDays = (date: string, endDate: string, days: number) => {
  if (!date || !days) return { date: undefined, days: 0 }
  const parsedDate = DateTime.fromISO(date)
  const newDate = parsedDate.plus({ days }) // offsett adding
  const now = endDate ? DateTime.fromISO(endDate) : DateTime.now()
  const duration = newDate.diff(now, 'days')
  return {
    date: newDate.toISODate(),
    days: Math.floor(duration.days),
  }
  // return now.diff(parsedDate, 'days')
}
