import Plot, { PlotParams } from 'react-plotly.js'
import * as Plotly from 'plotly.js'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import plotlyLocales from 'plotly.js-locales/index'
import styled from '@emotion/styled'
import { createRef, useId, useMemo, useRef } from 'react'
import { useSize } from 'lib/resizeObserver'
import { green, grey, blueGrey } from '@mui/material/colors'
import { yncoris } from 'theme'

interface IChartProps extends Omit<PlotParams, 'layout'> {
  // data: Plotly.Data[],
  // config: Partial<Plotly.Config>,
  title?: string
  layout?: Partial<Plotly.Layout>
}

const FullSize = styled.div`
    width: 100%;
    //height: 100%;
`
export default function LocalizedPlot({
                                        config,
                                        title,
                                        layout,
                                        ...rest
                                      }: IChartProps) {
  const { i18n } = useTranslation('plot')
  const ref = createRef<HTMLDivElement>()
  // const size = useMemo(() => ref.current?.getBoundingClientRect(), [ref])
  const size = useSize(ref)
  const id = useId()
  const plotConfig = useMemo(() => ({
    autosizable: true,
    displaylogo: false,
    locales: plotlyLocales,
    locale: i18n.language,
    toImageButtonOptions: {
      filename: title,
    },
    ...config,
  }), [config, i18n.language, title])
  const plotlyLayout = useMemo<Partial<Plotly.Layout>>(() => ({
    title,
    titlefont: {
      size: 18,
    },
    font: {
      family: '"Roboto", "Helvetica", "Arial", sans-serif',
      size: 16,
      // weight: undefined,
    },
    autosize: true,
    width: size?.width,
    colorway: [yncoris.petrol, yncoris.lightblue, yncoris.red, yncoris.darkred, yncoris.orange, yncoris.green],
    // height: size?.height,
    ...layout,
  }), [layout, title, size?.width])
  return (
    <FullSize ref={ref}>
      <Plot
        divId={id}
        config={plotConfig}
        {...rest}
        layout={plotlyLayout}
      />
    </FullSize>
  )
}
