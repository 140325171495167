import { PropsWithChildren } from 'react'
import { Grid } from '@mui/material'
import styled from '@emotion/styled'

export default function Center({ children }: PropsWithChildren) {
  return (
    <Grid container justifyContent="center">
      <Grid item >
        {children}
      </Grid>
    </Grid>
  )
}

const StyledCenter = styled.div`
  .center {
    margin: auto;
    padding: 10px;
  }
`
export function CenterCSS({ children }:PropsWithChildren) {
  return <StyledCenter>{children}</StyledCenter>
}
