import { RestModel } from 'app/RestModel'
import {
  SiteMeasuringAccountedRequestModel,
  SiteMeasuringAgentPhase, SiteMeasuringCancelledRequestModel,
  SiteMeasuringModel,
} from 'api/models/siteMeasuringModel'
import PermissionModel from 'models/PermissionModel'
import { api } from 'app/apiClient'
import { getQueryForArchive } from 'api/helper/archiveQuery'
import { KyResponse } from 'ky'

const clearPayload = (payload: SiteMeasuringModel) => ({
  ...payload,
  csOrderId: undefined,
  customerSiteMeasuringTabs: undefined,
  gdkComment: undefined,
  acceptedByGdk: undefined,
  scaffolderApproval: undefined,
  gdkApproval: undefined,
  scaffoldInquiry: undefined,
  isAccounted: undefined,
  isCancelled: undefined,
  additionalProperties: undefined,
})
class ConstructionInquiryRestModel extends RestModel<SiteMeasuringModel> {
  path = 'siteMeasuring'

  getItems = async (parameters: any, permissions: string[]|undefined):Promise<SiteMeasuringModel[]> => {
    const query = getQueryForArchive(parameters)

    const fetchAll = permissions?.includes(PermissionModel.VIEW_ALL_SITE_MEASURING)
    if (fetchAll) return super.getItems(parameters, permissions, true, query)
    if (permissions?.includes(PermissionModel.VIEW_MY_SITE_MEASURING)) return api.get(`${this.path}/myscaffolds${query}`).json()
    if (permissions?.includes(PermissionModel.VIEW_MY_SITE_MEASURING_FOR_INQUIRIES)) return api.get(`${this.path}/myinquiries${query}`).json()
    throw new Error('NoPermission')
    // return []
  }

  async updateItem(payload: SiteMeasuringModel): Promise<unknown> {
    console.log('update', payload, clearPayload(payload))
    if (payload.agentPhase === SiteMeasuringAgentPhase.GDK) {
      return api.put(`${this.path}/GdkReview`, { json: clearPayload(payload) }).json()
    }
    return super.updateItem(clearPayload(payload))
  }

  async setAccounted(payload: SiteMeasuringAccountedRequestModel) : Promise<SiteMeasuringModel[]> {
    return api.post(`${this.path}/accounted`, { json: payload }).json()
  }

  async setCancelled(payload: SiteMeasuringCancelledRequestModel) : Promise<SiteMeasuringModel[]> {
    return api.post(`${this.path}/cancelled`, { json: payload }).json()
  }

  async setGdkNoteAdditionalProperties(payload: { id: string, gdkNote: string }) : Promise<KyResponse> {
    return api.post(`${this.path}/setGdkNoteInAdditionalProperties`, { json: payload })
  }

  async createItemServer(payload: { scaffoldInquiryId:string }): Promise<unknown> {
    return api.post(this.path, {
      json: {
        ...payload,
        id: undefined,
      },
    }).json()
  }
}

export default new ConstructionInquiryRestModel()
