import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import de from './de'

const debug = process.env.REACT_APP_DEBUG_I18NEXT === 'true'
const initOptions = {
  fallbackLng: 'de-DE',
  debug,
  resources: { 'de-DE': de },
  lng: 'de-DE',
}

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init(initOptions).then((r:any) => console.log(r()))

export default i18n
