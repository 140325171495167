import { SiteMeasuringModel } from 'api/models/siteMeasuringModel'
import AsyncActionButton from 'framework/components/AsyncActionButton'
import { useCallback, MouseEvent } from 'react'
import {
  useDefaultButtonEventHandlerDispatchParameterAction,
  useDispatchParameterAction,
} from 'framework/lib/useDispatchAction'
import { setSiteMeasuringAsAccounted } from 'features/siteMeasurings'
import { useTranslation } from 'react-i18next'
import { useUserHasAllPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'

export default function SiteMeasuringAccountedAction({ item }: {item: SiteMeasuringModel}) {
  const handleCreateSiteMeasuringPdf = useDefaultButtonEventHandlerDispatchParameterAction(setSiteMeasuringAsAccounted)
  const { t } = useTranslation('siteMeasuring')
  const hasPermission = useUserHasAllPermissions([PermissionModel.MARK_SITE_MEASURING_ACCOUNTED])
  /* const handleClick = useCallback(async (event:MouseEvent<HTMLButtonElement>) => {
    await (event)
  }, [item, handleCreateSiteMeasuringPdf]) */
  if (!hasPermission) return (null)
  return (
    <AsyncActionButton variant="contained" onClick={handleCreateSiteMeasuringPdf([item], !item.isAccounted)}>{t(item.isAccounted ? t('cancelAccounted') : t('setAccounted'))}</AsyncActionButton>
  )
}
